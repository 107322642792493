import { Box, Tooltip } from '@mui/material';
import { ChatContext } from 'context/ChatContext';
import { ChatFileAttachment } from 'contracts/chat/ChatFileAttachment';
import React, { useContext } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList as List } from 'react-window';

export interface ChatFileAttachmentsRendererProps {
    attachments: ChatFileAttachment[];
}

const AttachmentRenderer = ({ index, style, data }: {
    index: number,
    style: React.CSSProperties,
    data: ChatFileAttachment[]
}) => {
    const { setAttachmentInFocus } = useContext(ChatContext);

    const attachment = data[index];

    const chatAttachmentUrl = (path: string) => {
        return `${process.env.REACT_APP_CHAT_SERVER_URL}/${path}`;
    };

    const handleClick = () => {
        setAttachmentInFocus(attachment);
    };

    return (
        <Tooltip
            title={'Click to Enlarge'}
            placement="top-start"
            arrow
        >
            <Box
                onClick={handleClick}
                component="img"
                className="box-media"
                sx={style}
                alt={`${index}`}
                src={chatAttachmentUrl(attachment.path)}
            />
        </Tooltip>
    );
};

export const ChatFileAttachmentsRenderer: React.FC<ChatFileAttachmentsRendererProps> = ({ attachments }) => {
    const itemSize = 200;
    const containerWidth = ((itemSize * attachments.length) > 480) ? 480 : itemSize * attachments.length;
    return attachments.length > 0 ? (
        <div style={{
            width: `${containerWidth}px`,
        }}>
            <AutoSizer
                disableHeight={true}
            >
                {
                    ({ width }) => (
                        <List<ChatFileAttachment[]>
                            layout="horizontal"
                            height={210}
                            itemKey={
                                (index, data) => data[index]._id
                            }
                            width={width}
                            itemSize={itemSize}
                            itemCount={attachments.length}
                            itemData={attachments}
                        >
                            {AttachmentRenderer}
                        </List>
                    )
                }
            </AutoSizer>
        </div>
    ) : null;
};
