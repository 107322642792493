import { IRolePermissionsResponse } from 'contracts/requests/IRolePermissionsResponse';
import { spotdifApi } from '.';
import { IUpdateRoleModulePermissionsRequest } from 'contracts/requests/IUpdateRoleModulePermissionsRequest';
import { IAddNewRoleResponse } from 'contracts/requests/IAddNewRoleResponse';
import { IGetAllModulesRequest } from 'contracts/requests/IGetAllModulesRequest';
import { IAddNewRoleRequest } from 'contracts/requests/IAddNewRoleRequest';
import { TAG_GET_PERMISSION_LIST } from 'contracts/spotDiffAPITags';

const rolePermissionApi = spotdifApi.injectEndpoints({
    endpoints: (builder) => ({
        rolePermissionsList: builder.query<IRolePermissionsResponse, void>({
            providesTags: [TAG_GET_PERMISSION_LIST],
            query: () => {
                return {
                    url: `/roles?page=1&perPage=20`,
                    method: 'get',
                };
            },
        }),
        updateRoleModulePermissions: builder.mutation<IRolePermissionsResponse, IUpdateRoleModulePermissionsRequest>({
            invalidatesTags: [TAG_GET_PERMISSION_LIST],
            query: ({ roleId, body }) => {
                return {
                    url: `/roles/${roleId}`,
                    method: 'post',
                    body,
                };
            },
        }),
        addNewRole: builder.mutation<IAddNewRoleRequest, IAddNewRoleResponse>({
            invalidatesTags: [TAG_GET_PERMISSION_LIST],
            query: (body) => {
                return {
                    url: `/roles`,
                    method: 'post',
                    body,
                };
            },
        }),
        getAllModules: builder.query<IGetAllModulesRequest, void>({
            providesTags: [TAG_GET_PERMISSION_LIST],
            query: () => {
                return {
                    url: `/roles/modules`,
                    method: 'get',
                };
            },
        }),
    }),
});

export const {
    useRolePermissionsListQuery,
    useUpdateRoleModulePermissionsMutation,
    useAddNewRoleMutation,
    useGetAllModulesQuery,
} = rolePermissionApi;

export default rolePermissionApi;
