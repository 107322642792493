import { jsonObject, jsonMember } from 'typedjson';

@jsonObject()
export class ColumnsNameDataObject {
    @jsonMember(String)
    defaultColumn: string;

    @jsonMember(String)
    renamedColumn: string;
}
