import UserAccountTabs from 'components/user-account-details/UserAccountTabs';
import React from 'react';

import { Helmet } from 'react-helmet';

const UserAccount: React.FC = () => {
    return (
        <>
            <Helmet>
                <title>Account</title>
            </Helmet>

            <UserAccountTabs />
        </>
    );
};

export default UserAccount;
