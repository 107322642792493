import MDInput from 'components/themed/MDInput';
import React from 'react';

interface ImageInputProps {
    imageUrl: string;
    altText: string;
    labelImage: string;
    nameImage: string;
    dataNameImage: string;
    placeholderImage: string;
    idImage: string;
    valueImage: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    touchedImage: boolean;
    errorsImage: string;
    labelAlt: string;
    nameAlt: string;
    dataNameAlt: string;
    placeholderAlt: string;
    idAlt: string;
    valueAlt: string;
    touchedAlt: boolean;
    errorsAlt: string;
    isDisabled?: boolean;
}

const ImageInput: React.FC<ImageInputProps> = ({
    imageUrl,
    altText,
    labelImage,
    nameImage,
    dataNameImage,
    placeholderImage,
    idImage,
    valueImage,
    onChange,
    touchedImage,
    errorsImage,
    labelAlt,
    nameAlt,
    dataNameAlt,
    placeholderAlt,
    idAlt,
    valueAlt,
    touchedAlt,
    errorsAlt,
    isDisabled,
}) => {
    return (
        <>
            <div className="bg-gray h-200 w-50 br-2 d-flex justify-content-center align-items-center p-2">
                <img className="h-150" src={imageUrl} alt={altText} />
            </div>
            <div className="banner-block">
                <MDInput
                    type="text"
                    label={labelImage}
                    name={nameImage}
                    data-name={dataNameImage}
                    placeholder={placeholderImage}
                    id={idImage}
                    value={valueImage}
                    onChange={onChange}
                    error={touchedImage && Boolean(errorsImage)}
                    helperText={touchedImage && errorsImage}
                    disabled={isDisabled}
                />
                <MDInput
                    type="text"
                    label={labelAlt}
                    name={nameAlt}
                    data-name={dataNameAlt}
                    placeholder={placeholderAlt}
                    id={idAlt}
                    value={valueAlt}
                    onChange={onChange}
                    error={touchedAlt && Boolean(errorsAlt)}
                    helperText={touchedAlt && errorsAlt}
                    disabled={isDisabled}
                />
            </div>
        </>
    );
};

export default ImageInput;
