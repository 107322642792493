import AuthorisedUsage from 'components/app-permission/AuthorisedUsage';
import { MODULE, PERMISSIONS } from 'components/app-permission/permission';
import { AuthenticationKeys } from 'components/AuthenticationKeys';
import MDInput from 'components/themed/MDInput';
import { Form, FormikProvider, useFormik } from 'formik';

import useAuthentication from 'hooks/useAuthentication';
import { useRegexPatterns } from 'hooks/useRegexPatterns';
import { useSnackbar } from 'notistack';
import React, { Fragment, useMemo, useState } from 'react';
import { useUpdateUserProfileMutation, useUserProfileDataQuery } from 'redux/services/spotdif/user';
import * as Yup from 'yup';

function AdminProfile() {
    const { enqueueSnackbar } = useSnackbar();

    const [loader, setloader] = useState(false);
    const { user } = useAuthentication();
    const { data } = useUserProfileDataQuery(user?._id);
    const [updateUserProfileTrigger] = useUpdateUserProfileMutation();

    const { emailRegex } = useRegexPatterns();

    const initState = useMemo(() => {
        return {
            firstName: data?.data?.firstName || '',
            lastName: data?.data?.lastName || '',
            email: data?.data?.email || '',
        };
    }, [data]);

    const validationSchema = Yup.object().shape({
        firstName: Yup.string().min(3, 'minimum 3 characters').required('First Name is required').trim(),
        lastName: Yup.string().min(3, 'minimum 3 characters length').required('Last Name is required').trim(),
        email: Yup.string()
            .email('Enter a valid email')
            .required('Email is required')
            .matches(emailRegex, 'Enter a valid email'),
    });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: initState,
        validationSchema,
        onSubmit: async (values) => {
            await updateUserProfileTrigger({ ...values, _id: user?._id })
                .unwrap()
                .then((res) => {
                    enqueueSnackbar('Profile Updated Successfully', { variant: 'success', key: 'profile' });
                })
                .catch((err) => {
                    console.log(err);
                    enqueueSnackbar('Something went wrong', { variant: 'error', key: 'profile' });
                });
        },
    });

    return (
        <Fragment>
            <AuthorisedUsage module={MODULE.PROFILE} permission={PERMISSIONS.READ}>
                <div className="app_tabs-content w-tab-content ">
                    <div data-w-tab="Tab 1" className="app_tab-wrap w-tab-pane  w--tab-active ">
                        <div className="app_tab-inner">
                            <div className="app_tab-layout">
                                <div className="app_card">
                                    <div className="w-form">
                                        <FormikProvider value={formik}>
                                            <Form onSubmit={formik.handleSubmit}>
                                                <div className="modal_wrap">
                                                    <div className="modal_title">
                                                        <h3 className="heading-style-card">Personal Details</h3>
                                                    </div>

                                                    <div className="modal_col-3">
                                                        <div className="modal_input-wrap">
                                                            <MDInput
                                                                type="text"
                                                                label="First Name"
                                                                name="firstName"
                                                                data-name="First Name"
                                                                placeholder="e.g Tom"
                                                                id="First-Name"
                                                                // isBordered={true}
                                                                value={formik.values.firstName}
                                                                onChange={formik.handleChange}
                                                                error={
                                                                    formik.touched.firstName &&
                                                                    Boolean(formik.errors.firstName)
                                                                }
                                                                helperText={
                                                                    formik.touched.firstName && formik.errors.firstName
                                                                }
                                                            />
                                                        </div>

                                                        <div className="modal_input-wrap">
                                                            <MDInput
                                                                label="Last Name"
                                                                type="text"
                                                                name="lastName"
                                                                data-name="Last Name"
                                                                placeholder="e.g Sutton"
                                                                id="Last-Name"
                                                                value={formik.values.lastName}
                                                                onChange={formik.handleChange}
                                                                error={
                                                                    formik.touched.lastName &&
                                                                    Boolean(formik.errors.lastName)
                                                                }
                                                                helperText={
                                                                    formik.touched.lastName && formik.errors.lastName
                                                                }
                                                            />
                                                        </div>

                                                        <div className="modal_input-wrap">
                                                            <MDInput
                                                                label="Email"
                                                                type="email"
                                                                name="email"
                                                                data-name="Email"
                                                                placeholder="e.g user@email.com"
                                                                id="Email"
                                                                value={formik.values.email}
                                                                onChange={formik.handleChange}
                                                                error={
                                                                    formik.touched.email && Boolean(formik.errors.email)
                                                                }
                                                                helperText={formik.touched.email && formik.errors.email}
                                                            />
                                                        </div>
                                                    </div>

                                                    <AuthorisedUsage
                                                        module={MODULE.PROFILE}
                                                        permission={PERMISSIONS.UPDATE}
                                                    >
                                                        <div className="buttons_component">
                                                            <div className="button-group">
                                                                <button type="submit" className="button w-button">
                                                                    {loader ? 'Loading...' : 'Save'}
                                                                </button>
                                                                <button
                                                                    type="button"
                                                                    onClick={() => {
                                                                        formik.resetForm();
                                                                    }}
                                                                    className="button button-outline-gray w-button"
                                                                >
                                                                    Reset
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </AuthorisedUsage>
                                                </div>
                                            </Form>
                                        </FormikProvider>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="mt-2">
                    <AuthenticationKeys />
                </div>
            </AuthorisedUsage>
        </Fragment>
    );
}

export default AdminProfile;
