import WithDateRangePicker from 'components/analytics/date-ranged-data';
import VerticalBarChart from 'components/shared/charts/VerticalBarChart';
import { IChartData } from 'contracts/view-models/IChartData';
import { IDateRangedChartProps } from 'contracts/view-models/IDateRangedChartProps';
import React, { useMemo } from 'react';
import { useGetLeftDashboardChartDataQuery } from 'redux/services/spotdif/stats';

interface ILeadCostChartProps extends IDateRangedChartProps {}

const LeadCostChart: React.FC<ILeadCostChartProps> = ({ range }) => {
    const { data: dataFromApi } = useGetLeftDashboardChartDataQuery({
        start: range.startDate.toISOString(),
        end: range.endDate.toISOString(),
    });

    const leadCostData = useMemo<IChartData>(() => {
        let dates = [];
        let data = [];

        dataFromApi?.data?.forEach((item) => {
            dates.push(item?.date);
            data.push(item?.total);
        });

        return {
            labels: dates,

            datasets: [
                {
                    label: 'Total',
                    color: 'dark',
                    data: data,
                },
            ],
        };
    }, [dataFromApi]);

    return (
        <>
            <VerticalBarChart title="Lead Cost" description="" chart={leadCostData} />
        </>
    );
};

export default WithDateRangePicker<ILeadCostChartProps>(LeadCostChart);
