import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import ErrorIcon from '@mui/icons-material/Error';

// import { Form, Formik, FormikProvider, useFormik } from "formik";
import StarIcon from '@mui/icons-material/Star';
import { Autocomplete, Card, Grid, InputAdornment, Switch } from '@mui/material';
import BasicModal from 'components/core/Modal';
import MDBox from 'components/themed/MDBox';
import MDButton from 'components/themed/MDButton';
import MDInput from 'components/themed/MDInput';
import MDTypography from 'components/themed/MDTypography';

import { UserBase } from 'contracts/spotdif/UserBase';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useSaveClientDetailsMutation, useUpdateCreditMutation } from 'redux/services/spotdif/clients';
import { copyUrl } from 'utils/text-helpers';

import PostalCodeModal from './PostalCodeModal/PostalCodeModal';

const entries = ['auto charge', 'weekly payment', 'add credits manually'];

interface IFormValues {
    dynamicFields: {
        fieldName?: string;
        value?: string | number | any;
        leadValue?: boolean;
        fieldType?: string;
        position?: string;
    }[];
}

interface ClientDetailFormProps {
    userDetail: UserBase;
    setExpanded: (obj: {}) => void;
    spanlength: number;
    availableAccessManagers: UserBase[];
}

const ClientDetailForm: React.FC<ClientDetailFormProps> = ({
    userDetail,
    setExpanded,
    spanlength,
    availableAccessManagers,
}) => {
    const [saveClientDetails, { isLoading, error, isError, isSuccess }] = useSaveClientDetailsMutation();
    const [updateCreditAmount, {}] = useUpdateCreditMutation();
    const { enqueueSnackbar } = useSnackbar();

    // if(isSuccess){
    //     enqueueSnackbar('Changed Successfully', {variant: 'success', key: 'update-client'});
    // }

    const [userData, setUserData] = useState<any>(userDetail);

    const [isLeadEqual, setIsLeadEqual] = useState(false);

    const [changedData, setChangedData] = useState({});
    const [clientPostCode, setClientPostCode] = useState<any>({
        ...userDetail?.userLeadsDetailsId?.postCodeTargettingList,
    });
    const [checkLeadCost, setCheckLeadCost] = useState(userDetail?.isLeadCostCheck);
    const [active, setActive] = useState(userDetail.isActive);

    const [archive, setArchive] = useState(userDetail.isArchived);

    const [openPostCodeForm, setOpenPostCodeForm] = useState(false);

    const handleOpenPostCodeModal = () => setOpenPostCodeForm(true);
    const handleClosePostCodeModal = () => setOpenPostCodeForm(false);

    const [creditsData, setCreditsData] = useState(userDetail?.credits);
    const [creditError, setCreditError] = useState('');
    const fullName = userData?.firstName;
    //  + ' ' + userData?.lastName

    const handleName = (e, key) => {
        setChangedData((prev) => {
            return {
                ...prev,
                [key]: e.target.value,
            };
        });
        setUserData((prev) => {
            return {
                ...prev,
                [key]: e.target.value,
            };
        });
    };
    const handleBusinessChange = (e, key) => {
        setChangedData((prev) => {
            return {
                ...prev,
                [key]: e.target.value,
            };
        });

        setUserData((prevState) => ({
            ...prevState,
            businessDetailsId: {
                ...prevState.businessDetailsId,
                [key]: e.target.value,
            },
        }));
    };

    // handle credit amount change
    const handleCreditChange = (e) => {
        // check lead cost for credit amount
        if (e.target.value % userData?.leadCost !== 0 && !(e.target.value < userData?.leadCost * 5)) {
            setCreditError(`Value must be a multiple of ${userData?.leadCost}`);
            setIsLeadEqual(true);
            return;
        } else if (e.target.value < userData?.leadCost * 5) {
            setCreditError(`Minimum amount should be 5 times lead cost ${userData?.leadCost * 5}`);
            setIsLeadEqual(true);
            return;
        } else {
            setIsLeadEqual(false);
        }
        setCreditsData(e.target.value);
    };

    const handleChange = (e, key) => {
        setChangedData((prev) => {
            return {
                ...prev,
                [key]: e.target.value,
            };
        });

        setUserData((prev) => {
            return {
                ...prev,
                [key]: e.target.value,
            };
        });
    };

    const handleLeadsDetailsChange = (e, key) => {
        setChangedData((prev) => {
            return {
                ...prev,
                [key]: e.target.value,
            };
        });

        setUserData((prevState) => ({
            ...prevState,
            userLeadsDetailsId: {
                ...prevState.userLeadsDetailsId,
                [key]: e.target.value,
            },
        }));
    };

    const handlePaymentMethodChange = (e, value, key) => {
        setChangedData((prev) => {
            return {
                ...prev,
                [key]: value,
            };
        });
        setUserData((prev) => {
            return {
                ...prev,
                // paymentMethod: value,
                [key]: value,
            };
        });
    };

    const handleUserNotes = (e, key) => {
        setChangedData((prev) => {
            return {
                ...prev,
                [key]: e.target.value,
            };
        });
        setUserData((prev) => {
            return {
                ...prev,
                userNotes: e.target.value,
            };
        });
    };

    const handleArchive = (e, isArchived, key) => {
        setChangedData((prev) => {
            return {
                ...prev,
                [key]: !isArchived,
            };
        });
        setArchive(!isArchived);
        setUserData((prev) => {
            return {
                ...prev,
                isArchived: !isArchived,
            };
        });
    };

    // UPDATE CREDIT AMOUNT
    const updateCredit = (e) => {
        let updatedData = {
            credits: creditsData,
        };
        updateCreditAmount({ userId: userDetail._id, ...updatedData })
            .unwrap()
            .then((payload) => {
                enqueueSnackbar('Changed Successfully', { variant: 'success', key: 'lead' });
                setExpanded({});
            })
            .catch(({ data }) => {
                enqueueSnackbar(data?.error?.message ?? 'Something went wrong.', { variant: 'error', key: 'lead' });
            });
    };

    const updateClientData = (e) => {
        // delete userData.credits;
        // delete userData.businessDetailsId;
        // delete userData.userLeadsDetailsId;
        let checkLeadCostChecked = { isLeadCostCheck: checkLeadCost };
        let updatedData = {
            ...changedData,
            ...checkLeadCostChecked,
        };
        // mutate({ id: userId, data: updatedData });
        saveClientDetails({ _id: userDetail._id, ...updatedData })
            .unwrap()
            .then((payload) => {
                enqueueSnackbar('Changed Successfully', { variant: 'success', key: 'lead' });
                setExpanded({});
            })
            .catch(({ data }) => {
                enqueueSnackbar(data?.error?.message ?? 'Something went wrong.', { variant: 'error', key: 'lead' });
            });
    };

    const handleActiveUserStatus = (e) => {
        setActive(e);
        // mutate({ id: userId, data: { isActive: e.target.checked } });
        // saveClientDetails({ _id: userDetail._id, isActive: e.target.checked })
        // mutate({ id: userId, data: { isActive: e.target.checked } });
        setChangedData((prev) => {
            return {
                ...prev,
                isActive: e.target.checked,
            };
        });
    };

    return (
        <>
            <td colSpan={12} className="details-row-expand">
                <div className="row-expand">
                    <>
                        <MDBox
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                // backgroundColor: "#f4f4fa",
                            }}
                        >
                            <MDBox
                                mt={1}
                                mb={2}
                                ml={2}
                                mr={2}
                                sx={{
                                    width: 'auto',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    overflowY: 'auto',
                                    xs: { flexDirection: 'column' },
                                }}
                            >
                                <Grid
                                    // ref={widthRef}
                                    container
                                    // xs={8}
                                    // md={8}
                                    xl={6}
                                    mr={4}
                                    maxHeight="355px"
                                    sx={{ display: 'flex', justifyContent: 'space-between' }}
                                >
                                    {/* <MDTypography
              style={{
                color: '#000',
                marginTop: '1rem',
                fontWeight: 400,
              }}
              variant="p3"
            >
              Client details
            </MDTypography> */}

                                    <MDBox
                                        width="90%"
                                        height="100%"
                                        sx={{ marginTop: 1.5 }}
                                        className="expand-left-box"
                                    >
                                        <MDBox className="expand-heading-top">Client details</MDBox>
                                        <MDBox className="expand-details-field">
                                            <p>Buyer ID</p>
                                            <MDBox className="expand-field-value">
                                                <MDTypography variant="p3">{userData?.buyerId ?? `-`}</MDTypography>
                                            </MDBox>
                                        </MDBox>

                                        <MDBox className="expand-details-field">
                                            <p>Company name</p>
                                            <MDBox className="expand-field-value">
                                                <MDInput
                                                    fullWidth
                                                    type="text"
                                                    variant="standard"
                                                    // defaultValue={userData?.businessDetailsId?.businessName ?? ""}
                                                    value={userData?.businessDetailsId?.businessName ?? ''}
                                                    id={'businessName'}
                                                    autoComplete={'businessName'}
                                                    inputProps={{ maxLength: 80 }}
                                                    // textAlign="end"
                                                    onChange={(e) => {
                                                        handleBusinessChange(e, 'businessName');
                                                    }}
                                                    // TODO:
                                                    // borderColorForExpand="#d2d6da"
                                                    fontFamily="EuclidLight"
                                                    fontSize="16px"
                                                />
                                            </MDBox>
                                        </MDBox>

                                        <MDBox className="expand-details-field">
                                            <p>Client name</p>
                                            <MDBox className="expand-field-value">
                                                <MDInput
                                                    fullWidth
                                                    type="text"
                                                    variant="standard"
                                                    // TODO:
                                                    // defaultValue={fullName}
                                                    // value={userData?.firstName}
                                                    // value={userData?.fullName}
                                                    value={userData?.firstName + ' ' + userData?.lastName}
                                                    id={'firstName'}
                                                    autoComplete={'firstName'}
                                                    onChange={(e) => handleName(e, 'firstName')}
                                                    disabled
                                                    // disabledColor="#e5e7ea"
                                                    // disabled={editDetail ? false : true}
                                                    // disableUnderline={true}
                                                />

                                                {/* <MDInput
                    fullWidth
                    type="text"
                    variant="standard"
                    defaultValue={userData?.firstName + ' ' + userData?.lastName}
                    // value={userData?.firstName + ' ' + userData?.lastName}
                    id={'firstName'}
                    autoComplete={'firstName'}
                    textAlign="end"
                    onChange={(e) => {
                      handleChange(e, 'firstName');
                    }}
                    borderColorForExpand="#d2d6da"
                    fontFamily="EuclidLight"
                    fontSize="16px"
                    // disabledColor="#e5e7ea"
                    // disabled={editDetail ? false : true}
                    // disableUnderline={true}
                  /> */}
                                                {/* <MDTypography variant="p3">
                    {userData?.firstName ?? `-`}
                  </MDTypography> */}
                                            </MDBox>
                                        </MDBox>

                                        <MDBox className="expand-details-field">
                                            <p>Email</p>
                                            <MDBox className="expand-field-value">
                                                <MDInput
                                                    disabled
                                                    fullWidth
                                                    type="email"
                                                    variant="standard"
                                                    // TODO:
                                                    // defaultValue={userData?.email}
                                                    value={userData?.email}
                                                    id={'email'}
                                                    autoComplete={'email'}
                                                    onChange={(e) => {
                                                        handleChange(e, 'email');
                                                    }}
                                                />
                                                <ContentCopyOutlinedIcon
                                                    className="copy-content-icon"
                                                    onClick={() => copyUrl(userData?.email, 'Email Copied')}
                                                />
                                            </MDBox>
                                        </MDBox>

                                        <MDBox className="expand-details-field">
                                            <p>Address</p>
                                            <MDBox className="expand-field-value">
                                                <MDInput
                                                    fullWidth
                                                    type="text"
                                                    variant="standard"
                                                    // defaultValue={userData?.businessDetailsId?.businessAddress ?? ""}
                                                    value={userData?.businessDetailsId?.businessAddress ?? ''}
                                                    id={'businessAddress'}
                                                    autoComplete={'businessAddress'}
                                                    // textAlign="end"
                                                    onChange={(e) => {
                                                        handleBusinessChange(e, 'businessAddress');
                                                    }}
                                                    // TODO:
                                                    // borderColorForExpand="#d2d6da"
                                                    fontFamily="EuclidLight"
                                                    fontSize="16px"
                                                />
                                                {/* <MDTypography variant="p3">
                    {userData?.businessDetailsId?.businessAddress ?? `-`}
                  </MDTypography> */}
                                            </MDBox>
                                        </MDBox>

                                        <MDBox className="expand-details-field">
                                            <p>Business Phone Number</p>
                                            <MDBox className="expand-field-value">
                                                <MDInput
                                                    fullWidth
                                                    type="text"
                                                    variant="standard"
                                                    // defaultValue={
                                                    //     userData?.businessDetailsId?.businessSalesNumber ?? ""
                                                    // }
                                                    value={userData?.businessDetailsId?.businessSalesNumber ?? ''}
                                                    id={'businessSalesNumber'}
                                                    autoComplete={'businessSalesNumber'}
                                                    // textAlign="end"
                                                    onChange={(e) => {
                                                        if (!/(^[0-9]+$|^$)/.test(e.target.value)) {
                                                            return;
                                                        }
                                                        handleBusinessChange(e, 'businessSalesNumber');
                                                    }}
                                                    // TODO:
                                                    // borderColorForExpand="#d2d6da"
                                                    fontFamily="EuclidLight"
                                                    fontSize="16px"
                                                />
                                                {/* <MDTypography variant="p3">
                    {userData?.businessDetailsId?.businessSalesNumber ?? `-`}
                  </MDTypography> */}
                                            </MDBox>
                                        </MDBox>

                                        <MDBox className="expand-details-field">
                                            <p>City</p>
                                            <MDBox className="expand-field-value">
                                                <MDInput
                                                    fullWidth
                                                    type="text"
                                                    variant="standard"
                                                    // TODO:
                                                    // defaultValue={userData?.businessDetailsId?.businessCity ?? ""}
                                                    value={userData?.businessDetailsId?.businessCity ?? ''}
                                                    id={'businessCity'}
                                                    autoComplete={'businessCity'}
                                                    onChange={(e) => {
                                                        handleBusinessChange(e, 'businessCity');
                                                    }}
                                                />
                                                {/* <MDTypography variant="p3">
                    {userData?.businessDetailsId?.businesCity ?? `-`}
                  </MDTypography> */}
                                            </MDBox>
                                        </MDBox>

                                        {/* <MDBox width="100%" height="35px" sx={{ display: 'flex', alignItems: 'center' }}>
                                            <MDBox
                                                width="50%"
                                                height="100%"
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center'
                                                }}
                                            >
                                                <MDTypography style={{ color: 'gray' }} variant="p3">
                                                    Country
                                                </MDTypography>
                                            </MDBox>
                                            <MDBox
                                                width="50%"
                                                height="100%"
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'flex-end'
                                                }}
                                            >
                                                <MDInput
                                                    fullWidth
                                                    type="text"
                                                    variant="standard"
                                                    defaultValue={userData?.businessDetailsId?.businessCountry}
                                                    value={userData?.businessDetailsId?.businessCountry}
                                                    id={'businessCountry'}
                                                    autoComplete={'businessCountry'}
                                                    textAlign="end"
                                                    onChange={(e) => {
                                                        handleBusinessChange(e, 'businessCountry');
                                                    }}
                                                    borderColorForExpand="#d2d6da"
                                                    fontFamily="EuclidLight"
                                                    fontSize="16px"
                                                />

                                            </MDBox>
                                        </MDBox> */}

                                        <MDBox className="expand-details-field">
                                            <p>Business Postcode</p>
                                            <MDBox className="expand-field-value">
                                                <MDInput
                                                    fullWidth
                                                    type="text"
                                                    variant="standard"
                                                    // TODO:
                                                    // defaultValue={userData?.businessDetailsId?.businessPostCode ?? ""}
                                                    value={userData?.businessDetailsId?.businessPostCode ?? ''}
                                                    id={'businessPostCode'}
                                                    autoComplete={'businessPostCode'}
                                                    onChange={(e) => {
                                                        handleBusinessChange(e, 'businessPostCode');
                                                    }}
                                                />
                                                {/* <MDTypography variant="p3">
                    {userData?.businessDetailsId?.businessPostCode ?? `-`}
                  </MDTypography> */}
                                            </MDBox>
                                        </MDBox>

                                        {/* <MDBox width="100%" height="35px" sx={{ display: 'flex', alignItems: 'center' }}>
                                            <MDBox
                                                width="50%"
                                                height="100%"
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center'
                                                }}
                                            >
                                                <MDTypography style={{ color: 'gray' }} variant="p3">
                                                    Zapier Url
                                                </MDTypography>
                                            </MDBox>
                                            <MDBox
                                                width="50%"
                                                height="100%"
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'flex-end'
                                                }}
                                            >
                                                <MDInput
                                                    fullWidth
                                                    type="text"
                                                    variant="standard"
                                                    defaultValue={userData?.userLeadsDetailsId?.zapierUrl}
                                                    value={userData?.userLeadsDetailsId?.zapierUrl}
                                                    id={'zapierUrl'}
                                                    autoComplete={'zapierUrl'}
                                                    textAlign="end"
                                                    onChange={(e) => {
                                                        handleLeadsDetailsChange(e, 'zapierUrl');
                                                    }}
                                                    borderColorForExpand="#d2d6da"
                                                    fontFamily="EuclidLight"
                                                    fontSize="16px"
                                                />
                                            </MDBox>
                                        </MDBox> */}
                                        <MDBox className="expand-details-field">
                                            <p>Lead API Url</p>
                                            <MDBox className="expand-field-value">
                                                <MDInput
                                                    fullWidth
                                                    type="text"
                                                    variant="standard"
                                                    // defaultValue={`${process.env.REACT_APP_API_SERVER}/api/v1/leads/${userData?.buyerId}`}
                                                    value={
                                                        `${process.env.REACT_APP_API_SERVER}/api/v1/leads/${userData?.buyerId}`.slice(
                                                            0,
                                                            22,
                                                        ) + '.....'
                                                    }
                                                    id={'zapierUrl'}
                                                    autoComplete={'zapierUrl'}
                                                    // onChange={(e) => {
                                                    //     handleLeadsDetailsChange(e, 'zapierUrl');
                                                    // }}
                                                    disabled
                                                />
                                                <ContentCopyOutlinedIcon
                                                    className="copy-content-icon"
                                                    onClick={() =>
                                                        copyUrl(
                                                            `${process.env.REACT_APP_API_SERVER}/api/v1/leads/${userData?.buyerId}`,
                                                            'URL Copied',
                                                        )
                                                    }
                                                />
                                            </MDBox>
                                        </MDBox>

                                        <MDBox className="expand-details-field">
                                            <p>Webhook / Zapier Url</p>
                                            <MDBox className="expand-field-value">
                                                <MDInput
                                                    fullWidth
                                                    type="text"
                                                    variant="standard"
                                                    // TODO:
                                                    // defaultValue={userData?.userLeadsDetailsId?.zapierUrl ?? ""}
                                                    value={userData?.userLeadsDetailsId?.zapierUrl ?? ''}
                                                    id={'zapierUrl'}
                                                    autoComplete={'zapierUrl'}
                                                    onChange={(e) => {
                                                        handleLeadsDetailsChange(e, 'zapierUrl');
                                                    }}
                                                />
                                                {/* <MDTypography variant="p3">
                    {userData?.businessDetailsId?.businessCountry ?? `-`}
                  </MDTypography> */}
                                            </MDBox>
                                        </MDBox>

                                        <MDBox className="expand-details-field">
                                            <p>Daily lead volume</p>
                                            <MDBox className="expand-field-value">
                                                <MDInput
                                                    fullWidth
                                                    type="text"
                                                    variant="standard"
                                                    // TODO:
                                                    // defaultValue={userData?.userLeadsDetailsId?.daily ?? ""}
                                                    value={userData?.userLeadsDetailsId?.daily ?? ''}
                                                    id={'daily'}
                                                    autoComplete={'daily'}
                                                    onChange={(e) => {
                                                        if (!/(^[0-9]+$|^$)/.test(e.target.value)) {
                                                            return;
                                                        }
                                                        handleLeadsDetailsChange(e, 'daily');
                                                    }}
                                                />
                                                {/* <MDTypography variant="p3">
                    {userData?.businessDetailsId?.businessCountry ?? `-`}
                  </MDTypography> */}
                                            </MDBox>
                                        </MDBox>

                                        <MDBox className="expand-details-field">
                                            <p>Client Postcode</p>
                                            <MDBox className="expand-field-value">
                                                <MDInput
                                                    fullWidth
                                                    disabled
                                                    type="text"
                                                    variant="standard"
                                                    // TODO:
                                                    // defaultValue={
                                                    //     clientPostCode && Object.keys(clientPostCode).length > 0
                                                    //         ? Object.values(clientPostCode)
                                                    //               .map((item: any) => item.county)
                                                    //               .join(",")
                                                    //         : ""
                                                    // }
                                                    value={
                                                        clientPostCode && Object.keys(clientPostCode).length > 0
                                                            ? Object.values(clientPostCode)
                                                                  .map((item: any) => item.county)
                                                                  .join(',')
                                                            : ''
                                                    }
                                                    id={'daily'}
                                                    autoComplete={'daily'}
                                                    onChange={(e) => {
                                                        handleLeadsDetailsChange(e, 'daily');
                                                    }}
                                                />

                                                {clientPostCode && Object.keys(clientPostCode).length > 0 ? (
                                                    <ErrorIcon
                                                        className="copy-content-icon"
                                                        onClick={handleOpenPostCodeModal}
                                                    />
                                                ) : (
                                                    ''
                                                )}
                                            </MDBox>
                                        </MDBox>

                                        <MDBox className="expand-details-field">
                                            <p>Account Manager</p>
                                            <MDBox className="expand-field-value">
                                                <Autocomplete
                                                    id="finance"
                                                    fullWidth
                                                    isOptionEqualToValue={(option, val) => {
                                                        return option.label === val;
                                                    }}
                                                    data-name="Business Industry"
                                                    className="autocomplete-field-client"
                                                    // value={formik.values.prices}
                                                    value={userData?.accountName ?? userData?.accountManager}
                                                    // onChange={(e, value) =>
                                                    //     formik.setFieldValue("prices", value)
                                                    // }
                                                    onChange={(event, value) => {
                                                        if (value) {
                                                            handlePaymentMethodChange(
                                                                event,
                                                                value.value,
                                                                'accountManager',
                                                            );
                                                            setUserData((prev) => ({
                                                                ...prev,
                                                                accountName: value.label,
                                                            }));
                                                        }
                                                    }}
                                                    // onBlur={formik.handleBlur}
                                                    // options={[...entries]}
                                                    // options={availableAccessManagers.map((manager) => manager?.firstName )}
                                                    options={availableAccessManagers.map((manager) => ({
                                                        label: manager?.firstName,
                                                        value: manager?._id,
                                                    }))}
                                                    renderInput={(params) => (
                                                        <MDInput
                                                            {...params}
                                                            name="prices"
                                                            // value={formik.values.prices}
                                                            value={userData?.accountName ?? userData?.accountManager}
                                                        />
                                                    )}
                                                />
                                            </MDBox>
                                        </MDBox>
                                    </MDBox>
                                </Grid>

                                <Grid
                                    container
                                    // xs={6}
                                    // md={6}
                                    xl={6}
                                    pr={0}
                                    mr={2}
                                    height="fit-content"
                                >
                                    <MDBox
                                        width="90%"
                                        height="100%"
                                        sx={{ marginTop: 1.5 }}
                                        className="expand-right-box"
                                    >
                                        <Card
                                            sx={{
                                                width: '100%',
                                                height: '50px',
                                                px: 4,
                                                boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
                                                opacity: 0.5,
                                                display: 'flex',
                                                flexDirection: 'row',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                                mb: 1,
                                                mt: 1,
                                            }}
                                        >
                                            <p style={{ color: '#1de993', fontWeight: 'bold', fontSize: 14 }}>
                                                {' '}
                                                {active ? 'ACTIVE' : 'PAUSED'}
                                            </p>
                                            {/* <ConfigProvider
                                            theme={{
                                                token: {
                                                    colorPrimary: '#1de993'
                                                }
                                            }}
                                        >
                                            <AntSwitch defaultChecked={active} onChange={(e) => handleActiveUserStatus(e)} />
                                        </ConfigProvider> */}
                                            <Switch
                                                value={active}
                                                defaultChecked={active}
                                                onChange={(e) => handleActiveUserStatus(e)}
                                            />
                                        </Card>
                                        <Card className="expand-card">
                                            <MDBox className="expand-card-top">
                                                <p>Payment information </p>
                                                <div>
                                                    <MDTypography variant="p3">
                                                        £ {userDetail?.credits ?? 0} credit
                                                    </MDTypography>
                                                    <StarIcon fontSize="small" height={10} width={30} />
                                                </div>
                                            </MDBox>

                                            {/* <div className="expand-card-top creditamount">
                                                <MDInput
                                                    fullWidth
                                                    type="number"
                                                    variant="standard"
                                                    defaultValue={creditsData}
                                                    id={"creditAmount"}
                                                    autoComplete={"credits"}
                                                    onChange={(e) => {
                                                        handleCreditChange(e);
                                                    }}
                                                    error={isLeadEqual}
                                                    helperText={isLeadEqual && creditError}
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment
                                                                position="start"
                                                                className="input-adorement"
                                                            >
                                                                   <span>£</span>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />

                                                <MDButton
                                                    onClick={(e) => updateCredit(e)}
                                                    sx={{
                                                        marginLeft: "10px",
                                                    }}
                                                    className="button"
                                                    disabled={isLeadEqual}
                                                >
                                                    Add Credits
                                                </MDButton>
                                            </div> */}

                                            <MDBox className="expand-card-middle">
                                                <p>Leads Cost</p>
                                                <div className="toggle-button">
                                                    <div
                                                        onClick={() => setCheckLeadCost(false)}
                                                        className={
                                                            !checkLeadCost
                                                                ? 'customCheckBoxChecked'
                                                                : 'customCheckBoxUnchecked'
                                                        }
                                                    >
                                                        <p>Industry CPL</p>
                                                    </div>
                                                    <div
                                                        onClick={() => setCheckLeadCost(true)}
                                                        className={
                                                            !checkLeadCost
                                                                ? 'customCheckBoxUnchecked'
                                                                : 'customCheckBoxChecked'
                                                        }
                                                        style={{}}
                                                    >
                                                        <p>Custom CPL</p>
                                                    </div>
                                                </div>
                                            </MDBox>
                                            {checkLeadCost ? (
                                                <MDBox className="expand-card-middle">
                                                    <p>Custom CPL</p>
                                                    <MDBox className="expand-card-input">
                                                        <MDInput
                                                            fullWidth
                                                            type="number"
                                                            variant="standard"
                                                            // TODO:
                                                            // defaultValue={userData?.leadCost}
                                                            value={userData?.leadCost}
                                                            id={'leadCost'}
                                                            autoComplete={'leadCost'}
                                                            onChange={(e) => {
                                                                handleChange(e, 'leadCost');
                                                            }}
                                                            InputProps={{
                                                                startAdornment: (
                                                                    <InputAdornment
                                                                        position="start"
                                                                        className="input-adorement"
                                                                    >
                                                                        <span>£</span>
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                        />
                                                    </MDBox>
                                                </MDBox>
                                            ) : null}

                                            <MDBox className="expand-card-middle">
                                                <p>Autocharge amount</p>
                                                <MDBox className="expand-card-input">
                                                    <MDInput
                                                        fullWidth
                                                        type="number"
                                                        variant="standard"
                                                        // TODO:
                                                        // defaultValue={userData?.autoChargeAmount}
                                                        value={userData?.autoChargeAmount}
                                                        id={'autoChargeAmount'}
                                                        autoComplete={'autoChargeAmount'}
                                                        onChange={(e) => {
                                                            handleChange(e, 'autoChargeAmount');
                                                        }}
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment
                                                                    position="start"
                                                                    className="input-adorement"
                                                                >
                                                                    <span>£</span>
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                    />
                                                </MDBox>
                                            </MDBox>
                                            <MDBox className="expand-card-middle">
                                                <p>Business Industry</p>
                                                <MDBox className="expand-card-input">
                                                    <MDInput
                                                        fullWidth
                                                        type="text"
                                                        variant="standard"
                                                        // defaultValue={userData?.businessDetailsId?.businessIndustry}
                                                        value={userData?.businessDetailsId?.businessIndustry}
                                                        id={'businessIndustryId'}
                                                        autoComplete={'businessIndustryId'}
                                                        // textAlign="end"
                                                        disabled={true}
                                                        // TODO:
                                                        // borderColorForExpand="#d2d6da"
                                                        fontFamily="EuclidLight"
                                                        fontSize="16px"
                                                    />
                                                </MDBox>
                                            </MDBox>

                                            <MDBox className="expand-card-middle">
                                                <p>Payment Mode</p>
                                                <MDBox>
                                                    <Autocomplete
                                                        id="finance"
                                                        data-name="Business Industry"
                                                        className="autocomplete-field"
                                                        // value={formik.values.prices}
                                                        value={userData?.paymentMethod}
                                                        // onChange={(e, value) =>
                                                        //     formik.setFieldValue("prices", value)
                                                        // }
                                                        onChange={(event, value) =>
                                                            handlePaymentMethodChange(event, value, 'paymentMethod')
                                                        }
                                                        // onBlur={formik.handleBlur}
                                                        options={[...entries]}
                                                        renderInput={(params) => (
                                                            <MDInput
                                                                {...params}
                                                                name="prices"
                                                                // value={formik.values.prices}
                                                                value={userData?.paymentMethod}
                                                            />
                                                        )}
                                                    />
                                                </MDBox>
                                            </MDBox>
                                            <MDBox className="expand-card-middle">
                                                <p>Client Notes</p>
                                                <MDBox className="expand-card-input">
                                                    <MDInput
                                                        type="text"
                                                        placeholder="Client Notes"
                                                        // TODO:
                                                        // defaultValue={userData?.userNotes}
                                                        value={userData?.userNotes}
                                                        id="userNotes"
                                                        autoComplete="userNotes"
                                                        onChange={(e) => handleUserNotes(e, 'userNotes')}
                                                        fullWidth
                                                        className="note-textarea"
                                                        // borderColorForExpand="#ffffff"

                                                        // disabledColor="#ffffff"
                                                        // disabled={editDetail ? false : true}
                                                    />
                                                </MDBox>
                                            </MDBox>
                                            {/* <MDInput
                    type="text"
                    label=""
                    defaultValue={userData?.userNotes}
                    value={userData?.userNotes}
                    id="userNotes"
                    autoComplete="userNotes"
                    onChange={(e) => handleUserNotes(e, "userNotes")}
                    fullWidth
                    borderColorForExpand="#ffffff"
                    // disabledColor="#ffffff"
                    // disabled={editDetail ? false : true}
                  /> */}
                                        </Card>

                                        <input
                                            onClick={(e) => handleArchive(e, archive, 'isArchived')}
                                            type="submit"
                                            value={archive ? 'Unarchive This Client' : 'Archive This Client'}
                                            id="w-node-_0bea9dcc-b36c-bc0d-2d8a-91c15aa9a1ab-9e7c9e88"
                                            className="max-width-full w-100 button button-gray"
                                        />
                                    </MDBox>
                                </Grid>
                            </MDBox>

                            <Grid
                                // xs={12}
                                sx={{ background: 'transparent', mb: 2 }}
                            >
                                {' '}
                                <MDBox mt={4} mb={1} sx={{ display: 'flex', justifyContent: 'center', gap: 2 }}>
                                    <MDButton
                                        // variant="circular"
                                        onClick={(e) => updateClientData(e)}
                                        sx={{
                                            marginRight: '10px',
                                        }}
                                        className="button"
                                    >
                                        Save Changes
                                    </MDButton>
                                    <MDButton
                                        type="reset"
                                        className="button button-outline-gray"
                                        sx={{ textTransform: 'capitalize' }}
                                        onClick={() => {
                                            setActive(userDetail.isActive);
                                            setUserData(userDetail);
                                            setCheckLeadCost(userDetail?.isLeadCostCheck);
                                        }}
                                    >
                                        Discard Changes
                                    </MDButton>
                                </MDBox>
                            </Grid>
                        </MDBox>

                        <BasicModal
                            open={openPostCodeForm}
                            handleClose={handleClosePostCodeModal}
                            postalCodeModal={true}
                        >
                            <PostalCodeModal
                                handleCloseModal={handleClosePostCodeModal}
                                allPostalCodes={clientPostCode}
                            />
                        </BasicModal>
                    </>
                </div>
            </td>
        </>
    );
};

export default ClientDetailForm;
