import Checkbox from '@mui/material/Checkbox';
import { usePostalCodeContext } from 'context/PostalCode/CountyContext';
import { LeadDetails } from 'contracts/spotdif/LeadDetails';
import { useFormikContext } from 'formik';
import { useCountyData } from 'hooks/useCountyData';
import React, { useMemo } from 'react';
import CollapseCard from '../shared/collapseLayout';
import MDBox from '../themed/MDBox';
import MDTypography from '../themed/MDTypography';

interface IPostCodeCollapseProps {
    field: string;
}

const PostalCodeCollapse: React.FC<IPostCodeCollapseProps> = ({ field }) => {
    const { availableCountyPostalCodes } = useCountyData();

    const { currentCountyCode, updateCurrentCounty } = usePostalCodeContext();

    const { values, setFieldValue } = useFormikContext<Partial<LeadDetails>>();
    const selectedCounties = useMemo(() => values[field] || [], [values, field]);

    const collapseIndex = useMemo<number>(() => {
        return selectedCounties.findIndex(
            (singleCounty) => `${singleCounty.key}.${singleCounty.county}` === currentCountyCode,
        );
    }, [currentCountyCode, selectedCounties]);

    const updatePostalCodeInCounty = (e, county) => {
        let countyIndex = selectedCounties.findIndex((singleCounty) => singleCounty.county === county);

        if (countyIndex !== -1) {
            const selectedCounty = selectedCounties[countyIndex];
            if (e.target.checked && selectedCounty.postalCode.indexOf(e.target.value) === -1) {
                selectedCounty.postalCode.push(e.target.value);
            } else {
                selectedCounty.postalCode = selectedCounty.postalCode.filter((postcode) => postcode !== e.target.value);
            }
            setFieldValue(field, [...selectedCounties]);
        }
    };

    const removeSelectedCounty = (e, county, key) => {
        e.stopPropagation();
        setFieldValue(
            field,
            selectedCounties.filter((singleCounty) => singleCounty.county !== county || singleCounty.key !== key),
        );
    };

    const uncheckPostalCodesFromCounty = (e, county) => {
        e.stopPropagation();
        let countyIndex = selectedCounties.findIndex((singleCounty) => singleCounty.county === county);
        if (countyIndex !== -1) {
            selectedCounties[countyIndex].postalCode = [];
            setFieldValue(field, [...selectedCounties]);
        }
    };

    const checkAllPostalCodesForCountry = (e, county) => {
        e.stopPropagation();
        let countyIndex = selectedCounties.findIndex((singleCounty) => singleCounty.county === county);
        if (countyIndex !== -1) {
            if (countyIndex !== -1) {
                const selectedCountyCode = `${selectedCounties[countyIndex].key}.${selectedCounties[countyIndex].county}`;
                if (selectedCountyCode && availableCountyPostalCodes[`${selectedCountyCode}`]) {
                    selectedCounties[countyIndex].postalCode = availableCountyPostalCodes[`${selectedCountyCode}`];
                    setFieldValue(field, [...selectedCounties]);
                }
            }
        }
    };

    return !selectedCounties?.length ? (
        <>
            <MDBox
                sx={{
                    width: '100%',
                    height: '70%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <MDTypography variant="caption" fontWeight="medium" color="text" sx={{ fontSize: '16px' }}>
                    Select Postcodes through Map
                </MDTypography>
            </MDBox>
        </>
    ) : (
        <>
            {selectedCounties.map((singleCounty, index) => {
                return (
                    <CollapseCard
                        key={`${singleCounty.county}-${singleCounty.key}`}
                        title={singleCounty?.county}
                        open={collapseIndex === index}
                        removeCountyFromList={(e) => removeSelectedCounty(e, singleCounty?.county, singleCounty.key)}
                        uncheckPostalCodesFromCounty={(e) => uncheckPostalCodesFromCounty(e, singleCounty?.county)}
                        checkAllPostalCodesForCountry={(e) => checkAllPostalCodesForCountry(e, singleCounty?.county)}
                        onClick={() =>
                            updateCurrentCounty(
                                `${singleCounty.key}.${singleCounty?.county}` === currentCountyCode
                                    ? ''
                                    : `${singleCounty.key}.${singleCounty?.county}`,
                            )
                        }
                    >
                        <MDBox display="flex" flexWrap="wrap" className="postal-map-checkboxes">
                            {availableCountyPostalCodes[`${singleCounty.key}.${singleCounty.county}`]?.map(
                                (postcode) => {
                                    return (
                                        <MDBox
                                            key={`${singleCounty.county}-${postcode}`}
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                width: '84px',
                                            }}
                                        >
                                            <Checkbox
                                                checked={singleCounty.postalCode.includes(postcode)}
                                                value={postcode}
                                                onChange={(e) => updatePostalCodeInCounty(e, singleCounty?.county)}
                                            />
                                            <MDTypography
                                                variant="caption"
                                                fontWeight="medium"
                                                color="text"
                                                sx={{ fontSize: '14px' }}
                                            >
                                                {postcode}
                                            </MDTypography>
                                        </MDBox>
                                    );
                                },
                            )}
                        </MDBox>
                    </CollapseCard>
                );
            })}
        </>
    );
};

export default PostalCodeCollapse;
