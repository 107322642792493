import Grid from '@mui/material/Grid';
import RoomListItem from 'components/Inbox/RoomListItem';
import { ChatContext } from 'context/ChatContext';
import React, { useContext, useRef } from 'react';
import 'components/Inbox/style.scss';
import AutoSizer from 'react-virtualized-auto-sizer';
import { VariableSizeList as List } from 'react-window';

interface IVirtualizedListProps {}

const VirtualizedChatList: React.FC<IVirtualizedListProps> = () => {
    const { chatRooms, handleRoomListScroll } = useContext(ChatContext);

    const rowHeights = useRef({});
    const listRef = useRef(null);

    function getRoomHeight(index) {
        return rowHeights.current[index] + 8 || 82;
    }

    function setRoomHeight(index, size) {
        if (listRef.current) {
            listRef.current?.resetAfterIndex(0);
            rowHeights.current = { ...rowHeights.current, [index]: size };
        }
    }

    return (
        <div className="user-inner-01">
            <Grid container xs={12} md={12} sx={{ height: '700px' }} item className="sdjsdhjsdhdjshdjsdh">
                <AutoSizer>
                    {({ height, width }) => (
                        <List
                            height={height}
                            itemCount={chatRooms.length}
                            // itemSize={100}
                            itemSize={getRoomHeight}
                            width={width}
                            onScroll={handleRoomListScroll}
                            ref={listRef}
                            style={{
                                height: '950px',
                            }}
                        >
                            {({ index, style }) => {
                                const room = chatRooms[index];
                                return (
                                    <RoomListItem
                                        index={index}
                                        style={style}
                                        room={room}
                                        setRoomHeight={setRoomHeight}
                                    />
                                );
                            }}
                        </List>
                    )}
                </AutoSizer>
            </Grid>
        </div>
    );
};

export default VirtualizedChatList;
