import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import DeleteIcon from '@mui/icons-material/Delete';

import EditIcon from '@mui/icons-material/Edit';
import { Tooltip } from '@mui/material';
import { createColumnHelper } from '@tanstack/react-table';
import classNames from 'classnames';
import AuthorisedUsage from 'components/app-permission/AuthorisedUsage';
import { MODULE, PERMISSIONS } from 'components/app-permission/permission';
import BasicModal from 'components/core/Modal';
import AlertPopUp from 'components/shared/AlertPopUp';
import Pagination from 'components/shared/Pagination/Pagination';
import PrimarySwitch from 'components/shared/PrimarySwitch';
import SharedDataTable from 'components/shared/SharedTable/SharedDataTable';
import SpinningComponent from 'components/shared/SpinningComponent';
import TableHeaderV2 from 'components/shared/TableHeaderV2';
import StringFormatter from 'components/shared/Text/StringFormatter';
import { IUserAd } from 'contracts/requests/IGetUserAdsResponse';
import { IPartnersQuery } from 'contracts/requests/IPartnersQuery';
import { IPartnersAdsQuery } from 'contracts/spotdif/IGetPartnersAdsResponse';
import { enqueueSnackbar } from 'notistack';
import React, { useCallback, useMemo, useState } from 'react';
import { useGetAllIndustiesLeadsQuery } from 'redux/services/spotdif/industriesLeads';
import {
    useChangePartnersAdsStatusMutation,
    useDeletePartnersAdsMutation,
    useGetPartnersAdsQuery,
} from 'redux/services/spotdif/partners';
import { copyUrl, stringFormatter, timeFormatter } from 'utils/text-helpers';
import GenerateAddPopup from './GenerateAddPopup';

const partnerShipAdsResponsiveHeader = {
    title: 'Ads Title',
    industriesName: 'Business Industry',
    callToAction: 'URL',
    clickTotal: 'Total Click Count',
    targetReach: 'Max Click Count',
    description: 'Description',
    startDate: 'Start Date',
    endDate: 'End Date',
};

const PartnersTable: React.FC = () => {
    const [query, setQuery] = useState<IPartnersQuery>({
        search: '',
        perPage: 10,
        total: 0,
        pageCount: 0,
        page: 1,
        industry: [],
    });
    const [isPartnerModalOpen, setIsPartnerModalOpen] = useState(false);
    const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);
    const [openEditPopup, setOpenEditPopup] = useState(false);
    const [partnerAdsID, setPartnersAdsID] = useState('');

    const [currentPartnerAd, setCurrentPartnerAd] = useState<IUserAd>(null);

    const { partnerAdsData, partnersAdsLoading } = useGetPartnersAdsQuery(query, {
        selectFromResult: ({ data, isLoading }) => {
            return {
                partnerAdsData: data,
                partnersAdsLoading: isLoading,
            };
        },
    });

    const { data: businessIndustries } = useGetAllIndustiesLeadsQuery();
    const [deletePartnersAdsTrigger] = useDeletePartnersAdsMutation();
    const [changePartnerAdsTrigger] = useChangePartnersAdsStatusMutation();

    const showPartnerModal = () => setIsPartnerModalOpen(true);
    const hidePartnerModal = () => setIsPartnerModalOpen(false);

    const updateFilters = useCallback(
        (key: string, value: string | number) => {
            if (key === 'industry') {
                setQuery((currentQueryValues) => {
                    let newQueryValues = { ...currentQueryValues } as IPartnersAdsQuery;

                    newQueryValues.page = 1;
                    newQueryValues.industry = value ? [value as string] : ([] as Array<string>);
                    return newQueryValues;
                });
                return;
            } else {
                setQuery({ ...query, [key]: value, page: 1 });
            }
        },
        [setQuery, query],
    );

    const handleCloseDeletePopup = useCallback(() => {
        setIsDeletePopupOpen(false);
        setPartnersAdsID('');
    }, [setIsDeletePopupOpen, setPartnersAdsID]);

    const handleCloseEditPopup = useCallback(() => {
        setOpenEditPopup(false);
        setPartnersAdsID('');
    }, [setOpenEditPopup, setPartnersAdsID]);

    const deletePartnersAds = useCallback(
        (id: string) => {
            deletePartnersAdsTrigger(id)
                .unwrap()
                .then(() => {
                    enqueueSnackbar('Deleted Successfully', { variant: 'success', key: 'deleted-promo' });
                    handleCloseDeletePopup();
                })
                .catch(() => {
                    enqueueSnackbar('Something went wrong', { variant: 'error', key: 'deleted-promo' });
                });
        },
        [deletePartnersAdsTrigger],
    );

    const changePartnershipAdsStatus = useCallback(
        (id: string, currentStatus: boolean) => {
            const newStatus = !currentStatus;
            const data = {
                id,
                isActive: newStatus,
            };

            changePartnerAdsTrigger(data)
                .unwrap()
                .then((res) => {
                    enqueueSnackbar(res?.data?.message, { variant: 'success', key: 'change-subAdmin' });
                })
                .catch((error) => {
                    enqueueSnackbar(error?.data?.err?.message, { variant: 'error', key: 'deleted-promo' });
                });
        },
        [changePartnerAdsTrigger],
    );

    const columnHelper = useMemo(() => createColumnHelper<any>(), []);

    const sanitizeAndExtractText = (htmlContent) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlContent, 'text/html');
        return doc.body.textContent || '';
    };

    const PartnerShipAdsColumns = [
        {
            accessor: 'id',
            header: () => (
                <AuthorisedUsage module={MODULE.PARTNERS} permission={PERMISSIONS.UPDATE}>
                    <span>Action</span>{' '}
                </AuthorisedUsage>
            ),
            cell: (info) => (
                <div className="table_col ml-1 pt-0">
                    <AuthorisedUsage module={MODULE.PARTNERS} permission={PERMISSIONS.DELETE}>
                        <div className="d-flex gap align-items-center">
                            <div
                                onClick={(e) => {
                                    setIsDeletePopupOpen(true);
                                    setPartnersAdsID(info.row.original._id);
                                }}
                                className="trash-icon-red"
                            >
                                <Tooltip title="Delete" placement="top">
                                    <DeleteIcon />
                                </Tooltip>
                            </div>
                        </div>
                    </AuthorisedUsage>

                    <AuthorisedUsage module={MODULE.PARTNERS} permission={PERMISSIONS.UPDATE}>
                        <div className="edit-icon cursor-pointer ml-1">
                            <EditIcon
                                onClick={(e) => {
                                    setOpenEditPopup(true);
                                    setCurrentPartnerAd(info.row.original);
                                    setPartnersAdsID(info.row.original._id);
                                }}
                            />
                        </div>
                    </AuthorisedUsage>
                </div>
            ),
        },
        {
            accessor: 'title',
            header: <span>Ad Title</span>,
            cell: (info) => <span className="partner-page-title-span"> {info.getValue() ?? '-'} </span>,
        },
        {
            accessor: 'promotionText',
            header: <span>Promotional Text</span>,
            cell: (info) => (
                <span
                    className="partner-page-title-span"
                >
                    <StringFormatter length={20} text={info.getValue()} />
                </span>
            ),
        },
        {
            accessor: 'industriesName',
            header: <span>Business Industry</span>,
            cell: (info) => <span className="partner-page-title-span"> {info.getValue() ?? '-'} </span>,
        },

        {
            accessor: 'callToAction',
            header: <span>URL</span>,
            cell: (info) => (
                <span className="m-3 text-align-left">
                    <span className="copy-url p-04">
                        {stringFormatter(20, info?.getValue()) ?? '-'}
                        <ContentCopyOutlinedIcon
                            onClick={() => {
                                const encodedCta = info.row.original.callToAction ? info.row.original.callToAction : '';

                                copyUrl(encodedCta);
                            }}
                            className="copy-url-icon"
                        />{' '}
                    </span>
                </span>
            ),
        },
        {
            accessor: 'clickTotal',
            header: <span className="ml-1 mr-1"> Total Click Count</span>,
            cell: (info) => <span>{info.getValue() ?? '-'}</span>,
        },
        {
            accessor: 'targetReach',
            header: <span className="ml-1">Max Click Allowed</span>,
            cell: (info) => <span>{info.getValue() ?? '-'}</span>,
        },
        {
            accessor: 'image',
            header: <span>Image</span>,
            cell: (info) => <img src={`${process.env.REACT_APP_API_SERVER}${info.getValue()}`} alt="" />,
        },
        {
            accessor: 'description',
            header: <span>Description</span>,
            cell: (info) => (
                <span className="text-align-left">
                    {stringFormatter(30, sanitizeAndExtractText(info.getValue())) ?? '-'}
                </span>
            ),
        },
        {
            accessor: 'startDate',
            header: <span>Start Date</span>,
            cell: (info) => <span>{timeFormatter(info.getValue(), 'DD/MM/YYYY')} </span>,
        },
        {
            accessor: 'endDate',
            header: <span>End Date</span>,
            cell: (info) => <span>{timeFormatter(info.getValue(), 'DD/MM/YYYY')} </span>,
        },
        {
            accessor: '_id',
            header: () => (
                <AuthorisedUsage module={MODULE.PARTNERS} permission={PERMISSIONS.UPDATE}>
                    {' '}
                    <span>Status</span>{' '}
                </AuthorisedUsage>
            ),
            cell: (info) => (
                <AuthorisedUsage module={MODULE.PARTNERS} permission={PERMISSIONS.UPDATE}>
                    <div className="table_col ml-3">
                        <div className="delete-icon">
                            <Tooltip title={info.row.original.isActive ? 'Active' : 'Disabled'}>
                                <PrimarySwitch
                                    name="Ads Status"
                                    checked={info.row.original.isActive ?? false}
                                    onChange={() =>
                                        changePartnershipAdsStatus(info.getValue(), info.row.original.isActive)
                                    }
                                />
                            </Tooltip>
                        </div>
                    </div>
                </AuthorisedUsage>
            ),
        },
    ];

    return (
        <>
            <SpinningComponent loading={partnersAdsLoading}>
                <div className="layout-middle table-v2 client-leads-v2">
                    <div className="table_header d-flex">
                        <AuthorisedUsage module={MODULE.PARTNERS} permission={PERMISSIONS.READ}>
                            <div className="table_header-col d-flex w-50">
                                <TableHeaderV2
                                    hideSearchAndPerPage={false}
                                    updateFilters={updateFilters}
                                    businessIndustries={businessIndustries?.data}
                                    filters={{
                                        perPage: 10,
                                        sortingOrder: 'desc',
                                        status: '',
                                        clientId: '',
                                    }}
                                    dateRange={true}
                                />
                            </div>
                        </AuthorisedUsage>

                        <AuthorisedUsage module={MODULE.PARTNERS} permission={PERMISSIONS.CREATE}>
                            <button
                                onClick={() => showPartnerModal()}
                                className={classNames(
                                    'button',
                                    //  { 'table_export_leads_disabled': isLoadingPromoLinks }
                                )}
                            >
                                Generate Partner Ad
                            </button>
                        </AuthorisedUsage>
                    </div>

                    <AuthorisedUsage module={MODULE.PARTNERS} permission={PERMISSIONS.READ}>
                        <SharedDataTable
                            tableData={partnerAdsData?.data}
                            columns={PartnerShipAdsColumns}
                            columnHelper={columnHelper}
                            responsiveTableHeader={partnerShipAdsResponsiveHeader}
                        />

                        {partnerAdsData?.meta && (
                            <Pagination
                                totalCount={partnerAdsData?.meta?.total}
                                currentPage={partnerAdsData?.meta?.page * 1}
                                pageSize={partnerAdsData?.meta?.perPage}
                                onPageChange={(page) => setQuery({ ...query, page })}
                            />
                        )}
                    </AuthorisedUsage>
                </div>
            </SpinningComponent>

            <BasicModal open={isPartnerModalOpen} handleClose={hidePartnerModal}>
                <GenerateAddPopup
                    modalHeading="Generate Partner Ad"
                    modalButton="Generate Ad"
                    closePopup={hidePartnerModal}
                    businessIndustries={businessIndustries?.data}
                />
            </BasicModal>

            <BasicModal open={isDeletePopupOpen} handleClose={handleCloseDeletePopup}>
                <AlertPopUp
                    fn={(partnerAdsID) => deletePartnersAds(partnerAdsID)}
                    handleCloseModal={handleCloseDeletePopup}
                    heading={`Are you sure?`}
                    subheading={`Do you want to delete this Ads? It cannot be undone.`}
                    buttonText="Yes"
                    value={partnerAdsID}
                />
            </BasicModal>

            {currentPartnerAd && (
                <BasicModal open={openEditPopup} handleClose={handleCloseEditPopup}>
                    <GenerateAddPopup
                        modalHeading="Update Partner Ad"
                        modalButton="Update Ad"
                        closePopup={handleCloseEditPopup}
                        businessIndustries={businessIndustries?.data}
                        data={currentPartnerAd}
                        adsID={partnerAdsID}
                    />
                </BasicModal>
            )}
        </>
    );
};

export default PartnersTable;
