import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    nationalPlusGeoOutputData: [],
    nationalOutputData: [],
    isLoading: true,
};

const postcodesSlice = createSlice({
    name: 'postcodes',
    initialState,
    reducers: {
        setNationalPlusGeoOutputData(state, action) {
            state.nationalPlusGeoOutputData = action.payload;
            state.isLoading = false;
        },
        setNationalOutputData(state, action) {
            state.nationalOutputData = action.payload;
            state.isLoading = false;
        },
    },
});

export const { setNationalPlusGeoOutputData, setNationalOutputData } = postcodesSlice.actions;

export default postcodesSlice.reducer;
