/**
 =========================================================
 * Material Dashboard 2 PRO React TS - v1.0.2
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
 * Copyright 2023 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Card from '@mui/material/Card';

// Material Dashboard 2 PRO React TS Base Styles
import colors from 'assets/theme/base/colors';

// Material Dashboard 2 PRO React TS components
import MDBox from 'components/themed/MDBox';

// Declaring props types for HorizontalBarChart
import { IChartRendererProps } from 'contracts/view-models/IChartRendererProps';
import React, { useMemo } from 'react';

// react-chartjs-2 components
import { Bar } from 'react-chartjs-2';
import { ChartTitleBox } from '../chartTitleBox';

// VerticalBarChart configurations
import configs from './configs';

const VerticalBarChart: React.FC<IChartRendererProps> = ({ icon, title, description, height, chart }) => {
    const chartDatasets = chart.datasets
        ? chart.datasets.map((dataset) => ({
            ...dataset,
            weight: 5,
            borderWidth: 0,
            borderRadius: 4,
            backgroundColor: colors[dataset.color] ? colors[dataset.color || 'dark'].main : colors.dark.main,
            fill: false,
            maxBarThickness: 35,
        }))
        : [];

    const { data, options } = configs(chart.labels || [], chartDatasets, title);

    const renderChart = (
        //   <MDBox py={0} pr={0} pl={icon.component ? 1 : 2}>
        <MDBox className="vertical-chart" py={0} pr={0} pl={0}>
            {title || description ? (
                <ChartTitleBox description={description} component={icon.component} color={icon.color} title={title} />
            ) : null}
            {useMemo(
                () => (
                    <MDBox>
                        <Bar data={data} options={options} />
                    </MDBox>
                ),
                [data, height, options],
            )}
        </MDBox>
    );

    return title || description ? <Card>{renderChart}</Card> : renderChart;
};

// Declaring default props for VerticalBarChart
VerticalBarChart.defaultProps = {
    icon: { color: 'info', component: '' },
    title: '',
    description: '',
    height: '19.125rem',
};

export default VerticalBarChart;
