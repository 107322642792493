import MoreVertIcon from '@mui/icons-material/MoreVert';
import SearchIcon from '@mui/icons-material/Search';
import { Grid, IconButton, Menu, MenuItem } from '@mui/material';
import InputBase from '@mui/material/InputBase';
import 'components/Inbox/style.scss';
import React, { MutableRefObject, useCallback, useRef, useState } from 'react';
import { setChatRoomQuery } from 'redux/slices/chat';
import { useAppDispatch } from 'redux/store';

const options = ['Closed Leads'];
const MENU_ITEM_HEIGHT = 48;

interface CustomMutableRefObject extends MutableRefObject<any> {
    timeoutId: number;
}

const ChatSearch = () => {
    const appDispatcher = useAppDispatch();

    const [localSearchTerm, setLocalSearchTerm] = useState('');
    const searchDebounceRef = useRef<CustomMutableRefObject>();

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const closeMenu = () => {
        setAnchorEl(null);
    };

    const handleMenuItemClick = (event: string) => {
        closeMenu();
    };

    const updateSearch = useCallback(
        (value) => {
            appDispatcher(setChatRoomQuery(value));
        },
        [localSearchTerm],
    );

    const handleSearchChange = useCallback(
        (event: React.ChangeEvent<HTMLTextAreaElement>) => {
            const { value } = event.target;
            setLocalSearchTerm(value);

            if (searchDebounceRef.current) {
                clearTimeout(searchDebounceRef.current.timeoutId);
                searchDebounceRef.current.timeoutId = window.setTimeout(() => {
                    updateSearch(value);
                }, 1000);
            }
        },
        [updateSearch],
    );

    return (
        <>
            <Grid container className="user-list-outer">
                <Grid item xs={11} className="user-list-inner">
                    <IconButton type="button" className="search-icon" aria-label="search">
                        <SearchIcon />
                    </IconButton>
                    <InputBase
                        ref={searchDebounceRef}
                        sx={{ ml: 1, flex: 1 }}
                        placeholder="Search by name or lead"
                        inputProps={{ 'aria-label': 'search by name' }}
                        onChange={handleSearchChange}
                        value={localSearchTerm}
                    />
                </Grid>
                <Grid item xs={1} className="menu-button">
                    <IconButton
                        aria-label="more"
                        id="long-button"
                        aria-controls={open ? 'long-menu' : undefined}
                        aria-expanded={open ? 'true' : undefined}
                        aria-haspopup="true"
                        onClick={handleClick}
                    >
                        <MoreVertIcon />
                    </IconButton>
                    <Menu
                        id="long-menu"
                        MenuListProps={{
                            'aria-labelledby': 'long-button',
                        }}
                        anchorEl={anchorEl}
                        open={open}
                        onClose={closeMenu}
                        PaperProps={{
                            style: {
                                maxHeight: MENU_ITEM_HEIGHT * 4.5,
                                width: '20ch',
                            },
                        }}
                    >
                        {options.map((option) => (
                            <MenuItem key={option} onClick={() => handleMenuItemClick(option)}>
                                {option}
                            </MenuItem>
                        ))}
                    </Menu>
                </Grid>
            </Grid>
        </>
    );
};

export default ChatSearch;
