import reportRejected from 'assets/images/icons/decline.svg';
import reportAccepted from 'assets/images/icons/report-accepted.png';

import reportedLeadsImg from 'assets/images/icons/reported-leads.png';
import classNames from 'classnames';
import AuthorisedUsage from 'components/app-permission/AuthorisedUsage';
import { MODULE, PERMISSIONS } from 'components/app-permission/permission';
import Table from 'components/leads-table/Table';
import HeaderCard from 'components/shared/Card/HeaderCard';
import Pagination from 'components/shared/Pagination/Pagination';
import SpinningComponent from 'components/shared/SpinningComponent';
import TableHeaderV2 from 'components/shared/TableHeaderV2';
import { reportedLeadsAvailableStatuses } from 'contracts/app-utils/ELeadsStatus';
import { ILeadsLIstQuery } from 'contracts/requests/ILeadsListResponse';
import { LeadsBidDataSerializer } from 'contracts/spotdif/LeadsBidData';
import { enqueueSnackbar } from 'notistack';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import { useGetAllClientsQuery } from 'redux/services/spotdif/clients';
import { useGetAllIndustiesLeadsQuery } from 'redux/services/spotdif/industriesLeads';
import { useGetAdminLeadsStatsQuery } from 'redux/services/spotdif/leads';
import { useExportReportedLeadsMutation, useGetAllReportedLeadsQuery } from 'redux/services/spotdif/reportedLeads';
import { useGetAllUsersQuery } from 'redux/services/spotdif/user';

const ReportedLeadsTable: React.FC = () => {
    const { data: allClients } = useGetAllUsersQuery();
    const { data: businessIndustries } = useGetAllIndustiesLeadsQuery();
    const { availableAccessManagers, isLoadingAccessManager } = useGetAllClientsQuery(
        { showOnlyAccountManagers: true, accountManagerId: '' },
        {
            selectFromResult: ({ data, isLoading }) => {
                return {
                    availableAccessManagers: data?.data,
                    isLoadingAccessManager: isLoading,
                };
            },
        },
    );
    const { statsData, statsLoading } = useGetAdminLeadsStatsQuery(null, {
        selectFromResult: ({ data, isLoading }) => {
            return {
                statsData: data?.data,
                statsLoading: isLoading,
            };
        },
    });

    const [query, setQuery] = useState<ILeadsLIstQuery>({
        search: '',
        perPage: 100,
        sortingOrder: 'desc',
        status: '',
        page: 1,
        clientId: '',
        leadsStatus: 'Reported',
        total: 0,
        pageCount: 0,
        industry: '',
        accountManagerId: '',
    });
    const { reportedLeadsData, reportedLeadsSerializedData, leadsLoading } = useGetAllReportedLeadsQuery(query, {
        selectFromResult: ({ data, isLoading }) => {
            return {
                reportedLeadsData: data,
                reportedLeadsSerializedData: data?.data?.map((item) => LeadsBidDataSerializer.parse(item)),
                leadsLoading: isLoading,
            };
        },
    });

    useEffect(() => {
        if (reportedLeadsData?.meta) {
            setQuery({
                ...query,
                pageCount: reportedLeadsData?.meta?.pages,
            });
        }
    }, [reportedLeadsData?.meta]);

    const updateLeadFilters = (key: string, value: string | number) => {
        setQuery({ ...query, [key]: value, page: 1 });
    };
    const [exportReportedLeads, { data: csvData }] = useExportReportedLeadsMutation();

    const csvInstance: any = useRef();

    useEffect(() => {
        if (csvData && csvInstance.current && csvInstance.current?.link) {
            setTimeout(() => {
                csvInstance.current.link.click();
            });
        }
    }, [csvData]);

    const exportReportedLead = useCallback(() => {
        let filterUrl = `?&file=Reported&sortingOrder=${query.sortingOrder}&status=${query.leadsStatus}`;
        if (query.clientId) {
            filterUrl = `${filterUrl}&id=${query.clientId}`;
        }
        exportReportedLeads(filterUrl)
            .then(() => {
                enqueueSnackbar('Leads exported successfully', { variant: 'success', key: 'reported-export' });
            })
            .catch(() => {
                enqueueSnackbar('Failed to export', { variant: 'error', key: 'reported-export' });
            });
    }, [query.sortingOrder, query.leadsStatus, query.clientId]);

    const renderStatusHeader = useMemo(
        () => (
            <>
                <AuthorisedUsage module={MODULE.REPORTED_LEADS_CSV_FILE} permission={PERMISSIONS.READ}>
                    <div
                        className={classNames('table_export_leads show-button', {
                            table_export_leads_disabled: leadsLoading,
                        })}
                    >
                        <button onClick={exportReportedLead} className="button is-small w-button export-csv">
                            Export Leads
                        </button>
                        {csvData ? <CSVLink data={csvData} filename={'Leads.CSV'} ref={csvInstance} /> : undefined}
                    </div>
                </AuthorisedUsage>
            </>
        ),
        [csvData, csvInstance, exportReportedLead],
    );

    return (
        <>
            <SpinningComponent loading={leadsLoading}>
                <div className="layout-middle table-v2 leads-table-v2 client-leads-v2">
                    <AuthorisedUsage module={MODULE.REPORTED_LEADS} permission={PERMISSIONS.READ}>
                        <TableHeaderV2
                            clients={allClients}
                            leadsType={reportedLeadsAvailableStatuses}
                            updateFilters={updateLeadFilters}
                            entriesPerPage={[100, 50, 25, 10]}
                            filters={query}
                            status={renderStatusHeader}
                            showSorting={true}
                            businessIndustries={businessIndustries?.data}
                            accountsManagers={availableAccessManagers}
                        />
                    </AuthorisedUsage>

                    <AuthorisedUsage module={MODULE.REPORTED_LEADS} permission={PERMISSIONS.READ}>
                        <div className="table-card-block leads-header-align">
                            <HeaderCard
                                icon={reportedLeadsImg}
                                value={statsData?.reportedLeads}
                                custClass="reported-leads"
                                heading="Reported Leads"
                            />
                            <HeaderCard
                                icon={reportAccepted}
                                value={statsData?.reportAcceptedLeads}
                                custClass="report-accepted"
                                heading="Report Accepted"
                            />
                            <HeaderCard
                                icon={reportRejected}
                                value={statsData?.reportRejectedLeads}
                                custClass="report-rejected"
                                heading="Report Rejected"
                            />
                        </div>
                    </AuthorisedUsage>

                    <AuthorisedUsage module={MODULE.REPORTED_LEADS} permission={PERMISSIONS.READ}>
                        <Table
                            adminSerializedData={reportedLeadsSerializedData}
                            query={query}
                            setQuery={setQuery}
                            adminLeadsData={reportedLeadsData}
                            loading={leadsLoading}
                        />
                        {reportedLeadsData?.meta && (
                            <Pagination
                                totalCount={reportedLeadsData?.meta?.total}
                                currentPage={
                                    typeof reportedLeadsData?.meta?.page === 'string'
                                        ? parseInt(reportedLeadsData?.meta?.page)
                                        : reportedLeadsData?.meta?.page * 1
                                }
                                pageSize={reportedLeadsData?.meta?.perPage}
                                onPageChange={(page) => setQuery({ ...query, page })}
                            />
                        )}
                    </AuthorisedUsage>
                </div>
            </SpinningComponent>
        </>
    );
};

export default ReportedLeadsTable;
