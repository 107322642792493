import { ECountry } from 'contracts/spotdif/currencyAndCountryCodes';
import useAuthentication from 'hooks/useAuthentication';
import React, { createContext, useState } from 'react';

interface ICountryContext {
    currentCountryCode: string;
    updateCurrentCode: (countryCode: string) => void;
}

export const CountryContext = createContext<ICountryContext>({
    currentCountryCode: '',
    updateCurrentCode: () => {}, // Initial function placeholder
});

interface ICountryCodeProviderProps {
    children: React.ReactNode;
}

export const useCountryCodeContext = () => {
    const context = React.useContext(CountryContext);

    if (!context) {
        throw new Error('The component is not within a CountryCodeProvider');
    }

    return context;
};

const CountryCodeProvider: React.FC<ICountryCodeProviderProps> = ({ children }) => {
    const { user } = useAuthentication();

    const [currentCountryCode, setCurrentCountryCode] = useState<string>(user?.country ?? ECountry.GB);

    const updateCurrentCode = (countryCode: string) => {
        setCurrentCountryCode(countryCode);
    };

    return (
        <CountryContext.Provider value={{ currentCountryCode, updateCurrentCode }}>{children}</CountryContext.Provider>
    );
};

export default CountryCodeProvider;
