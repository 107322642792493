import { ColumnHelper, createColumnHelper } from '@tanstack/table-core';
import Pagination from 'components/shared/Pagination/Pagination';
import SharedDataTable from 'components/shared/SharedTable/SharedDataTable';
import SpinningComponent from 'components/shared/SpinningComponent';
import TableHeaderV2 from 'components/shared/TableHeaderV2';
import { ICertifiedSupplierRequest } from 'contracts/requests/ICertifiedSupplierRequest';
import { BadgeStatusDetailObj } from 'contracts/requests/IGetBadgeStatusResponse';
import { useCallback, useMemo, useState } from 'react';
import { useGetCertifiedSupplierQuery } from 'redux/services/spotdif/certifiedSupplier';
import { useGetAllIndustiesLeadsQuery } from 'redux/services/spotdif/industriesLeads';
import { timeFormatter } from 'utils/text-helpers';

const certifiedSupplierResponsiveHeader = {
    user_firstName: 'First Name',
    status: 'Status',
    lastSeen: 'Last Seen',
    firstSeen: 'First Seen',
    amount: 'Amount',
    link: 'link',
};

const CertifiedTable: React.FC = () => {
    const [query, setQuery] = useState<ICertifiedSupplierRequest>({
        search: '',
        perPage: 10,
        total: 0,
        pageCount: 0,
        page: 1,
        industry: [],
    });

    const { certifiedTableData, certifiedTableLoading } = useGetCertifiedSupplierQuery(query, {
        selectFromResult: ({ data, isLoading }) => {
            return {
                certifiedTableData: data,
                certifiedTableLoading: isLoading,
            };
        },
    });

    const { data: businessIndustries } = useGetAllIndustiesLeadsQuery();

    const updateFilters = useCallback(
        (key: string, value: string | number) => {
            if (key === 'industry') {
                setQuery((currentQueryValues) => {
                    let newQueryValues = { ...currentQueryValues } as ICertifiedSupplierRequest;

                    newQueryValues.page = 1;
                    newQueryValues.industry = value ? [value as string] : ([] as Array<string>);
                    return newQueryValues;
                });
                return;
            } else {
                setQuery({ ...query, [key]: value, page: 1 });
            }
        },
        [setQuery, query],
    );

    // Using unknown type for columnHelper to avoid ts error
    const columnHelper = useMemo(() => createColumnHelper<BadgeStatusDetailObj>() as ColumnHelper<unknown>, []);

    const certifiedSupplierColumns = [
        {
            accessor: 'user.firstName',
            header: <span>First Name</span>,
            cell: (info) => <span className="certified-supplier-page-title-span"> {info.getValue() ?? '-'} </span>,
        },
        {
            accessor: 'status',
            header: <span>Status</span>,
            cell: (info) => <span className="certified-supplier-page-title-span"> {info.getValue() ?? '-'} </span>,
        },
        {
            accessor: 'lastSeen',
            header: <span>Last Seen</span>,
            cell: (info) => (
                <span className="certified-supplier-page-title-span">
                    {info.getValue() ? timeFormatter(info.getValue(), 'DD/MM/YYYY, h:mm:ss A') : '-'}
                </span>
            ),
        },
        {
            accessor: 'firstSeen',
            header: <span>First Seen</span>,
            cell: (info) => (
                <span className="certified-supplier-page-title-span">
                    {info.getValue() ? timeFormatter(info.getValue(), 'DD/MM/YYYY, h:mm:ss A') : '-'}
                </span>
            ),
        },
        {
            accessor: 'amount',
            header: <span>Amount</span>,
            cell: (info) => <span className="certified-supplier-page-title-span">{info.getValue() ?? '-'}</span>,
        },
        {
            accessor: 'link',
            header: <span>Site URL</span>,
            cell: (info) => <span>{info.getValue() ?? '-'}</span>,
        },
    ];

    return (
        <>
            <SpinningComponent loading={certifiedTableLoading}>
                <div className="layout-middle table-v2 client-leads-v2">
                    <div className="table_header d-flex">
                        <div className="table_header-col d-flex w-50">
                            <TableHeaderV2
                                hideSearchAndPerPage={false}
                                updateFilters={updateFilters}
                                businessIndustries={businessIndustries?.data}
                                filters={{
                                    perPage: 10,
                                    sortingOrder: 'desc',
                                    status: '',
                                    clientId: '',
                                }}
                                dateRange={true}
                            />
                        </div>
                    </div>

                    <SharedDataTable
                        tableData={certifiedTableData?.data}
                        columns={certifiedSupplierColumns}
                        columnHelper={columnHelper}
                        responsiveTableHeader={certifiedSupplierResponsiveHeader}
                    />

                    {certifiedTableData?.meta && (
                        <Pagination
                            totalCount={certifiedTableData?.meta?.total}
                            currentPage={certifiedTableData?.meta?.page * 1}
                            pageSize={certifiedTableData?.meta?.perPage}
                            onPageChange={(page) => setQuery({ ...query, page })}
                        />
                    )}
                </div>
            </SpinningComponent>
        </>
    );
};

export default CertifiedTable;
