import React, { useContext, useMemo, useRef } from 'react';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import BusinessDetails from 'components/business-details';
import LeadSettings from 'components/lead-settings';
import UserProfile from 'components/user-profile';
import { IOnboardingStepComponent } from 'contracts/view-models/IOnboardingStepComponent';
import AccountTabHeader from './AccountTabHeader';
import { CreditCardHandlerProvider, CreditCardHandlerContext } from 'context/CreditCardContext';
import BasicModal from 'components/core/Modal';
import MDButton from 'components/themed/MDButton';
import useAuthentication from 'hooks/useAuthentication';
import CreditAndBilling from 'components/creditAndBilling';
import NotificationSettings from 'components/notifications-settings';

interface IUserDetailsProps {}

const UserAccountTabs: React.FC<IUserDetailsProps> = () => {
    const { user, isUser } = useAuthentication();
    const { isNonBillableUser } = useAuthentication();
    const stepRef = useRef<IOnboardingStepComponent>();
    const { openAddCreditModal, setOpenAddCreditModal } = useContext(CreditCardHandlerContext);

    const onboardingSteps = useMemo(() => {
        const steps = [
            {
                id: 1,
                title: 'Your Details',
                path: '/account/info',
            },
            {
                id: 2,
                title: 'Your Business',
                path: '/account/business',
            },
            {
                id: 3,
                title: 'Lead Settings',
                path: '/account/leads',
            },
            {
                id: 4,
                title: 'Credit and Billing',
                path: '/account/credit-and-billing',
            },
            {
                id: 5,
                title: 'Notifications',
                path: '/account/notifications',
            },
        ];

        if ((isUser && user?.isCreditsAndBillingEnabled) || (isNonBillableUser && user?.isCreditsAndBillingEnabled)) {
            return steps;
        } else {
            return steps.filter((step) => step.id !== 4);
        }
    }, [user?.isCreditsAndBillingEnabled, isUser, isNonBillableUser]);

    const AddCredit = () => {
        return (
            <MDButton onClick={() => setOpenAddCreditModal(true)} className="button">
                Add Credit
            </MDButton>
        );
    };

    return (
        <>
            <div className="layout-middle">
                <AccountTabHeader steps={onboardingSteps} />
                <CreditCardHandlerProvider>
                    <BasicModal
                        button={AddCredit()}
                        open={openAddCreditModal}
                        handleClose={() => setOpenAddCreditModal(false)}
                    ></BasicModal>

                    <Routes>
                        <Route
                            path=""
                            element={
                                <div>
                                    <Outlet />
                                </div>
                            }
                        >
                            <Route path="" index element={<Navigate to="/account/info" replace={true} />} />
                            <Route path="info" element={<UserProfile ref={stepRef} />} />
                            <Route path="business" element={<BusinessDetails ref={stepRef} />} />
                            <Route path="leads" element={<LeadSettings ref={stepRef} />} />
                            {((isUser && user?.isCreditsAndBillingEnabled) ||
                                (isNonBillableUser && user?.isCreditsAndBillingEnabled)) && (
                                <Route path="credit-and-billing" element={<CreditAndBilling />} />
                            )}
                            <Route path="notifications" element={<NotificationSettings />} />
                        </Route>
                    </Routes>
                </CreditCardHandlerProvider>
            </div>
        </>
    );
};

export default UserAccountTabs;
