export const TAG_POST_CODE_MAP = 'postCodeMap' as const;
export const TAG_POST_CODE_MAP_UK = 'postCodeMapUK' as const;
export const TAG_POST_CODE_MAP_IR = 'postCodeMapIR' as const;
export const TAG_POST_CODE_MAP_ALL = 'postCodeMapAll' as const;

export const TAG_GET_PROMO_LINKS = 'getPromoLinks' as const;
export const TAG_GENERATE_PROMO_LINKS = 'generatePromoLinks' as const;

export const TAG_CHART_DATA = 'chartData' as const;
export const TAG_CHART_DATA_LEFT = 'chartDataLeft' as const;
export const TAG_CHART_DATA_RIGHT = 'chartDataLeftRight' as const;

export const TAG_REPORT_LEAD = 'reportLead' as const;
export const TAG_LEAD_PREFERENCE = 'leadPreference' as const;
export const TAG_USER_PREFERENCE = 'leadPreference' as const;

// export const
export const TAG_USER_PROFILE = 'userProfile' as const;
export const TAG_GET_USER_PROFILE = 'getUserProfile' as const;

export const TAG_INDUSTRIES_LEAD = 'industriesLead' as const;
export const TAG_TRANSACTION_TABLE = 'transactionTable' as const;
export const TAG_GET_TRANSACTION_TABLE = 'getTransactionTable' as const;

export const TAG_CLIENT_UPDATE = 'clientUpdate' as const;

export const TAG_INVITE_USER = 'inviteUser' as const;
export const TAG_SUBSCRIBER_USER = 'subscriberUser' as const;

export const TAG_CLIENT_COLUMN_UPDATE = 'clientColumnUpdate' as const;
export const TAG_CHANGE_LEAD = 'changeLeadStatus' as const;
export const TAG_SAVE_CLIENT_DETAIL = 'saveClientDetail' as const;
export const TAG_UPDATE_CREDIT = 'updateCredit' as const;

export const TAG_USER_CARD = 'userCard' as const;
export const TAG_GET_USER_CARD = 'getUserCard' as const;

export const TAG_SUB_ADMIN = 'subAdmin' as const;
export const TAG_GET_SUB_ADMIN = 'getSubAdmin' as const;

export const TAG_NON_BILLABLE_USER = 'nonBillableUser' as const;

export const TAG_PLAN_PACKAGES = 'planpackages' as const;

export const TAG_ACCOUNT_MANAGER_STATS = 'accountManagerStats' as const;

export const TAG_GET_PARTNERS_ADS = 'getPartnersAds' as const;

export const TAG_GET_ACCOUNT_MANAGER = 'getAccountManager' as const;
export const TAG_GET_PERMISSION_LIST = 'rolePermissionsList' as const;
export const TAG_GET_USER_ADS = 'getUserPartnersAds' as const;

export const TAG_GET_SITE_CONFIGS = 'getSiteConfigs' as const;

export const TAG_GET_VALIDATION_CONFIGS = 'getValidationConfigs' as const;
export const TAG_GET_CERTIFIED_SUPPLIER = 'getCertifiedSupplier' as const;

export const TAG_GET_BADGE_STATUS = 'getBadgeStatus' as const;

export const TAG_GET_XERO_DETAILS = 'getXeroDetails' as const;

export const TAG_CHAT_ROOMS = 'chatRooms' as const;
export const TAG_CHAT_ROOM_DETAILS = 'chatLeadDetails' as const;

export const TAG_AUTHENTICATION_KEYS = 'authenticationKeys' as const;
