import classNames from 'classnames';
import BasicModal from 'components/core/Modal';
import LeadInfoModal from 'components/LeadInfoModal';

import { LeadsBidData } from 'contracts/spotdif/LeadsBidData';
import { enqueueSnackbar } from 'notistack';
import React, { useCallback, useState } from 'react';
import { useChangeLeadStatusMutation } from 'redux/services/spotdif/reportedLeads';
import { expandDataCopy } from 'utils/data-format-utils';
import BadgeItem from './BadgeItem';
import { BadgeNature } from './BadgeV2';

import InfoItem from './InfoItem';
import { TableCardView } from './LeadsResponsiveTable';

interface ExpandedRowProps {
    leadData: LeadsBidData;
    // setExpanded: (expand: ExpandedState) => void,  // May require later
    adminLeadsData: any;
    view: TableCardView;
}

const itemToExeclude = ['buisnessName', 'businessIndustry', 'firstname', 'lastname', 'phone1'];
const LeadsExpandedResponsiveRow: React.FC<ExpandedRowProps> = ({ leadData, adminLeadsData, view }) => {
    const [showLeadModal, setShowLeadModal] = useState<boolean>(false);

    const [changeLeadStatus] = useChangeLeadStatusMutation();

    const handleCloseLeadModal = useCallback(() => {
        setShowLeadModal(false);
    }, []);

    const toggleLeadStatusHandler = useCallback(
        (status, isReprocessed) => {
            changeLeadStatus({ leadId: leadData._id, status, isReprocessed: isReprocessed })
                .unwrap()
                .then((payload) => {
                    enqueueSnackbar(status, {
                        variant: 'success',
                        key: 'user-auth',
                    });
                    // setExpanded({});   // May require later
                })
                .catch(({ data }) => {
                    enqueueSnackbar(data?.error?.message ?? 'Something went wrong.', {
                        variant: 'error',
                        key: 'user-auth',
                    });
                });
        },
        [leadData],
    );

    return (
        <>
            <div className="expanded-row">
                <td colSpan={12}>
                    <div className="expanded-row-block">
                        <div
                            className={classNames('table-v2-responsive-more-info', {
                                'd-none': view !== TableCardView.MoreDetails,
                            })}
                        >
                            <div className="block-left">
                                <h2 className="expanded-heading">More Details</h2>
                                <div className="info">
                                    <div className="info-right">
                                        <BadgeItem
                                            item={{
                                                nature: BadgeNature.normal,
                                                className: `${leadData?.status?.split(' ').join('-')}-v2`,
                                                label: 'Status',
                                                value: leadData?.status,
                                            }}
                                        />
                                        {expandDataCopy(leadData, adminLeadsData, itemToExeclude)
                                            ?.slice(
                                                0,
                                                Math.ceil(
                                                    expandDataCopy(leadData, adminLeadsData, itemToExeclude).length / 2,
                                                ),
                                            )
                                            ?.map(({ label, value }) => {
                                                return <InfoItem item={{ label: label, value: value ?? '-' }} />;
                                            })}
                                    </div>
                                    <div className="info-left">
                                        {expandDataCopy(leadData, adminLeadsData, itemToExeclude)
                                            ?.slice(
                                                Math.ceil(
                                                    expandDataCopy(leadData, adminLeadsData, itemToExeclude).length / 2,
                                                ),
                                            )
                                            ?.map(({ label, value }) => {
                                                return <InfoItem item={{ label: label, value: value ?? '-' }} />;
                                            })}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            className={classNames('table-v2-responsive-report-lead', {
                                'd-none': view !== TableCardView.ReportLead,
                            })}
                        >
                            <div className="block-right">
                                <div>
                                    <div className="block-right-header">
                                        <h2 className="expanded-heading">Report Lead</h2>
                                        <span className="ques-icon" onClick={() => setShowLeadModal(true)}>
                                            {' '}
                                            ?{' '}
                                        </span>
                                    </div>
                                    <BadgeItem
                                        item={{
                                            nature: BadgeNature.inverted,
                                            className: `${leadData?.status?.split(' ').join('-')}`,
                                            label: 'Status',
                                            value: leadData?.status,
                                        }}
                                    />

                                    <InfoItem
                                        item={{ label: 'Reason for report', value: leadData?.invalidLeadReason }}
                                    />
                                    <InfoItem item={{ label: 'Client Notes', value: leadData?.clientNotes }} />
                                </div>
                                <div>
                                    <div className="action-box">
                                        <div
                                            className={classNames('w-100', {
                                                'disabled-wrapper': leadData?.status !== 'Reported',
                                            })}
                                        >
                                            <input
                                                disabled={leadData?.status !== 'Reported'}
                                                type="button"
                                                // className="max-width-full w-100 button"
                                                className={classNames('max-width-full w-100 button', {
                                                    disabled: leadData?.status !== 'Reported',
                                                })}
                                                value="Reject Report"
                                                onClick={(e) => toggleLeadStatusHandler('Report Rejected', false)}
                                            />
                                        </div>

                                        <div
                                            className={classNames('w-100', {
                                                'disabled-wrapper': leadData?.status !== 'Reported',
                                            })}
                                        >
                                            <input
                                                disabled={leadData?.status !== 'Reported'}
                                                onClick={(e) => toggleLeadStatusHandler('Report Accepted', false)}
                                                type="button"
                                                className={classNames('max-width-full w-100 button button-green', {
                                                    disabled: leadData?.status !== 'Reported',
                                                })}
                                                value="Accept Report"
                                            />
                                        </div>
                                    </div>
                                    <div className="action-box action-box-long">
                                        <div
                                            className={classNames('w-100', {
                                                'disabled-wrapper': leadData?.status !== 'Reported',
                                            })}
                                        >
                                            <input
                                                disabled={leadData?.status !== 'Reported'}
                                                type="button"
                                                // className="max-width-full w-100 button"
                                                className={classNames('max-width-full w-100 button button-inverted', {
                                                    disabled: leadData?.status !== 'Reported',
                                                })}
                                                value="Reject Report + Reprocess"
                                                onClick={(e) => toggleLeadStatusHandler('Report Rejected', true)}
                                            />
                                        </div>

                                        <div
                                            className={classNames('w-100', {
                                                'disabled-wrapper': leadData?.status !== 'Reported',
                                            })}
                                        >
                                            <input
                                                disabled={leadData?.status !== 'Reported'}
                                                type="button"
                                                // className="max-width-full w-100 button"
                                                className={classNames(
                                                    'max-width-full w-100 button button-green-inverted',
                                                    { disabled: leadData?.status !== 'Reported' },
                                                )}
                                                value="Accept Report + Reprocess"
                                                onClick={(e) => toggleLeadStatusHandler('Report Accepted', true)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </td>
            </div>
            <BasicModal open={showLeadModal} handleClose={() => setShowLeadModal(false)}>
                <LeadInfoModal handleCloseLeadModal={handleCloseLeadModal} />
            </BasicModal>
        </>
    );
};

export default LeadsExpandedResponsiveRow;
