import { useMediaQuery } from '@mui/material';
import stepFive from 'assets/images/howItWorks/five.svg';
import stepFour from 'assets/images/howItWorks/four.svg';

import stepOne from 'assets/images/howItWorks/one.svg';
import stepThree from 'assets/images/howItWorks/three.svg';
import stepTwo from 'assets/images/howItWorks/two.svg';
import classNames from 'classnames';
import React from 'react';
import { Navigation, Scrollbar } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/scrollbar';
import 'swiper/css/navigation';

import './styles.scss';
import { Swiper, SwiperSlide } from 'swiper/react';

interface IHowItWorksProps {}

export const HowItWorks: React.FC<IHowItWorksProps> = () => {
    const isMobile = useMediaQuery('(max-width:768px)');

    const slides = [
        { src: stepOne, alt: 'step-one' },
        { src: stepTwo, alt: 'step-two' },
        { src: stepThree, alt: 'step-three' },
        { src: stepFour, alt: 'step-four' },
        { src: stepFive, alt: 'step-five' },
    ];
    return (
        <div className="how_its_work_sec">
            <p className="how-it-works-title">
                How it <b>works</b>
            </p>
            <Swiper
                slidesPerView={isMobile ? 1 : 3}
                spaceBetween={0}
                navigation={true}
                scrollbar={{}}
                modules={[Scrollbar, Navigation]}
                className={classNames({ 'mySwiper-responsive': isMobile }, { mySwiper: isMobile === false })}
            >
                {slides.map((slide, index) => (
                    <SwiperSlide key={index}>
                        <img src={slide.src} alt={slide.alt} />
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    );
};
