import { IValidationConfigRequest, ValidationConfigCardObject } from 'contracts/requests/IValidationConfigRequest';
import { FormikProvider, useFormik } from 'formik';
import { onlyNumbersRegExp } from 'utils/text-helpers';
import { Form } from 'react-router-dom';
import AuthorisedUsage from 'components/app-permission/AuthorisedUsage';
import { MODULE, PERMISSIONS } from 'components/app-permission/permission';
import { Switch, Tooltip } from '@mui/material';
import MDInput from 'components/themed/MDInput';
import { useSnackbar } from 'notistack';
import { useUpdateValidationConfigsMutation } from 'redux/services/spotdif/siteConfigs';
import * as Yup from 'yup';
import { useEffect } from 'react';
import { useUserHasPermission } from 'components/app-permission/useUserHasPermission';

export interface validationConfigCardProps {
    title: string;
    key?: string;
    data: ValidationConfigCardObject;
}

const ValidationConfigCard: React.FC<validationConfigCardProps> = ({ title, key, data }) => {
    const { enqueueSnackbar } = useSnackbar();
    const [updateValidationConfigsDetailsTrigger] = useUpdateValidationConfigsMutation();

    const hasValidationConfigPermission = useUserHasPermission(MODULE.VALIDATION_CONFIGS, PERMISSIONS.UPDATE);

    const updateValidationConfigsDetails = async (data: IValidationConfigRequest) => {
        updateValidationConfigsDetailsTrigger(data)
            .unwrap()
            .then((res) => {
                enqueueSnackbar(res?.message || 'Global minimum top-up updated successfully.', {
                    variant: 'success',
                    key: 'validation-config-update',
                });
            })
            .catch((err) => {
                enqueueSnackbar(err?.message || 'Something Went Wrong', {
                    variant: 'error',
                    key: 'validation-config-update',
                });
            });
    };
    const formik = useFormik({
        initialValues: {
            enabled: data?.enabled || false,
            value: data?.value || 0,
        },
        validationSchema: Yup.object().shape({
            value: Yup.string().when('enabled', {
                is: true,
                then: (schema) =>
                    schema
                        .matches(onlyNumbersRegExp, 'amount not valid')
                        .max(4, 'amount should not be more than 9999')
                        .required('amount is required'),
            }),
        }),
        onSubmit: async (values) => {
            updateValidationConfigsDetails({ enabled: values.enabled, value: Number(values?.value) });
        },
    });

    useEffect(() => {
        if (data) {
            formik.setValues({
                enabled: data?.enabled || false,
                value: data?.value || 0,
            });
        }
    }, [data]);

    return (
        <AuthorisedUsage module={MODULE.VALIDATION_CONFIGS} permission={PERMISSIONS.READ}>
            <div className="site-configs-settings-content">
                <h6 className="site-config-content-card-header">{title}</h6>
                {formik && (
                    <FormikProvider value={formik}>
                        <Form onSubmit={formik.handleSubmit}>
                            <div className="site-configs-settings-card payment-card">
                                <div className="full-width-field">
                                    <div className="d-flex">
                                        <Tooltip title={formik?.values?.enabled ? 'Active' : 'InActive'}>
                                            <Switch
                                                disabled={!hasValidationConfigPermission}
                                                checked={formik?.values?.enabled}
                                                onChange={() => {
                                                    formik.setFieldValue('enabled', !formik?.values?.enabled);
                                                }}
                                            />
                                        </Tooltip>

                                        <p className="para-graph-color">Enabled.</p>
                                    </div>

                                    <MDInput
                                        type="text"
                                        id="value"
                                        name="value"
                                        placeholder="Enter value"
                                        label={title}
                                        value={formik.values?.value}
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                        error={formik.touched?.value && Boolean(formik.errors?.value)}
                                        helperText={formik.touched?.value && formik.errors?.value}
                                        disabled={!hasValidationConfigPermission || !formik?.values?.enabled}
                                    />
                                </div>
                                <div className="free-leads-text">
                                    {`Minimum number of leads the users will be required to top up.`}
                                </div>
                                <AuthorisedUsage module={MODULE.VALIDATION_CONFIGS} permission={PERMISSIONS.UPDATE}>
                                    <div className="d-flex save-button-justify-end">
                                        <button type="submit" className="button">
                                            Save
                                        </button>
                                    </div>
                                </AuthorisedUsage>
                            </div>
                        </Form>
                    </FormikProvider>
                )}
            </div>
        </AuthorisedUsage>
    );
};

export default ValidationConfigCard;
