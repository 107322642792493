import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import theme from 'assets/theme';
import { ApplicationUserContextProvider } from 'context/ApplicationUser';
import ChatContextProvider from 'context/ChatContext';
import { PaymentHandlerProvider } from 'context/RfytPaymentContext';
import { SessionHandlerProvider } from 'context/RfytSessionContext';
import dayjs from 'dayjs';
import RelativeTime from 'dayjs/plugin/relativeTime';
import React from 'react';
import { Provider } from 'react-redux';

import store from 'redux/store';
import 'styles/global.scss';
import './i18n';
import SpotDifApp from 'routing/SpotDifApp';

dayjs.extend(RelativeTime);

const App: React.FC = () => (
    <ThemeProvider theme={theme}>
        <CssBaseline />
        <Provider store={store}>
            <SessionHandlerProvider>
                <ApplicationUserContextProvider>
                    <PaymentHandlerProvider>
                        <ChatContextProvider>
                            <SpotDifApp />
                        </ChatContextProvider>
                    </PaymentHandlerProvider>
                </ApplicationUserContextProvider>
            </SessionHandlerProvider>
        </Provider>
    </ThemeProvider>
);

export default App;
