import dayjs from 'dayjs';
import React from 'react';
import DateRenderer from './DateRenderer';

interface DateRangeRendererProps {
    startDate: dayjs.Dayjs;
    endDate: dayjs.Dayjs;
    format: string;
    fallback?: string;
}

const DateRangeRenderer: React.FC<DateRangeRendererProps> = ({ startDate, endDate, format, fallback = '-' }) => {
    return (
        <>
            {startDate.isValid() ? <DateRenderer date={startDate} format={format} /> : fallback} -{' '}
            {endDate.isValid() ? <DateRenderer date={endDate} format={format} /> : fallback}
        </>
    );
};

export default DateRangeRenderer;
