import { IDateRangeQuery } from 'contracts/requests/IDateRangeQuery';
import { GetBusinessIndustryResponseDataObject } from 'contracts/spotdif/GetBusinessIndustryResponseDataObject';
import { UserBase } from 'contracts/spotdif/UserBase';
import ITableHeaderFilters from 'contracts/view-models/ITableHeaderFilters';
import { GetTableResponseDataObject } from 'contracts/spotdif/GetTableResponseDataObject';

import dayjs from 'dayjs';
import useToggle from 'hooks/useToggle';
import { DateRangePicker } from 'mui-daterange-picker';
import React, { useEffect, useState } from 'react';
import './style.scss';

type leadsType = {
    name: string;
    value: string;
};

interface TableHeaderV2Props {
    entriesPerPage?: Array<number>;
    leadsType?: Array<leadsType>;
    updateFilters?: (key: string, value: string | number | IDateRangeQuery) => void;
    filters?: ITableHeaderFilters;
    status?: JSX.Element;
    showSorting?: boolean;
    hideSearchAndPerPage?: boolean;
    hideSearch?: boolean;
    handleStatus?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
    accountManagerList?: GetTableResponseDataObject;
    handleFilter?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
    clients?: Array<UserBase>;
    accountsManagers?: Array<UserBase>;
    businessIndustries?: Array<GetBusinessIndustryResponseDataObject>;
    handleCommissionStatus?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
    showCommissionStatus?: boolean;
    dateRange?: boolean;
    isPromoLink?: boolean;
    hidePerPage?: boolean;
}

const TableHeaderV2: React.FC<TableHeaderV2Props> = ({
                                                         entriesPerPage = [10, 25, 50, 100],
                                                         filters = {
                                                             search: '',
                                                             perPage: 10,
                                                             sortingOrder: 'desc',
                                                             status: '',
                                                             clientId: '',
                                                         },
                                                         status,
                                                         leadsType,
                                                         updateFilters = () => {
                                                         },
                                                         hideSearchAndPerPage = false,
                                                         hideSearch = false,
                                                         handleStatus,
                                                         accountManagerList,
                                                         handleFilter,
                                                         showSorting,
                                                         clients = [],
                                                         businessIndustries = [],
                                                         accountsManagers = [],
                                                         handleCommissionStatus = () => {
                                                         },
                                                         showCommissionStatus = false,
                                                         dateRange = false,
                                                         isPromoLink = false,
                                                         hidePerPage = false,
                                                     }) => {
    const [debouncedSearch, setDebouncedSearch] = useState(filters.search);
    const [isDatePickerOpen, toggleDatePicker] = useToggle(false);

    const [range, setDateRange] = useState<IDateRangeQuery>({
        startDate: dayjs().startOf('month'),
        endDate: dayjs().endOf('month'),
    });

    useEffect(() => {
        if (debouncedSearch) {
            const timeoutId = setTimeout(() => {
                updateFilters('search', debouncedSearch);
            }, 600);

            return () => {
                updateFilters('search', '');
                clearTimeout(timeoutId);
            };
        }
    }, [debouncedSearch]);

    const handleSearchChange = (event) => {
        const { value } = event.currentTarget;
        setDebouncedSearch(value);
    };
    //* --commented for now
    // const onDateRangeChange = useCallback((dateRange: DateRange) => {
    //     updateFilters('timePeriod', {
    //         startDate: dayjs(dateRange.startDate),
    //         endDate: dayjs(dateRange.endDate)
    //     })
    // }, []);

    function isOfType(obj: any): obj is IDateRangeQuery {
        return obj && typeof obj === 'object' && 'startDate' in obj && 'endDate' in obj;
    }

    const statusArray = [
        { value: 'all', label: 'All' },
        { value: 'live', label: 'Live' },
        { value: 'expired', label: 'Expired' },
    ];

    const commissionStatusArray = [
        { value: 'commissioned', label: 'Commission' },
        { value: 'nonCommissioned', label: 'Non Commission' },
    ];

    return (
        <div className="table_header full-width">
            <div className="table_header-left">
                {/* add-shadow */}
                <div className="table_header-grid">
                    <div className="table_header-grid-fixed">
                        {!hideSearchAndPerPage && (
                            <>
                                {!hideSearch && (
                                    <div className="table_header-col">
                                        <input
                                            type="text"
                                            className="form-select-small table-input filters-bold placeholder-bold"
                                            maxLength={256}
                                            name="field"
                                            data-name="Field"
                                            placeholder="Search"
                                            id="field"
                                            value={debouncedSearch}
                                            onChange={handleSearchChange} // Use the debounced event handler
                                        />
                                    </div>
                                )}

                                {!hidePerPage && (
                                    <div className="table_header-col">
                                        <select
                                            id="Per-page"
                                            name="Per-page"
                                            data-name="Per page"
                                            onChange={({ currentTarget }) =>
                                                updateFilters('perPage', Number(currentTarget.value))
                                            }
                                            className="form-select-small w-select table-input filters-bold"
                                        >
                                            {/* <option value="100">100 PER PAGE</option>; */}
                                            {entriesPerPage.map((val, index) => {
                                                return <option key={index} value={val}>{`${val} per page`}</option>;
                                            })}
                                        </select>
                                    </div>
                                )}

                                {showSorting && (
                                    <div className="table_header-col">
                                        <select
                                            id="field-2"
                                            name="field-2"
                                            data-name="Field 2"
                                            onChange={({ currentTarget }) =>
                                                updateFilters('sortingOrder', currentTarget.value)
                                            }
                                            className="form-select-small w-select table-input filters-bold placeholder-bold"
                                        >
                                            <option value="desc">Newest to oldest</option>
                                            <option value="asc">Oldest to newest</option>
                                        </select>
                                    </div>
                                )}

                                {leadsType?.length > 0 && (
                                    <div className="table_header-col">
                                        <select
                                            id="field-2"
                                            name="field-2"
                                            data-name="Field 2"
                                            defaultValue={leadsType[0].name}
                                            onChange={({ target }) => updateFilters('leadsStatus', target.value)}
                                            className="form-select-small w-select table-input filters-bold placeholder-bold"
                                        >
                                            {leadsType.map((item: leadsType) => (
                                                <option key={item.value} value={item.value}>
                                                    {item.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                )}
                                {clients.length > 0 && (
                                    <div className="table_header-col">
                                        <select
                                            id="field-2"
                                            name="field-2"
                                            data-name="Field 2"
                                            defaultValue={'All Clients'}
                                            onChange={({ target }) => updateFilters('clientId', target.value)}
                                            className="form-select-small w-select table-input filters-bold placeholder-bold"
                                        >
                                            <option value={''}>All Business</option>
                                            {clients?.map((val, index) => {
                                                return (
                                                    <option key={index} value={val._id} className="truncate-text">
                                                        {/* {`${val?.firstName} ${val?.lastName}`} */}
                                                        {`${val?.businessName} `}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </div>
                                )}
                                {businessIndustries.length > 0 && (
                                    <div className="table_header-col">
                                        <select
                                            id="field-2"
                                            name="field-2"
                                            data-name="Field 2"
                                            defaultValue={'All Clients'}
                                            onChange={({ target }) => updateFilters('industry', target.value)}
                                            className="form-select-small w-select table-input  table-input filters-bold"
                                        >
                                            <option value={''}>All Business Industries</option>
                                            {businessIndustries?.map((val, index) => {
                                                return (
                                                    <option key={index} value={val._id}>
                                                        {val.industry}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </div>
                                )}

                                {accountsManagers?.length > 0 && (
                                    <div className="table_header-col">
                                        <select
                                            id="field-2"
                                            name="field-2"
                                            data-name="Field 2"
                                            defaultValue={'Account Manager'}
                                            onChange={({ target }) => updateFilters('accountManagerId', target.value)}
                                            className="form-select-small w-select table-input table-input filters-bold"
                                        >
                                            <option value={''}>Accounts Managers</option>
                                            {accountsManagers?.map((val, index) => {
                                                return (
                                                    <option key={index} value={val._id}>
                                                        {`${val?.firstName ?? '-'} ${val?.lastName ?? ''}`}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </div>
                                )}
                            </>
                        )}
                        {hideSearchAndPerPage && (
                            <>
                                {!showCommissionStatus && (
                                    <div className="table_header-col">
                                        <select
                                            id="Per-page"
                                            name="Per-page"
                                            data-name="Per page"
                                            onChange={handleStatus}
                                            className="form-select-small w-select table-input filters-bold"
                                        >
                                            {statusArray.map((val, index) => {
                                                return (
                                                    <option key={index} value={val.value}>
                                                        {val.label}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </div>
                                )}

                                {dateRange && (
                                    <div className="table_header-col chart-date-filter">
                                        <input
                                            // type="text"
                                            className="form-select-small table-input filters-bold"
                                            maxLength={256}
                                            name="field"
                                            data-name="Field"
                                            placeholder="Time Period"
                                            id="field"
                                            onClick={toggleDatePicker}
                                            value={
                                                isOfType(filters.timePeriod) && Object.keys(filters.timePeriod).length
                                                    ? `${dayjs(filters.timePeriod.startDate).format(
                                                        'YYYY MMM  DD',
                                                    )} - ${dayjs(filters.timePeriod.endDate).format('YYYY MMM DD')}`
                                                    : 'Time Period'
                                            }
                                            // disabled
                                            type="button"
                                            // value={debouncedSearch}
                                            // onChange={handleSearchChange} // Use the debounced event handler
                                        />

                                        <div className="chart-date-picker-dropdown date-picker-dropdown--dashboard">
                                            <DateRangePicker
                                                open={isDatePickerOpen}
                                                wrapperClassName="spotdif-calendar-wrapper spotdif-calender-wrapper-dashboard"
                                                toggle={toggleDatePicker}
                                                onChange={(dateRange) =>
                                                    updateFilters('timePeriod', {
                                                        startDate: dayjs(dateRange.startDate),
                                                        endDate: dayjs(dateRange.endDate),
                                                    })
                                                }
                                                initialDateRange={{
                                                    startDate: range.startDate.toDate(),
                                                    endDate: range.endDate.toDate(),
                                                }}
                                            />
                                        </div>
                                    </div>
                                )}

                                <div className="table_header-col">
                                    <select
                                        id="Per-page"
                                        name="Per-page"
                                        data-name="Per page"
                                        // onChange={({ target }) => updateFilters("accountManagerId", target.value)}
                                        onChange={
                                            isPromoLink
                                                ? handleFilter
                                                : ({ target }) => updateFilters('accountManagerId', target.value)
                                        }
                                        className="form-select-small w-select table-input filters-bold"
                                    >
                                        <option className="text-bold" value={[]}>
                                            Accounts Managers
                                        </option>

                                        {accountManagerList?.data?.map((val, index) => {
                                            return (
                                                <option key={index} value={val._id}>
                                                    {`${val?.firstName ?? '-'} ${val?.lastName ?? ''}`}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </div>

                                {businessIndustries.length > 0 && (
                                    <div className="table_header-col">
                                        <select
                                            id="field-2"
                                            name="field-2"
                                            data-name="Field 2"
                                            defaultValue={'All Clients'}
                                            onChange={({ target }) => updateFilters('industry', target.value)}
                                            className="form-select-small w-select table-input filters-bold"
                                        >
                                            <option value={[]}>All Business Industries</option>
                                            {businessIndustries?.map((val, index) => {
                                                return (
                                                    <option key={index} value={val._id}>
                                                        {val.industry}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </div>
                                )}

                                {showCommissionStatus && (
                                    <div className="table_header-col">
                                        <select
                                            id="commission-status"
                                            name="commission-status"
                                            data-name="commission-status"
                                            onChange={({ target }) => updateFilters('commissionStatus', target.value)}
                                            className="form-select-small w-select table-input filters-bold"
                                        >
                                            <option value={''}>Commission Status</option>
                                            {commissionStatusArray.map((val, index) => {
                                                return (
                                                    <option key={index} value={val.value}>
                                                        {val.label}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </div>
            <div className="table_header-right">{status}</div>
        </div>
    );
};

export default TableHeaderV2;
