import React from 'react';
import { InputAdornment, MenuItem, Select } from '@mui/material';
import { useFormikContext } from 'formik';
import { IUserProfileRequest } from 'contracts/requests/IUserProfileResponse';
import { useGetMobilePrefixQuery } from 'redux/services/spotdif';
import MDInput from 'components/themed/MDInput';

const UserProfileDetails: React.FC = () => {
    const { values, handleBlur, handleChange, touched, errors } = useFormikContext<Omit<IUserProfileRequest, '_id'>>();
    const { data: mobilePrefixList } = useGetMobilePrefixQuery();
    return (
        <div className="tab-user-content-card">
            <div className="form-middle">
                <MDInput
                    type="text"
                    label="First Name"
                    placeholder="First Name"
                    onBlur={handleBlur}
                    value={values.firstName}
                    id="firstName"
                    name="firstName"
                    onChange={handleChange}
                    error={touched.firstName && Boolean(errors.firstName)}
                    helperText={touched.firstName && errors.firstName}
                />
                <MDInput
                    type="text"
                    label="Last Name"
                    placeholder="Last Name"
                    value={values.lastName}
                    onBlur={handleBlur}
                    id="lastName"
                    name="lastName"
                    onChange={handleChange}
                    error={touched.lastName && Boolean(errors.lastName)}
                    helperText={touched.lastName && errors.lastName}
                />
                <MDInput
                    type="text"
                    label="Email"
                    value={values.email}
                    onBlur={handleBlur}
                    id="email"
                    name="email"
                    onChange={handleChange}
                    error={touched.email && Boolean(errors.email)}
                    helperText={touched.email && errors.email}
                    disabled={true}
                />
                <MDInput
                    type="text"
                    label="Phone Number"
                    placeholder="Phone Number"
                    value={values.phoneNumber}
                    onBlur={handleBlur}
                    id="phoneNumber"
                    name="phoneNumber"
                    onChange={handleChange}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Select
                                    value={values.mobilePrefixCode}
                                    name="mobilePrefixCode"
                                    onChange={handleChange}
                                    MenuProps={{
                                        style: {
                                            maxHeight: '300px',
                                        },
                                        anchorOrigin: {
                                            vertical: 'top',
                                            horizontal: 'left',
                                        },
                                        transformOrigin: {
                                            vertical: 'top',
                                            horizontal: 'left',
                                        },
                                    }}
                                    className="input-adornment-select-mobile-prefix-business"
                                    renderValue={(selected) => {
                                        const selectedCountry = mobilePrefixList?.find(
                                            (country) => country?.mobilePrefixCode === selected,
                                        );
                                        return selectedCountry ? `+${selectedCountry?.mobilePrefixCode}` : '';
                                    }}
                                >
                                    {mobilePrefixList?.map((country) => (
                                        <MenuItem
                                            key={country?.label}
                                            value={country?.mobilePrefixCode}
                                            autoFocus={values.mobilePrefixCode === country?.mobilePrefixCode}
                                        >
                                            {`+${country?.mobilePrefixCode} - ${country?.label}`}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </InputAdornment>
                        ),
                    }}
                    error={touched.phoneNumber && Boolean(errors.phoneNumber)}
                    helperText={touched.phoneNumber && errors.phoneNumber}
                />
            </div>
        </div>
    );
};

export default UserProfileDetails;
