import { createSlice } from '@reduxjs/toolkit';
import { isApiErrorResponse } from 'contracts/requests/IApiErrorResponse';

import { isUserLoginResponse, IUserRegisterResponse } from 'contracts/requests/IRegisterUserRequest';
import { RootState } from 'redux/reducers';
import { spotdifApi } from 'redux/services/spotdif';
import { chatTokenApi } from 'redux/services/spotdif/chatToken';

export const AUTH_KEY = 'auth';
export const AUTH_TOKEN = 'auth_token';
export const CHAT_AUTH_TOKEN = 'auth_token_chat';

const persistedAuth = sessionStorage.getItem(AUTH_KEY) || localStorage.getItem(AUTH_KEY);
const persistedToken = sessionStorage.getItem(AUTH_TOKEN) || localStorage.getItem(AUTH_TOKEN);


interface AuthSlice {
    authenticated?: IUserRegisterResponse;
    token: string;
}

let initialState: AuthSlice = {
    authenticated: persistedAuth ? JSON.parse(persistedAuth) : {},
    token: persistedToken ? JSON.parse(persistedToken).token : '',
};

export const authSlice = createSlice({
    name: AUTH_KEY,
    initialState,
    reducers: {
        setAccessToken: (state, { payload }) => {
            state.token = payload;
        },
        logout: (state) => {
            sessionStorage.removeItem(AUTH_KEY);
            sessionStorage.removeItem(AUTH_TOKEN);
            localStorage.removeItem(AUTH_KEY);
            localStorage.removeItem(AUTH_TOKEN);
            sessionStorage.removeItem(CHAT_AUTH_TOKEN);
            localStorage.removeItem(CHAT_AUTH_TOKEN);

            window.location.reload();
        },
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(spotdifApi.endpoints.loginUser.matchFulfilled, (state, { payload }) => {
                // "token" in payload?.data

                if (isUserLoginResponse(payload)) {
                    state.authenticated = payload;
                    sessionStorage.setItem(AUTH_TOKEN, JSON.stringify({ token: payload.token }));
                    localStorage.setItem(AUTH_TOKEN, JSON.stringify({ token: payload.token }));
                    sessionStorage.setItem(AUTH_KEY, JSON.stringify(payload));
                    localStorage.setItem(AUTH_KEY, JSON.stringify(payload));
                } else {
                    state = null;
                    sessionStorage.removeItem(AUTH_KEY);
                    localStorage.removeItem(AUTH_TOKEN);
                    sessionStorage.removeItem(AUTH_TOKEN);

                    localStorage.removeItem(AUTH_KEY);
                }
            })
            .addMatcher(spotdifApi.endpoints.loginUser.matchRejected, (state, { payload }) => {
                if (isApiErrorResponse(payload.data)) {
                } else {
                    console.error(`something went wrong`);
                }
                console.error(payload.data, 'error');
            })
            .addMatcher(chatTokenApi.endpoints.loginUser.matchRejected, (state, { payload }) => {
                if (isApiErrorResponse(payload.data)) {
                } else {
                    console.error(`something went wrong`);
                }
                console.error(payload.data, 'error');
            })
            .addMatcher(spotdifApi.endpoints.registerUser.matchFulfilled, (state, { payload }) => {
                if (isUserLoginResponse(payload)) {
                    state.authenticated = payload;
                    state.token = payload.token;
                    sessionStorage.setItem(AUTH_KEY, JSON.stringify(payload));

                    localStorage.setItem(AUTH_KEY, JSON.stringify(payload));
                    sessionStorage.setItem(AUTH_TOKEN, JSON.stringify({ token: payload.token }));
                    localStorage.setItem(AUTH_TOKEN, JSON.stringify({ token: payload.token }));
                } else {
                    state = null;
                    sessionStorage.removeItem(AUTH_KEY);
                    localStorage.removeItem(AUTH_KEY);
                    sessionStorage.removeItem(AUTH_TOKEN);
                    localStorage.removeItem(AUTH_TOKEN);
                    console.error('something went wrong');
                }
            });
    },
});

export default authSlice.reducer;
export const { logout, setAccessToken } = authSlice.actions;
export const selectCurrentUser = (state: RootState) => state.auth.authenticated;

export const selectIsLoggedIn = (state: RootState) => !!state.auth.authenticated?.token && !!state.auth.token;
