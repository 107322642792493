import BorderColorIcon from '@mui/icons-material/BorderColor';

// MATERIAL ICONS
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import ErrorIcon from '@mui/icons-material/Error';
import StarIcon from '@mui/icons-material/Star';
import { Autocomplete, Card, Checkbox, Grid, InputAdornment, Switch, Tooltip } from '@mui/material';
import classNames from 'classnames';
import { MODULE, PERMISSIONS } from 'components/app-permission/permission';
import { useUserHasPermission } from 'components/app-permission/useUserHasPermission';
import BasicModal from 'components/core/Modal';
import CurrencySymbol from 'components/shared/CurrencyFormat';

// CUSTOM COMPONENT
import MDBox from 'components/themed/MDBox';
import MDButton from 'components/themed/MDButton';
import MDInput from 'components/themed/MDInput';
import MDTypography from 'components/themed/MDTypography';
import { DaySchedule } from 'contracts/spotdif/DaySchedule';

// IMPORT INTERFACE
import { UserBase, UserBaseSerializer } from 'contracts/spotdif/UserBase';
import { TAG_CLIENT_UPDATE, TAG_SAVE_CLIENT_DETAIL } from 'contracts/spotDiffAPITags';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { spotdifApi } from 'redux/services/spotdif';

// IMPORT API HOOKS
import { useSaveClientDetailsMutation, useUpdateCreditMutation } from 'redux/services/spotdif/clients';
import { useSendTestLeadMutation } from 'redux/services/spotdif/onBoarding';
import { useAppDispatch } from 'redux/store';
import {
    capitalizeFirstLetter,
    copyUrl,
    onlyNumbersRegExp,
    onlyNumbersWithTwoDecimal,
    onlyNumberWithPositiveNegativeRegExp,
    sumIfAbsoluteDifferenceIsNonNegative,
} from 'utils/text-helpers';
import handleKeyPress from 'utils/validation';

import CopyModal from './CopyModal/CopyModal';
import { PopupType } from './EnumTypeVal';
import UpdateModal from './UpdateModal';

const entries = [
    { label: 'Auto charge', value: 'auto charge' },
    // { label: 'Weekly payment', value: 'weekly payment' },
    { label: 'Add credits manually', value: 'add credits manually' },
];

interface ChangedData {
    paymentMethod?: string;
    autoChargeAmount?: number;
    isAutoChargeEnabled?: boolean;
    triggerAmount?: number;
    secondaryLeads?: number;
    secondaryLeadCost?: number;
}

interface ExpandedRowProps {
    userDetail: UserBase;
    setExpanded: (obj: {}) => void;
    spanlength: number;
    availableAccessManagers: UserBase[];
}

const ExpandedRow: React.FC<ExpandedRowProps> = ({ userDetail, setExpanded, spanlength, availableAccessManagers }) => {
    const [saveClientDetails, { isLoading: isSavingClientDetails }] = useSaveClientDetailsMutation();
    const [updateCreditAmount, { isLoading: isUpdatingCredits }] = useUpdateCreditMutation();
    const [sendTestLeadTrigger] = useSendTestLeadMutation();
    const { enqueueSnackbar } = useSnackbar();
    const appDispatch = useAppDispatch();

    const [userData, setUserData] = useState<any>(userDetail);
    const [secondaryUserData, setSecondaryUserData] = useState<any>(userDetail);
    const [isLeadEqual, setIsLeadEqual] = useState(false);

    const [isBillable, setIsBillable] = useState(userDetail?.isCreditsAndBillingEnabled);
    const [changedData, setChangedData] = useState<ChangedData>({
        isAutoChargeEnabled: userDetail.isAutoChargeEnabled,
    });
    const [changedSecondaryData, setChangedSecondaryData] = useState<ChangedData>({});
    const [clientPostCode, setClientPostCode] = useState<any>(
        userDetail?.userLeadsDetailsId?.type === 'map'
            ? {
                ...userDetail?.userLeadsDetailsId?.postCodeTargettingList,
            }
            : [...userDetail?.userLeadsDetailsId?.postCodeList],
    );
    const [checkLeadCost, setCheckLeadCost] = useState(userDetail?.isLeadCostCheck);
    const [active, setActive] = useState(userDetail.isActive);
    const [archive, setArchive] = useState(userDetail.isArchived);

    const [openCopyModal, setOpenCopyModal] = useState(false);
    const [copyPopupType, setCopyPopupType] = useState(null);

    const handleCloseCopyCodeModal = () => setOpenCopyModal(false);

    // const [creditsData, setCreditsData] = useState(userDetail?.credits);
    const [creditsData, setCreditsData] = useState(0);
    const [creditError, setCreditError] = useState('');

    const [showTriggerAmountErorr, setShowTriggerAmountError] = useState(false);
    const [triggerAmountError, setTriggerAmountError] = useState('');
    const [showAutoChargeError, setShowAutoChargeError] = useState(false);
    const [AutoChargeError, setAutoChargeError] = useState('');

    const [showSecondaryLeadCostError, setShowSecondaryLeadCostError] = useState(false);
    const [secondaryLeadCostError, setSecondaryLeadCostError] = useState('');
    const [showSecondaryCreditError, setShowSecondaryCreditError] = useState(false);
    const [secondaryCreditError, setSecondaryCreditError] = useState('');

    const [isModalOpen, setIsModalOpen] = useState(false);

    const hideModal = () => setIsModalOpen(false);

    const [generateInvoice, setGenerateInvoice] = useState(true);
    const [generateSecondaryInvoice, setGenerateSecondaryInvoice] = useState(true);

    const hasAdjustPermission = useUserHasPermission(MODULE.ADJUST_LEADS, PERMISSIONS.UPDATE);

    useEffect(() => {
        setUserData(userDetail);
    }, [userDetail]);

    const handleName = useCallback(
        (e, key) => {
            setChangedData((prev) => ({
                ...prev,
                [key]: e.target.value,
            }));

            setUserData((prev) => ({
                ...prev,
                [key]: e.target.value,
            }));
        },
        [setChangedData, setUserData],
    );

    const handleBusinessChange = useCallback(
        (e, key) => {
            setChangedData((prev) => {
                return {
                    ...prev,
                    [key]: e.target.value,
                };
            });

            setUserData((prevState) => ({
                ...prevState,
                businessDetailsId: {
                    ...prevState.businessDetailsId,
                    [key]: e.target.value,
                },
            }));
        },
        [setChangedData, setUserData],
    );

    // handle credit amount change
    // const handleCreditChange = useCallback((e) => {
    //     if (e.target.value % userData?.leadCost !== 0 && !(e.target.value < userData?.leadCost * 5)) {
    //         setCreditError(`Value must be a multiple of ${userData?.leadCost}`);
    //         setIsLeadEqual(true);
    //         return;
    //     } else if (e.target.value < userData?.leadCost * 5) {
    //         setCreditError(`Minimum amount should be 5 times lead cost ${userData?.leadCost * 5}`);
    //         setIsLeadEqual(true);
    //         return;
    //     } else {
    //         setIsLeadEqual(false);
    //     }
    //     setCreditsData(e.target.value);
    // }, [userData?.leadCost]);

    const handleCreditLeadsChange = useCallback(
        (e) => {
            if (hasAdjustPermission) {
                if (onlyNumberWithPositiveNegativeRegExp.test(e.target.value)) {
                    handleCreditChange(e);
                }
            } else {
                if (onlyNumbersRegExp.test(e.target.value)) {
                    handleCreditChange(e);
                }
            }
        },
        [hasAdjustPermission],
    );

    const handleCreditChange = useCallback(
        (e) => {
            if (hasAdjustPermission) {
                const inputValue = e.target.value;

                if (inputValue.length === 0 || inputValue === '-') {
                    setCreditError('Leads cannot be empty');
                    setIsLeadEqual(true);
                    setCreditsData(inputValue);
                    return;
                } else {
                    setIsLeadEqual(false);
                }

                // Check if the entered value is a valid number
                const parsedValue = parseFloat(inputValue);
                if (!isNaN(parsedValue) && Number.isInteger(parsedValue)) {
                    setCreditsData(parsedValue);
                } else {
                    setCreditError('Please enter a valid integer value');
                    setIsLeadEqual(true);
                }
            } else {
                if (e.target.value <= 0) {
                    setCreditError(`Leads Cannot be in Negative or zero`);
                    setIsLeadEqual(true);
                    setCreditsData(e.target.value);

                    return;
                } else {
                    setIsLeadEqual(false);
                }
                setCreditsData(e.target.value * 1);
            }
        },
        [userData?.leadCost, hasAdjustPermission],
    );

    const handleCPLChange = useCallback((e) => {
        if (onlyNumbersWithTwoDecimal.test(e.target.value)) {
            handleChange(e, 'leadCost');
        }
    }, []);

    const handleSecondaryCreditsChange = useCallback((e) => {
        const inputValue = e.target.value;
        if (onlyNumbersRegExp.test(inputValue)) {
            if (inputValue.length === 0) {
                setSecondaryCreditError('Secondary leads are required');
                setShowSecondaryCreditError(true);
            } else {
                setSecondaryCreditError('');
                setShowSecondaryCreditError(false);
            }
            handleChangeSecondaryLeads(e, 'secondaryLeads');
        }
    }, []);

    const handleSecondaryLeadCostChange = useCallback((e) => {
        const inputValue = e.target.value;
        if (onlyNumbersWithTwoDecimal.test(inputValue)) {
            if (inputValue.length === 0) {
                setSecondaryLeadCostError('Secondary lead cost is required');
                setShowSecondaryLeadCostError(true);
            } else {
                setSecondaryLeadCostError('');
                setShowSecondaryLeadCostError(false);
            }
            handleChangeSecondaryLeads(e, 'secondaryLeadCost');
        }
    }, []);

    const handleTriggerAmountChange = useCallback((e) => {
        if (e.target.value <= 0) {
            setTriggerAmountError('Remaining leads is required');
            setShowTriggerAmountError(true);
        } else if (e.target.value > 0 && e.target.value < 10) {
            setAutoChargeError('Minimum 10 leads');
            setShowTriggerAmountError(true);
        } else {
            setTriggerAmountError('');
            setShowTriggerAmountError(false);
        }
        handleChange(e, 'triggerAmount');
    }, []);

    // const handleTriggerAmountChange = useCallback((e) => {
    //     if (onlyNumbersRegExp.test(e.target.value)) {
    //         handleChange(e, "triggerAmount");
    //     }
    // }, []);

    // const handleAutoChargeChange = useCallback((e) => {
    //     if (onlyNumbersRegExp.test(e.target.value)) {
    //         handleChange(e, "autoChargeAmount");
    //     }
    // }, []);

    const handleAutoChargeChange = useCallback((e) => {
        if (e.target.value <= 0) {
            setAutoChargeError('Leads is required');
            setShowAutoChargeError(true);
        } else if (e.target.value > 0 && e.target.value < 10) {
            setAutoChargeError('Minimum 10 leads');
            setShowAutoChargeError(true);
        } else {
            setAutoChargeError('');
            setShowAutoChargeError(false);
        }
        handleChange(e, 'autoChargeAmount');
    }, []);

    const handleChange = useCallback((e, key) => {
        setChangedData((prev) => {
            return {
                ...prev,
                [key]: e.target.value,
            };
        });

        setUserData((prev) => {
            return {
                ...prev,
                [key]: e.target.value,
            };
        });
    }, []);

    const handleChangeSecondaryLeads = useCallback((e, key) => {
        setChangedSecondaryData((prev) => {
            return {
                ...prev,
                [key]: e.target.value,
            };
        });

        setSecondaryUserData((prev) => {
            return {
                ...prev,
                [key]: e.target.value,
            };
        });
    }, []);

    const handleLeadsDetailsChange = useCallback((e, key) => {
        setChangedData((prev) => {
            return {
                ...prev,
                [key]: e.target.value,
            };
        });

        setUserData((prevState) => ({
            ...prevState,
            userLeadsDetailsId: {
                ...prevState.userLeadsDetailsId,
                [key]: e.target.value,
            },
        }));
    }, []);

    const handlePaymentMethodChange = useCallback((e, value, key) => {
        if (key === 'paymentMethod') {
            setAutoChargeError('');
            setShowAutoChargeError(false);
            setTriggerAmountError('');
            setShowTriggerAmountError(false);
        }
        setChangedData((prev) => {
            return {
                ...prev,
                [key]: value,
            };
        });
        setUserData((prev) => {
            return UserBaseSerializer.parse({
                ...prev,
                // paymentMethod: value,
                [key]: value,
            });
        });
    }, []);

    const handleUserNotes = useCallback((e, key) => {
        setChangedData((prev) => {
            return {
                ...prev,
                [key]: e.target.value,
            };
        });
        setUserData((prev) => {
            return {
                ...prev,
                userNotes: e.target.value,
            };
        });
    }, []);

    const handleArchive = useCallback((e, isArchived, key) => {
        setChangedData((prev) => {
            return {
                ...prev,
                [key]: !isArchived,
            };
        });
        setArchive(!isArchived);
        setUserData((prev) => {
            return {
                ...prev,
                isArchived: !isArchived,
            };
        });
    }, []);

    // UPDATE CREDIT AMOUNT
    const updateCredit = useCallback(
        (e) => {
            if (hasAdjustPermission) {
                if (
                    sumIfAbsoluteDifferenceIsNonNegative(
                        Number(userDetail?.credits / userDetail?.leadCost),
                        creditsData,
                    ) < 0
                ) {
                    return enqueueSnackbar('The credit balance of this user cannot be negative.', {
                        variant: 'error',
                        key: 'lead',
                    });
                }
                if (creditsData === 0) {
                    return enqueueSnackbar('Leads Cannot zero', { variant: 'error', key: 'lead' });
                }
            } else {
                if (creditsData <= 0) {
                    return enqueueSnackbar('Leads Cannot be in Negative or zero', { variant: 'error', key: 'lead' });
                }
            }

            let updatedData = {
                generateInvoice,
                credits: creditsData,
            };
            updateCreditAmount({ userId: userDetail._id, ...updatedData })
                .unwrap()
                .then((res) => {
                    enqueueSnackbar(res?.data?.message, { variant: 'success', key: 'lead' });
                    // setExpanded({});
                    // FIXME: The RTK Invalidate Tags within mutation should work just fine. [12-10-2023 1257]
                    // Will need to retry the same with apis on same network before Production.
                    setTimeout(() => {
                        appDispatch(spotdifApi.util.invalidateTags([TAG_CLIENT_UPDATE, TAG_SAVE_CLIENT_DETAIL]));
                    }, 500);
                })
                .catch(({ data }) => {
                    enqueueSnackbar(data?.error?.message ?? 'Something went wrong.', {
                        variant: 'error',
                        key: 'lead',
                    });
                });
        },
        [
            appDispatch,
            creditsData,
            enqueueSnackbar,
            setExpanded,
            updateCreditAmount,
            userDetail,
            userDetail?._id,
            hasAdjustPermission,
            generateInvoice,
        ],
    );

    const updateClientData = useCallback(
        (e) => {
            if (isBillable && !changedData.paymentMethod && !userData.paymentMethod) {
                enqueueSnackbar('Please select a payment method.', { variant: 'error', key: 'paymentMethod' });
                return;
            }
            if (changedData.paymentMethod === 'auto charge' || userData.paymentMethod === 'auto charge') {
                if (
                    (!changedData.autoChargeAmount &&
                        (changedData.autoChargeAmount < 5 || changedData.autoChargeAmount === null)) ||
                    (changedData.autoChargeAmount && changedData.autoChargeAmount < 5)
                ) {
                    return;
                }
                if (
                    (!changedData.triggerAmount &&
                        (changedData.triggerAmount < 5 || changedData.triggerAmount === null)) ||
                    (changedData.triggerAmount && changedData.triggerAmount < 5)
                ) {
                    return;
                }
            }

            if (showSecondaryCreditError || showSecondaryLeadCostError) {
                return;
            }
            // }
            // delete userData.credits;
            // delete userData.businessDetailsId;
            // delete userData.userLeadsDetailsId;

            let checkLeadCostChecked = { isLeadCostCheck: checkLeadCost };
            let updatedData = {
                ...changedData,
                ...checkLeadCostChecked,
            };

            if (!(userData?.leadCost == null || +userData?.leadCost === 0)) {
                // mutate({ id: userId, data: updatedData });
                if (updatedData?.autoChargeAmount) {
                    updatedData.autoChargeAmount = updatedData?.autoChargeAmount * userData?.leadCost;
                    // updatedData.triggerAmount = updatedData?.triggerAmount * userData?.leadCost;
                }
                if (updatedData?.triggerAmount) {
                    // updatedData.autoChargeAmount = updatedData?.autoChargeAmount * userData?.leadCost;
                    updatedData.triggerAmount = updatedData?.triggerAmount * userData?.leadCost;
                }
            }

            const { secondaryLeads, secondaryLeadCost, ...restValues } = updatedData;
            saveClientDetails({ _id: userDetail._id, ...restValues })
                .unwrap()
                .then((res) => {
                    enqueueSnackbar(res?.message, { variant: 'success', key: 'lead' });
                })
                .catch(({ data }) => {
                    enqueueSnackbar(data?.error?.message ?? 'Something went wrong.', { variant: 'error', key: 'lead' });
                });
        },
        [isBillable, changedData, checkLeadCost, enqueueSnackbar, saveClientDetails, setExpanded, userDetail?._id],
    );

    const handleActiveUserStatus = useCallback((e) => {
        setActive(e);
        // mutate({ id: userId, data: { isActive: e.target.checked } });
        // saveClientDetails({ _id: userDetail._id, isActive: e.target.checked })
        // mutate({ id: userId, data: { isActive: e.target.checked } });
        setChangedData((prev) => {
            return {
                ...prev,
                isActive: e.target.checked,
            };
        });
    }, []);

    const handleAutoChargeStatus = useCallback((isChecked: boolean) => {
        setChangedData((prev) => {
            return {
                ...prev,
                isAutoChargeEnabled: isChecked,
            };
        });
    }, []);

    // HANDLE CLIENT STATUS
    const changeClientStatus = useCallback(() => {
        let updatedData = { isCreditsAndBillingEnabled: !isBillable };
        saveClientDetails({ _id: userDetail._id, ...updatedData })
            .unwrap()
            .then((res) => {
                enqueueSnackbar(res?.message, { variant: 'success', key: 'lead' });
                appDispatch(spotdifApi.util.invalidateTags([TAG_CLIENT_UPDATE, TAG_SAVE_CLIENT_DETAIL]));
                setIsBillable(!isBillable);
            })
            .catch(({ data }) => {
                enqueueSnackbar(data?.error?.message ?? 'Something went wrong.', { variant: 'error', key: 'lead' });
            });
    }, [appDispatch, enqueueSnackbar, isBillable, saveClientDetails, userDetail?._id]);

    const formatBusinessPhone = (businessPhone: string) => {
        const phone = businessPhone;
        const formattedPhone = phone ? (phone.startsWith('0') ? phone : `0${phone}`) : '';
        return formattedPhone;
    };

    const sendTestLead = useCallback(
        (id: string, zapierUrl: string) => {
            const testLeadData = {
                _id: id,
                zapierUrl: zapierUrl,
            };
            sendTestLeadTrigger(testLeadData)
                .unwrap()
                .then((res) => {
                    return enqueueSnackbar(res?.data, { variant: 'success', key: 'send-test-lead' });
                })
                .catch((error) => {
                    return enqueueSnackbar(error?.data?.data ?? error?.data?.error?.message ?? 'Something went wrong', {
                        variant: 'error',
                        key: 'send-test-lead',
                    });
                });
        },
        [sendTestLeadTrigger],
    );

    const handleAddSecondaryLeads = useCallback(() => {
        if (changedSecondaryData?.secondaryLeadCost && changedSecondaryData?.secondaryLeads) {
            let updatedData = {
                ...changedSecondaryData,
                generateInvoice: generateSecondaryInvoice,
            };

            if (updatedData?.secondaryLeads) {
                updatedData.secondaryLeads = Number(updatedData.secondaryLeads);
            }
            if (updatedData.secondaryLeadCost) {
                updatedData.secondaryLeadCost = Number(updatedData.secondaryLeadCost);
            }
            saveClientDetails({ _id: userDetail._id, ...updatedData })
                .unwrap()
                .then((res) => {
                    enqueueSnackbar(res?.message, { variant: 'success', key: 'lead' });
                })
                .catch(({ data }) => {
                    enqueueSnackbar(data?.error?.message ?? 'Something went wrong.', { variant: 'error', key: 'lead' });
                });
        } else {
            return enqueueSnackbar('Please enter Secondary Lead Cost and Secondary Leads', {
                variant: 'error',
                key: 'secondary lead',
            });
        }
    }, [
        appDispatch,
        changedSecondaryData,
        enqueueSnackbar,
        setExpanded,
        saveClientDetails,
        userDetail,
        userDetail?._id,
    ]);

    const [modalContent, setModalContent] = useState({
        modalHeader: '',
        modalButton: '',
    });

    const handleOpenModal = useCallback(
        (isOpen, type) => {
            setIsModalOpen(isOpen);
            if (type === PopupType.RESET_PASS) {
                setModalContent({
                    modalHeader: 'Change Password',
                    modalButton: 'Change Password',
                });
            } else if (type === PopupType.RESET_EMAIL) {
                setModalContent({
                    modalHeader: 'Update Email',
                    modalButton: 'Update Email',
                });
            }
        },
        [setIsModalOpen, setModalContent],
    );

    const handleCopyOpenModal = useCallback(
        (isOpen: boolean, type: string) => {
            setOpenCopyModal(true);
            if (type === 'schedule') {
                setCopyPopupType('schedule');
            } else if (type === 'postcode') {
                setCopyPopupType('postcode');
            } else {
                setCopyPopupType(null);
            }
        },
        [setOpenCopyModal, setCopyPopupType],
    );

    const scheduleValue = useMemo(() => {
        return (
            userData?.userLeadsDetailsId?.leadSchedule
                ?.filter((daysObj: DaySchedule) => daysObj?.isActive)
                .map((dayObj: DaySchedule) => dayObj.day.slice(0, 3))
                .join(', ') ?? ''
        );
    }, [userData]);

    return (
        <>
            <td colSpan={12} className="details-row-expand expanded-client-row">
                <div className="row-expand">
                    <>
                        <MDBox
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                // backgroundColor: "#f4f4fa",
                            }}
                        >
                            <MDBox
                                mt={1}
                                mb={2}
                                ml={2}
                                mr={2}
                                sx={{
                                    width: 'auto',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    overflowY: 'hidden',
                                    xs: { flexDirection: 'column' },
                                }}
                            >
                                <Grid container xl={6} mr={4} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                    {/* LEFT CARD */}
                                    <MDBox
                                        width="90%"
                                        height="100%"
                                        sx={{ marginTop: 1.5, background: '#F8F8F8', padding: '30px 60px' }}
                                        className="expand-left-box"
                                    >
                                        <MDBox className="expand-heading-top">Client details</MDBox>
                                        <MDBox className="" marginTop={2} marginBottom={2}>
                                            <p>Buyer ID</p>
                                            <MDBox className="expand-field-value">
                                                <MDTypography variant="p3">{userData?.buyerId ?? `-`}</MDTypography>
                                            </MDBox>
                                        </MDBox>

                                        <MDBox className="" marginTop={2} marginBottom={2}>
                                            <p>Company name</p>
                                            <MDBox className="expand-field-value text-field-icon">
                                                <MDInput
                                                    fullWidth
                                                    type="text"
                                                    value={userData?.businessDetailsId?.businessName ?? ''}
                                                    id={'businessName'}
                                                    autoComplete={'businessName'}
                                                    inputProps={{ maxLength: 80 }}
                                                    onChange={(e) => {
                                                        handleBusinessChange(e, 'businessName');
                                                    }}
                                                    disabled={userDetail?.hasOnboarded}
                                                    fontFamily="EuclidLight"
                                                    fontSize="16px"
                                                    className="client-table-width"
                                                />
                                                {
                                                    userDetail?.hasOnboarded &&
                                                    <Tooltip
                                                        title="Business name can be changed from Lead Center"
                                                        placement="top"
                                                    >
                                                        <ErrorIcon
                                                            className="copy-content-icon"
                                                        />

                                                    </Tooltip>
                                                }

                                            </MDBox>
                                        </MDBox>

                                        <MDBox className="" marginTop={2} marginBottom={2}>
                                            <p>Client name</p>
                                            <MDBox className="expand-field-value">
                                                <MDInput
                                                    fullWidth
                                                    type="text"
                                                    value={userData?.firstName + ' ' + userData?.lastName}
                                                    id={'firstName'}
                                                    autoComplete={'firstName'}
                                                    onChange={(e) => handleName(e, 'firstName')}
                                                    disabled
                                                />
                                            </MDBox>
                                        </MDBox>

                                        <MDBox className="" marginTop={2} marginBottom={2}>
                                            <p>Email</p>
                                            <MDBox className="expand-field-value text-field-icon">
                                                <MDInput
                                                    disabled
                                                    fullWidth
                                                    type="email"
                                                    value={userData?.email}
                                                    id={'email'}
                                                    autoComplete={'email'}
                                                    onChange={(e) => {
                                                        handleChange(e, 'email');
                                                    }}
                                                />
                                                <ContentCopyOutlinedIcon
                                                    className="copy-content-icon"
                                                    onClick={() => copyUrl(userData?.email, 'Email Copied')}
                                                />
                                                <BorderColorIcon
                                                    className="edit-content-icon"
                                                    onClick={() => handleOpenModal(true, PopupType.RESET_EMAIL)}
                                                />
                                            </MDBox>
                                        </MDBox>

                                        <MDBox className="" marginTop={2} marginBottom={2}>
                                            <p>Address</p>
                                            <MDBox className="expand-field-value">
                                                <MDInput
                                                    fullWidth
                                                    type="text"
                                                    value={userData?.businessDetailsId?.businessAddress ?? ''}
                                                    id={'businessAddress'}
                                                    autoComplete={'businessAddress'}
                                                    onChange={(e) => {
                                                        handleBusinessChange(e, 'businessAddress');
                                                    }}
                                                    fontFamily="EuclidLight"
                                                    fontSize="16px"
                                                />
                                            </MDBox>
                                        </MDBox>

                                        <MDBox className="" marginTop={2} marginBottom={2}>
                                            <p>Business Phone Number</p>
                                            <MDBox className="expand-field-value">
                                                <MDInput
                                                    fullWidth
                                                    type="text"
                                                    value={formatBusinessPhone(
                                                        userData?.businessDetailsId?.businessSalesNumber,
                                                    )}
                                                    id={'businessSalesNumber'}
                                                    autoComplete={'businessSalesNumber'}
                                                    onChange={(e) => {
                                                        if (!/(^[0-9]+$|^$)/.test(e.target.value)) {
                                                            return;
                                                        }
                                                        handleBusinessChange(e, 'businessSalesNumber');
                                                    }}
                                                    fontFamily="EuclidLight"
                                                    fontSize="16px"
                                                />
                                            </MDBox>
                                        </MDBox>

                                        <MDBox className="" marginTop={2} marginBottom={2}>
                                            <p>Business Website</p>
                                            <MDBox className="expand-field-value">
                                                <MDInput
                                                    fullWidth
                                                    type="text"
                                                    value={userData?.businessDetailsId?.businessUrl}
                                                    id={'businessUrl'}
                                                    autoComplete={'businessUrl'}
                                                    placeholder="https://spotdif.com"
                                                    onChange={(e) => {
                                                        handleBusinessChange(e, 'businessUrl');
                                                    }}
                                                    fontFamily="EuclidLight"
                                                    fontSize="16px"
                                                />
                                            </MDBox>
                                        </MDBox>

                                        <MDBox className="" marginTop={2} marginBottom={2}>
                                            <p>City</p>
                                            <MDBox className="expand-field-value">
                                                <MDInput
                                                    fullWidth
                                                    type="text"
                                                    value={userData?.businessDetailsId?.businessCity ?? ''}
                                                    id={'businessCity'}
                                                    autoComplete={'businessCity'}
                                                    onChange={(e) => {
                                                        handleBusinessChange(e, 'businessCity');
                                                    }}
                                                />
                                            </MDBox>
                                        </MDBox>

                                        <MDBox className="" marginTop={2} marginBottom={2}>
                                            <p>Business Postcode</p>
                                            <MDBox className="expand-field-value">
                                                <MDInput
                                                    fullWidth
                                                    type="text"
                                                    value={userData?.businessDetailsId?.businessPostCode ?? ''}
                                                    id={'businessPostCode'}
                                                    autoComplete={'businessPostCode'}
                                                    onChange={(e) => {
                                                        handleBusinessChange(e, 'businessPostCode');
                                                    }}
                                                />
                                            </MDBox>
                                        </MDBox>

                                        <MDBox className="" marginTop={2} marginBottom={2}>
                                            <p>Lead API Url</p>
                                            <MDBox className="expand-field-value text-field-icon">
                                                <MDInput
                                                    fullWidth
                                                    type="text"
                                                    value={
                                                        userData?.buyerId && userData?.buyerId?.length > 150
                                                            ? `${process.env.REACT_APP_API_SERVER}/api/v1/leads/${userData?.buyerId}`.slice(
                                                            0,
                                                            150,
                                                        ) + '...'
                                                            : `${process.env.REACT_APP_API_SERVER}/api/v1/leads/${userData?.buyerId}`
                                                    }
                                                    id={'zapierUrl'}
                                                    autoComplete={'zapierUrl'}
                                                    disabled
                                                />
                                                <ContentCopyOutlinedIcon
                                                    className="copy-content-icon"
                                                    onClick={() =>
                                                        copyUrl(
                                                            `${process.env.REACT_APP_API_SERVER}/api/v1/leads/${userData?.buyerId}`,
                                                            'URL Copied',
                                                        )
                                                    }
                                                />
                                            </MDBox>
                                        </MDBox>

                                        <MDBox className="" marginTop={2} marginBottom={2}>
                                            <p>Webhook / Zapier Url</p>
                                            <MDBox className="d-flex send-test-lead-button">
                                                <MDInput
                                                    fullWidth
                                                    type="text"
                                                    value={userData?.userLeadsDetailsId?.zapierUrl ?? ''}
                                                    id={'zapierUrl'}
                                                    autoComplete={'zapierUrl'}
                                                    onChange={(e) => {
                                                        handleLeadsDetailsChange(e, 'zapierUrl');
                                                    }}
                                                />

                                                <button
                                                    type="button"
                                                    onClick={() =>
                                                        sendTestLead(
                                                            userData?._id,
                                                            userData?.userLeadsDetailsId?.zapierUrl,
                                                        )
                                                    }
                                                    className="button ml-1"
                                                >
                                                    Send Test Lead
                                                </button>
                                            </MDBox>
                                        </MDBox>
                                        <MDBox className="" marginTop={2} marginBottom={2}>
                                            <p>Daily lead volume</p>
                                            <MDBox className="expand-field-value">
                                                <MDInput
                                                    fullWidth
                                                    type="text"
                                                    value={userData?.userLeadsDetailsId?.daily ?? ''}
                                                    id={'daily'}
                                                    autoComplete={'daily'}
                                                    onChange={(e) => {
                                                        if (!/(^[0-9]+$|^$)/.test(e.target.value)) {
                                                            return;
                                                        }
                                                        handleLeadsDetailsChange(e, 'daily');
                                                    }}
                                                />
                                            </MDBox>
                                        </MDBox>
                                        {/*-- Client Schedule */}

                                        <MDBox className="" marginTop={2} marginBottom={2}>
                                            <p>Client Schedule</p>
                                            <MDBox className="expand-field-value text-field-icon">
                                                <MDInput
                                                    disabled
                                                    fullWidth
                                                    type="text"
                                                    value={scheduleValue}
                                                    id={'schedule'}
                                                    autoComplete={'schedule'}
                                                />
                                                {userData?.userLeadsDetailsId?.leadSchedule?.filter(
                                                    (daysObj: DaySchedule) => daysObj.isActive,
                                                ).length > 0 ? (
                                                    <ErrorIcon
                                                        className="copy-content-icon"
                                                        onClick={() => handleCopyOpenModal(true, 'schedule')}
                                                    />
                                                ) : (
                                                    ''
                                                )}
                                            </MDBox>
                                        </MDBox>

                                        <MDBox className="" marginTop={2} marginBottom={2}>
                                            <p>
                                                {userDetail?.userLeadsDetailsId?.postCodeList?.length > 0
                                                    ? 'Radius Postcode'
                                                    : 'Client Postcode'}
                                            </p>
                                            {userDetail?.userLeadsDetailsId?.type === 'map' ? (
                                                <MDBox className="expand-field-value text-field-icon">
                                                    <MDInput
                                                        fullWidth
                                                        disabled
                                                        type="text"
                                                        value={
                                                            clientPostCode && Object.keys(clientPostCode).length > 0
                                                                ? Object.values(clientPostCode)
                                                                    .map((item: any) => item.county)
                                                                    .join(',')
                                                                : ''
                                                        }
                                                        id={'daily'}
                                                        autoComplete={'daily'}
                                                        onChange={(e) => {
                                                            handleLeadsDetailsChange(e, 'daily');
                                                        }}
                                                    />

                                                    {clientPostCode && Object.keys(clientPostCode).length > 0 ? (
                                                        <ErrorIcon
                                                            className="copy-content-icon"
                                                            onClick={() => handleCopyOpenModal(true, 'postcode')}
                                                        />
                                                    ) : (
                                                        ''
                                                    )}
                                                </MDBox>
                                            ) : (
                                                <MDBox className="expand-field-value text-field-icon">
                                                    <MDInput
                                                        fullWidth
                                                        disabled
                                                        type="text"
                                                        value={
                                                            clientPostCode?.length > 0
                                                                ? clientPostCode.map((item) => item.postcode).join(',')
                                                                : ''
                                                        }
                                                        id={'daily'}
                                                        autoComplete={'daily'}
                                                        onChange={(e) => {
                                                            handleLeadsDetailsChange(e, 'daily');
                                                        }}
                                                    />

                                                    {clientPostCode?.length > 0 ? (
                                                        <ErrorIcon
                                                            className="copy-content-icon"
                                                            onClick={() => handleCopyOpenModal(true, 'postcode')}
                                                        />
                                                    ) : (
                                                        ''
                                                    )}
                                                </MDBox>
                                            )}
                                        </MDBox>
                                    </MDBox>
                                </Grid>

                                <Grid container xl={6} pr={0} mr={2} height="fit-content">
                                    <MDBox
                                        width="90%"
                                        height="100%"
                                        sx={{ marginTop: 1.5 }}
                                        className="expand-right-box"
                                    >
                                        <Card
                                            className="client-tab-card-1"
                                            sx={{
                                                width: '100%',

                                                py: 6,
                                                px: 4,
                                                border: '1px solid #efeff3',
                                                opacity: 0.5,
                                                mb: 4,
                                                mt: 0,
                                            }}
                                        >
                                            <MDBox
                                                className="expand-card-top"
                                                sx={{ display: 'flex', justifyContent: 'space-between' }}
                                            >
                                                <h5
                                                    className="text-warning"
                                                    style={{ fontWeight: 'bold', fontSize: 14 }}
                                                >
                                                    {' '}
                                                    {active ? 'ACTIVE' : 'PAUSED'}
                                                </h5>
                                                <Switch
                                                    value={active}
                                                    defaultChecked={active}
                                                    color="warning"
                                                    onChange={(e) => handleActiveUserStatus(e)}
                                                />
                                            </MDBox>
                                            {/* Account manager */}
                                            <MDBox className="" marginTop={2} marginBottom={2}>
                                                <p>Account Manager</p>
                                                <MDBox className="expand-field-value">
                                                    <Autocomplete
                                                        id="account-manager"
                                                        fullWidth
                                                        isOptionEqualToValue={(option, val) => {
                                                            return option.label === val;
                                                        }}
                                                        data-name="Business Industry"
                                                        className="autocomplete-field-client"
                                                        value={userData?.accountManager ?? userData?.accountManager}
                                                        onChange={(event, value) => {
                                                            if (value) {
                                                                handlePaymentMethodChange(
                                                                    event,
                                                                    value.value,
                                                                    'accountManager',
                                                                );
                                                                setUserData((prev) => ({
                                                                    ...prev,
                                                                    accountManager: value.label ?? '-',
                                                                }));
                                                            }
                                                        }}
                                                        options={
                                                            availableAccessManagers &&
                                                            availableAccessManagers.length > 0 &&
                                                            availableAccessManagers.map((manager) => ({
                                                                label:
                                                                    manager?.firstName +
                                                                    ' ' +
                                                                    (manager?.lastName ?? ''),
                                                                value: manager?._id,
                                                            }))
                                                        }
                                                        renderInput={(params) => (
                                                            <MDInput
                                                                {...params}
                                                                name="prices"
                                                                value={userData?.accountManager ?? '-'}
                                                            />
                                                        )}
                                                    />
                                                </MDBox>
                                            </MDBox>

                                            {/* Commission Status */}

                                            <MDBox marginTop={2} marginBottom={2}>
                                                <p>Commission Status</p>
                                                <MDBox className="expand-field-value">
                                                    <Autocomplete
                                                        id="commission-status"
                                                        fullWidth
                                                        isOptionEqualToValue={(option, val) => {
                                                            return option === val;
                                                        }}
                                                        data-name="Commission Status"
                                                        className="autocomplete-field-client"
                                                        value={
                                                            userData?.isCommissionedUser
                                                                ? 'Commission'
                                                                : 'Non Commission'
                                                        }
                                                        options={['Commission', 'Non Commission']}
                                                        onChange={(event, value) => {
                                                            if (value) {
                                                                handlePaymentMethodChange(
                                                                    event,
                                                                    value === 'Commission' ? true : false,
                                                                    'isCommissionedUser',
                                                                );
                                                            }
                                                        }}
                                                        renderInput={(params) => (
                                                            <MDInput
                                                                {...params}
                                                                name="commission-status"
                                                                value={
                                                                    userData?.isCommissionedUser
                                                                        ? 'Commission'
                                                                        : 'NonCommission'
                                                                }
                                                            />
                                                        )}
                                                    />
                                                </MDBox>
                                            </MDBox>
                                        </Card>
                                        {/* RIGHT CARD */}
                                        <Card
                                            sx={{
                                                border: '1px solid #efeff3',
                                                boxShadow: '0px',
                                            }}
                                            className="expand-card"
                                            variant="outlined"
                                        >
                                            <MDBox className="expand-card-top">
                                                <h5 className="text-warning">Payment information </h5>
                                                <Switch
                                                    checked={isBillable}
                                                    color="warning"
                                                    onChange={changeClientStatus}
                                                />
                                            </MDBox>
                                            <div className={!isBillable ? 'opacity-50' : ''}>
                                                <div>
                                                    <MDTypography
                                                        variant="p3"
                                                        sx={{ color: '#ff5252', fontWeight: '600' }}
                                                    >
                                                        <CurrencySymbol currencyCode={userDetail?.currency} />{' '}
                                                        {userDetail?.credits ?? 0} credit (
                                                        {userDetail?.hasOnboarded
                                                            ? Math.floor(userDetail?.credits / userDetail?.leadCost)
                                                            : 0}{' '}
                                                        Leads remaining)
                                                    </MDTypography>
                                                    <StarIcon
                                                        fontSize="small"
                                                        height={15}
                                                        width={35}
                                                        sx={{
                                                            marginLeft: '10px',
                                                            marginBottom: '-3px',
                                                            color: '#ff5252',
                                                        }}
                                                    />
                                                </div>

                                                <MDBox
                                                    className={`expand-card-middle ${
                                                        !isBillable ? 'pointer-none' : ''
                                                    }`}
                                                >
                                                    {/* <p>Leads Cost</p> */}
                                                    <div className="toggle-button w-100">
                                                        <div
                                                            onClick={() => setCheckLeadCost(false)}
                                                            className={
                                                                !checkLeadCost
                                                                    ? 'customCheckBoxChecked'
                                                                    : 'customCheckBoxUnchecked'
                                                            }
                                                        >
                                                            <p>Industry CPL</p>
                                                        </div>
                                                        <div
                                                            onClick={() => setCheckLeadCost(true)}
                                                            className={
                                                                !checkLeadCost
                                                                    ? 'customCheckBoxUnchecked'
                                                                    : 'customCheckBoxChecked'
                                                            }
                                                            style={{}}
                                                        >
                                                            <p>Custom CPL</p>
                                                        </div>
                                                    </div>
                                                </MDBox>
                                                <MDBox className="creditamount">
                                                    <p>{hasAdjustPermission === true ? 'Adjust' : 'Add'} leads</p>
                                                    <div className="expand-card-top client-table-field">
                                                        <MDInput
                                                            fullWidth
                                                            type="text"
                                                            placeholder="Type here"
                                                            disabled={!userDetail?.hasOnboarded || !isBillable}
                                                            id={'creditAmount'}
                                                            autoComplete={'credits'}
                                                            onChange={handleCreditLeadsChange}
                                                            value={creditsData}
                                                            error={isLeadEqual}
                                                            helperText={isLeadEqual && creditError}
                                                        />
                                                        <div className="invoice-wrapper">
                                                            <Tooltip
                                                                title="Generate Invoice for this transaction"
                                                                placement="top"
                                                            >
                                                                <Checkbox
                                                                    disabled={!userDetail?.hasOnboarded || !isBillable}
                                                                    checked={generateInvoice}
                                                                    name="generateInvoice"
                                                                    value={generateInvoice}
                                                                    onChange={(e) => {
                                                                        setGenerateInvoice(e.target.checked);
                                                                    }}
                                                                />
                                                            </Tooltip>
                                                            Invoice
                                                        </div>
                                                        <MDButton
                                                            onClick={(e) => updateCredit(e)}
                                                            sx={{
                                                                marginLeft: '10px',
                                                                border: '2px solid #19cbb1',
                                                                borderRadius: '22px',
                                                                color: '#19cbb1',
                                                                textTransform: 'capitalize',
                                                                padding: '12px 29px',
                                                            }}
                                                            className="button-success-outline"
                                                            disabled={
                                                                !userDetail?.hasOnboarded || isLeadEqual || !isBillable
                                                            }
                                                        >
                                                            {hasAdjustPermission === true ? 'Adjust' : 'Add'} leads
                                                        </MDButton>
                                                    </div>
                                                </MDBox>
                                                {checkLeadCost ? (
                                                    <MDBox className="" marginTop={2} marginBottom={2}>
                                                        <p>Custom CPL</p>
                                                        <MDBox className="expand-card-input">
                                                            <MDInput
                                                                fullWidth
                                                                type="text"
                                                                disabled={!isBillable}
                                                                value={userData?.leadCost}
                                                                id={'leadCost'}
                                                                autoComplete={'leadCost'}
                                                                className="custom-cpl-input"
                                                                onChange={handleCPLChange}
                                                                InputProps={{
                                                                    startAdornment: (
                                                                        <InputAdornment
                                                                            position="start"
                                                                            className="input-adorement"
                                                                        >
                                                                            <span>
                                                                                <CurrencySymbol
                                                                                    currencyCode={userDetail?.currency}
                                                                                />
                                                                            </span>
                                                                        </InputAdornment>
                                                                    ),
                                                                }}
                                                            />
                                                        </MDBox>
                                                    </MDBox>
                                                ) : (
                                                    <MDBox className="" marginTop={2} marginBottom={2}>
                                                        <p>Lead Cost</p>
                                                        <MDBox className="expand-card-input">
                                                            <MDInput
                                                                disabled
                                                                fullWidth
                                                                type="text"
                                                                id={'leadCost'}
                                                                autoComplete={'leadCost'}
                                                                onChange={(e) => {
                                                                    handleChange(e, 'leadCost');
                                                                }}
                                                                className="lead-cost-disable-input"
                                                                InputProps={{
                                                                    startAdornment: (
                                                                        <InputAdornment
                                                                            position="start"
                                                                            className="input-adorement"
                                                                        >
                                                                            <span>
                                                                                <CurrencySymbol
                                                                                    currencyCode={userDetail?.currency}
                                                                                />
                                                                            </span>
                                                                        </InputAdornment>
                                                                    ),
                                                                    endAdornment: (
                                                                        <InputAdornment
                                                                            position="start"
                                                                            className="input-adorement"
                                                                        >
                                                                            <span>{userData?.leadCost}</span>
                                                                        </InputAdornment>
                                                                    ),
                                                                }}
                                                            />
                                                        </MDBox>
                                                    </MDBox>
                                                )}

                                                <MDBox className="creditamount" marginTop={2} marginBottom={2}>
                                                    <p>Add Secondary Leads</p>
                                                    <div className="expand-card-top client-table-field">
                                                        <MDInput
                                                            fullWidth
                                                            disabled={!isBillable || userData?.isSecondaryUsage}
                                                            type="text"
                                                            value={secondaryUserData?.secondaryLeads}
                                                            id={'secondaryLeads'}
                                                            autoComplete={'secondaryLeads'}
                                                            className="custom-cpl-input fix-disabled-background"
                                                            onChange={handleSecondaryCreditsChange}
                                                            onKeyPress={handleKeyPress}
                                                            inputProps={{ maxLength: 5 }}
                                                            helperText={secondaryCreditError}
                                                            error={showSecondaryCreditError}
                                                        />

                                                        <div className="invoice-wrapper">
                                                            <Tooltip
                                                                title="Generate Invoice for this transaction"
                                                                placement="top"
                                                            >
                                                                <Checkbox
                                                                    checked={generateSecondaryInvoice}
                                                                    name="generateSecINvoice"
                                                                    value={generateSecondaryInvoice}
                                                                    onChange={(e) => {
                                                                        setGenerateSecondaryInvoice(e.target.checked);
                                                                    }}
                                                                />
                                                            </Tooltip>
                                                            Invoice
                                                        </div>
                                                        <MDButton
                                                            onClick={() => handleAddSecondaryLeads()}
                                                            sx={{
                                                                marginLeft: '10px',
                                                                border: '2px solid #19cbb1',
                                                                borderRadius: '22px',
                                                                color: '#19cbb1',
                                                                textTransform: 'capitalize',
                                                                padding: '12px 29px',
                                                            }}
                                                            className="button-success-outline"
                                                            disabled={userData?.isSecondaryUsage || !isBillable}
                                                        >
                                                            Add Secondary Leads
                                                        </MDButton>
                                                    </div>
                                                </MDBox>

                                                <MDBox className="" marginTop={2} marginBottom={2}>
                                                    <p>Secondary Lead Cost</p>
                                                    <MDBox className="expand-card-input">
                                                        <MDInput
                                                            fullWidth
                                                            type="text"
                                                            value={secondaryUserData?.secondaryLeadCost}
                                                            id={'secondaryLeadCost'}
                                                            autoComplete={'secondaryLeadCost'}
                                                            onChange={handleSecondaryLeadCostChange}
                                                            onKeyPress={handleKeyPress}
                                                            helperText={secondaryLeadCostError}
                                                            error={showSecondaryLeadCostError}
                                                            className="lead-cost-disable-input"
                                                            inputProps={{
                                                                readOnly: !isBillable || userData?.isSecondaryUsage,
                                                            }}
                                                            disabled={!isBillable || userData?.isSecondaryUsage}
                                                            InputProps={
                                                                !isBillable || userData?.isSecondaryUsage
                                                                    ? {
                                                                        startAdornment: (
                                                                            <InputAdornment
                                                                                position="start"
                                                                                className="input-adorement"
                                                                            >
                                                                                  <span>
                                                                                      <CurrencySymbol
                                                                                          currencyCode={
                                                                                              userDetail?.currency
                                                                                          }
                                                                                      />
                                                                                  </span>
                                                                            </InputAdornment>
                                                                        ),

                                                                        endAdornment: (
                                                                            <InputAdornment
                                                                                position="start"
                                                                                className="input-adorement"
                                                                            >
                                                                                  <span>
                                                                                      {userData?.secondaryLeadCost}
                                                                                  </span>
                                                                            </InputAdornment>
                                                                        ),
                                                                    }
                                                                    : {
                                                                        startAdornment: (
                                                                            <InputAdornment
                                                                                position="start"
                                                                                className="input-adorement"
                                                                            >
                                                                                  <span>
                                                                                      <CurrencySymbol
                                                                                          currencyCode={
                                                                                              userDetail?.currency
                                                                                          }
                                                                                      />
                                                                                  </span>
                                                                            </InputAdornment>
                                                                        ),
                                                                    }
                                                            }
                                                        />
                                                    </MDBox>
                                                </MDBox>

                                                <MDBox className="" marginTop={2} marginBottom={2}>
                                                    <p>Business Industry</p>
                                                    <MDBox className="expand-card-input">
                                                        <MDInput
                                                            fullWidth
                                                            type="text"
                                                            value={userData?.businessDetailsId?.businessIndustry}
                                                            id={'businessIndustryId'}
                                                            autoComplete={'businessIndustryId'}
                                                            disabled={true}
                                                            fontFamily="EuclidLight"
                                                            fontSize="16px"
                                                        />
                                                    </MDBox>
                                                </MDBox>

                                                <MDBox className="" marginTop={2} marginBottom={2}>
                                                    <p>Payment Mode</p>
                                                    <MDBox>
                                                        <Autocomplete
                                                            id="finance"
                                                            data-name="Business Industry"
                                                            className="autocomplete-field"
                                                            value={{
                                                                label: capitalizeFirstLetter(userData?.paymentMethod),
                                                                value: userData?.paymentMethod,
                                                            }}
                                                            disabled={!isBillable}
                                                            onChange={(event, value) => {
                                                                if (value) {
                                                                    handlePaymentMethodChange(
                                                                        event,
                                                                        value.value,
                                                                        'paymentMethod',
                                                                    );
                                                                }
                                                            }}
                                                            options={entries}
                                                            renderInput={(params) => (
                                                                <MDInput
                                                                    {...params}
                                                                    name="prices"
                                                                    value={capitalizeFirstLetter(
                                                                        userData?.paymentMethod,
                                                                    )}
                                                                />
                                                            )}
                                                        />
                                                    </MDBox>
                                                </MDBox>
                                                {userData?.paymentMethod === 'auto charge' && (
                                                    <div className="credit-form-inline">
                                                        <MDBox
                                                            className="d-flex align-items-center"
                                                            justifyContent="space-between"
                                                            marginTop={2}
                                                            marginBottom={2}
                                                        >
                                                            <span>Auto Charge Status:</span>
                                                            <Tooltip
                                                                title={`Auto Charge ${changedData.isAutoChargeEnabled ? 'Enabled' : 'Disabled'}`}
                                                                placement="top"
                                                            >
                                                                <Switch
                                                                    value={changedData.isAutoChargeEnabled}
                                                                    defaultChecked={userData.isAutoChargeEnabled}
                                                                    color="warning"
                                                                    onChange={(e) => handleAutoChargeStatus(e.target.checked)}
                                                                />
                                                            </Tooltip>
                                                        </MDBox>
                                                        <MDBox
                                                            className="d-flex align-items-center"
                                                            justifyContent="space-between"
                                                            marginTop={2}
                                                            marginBottom={2}
                                                        >
                                                            <p>At</p>
                                                            <MDInput
                                                                fullWidth
                                                                type="text"
                                                                value={userData?.normalizedTriggerAmount}
                                                                id={'triggerAmount'}
                                                                autoComplete={'triggerAmount'}
                                                                onChange={handleTriggerAmountChange}
                                                                onKeyPress={handleKeyPress}
                                                                inputProps={{ maxLength: 5 }}
                                                                helperText={triggerAmountError}
                                                                error={showTriggerAmountErorr}
                                                                disabled={!isBillable}
                                                            />
                                                            remaining leads auto-charge,
                                                        </MDBox>

                                                        <MDBox
                                                            className="d-flex align-items-center"
                                                            justifyContent="space-between"
                                                        >
                                                            <p> client account for</p>
                                                            <MDInput
                                                                fullWidth
                                                                type="text"
                                                                value={userData?.normalizedTopupAmount}
                                                                id={'autoChargeAmount'}
                                                                autoComplete={'autoChargeAmount'}
                                                                onChange={handleAutoChargeChange}
                                                                onKeyPress={handleKeyPress}
                                                                inputProps={{ maxLength: 5 }}
                                                                helperText={AutoChargeError}
                                                                error={showAutoChargeError}
                                                                disabled={!isBillable}
                                                            />
                                                            more leads.
                                                        </MDBox>
                                                    </div>
                                                )}
                                            </div>
                                            <MDBox className="" marginTop={2} marginBottom={2}>
                                                <p>Client Notes</p>
                                                <MDBox className="expand-card-input">
                                                    <MDInput
                                                        type="textarea"
                                                        placeholder="Client Notes"
                                                        value={userData?.userNotes}
                                                        id="userNotes"
                                                        autoComplete="userNotes"
                                                        onChange={(e) => handleUserNotes(e, 'userNotes')}
                                                        fullWidth
                                                        className="note-textarea"
                                                        multiline
                                                        rows={10}
                                                    />
                                                </MDBox>
                                            </MDBox>

                                            <input
                                                onClick={(e) => handleArchive(e, archive, 'isArchived')}
                                                type="submit"
                                                value={archive ? 'Unarchive This Client' : 'Archive This Client'}
                                                id="w-node-_0bea9dcc-b36c-bc0d-2d8a-91c15aa9a1ab-9e7c9e88"
                                                className="max-width-full w-100 button button-gray"
                                            />
                                        </Card>
                                    </MDBox>
                                </Grid>
                            </MDBox>

                            <Grid sx={{ background: 'transparent', mb: 2 }}>
                                {' '}
                                <MDBox mt={4} mb={1} sx={{ display: 'flex', justifyContent: 'center', gap: 2 }}>
                                    <MDButton
                                        isLoading={isSavingClientDetails}
                                        onClick={(e) => updateClientData(e)}
                                        sx={{
                                            marginRight: '10px',
                                        }}
                                        className={classNames('button', {
                                            'button-disabled': userDetail.isDeleted,
                                        })}
                                        disabled={userDetail.isDeleted}
                                    >
                                        Save Changes
                                    </MDButton>

                                    <MDButton
                                        type="reset"
                                        className={classNames('button button-outline-gray', {
                                            'button-disabled': userDetail.isDeleted,
                                        })}
                                        sx={{ textTransform: 'capitalize' }}
                                        onClick={() => {
                                            setActive(userDetail.isActive);
                                            setUserData(userDetail);
                                            setCheckLeadCost(userDetail?.isLeadCostCheck);
                                        }}
                                        disabled={userDetail.isDeleted}
                                    >
                                        Discard Changes
                                    </MDButton>

                                    <MDButton
                                        // type="reset"
                                        className={classNames('button button-outline-gray', {
                                            'button-disabled': userDetail.isDeleted,
                                        })}
                                        sx={{ textTransform: 'capitalize' }}
                                        onClick={() => {
                                            handleOpenModal(true, PopupType.RESET_PASS);
                                        }}
                                        disabled={userDetail.isDeleted}
                                    >
                                        Change Password
                                    </MDButton>
                                </MDBox>
                            </Grid>
                        </MDBox>

                        <BasicModal open={openCopyModal} handleClose={handleCloseCopyCodeModal} postalCodeModal={true}>
                            <CopyModal
                                handleCloseModal={handleCloseCopyCodeModal}
                                type={copyPopupType}
                                leadSchedule={userData?.userLeadsDetailsId?.leadSchedule.filter(
                                    (daysObj: DaySchedule) => daysObj.isActive,
                                )}
                                allPostalCodes={clientPostCode}
                                postCodeType={userDetail?.userLeadsDetailsId?.type}
                            />
                        </BasicModal>
                        <BasicModal open={isModalOpen} handleClose={hideModal}>
                            <UpdateModal
                                onModalClose={hideModal}
                                modalHeader={modalContent.modalHeader}
                                modalButton={modalContent.modalButton}
                                id={userDetail?._id}
                            />
                        </BasicModal>
                    </>
                </div>
            </td>
        </>
    );
};

export default ExpandedRow;
