import CloseIcon from '@mui/icons-material/Close';
import { Autocomplete } from '@mui/material';
import fileUploadIcon from 'assets/images/small-logos/fileUploadIcon.svg';
import { FormikErrorsRenderer } from 'components/shared/Formik/FormikErrorsRenderer';
import Modal from 'components/shared/Modal';
import MDBox from 'components/themed/MDBox';
import MDButton from 'components/themed/MDButton';
import MDInput from 'components/themed/MDInput';
import { IDateRangeQuery } from 'contracts/requests/IDateRangeQuery';
import { IUserAd } from 'contracts/requests/IGetUserAdsResponse';

import { GetBusinessIndustryDropdownDataObject } from 'contracts/spotdif/BusinessIndustry';

import dayjs from 'dayjs';

import { convertToHTML } from 'draft-convert';
import { ContentState, convertFromHTML, EditorState } from 'draft-js';

import { Form, FormikProvider, useFormik } from 'formik';
import useToggle from 'hooks/useToggle';
import { DateRange, DateRangePicker } from 'mui-daterange-picker';
import { useSnackbar } from 'notistack';
import React, { useCallback, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { useDropzone } from 'react-dropzone';
import { useGeneratePartnersAdsMutation, useUpdatePartnersAdsMutation } from 'redux/services/spotdif/partners';
import * as Yup from 'yup';

import './style.scss';

interface IGenerateLinkProps {
    closePopup: () => void;
    modalHeading: string;
    modalButton: string;
    businessIndustries?: Array<GetBusinessIndustryDropdownDataObject>;
    adsID?: string;
    data?: IUserAd;
}

type DropdownOption = { label: string; value: string };

const GenerateAddPopup: React.FC<IGenerateLinkProps> = ({
                                                            closePopup,
                                                            businessIndustries,
                                                            data,
                                                            adsID,
                                                            modalHeading,
                                                            modalButton,
                                                        }) => {
    const [createPartnersAdsTrigger, { isLoading: isCreatingPartnersAds }] = useGeneratePartnersAdsMutation();
    const [updatePartnersAdsTrigger] = useUpdatePartnersAdsMutation();

    const { enqueueSnackbar } = useSnackbar();

    const [file, setFile] = useState<File | string>(() => {
        return data?.image ? data.image : null;
    });

    const [isDatePickerOpen, toggleDatePicker] = useToggle(false);

    const [range, setDateRange] = React.useState<IDateRangeQuery>({
        startDate: data?.startDate ? dayjs(data.startDate) : dayjs().startOf('month'),
        endDate: data?.endDate ? dayjs(data.endDate) : dayjs().endOf('month'),
    });

    const { acceptedFiles, getRootProps, getInputProps, isDragActive } = useDropzone({
        accept: {
            'image/*': [],
        },
        maxSize: 5242880,
        onDrop: (acceptedFiles) => {
            if (acceptedFiles.length > 0) {
                generateLinkForm.setFieldValue('image', acceptedFiles[0]);
                setFile(acceptedFiles[0]);
            }
        },
    });

    function isOfType(obj: any): obj is IDateRangeQuery {
        return obj && typeof obj === 'object' && 'startDate' in obj && 'endDate' in obj;
    }

    const removeImageHandler = () => {
        setFile('');
        generateLinkForm.setFieldValue('image', '');
    };

    const initialValues = {
        title: data?.title ?? '',
        promotionText: data?.promotionText ?? '',
        industries: data?.industriesName[0] ? { label: data?.industriesName[0], value: data?.industries[0] } : '',
        image: data?.image ?? '',
        callToAction: data?.callToAction ?? '',
        description: data?.description ?? '',
        targetReach: data?.targetReach ?? '',
        buttonText: data?.buttonText ?? '',
    };

    const generateLinkForm = useFormik({
        initialValues,
        validationSchema: Yup.object().shape({
            title: Yup.string()
                .required('Title is required')
                .min(3, 'Minimum 3 characters length')
                .max(100, 'Maximum limit is 100')
                .trim(),

            industries: Yup.object().required('Business Industry is required'),

            image: Yup.string().required('Image is required'),

            callToAction: Yup.string().required('URL is required').min(3, 'Minimum 3 characters length').trim(),

            description: Yup.string()
                .required('Description is required')
                .min(3, 'Minimum 3 characters length')
                .max(1000, 'Maximum limit is 1000')
                .trim(),

            targetReach: Yup.number()
                .required('Max Click Count is required')
                .min(1, 'Minimum value should be 1')
                .positive('Negitive value is not allowed'),

            buttonText: Yup.string()
                .required('Button Text is required')
                .min(3, 'Minimum 3 characters length')
                .max(100, 'Maximum limit is 100')
                .trim(),
        }),
        onSubmit: async (values) => {
            const formData = new FormData();

            formData.append('callToAction', values.callToAction);
            formData.append('description', values.description);
            formData.append('title', values.title);
            formData.append('promotionText', values.promotionText);
            formData.append('image', values.image);
            formData.append('targetReach', parseInt(`${values.targetReach}`) + '');
            formData.append('startDate', range.startDate.format('YYYY-MM-DD'));
            formData.append('endDate', range.endDate.format('YYYY-MM-DD'));
            formData.append('buttonText', values.buttonText);

            adsID && formData.append('_id', adsID);

            const industriesArray = [];
            if (
                values.industries &&
                typeof values.industries === 'object' &&
                values.industries.label &&
                values.industries.value
            ) {
                industriesArray.push(values.industries.value);
            }

            industriesArray.forEach((id, index) => {
                formData.append(`industries[${index}]`, id);
            });

            adsID
                ? updatePartnersAdsTrigger(formData)
                    .unwrap()
                    .then((res) => {
                        enqueueSnackbar(res?.data?.message, { variant: 'success' });
                        closePopup();
                    })
                    .catch((error) => {
                        enqueueSnackbar(error?.data?.error?.message, { variant: 'error' });
                    })
                : createPartnersAdsTrigger(formData)
                    .unwrap()
                    .then((res) => {
                        enqueueSnackbar(res?.message, { variant: 'success' });
                        closePopup();
                    })
                    .catch((error) => {
                        enqueueSnackbar(error?.data?.error?.message, { variant: 'error' });
                    });
        },
    });

    const renderPreview = () => {
        if (file instanceof File) {
            return (
                <MDBox className="uploaded-image" onClick={removeImageHandler}>
                    <img src={URL.createObjectURL(file)} alt="uploaded preview" width={100} />
                    <MDBox className="deleteIcon">
                        <CloseIcon />
                    </MDBox>
                </MDBox>
            );
        } else if (typeof file === 'string' && file) {
            return (
                <MDBox className="uploaded-image" onClick={removeImageHandler}>
                    <img
                        src={process.env.REACT_APP_API_SERVER + generateLinkForm.values.image}
                        alt="uploaded preview this one"
                        width={100}
                    />
                    <MDBox className="deleteIcon">
                        <CloseIcon />
                    </MDBox>
                </MDBox>
            );
        } else {
            return <></>;
        }
    };

    const onDateRangeChange = useCallback((dateRange: DateRange) => {
        setDateRange({
            startDate: dayjs(dateRange.startDate),
            endDate: dayjs(dateRange.endDate),
        });
    }, []);

    const [editorState, setEditorState] = useState(() => {
        if (modalHeading === 'Update Partner Ad') {
            const blocksFromHTML = convertFromHTML(generateLinkForm.values.description);
            const contentState = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks);
            return EditorState.createWithContent(contentState);
        } else {
            return EditorState.createEmpty();
        }
    });

    const toolbarOptions = {
        options: ['inline', 'blockType', 'list', 'history'],
        inline: {
            options: ['bold', 'italic', 'underline', 'strikethrough'],
        },
        blockType: {
            options: ['Normal', 'H2', 'H3', 'H4'],
        },
        list: {
            options: ['unordered', 'ordered'],
        },
        history: {
            options: ['undo', 'redo'],
        },
    };

    return (
        <Modal open={true} handleClose={closePopup}>
            <FormikProvider value={generateLinkForm}>
                <Form onSubmit={generateLinkForm.handleSubmit}>
                    <MDBox className="modalFormMainWrapper ">
                        <MDBox className="modalMainWrapper promo-link-responsive-modal modal-veritical-scroll-partners">
                            <div className="modal-heading-2">{modalHeading}</div>

                            {/* Title & Business Industry */}
                            <div className="form-groups">
                                <div className="form-input mb-0">
                                    <MDInput
                                        type="text"
                                        label="Ad Title"
                                        placeholder="Enter Title"
                                        name="title"
                                        onBlur={generateLinkForm.handleBlur}
                                        value={generateLinkForm.values.title}
                                        onChange={generateLinkForm.handleChange}
                                        error={generateLinkForm.touched.title && Boolean(generateLinkForm.errors.title)}
                                        helperText={generateLinkForm.touched.title && generateLinkForm.errors.title}
                                    />
                                </div>
                                <div className="form-input mb-0">
                                    <MDInput
                                        type="text"
                                        label="Promotional Text"
                                        placeholder="Get First Year Free"
                                        name="promotionText"
                                        onBlur={generateLinkForm.handleBlur}
                                        value={generateLinkForm.values.promotionText}
                                        onChange={generateLinkForm.handleChange}
                                        error={generateLinkForm.touched.promotionText && Boolean(generateLinkForm.errors.promotionText)}
                                        helperText={generateLinkForm.touched.promotionText && generateLinkForm.errors.promotionText}
                                    />
                                </div>

                                <div className="form-input mb-0">
                                    <Autocomplete
                                        id="industries"
                                        data-name="industries"
                                        className="spotdif-field w-50 dropdown-input"
                                        value={generateLinkForm.values.industries}
                                        onChange={(e, value) => {
                                            generateLinkForm.setFieldValue('industries', value);
                                        }}
                                        onBlur={generateLinkForm.handleBlur}
                                        options={businessIndustries?.map((item) => {
                                            return {
                                                label: item.industry,
                                                value: item._id,
                                            } as DropdownOption;
                                        })}
                                        renderInput={(params) => (
                                            <MDInput
                                                {...params}
                                                isBordered={true}
                                                name="businessIndustry"
                                                value={generateLinkForm.values.industries}
                                                sx={{ padding: 0 }}
                                                label={'Business Industry'}
                                                placeholder="Business Industry"
                                                error={
                                                    generateLinkForm.touched.industries &&
                                                    Boolean(generateLinkForm.errors.industries)
                                                }
                                                helperText={
                                                    generateLinkForm.touched.industries &&
                                                    generateLinkForm.errors.industries
                                                }
                                            />
                                        )}
                                    />
                                </div>
                            </div>

                            {/* Call to Action & Date */}
                            <div className="form-groups">
                                <div className="form-input mb-0">
                                    <MDInput
                                        type="string"
                                        label="URL"
                                        placeholder="Enter URL"
                                        value={generateLinkForm.values.callToAction}
                                        onBlur={generateLinkForm.handleBlur}
                                        name="callToAction"
                                        onChange={generateLinkForm.handleChange}
                                        error={
                                            generateLinkForm.touched.callToAction &&
                                            Boolean(generateLinkForm.errors.callToAction)
                                        }
                                        helperText={
                                            generateLinkForm.touched.callToAction &&
                                            generateLinkForm.errors.callToAction
                                        }
                                    />
                                </div>

                                <div className="form-input mb-0">
                                    <MDInput
                                        type="string"
                                        label="Button Text"
                                        placeholder="Button Text"
                                        onBlur={generateLinkForm.handleBlur}
                                        value={generateLinkForm.values.buttonText}
                                        name="buttonText"
                                        onChange={generateLinkForm.handleChange}
                                        error={
                                            generateLinkForm.touched.buttonText &&
                                            Boolean(generateLinkForm.errors.buttonText)
                                        }
                                        helperText={
                                            generateLinkForm.touched.buttonText && generateLinkForm.errors.buttonText
                                        }
                                    />
                                </div>
                            </div>

                            <div className="form-groups">
                                <div className="form-input mb-0">
                                    {/* <label className="date-range-label">Date Range</label> */}
                                    <MDInput
                                        label="Date Range"
                                        type="text"
                                        className="form-select-small table-input filters-bold"
                                        maxLength={256}
                                        name="field"
                                        data-name="Field"
                                        placeholder="Time Period"
                                        id="field"
                                        onClick={toggleDatePicker}
                                        value={
                                            isOfType(range) && Object.keys(range).length
                                                ? `${dayjs(range.startDate).format('YYYY MMM  DD')} - ${dayjs(
                                                    range.endDate,
                                                ).format('YYYY MMM DD')}`
                                                : 'Time Period'
                                        }
                                    />

                                    <div className="chart-date-picker-dropdown date-picker-dropdown--dashboard">
                                        <DateRangePicker
                                            open={isDatePickerOpen}
                                            wrapperClassName="spotdif-calendar-wrapper spotdif-calender-wrapper-dashboard"
                                            toggle={toggleDatePicker}
                                            onChange={onDateRangeChange}
                                            initialDateRange={{
                                                startDate: range.startDate.toDate(),
                                                endDate: range.endDate.toDate(),
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>

                            {/* Description */}
                            <div className="form-groups">
                                <div className="form-input mb-0">
                                    <Editor
                                        editorState={editorState}
                                        toolbar={toolbarOptions}
                                        toolbarClassName="toolbarClassName"
                                        wrapperClassName="wrapperClassName"
                                        editorClassName="editorClassName"
                                        onEditorStateChange={(newEditorState) => {
                                            setEditorState(newEditorState);

                                            const editorContentAsHTML = convertToHTML(
                                                newEditorState.getCurrentContent(),
                                            );
                                            generateLinkForm.setFieldValue('description', editorContentAsHTML);
                                        }}
                                    />
                                    {generateLinkForm.touched.description && generateLinkForm.errors.description && (
                                        <div className="errors">
                                            <FormikErrorsRenderer errors={generateLinkForm.errors.description} />
                                        </div>
                                    )}
                                </div>
                            </div>

                            {/* Image */}
                            <MDBox className="form-groups">
                                <div className="form-input mb-0">
                                    <div>
                                        {renderPreview()}
                                        <div {...getRootProps({ className: 'mt-2' })}>
                                            <input {...getInputProps()} />
                                            {isDragActive ? (
                                                <p className="button button-outline-primary w-50">
                                                    Drop the files here ...
                                                </p>
                                            ) : (
                                                <p className="button button-outline-primary w-100">
                                                    Upload Image
                                                    <img
                                                        className="upload-image-icon"
                                                        src={fileUploadIcon}
                                                        alt="upload icon "
                                                    />
                                                </p>
                                            )}
                                        </div>

                                        {/* <p>{formik.values.image && formik.values.image.name}</p> */}
                                    </div>
                                </div>
                                {file instanceof File && file.size > 5242880 ? (
                                    <MDBox width="100%" display="flex" justifyContent="center" color="red">
                                        Logo is too big, Maximum size should be 5MB
                                    </MDBox>
                                ) : null}
                            </MDBox>
                            {generateLinkForm.errors.image && generateLinkForm.touched.image ? (
                                <MDBox width="100%" display="flex" justifyContent="left" color="red">
                                    <p className="error-text input-error-text">Image is required</p>
                                </MDBox>
                            ) : null}

                            {/* Target Reach  & Max use Count*/}
                            {/* <div className="form-input mt-2 mb-15">
                                <label>
                                    Target Reach: <span className="badge">Unlimited</span>
                                </label>
                                <Tooltip
                                    title={generateLinkForm.values.isUnlimitedTargetReach ? "Active" : "Inactive"}
                                    placement="top"
                                >
                                    <Switch
                                        name="isUnlimitedTargetReach"
                                        onChange={generateLinkForm.handleChange}
                                        onBlur={generateLinkForm.handleBlur}
                                        value={generateLinkForm.values.isUnlimitedTargetReach}
                                    />
                                </Tooltip>
                            </div> */}

                            <div className="full-width-field">
                                <MDInput
                                    type="number"
                                    label="Max Click Allowed"
                                    placeholder="Max Click Allowed"
                                    onBlur={generateLinkForm.handleBlur}
                                    name="targetReach"
                                    value={generateLinkForm.values.targetReach}
                                    onChange={generateLinkForm.handleChange}
                                    error={
                                        generateLinkForm.touched.targetReach &&
                                        Boolean(generateLinkForm.errors.targetReach)
                                    }
                                    helperText={
                                        generateLinkForm.touched.targetReach && generateLinkForm.errors.targetReach
                                    }
                                />
                            </div>
                        </MDBox>
                        <MDButton
                            className="button modal-button"
                            type="submit"
                            disabled={isCreatingPartnersAds}
                            isLoading={isCreatingPartnersAds}
                        >
                            {modalButton}
                        </MDButton>
                    </MDBox>
                </Form>
            </FormikProvider>
        </Modal>
    );
};
export default GenerateAddPopup;
