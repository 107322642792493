import CloseIcon from '@mui/icons-material/Close';
import MUIModal from '@mui/material/Modal';
import MDBox from 'components/themed/MDBox';
import MDButton from 'components/themed/MDButton';
import React from 'react';
import './style.scss';

interface IModalProps {
    children: React.ReactNode;
    button?: React.ReactNode;
    handleClose: () => void;
    open: boolean;
    width?: number;
    height?: number;
    innerClassNames?: string;
}

const Modal: React.FC<IModalProps> = ({
    children,
    button,
    handleClose,
    open,
    width = 700,
    height = 400,
    innerClassNames,
}) => {
    return (
        <div style={{ position: 'relative' }}>
            {button}
            <MUIModal
                className="modal-wrapper "
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <MDBox className="boxModalStyles">
                    <MDBox className="modalFormMainWrapper">
                        {children}

                        <MDBox className="modalCloseWrapper">
                            <MDButton className="modalCloseBtn" onClick={handleClose}>
                                <CloseIcon fontSize="large" />
                            </MDButton>
                        </MDBox>
                    </MDBox>
                </MDBox>
            </MUIModal>
        </div>
    );
};

export default Modal;
