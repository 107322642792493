import CloseIcon from '@mui/icons-material/Close';
import './style.scss';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import classNames from 'classnames';
import MDBox from 'components/themed/MDBox';
import MDButton from 'components/themed/MDButton';
import MDTypography from 'components/themed/MDTypography';
import { DaySchedule } from 'contracts/spotdif/DaySchedule';
import { PostCodePair } from 'contracts/spotdif/LeadDetails';
import { PostCodeTargetingList } from 'contracts/spotdif/PostCodeTargetingList';
import { useSnackbar } from 'notistack';
import React, { useCallback } from 'react';

const copyUrl = (url) => {
    const formattedUrls = url.join(',\n');
    navigator.clipboard.writeText(formattedUrls).then(() => {
        // notifySuccess('Copied Successfully');
    });
};

interface CopyModalProps {
    handleCloseModal: () => void;
    allPostalCodes: PostCodePair[] | PostCodeTargetingList[];
    postCodeType: 'map' | 'radius';
    type: 'postcode' | 'schedule';
    leadSchedule: DaySchedule[];
}

const CopyModal: React.FC<CopyModalProps> = ({
                                                 handleCloseModal,
                                                 allPostalCodes,
                                                 postCodeType,
                                                 type,
                                                 leadSchedule,
                                             }) => {
    const { enqueueSnackbar } = useSnackbar();

    const handleCopyAllPostalCode = useCallback(() => {
        const allCodes: string[] = [];

        if (type === 'postcode') {
            if (postCodeType === 'map') {
                Object.keys(allPostalCodes).forEach((item) => {
                    allCodes.push(allPostalCodes[item].postalCode);
                });
            } else {
                allPostalCodes?.forEach((postCodePair) => {
                    return allCodes.push(`${postCodePair.miles} miles of ${postCodePair.postcode}`);
                });
            }
        } else {
            leadSchedule.forEach((daysObj) => {
                return allCodes.push(daysObj.day);
            });
        }

        copyUrl(allCodes);
        enqueueSnackbar('Copied Successfully', { variant: 'success', key: 'copied-code' });
    }, [allPostalCodes, enqueueSnackbar, leadSchedule, postCodeType, type]);

    const handlePostalCode = useCallback(
        (newPostalCode) => {
            copyUrl(newPostalCode.postalCode);
            enqueueSnackbar('Copied Successfully', { variant: 'success', key: 'copied-code' });
        },
        [enqueueSnackbar],
    );

    return (
        <MDBox class="modalFormMainWrapper" component="form" role="form">
            <MDBox class="modalMainWrapper postalModalWrapper">
                <div className="modalScrollArea">
                    <MDBox className="modal-heading-wrap">
                        <h6 className="modal-heading-2">{type === 'postcode' ? 'Postcode' : 'Client schedule'}</h6>
                        <MDBox className="copy-all-btn" onClick={handleCopyAllPostalCode}>
                            {type === 'postcode' ? 'Copy all postcodes' : 'Copy all schedule'}
                            <ContentCopyOutlinedIcon className="copy-to-clipboard" />
                        </MDBox>
                    </MDBox>
                    {type === 'postcode' ? (
                        <>
                            {postCodeType === 'map' ? (
                                <>
                                    {Object.keys(allPostalCodes).map((items) => {
                                        let newPostalCode = allPostalCodes[items];
                                        return (
                                            <div>
                                                <MDTypography class="modalHeadingPostalCode">
                                                    {newPostalCode.county}
                                                    <ContentCopyOutlinedIcon
                                                        onClick={() => handlePostalCode(newPostalCode)}
                                                        className="copy-to-clipboard"
                                                    />
                                                </MDTypography>

                                                <div className="postalModalGrid">
                                                    {newPostalCode.postalCode.map((postalCodes) => (
                                                        <div className="modal_input-wrapNew postalModalContentWrapper">
                                                            <MDTypography
                                                                class="postalModalContentDetails postalModalUserName">
                                                                {postalCodes}
                                                            </MDTypography>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        );
                                    })}
                                </>
                            ) : (
                                <div className="postalModalGrid">
                                    {allPostalCodes?.map((postCodePair) => (
                                        <div
                                            className={classNames('modal_input-wrapNew postalModalContentWrapper', {
                                                largePostcodeTile: postCodeType === 'radius',
                                            })}
                                        >
                                            <MDTypography class="postalModalContentDetails postalModalUserName">
                                                {`${postCodePair.miles} miles of ${postCodePair.postcode}`}
                                            </MDTypography>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </>
                    ) : (
                        <div className="postalModalGrid">
                            {leadSchedule?.map((daysObj) => (
                                <div
                                    className={classNames('modal_input-wrapNew postalModalContentWrapper', {
                                        largePostcodeTile: postCodeType === 'radius',
                                    })}
                                >
                                    <MDTypography class="postalModalContentDetails postalModalUserName">
                                        {daysObj.day}
                                    </MDTypography>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </MDBox>
            <MDBox class="modalCloseWrapper">
                <MDButton class="modalCloseBtn" onClick={handleCloseModal}>
                    <CloseIcon fontSize="large" />
                </MDButton>
            </MDBox>
        </MDBox>
    );
};

export default CopyModal;
