import React from 'react';
import PostalCodeProvider from '../../context/PostalCode/CountyContext';
import PostalCodePicker from '../shared/PostalCodePicker';
import MDBox from '../themed/MDBox';
import PostalCodeCollapse from './PostalCodeCollapse';

interface ILeadCoverageProps {
    field: string;
}

const LeadCoverage: React.FC<ILeadCoverageProps> = ({ field }) => {
    return (
        <PostalCodeProvider>
            <MDBox
                sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: { md: 'row', xs: 'column' },
                    gap: 4,
                }}
            >
                <MDBox
                    className="lead-area-map"
                    sx={{
                        width: { xs: '100%', md: '50%' },
                        position: 'relative',
                    }}
                >
                    <PostalCodePicker field={field} />
                </MDBox>

                <MDBox
                    className="leadAreaTabPostalSelect"
                    sx={{
                        width: { xs: '100%', md: '50%' },
                    }}
                >
                    <MDBox>
                        <h4 style={{ marginBottom: 20 }}>Postcodes Added</h4>
                        <PostalCodeCollapse field={field} />
                    </MDBox>
                </MDBox>
            </MDBox>
        </PostalCodeProvider>
    );
};

export default LeadCoverage;
