import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import { Icon } from '@mui/material';
import signUpmain from 'assets/images/sign-up-main.svg';

import { SIGNINV2 } from 'components/layout/brandingLayout/CONST';
import MDBox from 'components/themed/MDBox';
import MDButton from 'components/themed/MDButton';
import MDInput from 'components/themed/MDInput';
import MDTypography from 'components/themed/MDTypography';
import { Form, FormikProvider, useFormik } from 'formik';
import { useRegexPatterns } from 'hooks/useRegexPatterns';

import { useSnackbar } from 'notistack';
import React from 'react';
import { Link } from 'react-router-dom';
import { useForgetPasswordMutation } from 'redux/services/spotdif';
import * as Yup from 'yup';

const ForgotPassword: React.FC = () => {
    const [requestNewPassword, { isLoading }] = useForgetPasswordMutation();
    const { enqueueSnackbar } = useSnackbar();
    const { emailRegex } = useRegexPatterns();

    const initialValues = {
        email: '',
    };

    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .required('Email required *')
            .email('Must be a valid email')
            .matches(emailRegex, 'Enter a valid email'),
    });

    const formik = useFormik({
        initialValues,
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            await requestNewPassword(values)
                .unwrap()
                .then((res) => {
                    enqueueSnackbar(res?.data?.message, { variant: 'success', key: 'forgot-sucess' });
                })
                .catch((error) => {
                    enqueueSnackbar(error?.data?.data?.message ?? 'Something went wrong.', {
                        variant: 'error',
                        key: 'forgot-error',
                    });
                });
        },
    });

    return (
        <div className="signup-wrapper-new">
            <div className="left-wrapper">
                <h1 className="signup-heading signin-heading">
                    <p>
                        Get <span>qualified leads</span> for your business
                    </p>
                </h1>
                <p className="signup-para">
                    Unlock <b>exceptional leads</b> without any membership fees, utilising our
                    <b> user-friendly pay-as-you-go dashboard</b> to supercharge your business growth.
                </p>
            </div>
            <img src={signUpmain} alt="" className="sign-up-main" />
            <div className="right-wrapper">
                <h3 className="signup-heading-two">Forgot Password?</h3>
                <MDBox mb={2} className="spotdif-login-wrapper spotdif-signin-wrapper">
                    <FormikProvider value={formik}>
                        <Form onSubmit={formik.handleSubmit} className="spotdif-login-form">
                            <MDBox mb={2}>
                                <MDInput
                                    type="email"
                                    label="Email"
                                    name="email"
                                    onBlur={formik.handleBlur}
                                    className="spotdif-field spotdif-field-float"
                                    value={formik.values.email}
                                    id="email"
                                    isBordered={false}
                                    autoComplete="email"
                                    onChange={(e) => formik.setFieldValue('email', e.target.value.trim())}
                                    error={formik.touched.email && Boolean(formik.errors.email)}
                                    helperText={formik.touched.email && formik.errors.email}
                                />
                            </MDBox>
                            <MDTypography variant="button">
                                <Link to={SIGNINV2} style={{ color: '#9FA1B3' }}>
                                    Already have an account? Log in
                                </Link>
                            </MDTypography>
                            <MDBox my={4}>
                                <MDButton
                                    className="spotdif-signup-Button"
                                    type="submit"
                                    isLoading={isLoading}
                                    disabled={formik.isSubmitting}
                                >
                                    <Icon className="spotdif-arrow">
                                        <ArrowForwardIcon />
                                    </Icon>
                                </MDButton>
                            </MDBox>
                        </Form>
                    </FormikProvider>
                </MDBox>
            </div>
        </div>
    );
};

export default ForgotPassword;
