import { ButtonGroup, Popover, Tooltip } from '@mui/material';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state';
import React from 'react';


export interface ConfirmActionPopoverProps {
    id?: string;
    children: React.ReactNode;
    title: React.ReactNode;
    toolTipTitle?: React.ReactNode;
    onConfirm: () => void;
    onCancel?: () => void;
}

export const ConfirmActionPopover: React.FC<ConfirmActionPopoverProps> = ({
                                                                              id,
                                                                              children,
                                                                              title,
                                                                              toolTipTitle,
                                                                              onConfirm,
                                                                              onCancel,

                                                                          }) => {

    const popoverId = id ?? 'confirm-action-popover';

    return (
        <PopupState variant="popover" popupId={popoverId} disableAutoFocus={false}>
            {(popupState) => (
                <>
                    <Tooltip
                        title={toolTipTitle ?? title}
                        placement="top"
                        arrow={true}
                    >
                        <div {...bindTrigger(popupState)}>
                            {children}
                        </div>
                    </Tooltip>

                    <Popover
                        {...bindPopover(popupState)}
                        // anchorReference="anchorEl"
                        // anchorPosition={{ top: 0, left: 0 }}

                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}


                        PaperProps={{ style: { background: 'white' } }}
                    >
                        {title && <Typography sx={{ p: 2 }}>{title}</Typography>}

                        <ButtonGroup aria-label="Basic button group">

                            <Button className="button" onClick={() => {
                                onConfirm();
                                popupState.close();
                            }}>
                                Confirm
                            </Button>

                            <Button className="button" onClick={() => {
                                onCancel && onCancel();
                                popupState.close();
                            }}>
                                Cancel
                            </Button>
                        </ButtonGroup>


                    </Popover>
                </>
            )}
        </PopupState>
    );
};
