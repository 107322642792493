import React from 'react';
import Modal from 'components/shared/Modal';
import MDBox from 'components/themed/MDBox';
import { FreeCreditUser } from 'contracts/spotdif/GetFreeCreditsLinkResponseData';
import { timeFormatter } from 'utils/text-helpers';

interface ISignedUpUserProps {
    isUserPopupOpen: Boolean;
    toggleUserPopup: () => void;
    selectedPromoUsers: FreeCreditUser[];
}
const SignedUpUserSection: React.FC<ISignedUpUserProps> = ({
    isUserPopupOpen,
    toggleUserPopup,
    selectedPromoUsers,
}) => {
    return (
        <>
            {isUserPopupOpen && (
                <Modal open={true} handleClose={toggleUserPopup}>
                    <MDBox className="modalMainWrapper">
                        <div className="modal-heading-2">Signed up user</div>
                        <div className="modal-veritical-scroll">
                            <table>
                                <tr className="user-list-wrapper">
                                    <th>Name</th>
                                    <th>Business Name</th>
                                    <th>Used at</th>
                                </tr>
                                {selectedPromoUsers.map((user, index) => {
                                    return (
                                        <tr key={index} className="user-list-wrapper">
                                            <td>
                                                {user.firstName} {user.lastName}
                                            </td>
                                            <td>{user?.businessName}</td>
                                            <td>{timeFormatter(user.createdAt, 'DD/MM/YYYY, h:mm:ss A')}</td>
                                        </tr>
                                    );
                                })}
                            </table>
                        </div>
                    </MDBox>
                </Modal>
            )}
        </>
    );
};

export default SignedUpUserSection;
