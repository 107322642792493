import { useEffect, useState } from 'react';
import { enqueueSnackbar } from 'notistack';
import { ContentState, convertFromHTML, EditorState } from 'draft-js';
import { convertToHTML } from 'draft-convert';
import MDInput from 'components/themed/MDInput';
import { Form, FormikProvider, useFormik } from 'formik';
import ColorInputField from 'components/shared/ColorInputField';
import { GetFreeCreditsLinkResponseData } from 'contracts/spotdif/GetFreeCreditsLinkResponseData';
import { usePromoLinkContent } from 'hooks/usePromoLinkContent';
import {
    useGeneratePromoLinkContentMutation,
    useUpdatePromoLinkContentMutation,
} from 'redux/services/spotdif/promoLink';
import CodeToolbar from './CodeToolbar';
import * as Yup from 'yup';
import EditorField from './EditorField';

interface IGeneratePromoLinkContentProps {
    closePopup?: () => void;
    promoLinkId?: string;
    promoLinkData?: GetFreeCreditsLinkResponseData;
    isEditMode?: boolean;
}

const GeneratePromolinkContent: React.FC<IGeneratePromoLinkContentProps> = ({
    closePopup,
    promoLinkId,
    promoLinkData,
    isEditMode,
}) => {
    const [generatePromoLinkContent] = useGeneratePromoLinkContentMutation();
    const [updatePromoLinkContent] = useUpdatePromoLinkContentMutation();

    const {
        badgeTitle,
        badgeSubTitle,
        badgeColor,
        heroSection,
        qualityLeads,
        leadShowCase,
        replacementPolicyHeader,
        replacementPolicyText,
    } = usePromoLinkContent(promoLinkData?.code);

    const initialValues = {
        badgeTitle: isEditMode ? badgeTitle : '',
        badgeSubTitle: isEditMode ? badgeSubTitle : '',
        badgeColor: isEditMode ? badgeColor : '',
        heroSection: isEditMode ? heroSection : '',
        qualityLeads: isEditMode ? qualityLeads : '',
        leadShowCase: isEditMode ? leadShowCase : '',
        replacementPolicyHeader: isEditMode ? replacementPolicyHeader : '',
        replacementPolicyText: isEditMode ? replacementPolicyText : '',
    };

    useEffect(() => {
        generateContentForm.setFieldValue('badgeTitle', badgeTitle);
        generateContentForm.setFieldValue('badgeSubTitle', badgeSubTitle);
        generateContentForm.setFieldValue('badgeColor', badgeColor);
        generateContentForm.setFieldValue('heroSection', heroSection);
        generateContentForm.setFieldValue('qualityLeads', qualityLeads);
        generateContentForm.setFieldValue('leadShowCase', leadShowCase);
        generateContentForm.setFieldValue('replacementPolicyHeader', replacementPolicyHeader);
        generateContentForm.setFieldValue('replacementPolicyText', replacementPolicyText);
        return () => {
            generateContentForm.resetForm();
        };
    }, [
        badgeTitle,
        badgeSubTitle,
        badgeColor,
        heroSection,
        qualityLeads,
        leadShowCase,
        replacementPolicyHeader,
        replacementPolicyText,
    ]);

    const generateContentForm = useFormik({
        initialValues,
        validationSchema: Yup.object().shape({
            badgeTitle: Yup.string()
                .nullable()
                .min(3, 'Minimum 3 characters length')
                .max(10, 'Maximum limit is 10')
                ?.trim(),
            badgeSubTitle: Yup.string()
                .nullable()
                .min(3, 'Minimum 3 characters length')
                .max(30, 'Maximum limit is 30')
                ?.trim(),
            badgeColor: Yup.string().nullable()?.trim(),
            heroSection: Yup.string()
                .min(3, 'Minimum 3 characters length')
                .max(1000, 'Maximum limit is 1000')
                ?.trim()
                .required('Hero section is required'),
            qualityLeads: Yup.string()
                .min(3, 'Minimum 3 characters length')
                .max(1000, 'Maximum limit is 1000')
                ?.trim()
                .required('Quality leads is required'),
            leadShowCase: Yup.string()
                .min(3, 'Minimum 3 characters length')
                .max(1000, 'Maximum limit is 1000')
                ?.trim()
                .required('Lead show case is required'),
            replacementPolicyHeader: Yup.string()
                .min(3, 'Minimum 3 characters length')
                .max(1000, 'Maximum limit is 1000')
                .nullable()
                ?.trim(),
            replacementPolicyText: Yup.string().min(3, 'Minimum 3 characters length').nullable()?.trim(),
        }),

        onSubmit: (values, formik) => {
            const payload = {
                ...values,
                promoLink: isEditMode ? promoLinkData?._id : promoLinkId,
                ...(isEditMode && { promoLinkContentId: promoLinkData?.promoLinkContentId }),
            };
            const mutateFunction = isEditMode ? updatePromoLinkContent : generatePromoLinkContent;
            mutateFunction(payload)
                .unwrap()
                .then(() => {
                    enqueueSnackbar(`Promo link content ${isEditMode ? 'updated' : 'added'} successfully`, {
                        variant: 'success',
                        key: 'promo-content',
                    });
                    closePopup();
                })
                .catch((err) => {
                    enqueueSnackbar(err?.data?.error?.message, { variant: 'error', key: 'promo-content' });
                });
        },
    });

    const [heroSectionState, setHeroSectionState] = useState(EditorState.createEmpty());
    const [qualityLeadsState, setQualityLeadsState] = useState(EditorState.createEmpty());
    const [leadShowCaseState, setLeadShowCaseState] = useState(EditorState.createEmpty());
    const [replacementPolicyHeaderState, setReplacementPolicyHeaderState] = useState(EditorState.createEmpty());
    const [replacementPolicyTextState, setReplacementPolicyTextState] = useState(EditorState.createEmpty());

    const updateTextEditorValue = (textEditor, textEditorState) => {
        if (textEditor) {
            const blocksFromHTML = convertFromHTML(textEditor);
            const contentState = ContentState.createFromBlockArray(blocksFromHTML?.contentBlocks);
            textEditorState(EditorState.createWithContent(contentState));

            const editorContentAsHTML = convertToHTML(heroSectionState.getCurrentContent());
            generateContentForm.setFieldValue('textEditor', editorContentAsHTML);
            generateContentForm.setFieldTouched('textEditor', true);
        }
    };

    useEffect(() => {
        updateTextEditorValue(heroSection, setHeroSectionState);
        updateTextEditorValue(qualityLeads, setQualityLeadsState);
        updateTextEditorValue(leadShowCase, setLeadShowCaseState);
        updateTextEditorValue(replacementPolicyHeader, setReplacementPolicyHeaderState);
        updateTextEditorValue(replacementPolicyText, setReplacementPolicyTextState);
    }, [heroSection, qualityLeads, leadShowCase, replacementPolicyHeader, replacementPolicyText]);

    return (
        <FormikProvider value={generateContentForm}>
            <Form onSubmit={generateContentForm.handleSubmit}>
                <div className="modal-heading-2">{isEditMode ? 'Update Content' : 'Generate Content'}</div>

                <div className="form-groups">
                    <div className="form-input mb-0">
                        <MDInput
                            type="text"
                            label="Badge Title"
                            placeholder="Badge Title"
                            name="badgeTitle"
                            onBlur={generateContentForm.handleBlur}
                            onChange={generateContentForm.handleChange}
                            error={
                                generateContentForm.touched.badgeTitle && Boolean(generateContentForm.errors.badgeTitle)
                            }
                            helperText={generateContentForm.touched.badgeTitle && generateContentForm.errors.badgeTitle}
                            value={generateContentForm.values.badgeTitle}
                        />
                    </div>
                    <div className="form-input mb-0">
                        <MDInput
                            type="text"
                            label="Badge SubTitle"
                            placeholder="Badge SubTitle"
                            name="badgeSubTitle"
                            onBlur={generateContentForm.handleBlur}
                            onChange={generateContentForm.handleChange}
                            error={
                                generateContentForm.touched.badgeSubTitle &&
                                Boolean(generateContentForm.errors.badgeSubTitle)
                            }
                            helperText={
                                generateContentForm.touched.badgeSubTitle && generateContentForm.errors.badgeSubTitle
                            }
                            value={generateContentForm.values.badgeSubTitle}
                        />
                    </div>
                    <ColorInputField
                        value={generateContentForm.values.badgeColor}
                        onBlur={generateContentForm.handleBlur}
                        onChange={generateContentForm.handleChange}
                        error={generateContentForm.touched.badgeColor && Boolean(generateContentForm.errors.badgeColor)}
                        helperText={generateContentForm.touched.badgeColor && generateContentForm.errors.badgeColor}
                    />
                </div>
                <EditorField
                    label="Hero Section"
                    editorState={heroSectionState}
                    onEditorStateChange={setHeroSectionState}
                    fieldName="heroSection"
                    generateContentForm={generateContentForm}
                    customStyleMap={{
                        CODE: {
                            backgroundColor: 'rgba(255, 78, 2, 0.5)',
                            fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
                            fontSize: 16,
                            padding: 2,
                        },
                    }}
                    // @ts-ignore
                    toolbarCustomButtons={[<CodeToolbar />]}
                />

                <EditorField
                    label="Quality Leads"
                    editorState={qualityLeadsState}
                    onEditorStateChange={setQualityLeadsState}
                    fieldName="qualityLeads"
                    generateContentForm={generateContentForm}
                />

                <EditorField
                    label="Lead Show Case"
                    editorState={leadShowCaseState}
                    onEditorStateChange={setLeadShowCaseState}
                    fieldName="leadShowCase"
                    generateContentForm={generateContentForm}
                />

                <EditorField
                    label="Replacement Policy Header"
                    editorState={replacementPolicyHeaderState}
                    onEditorStateChange={setReplacementPolicyHeaderState}
                    fieldName="replacementPolicyHeader"
                    generateContentForm={generateContentForm}
                />

                <EditorField
                    label="Replacement Policy Text"
                    editorState={replacementPolicyTextState}
                    onEditorStateChange={setReplacementPolicyTextState}
                    fieldName="replacementPolicyText"
                    generateContentForm={generateContentForm}
                />
                <button className="button modal-button w-100 mt-2" type="submit">
                    {isEditMode ? 'Update Content' : 'Generate Content'}
                </button>
            </Form>
        </FormikProvider>
    );
};

export default GeneratePromolinkContent;
