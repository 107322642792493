// useRegexPatterns.ts
import { useMemo } from 'react';

export const useRegexPatterns = () => {
    const emailRegex = useMemo(() => {
        return /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g;
    }, []);

    const passwordRegex = useMemo(() => {
        return /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/gm;
    }, []);

    const phoneNumberRegex = useMemo(() => {
        return /^(?:(?:\(?0\)?[\s-]?(?:0|11)?[\s-]?\d{4,5})|(?:0?\d{4}\)?[\s-]?(?:\d{5}|\d{3}[\s-]?\d{3}))|(?:0?\d{3}\)?[\s-]?\d{3}[\s-]?\d{3,4})|(?:0?\d{2}\)?[\s-]?\d{4}[\s-]?\d{4}))(?:[\s-]?(?:x|ext\.?|\#)\d{3,4})?$/;
    }, []);

    const urlRegex = useMemo(() => {
        return /https?:\/\/(?:www\.)?([-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b)*(\/[\/\d\w\.-]*)*(?:[\?])*(.+)*/gi;
    }, []);

    const imageUrlRegex = useMemo(() => {
        return /(http(s?):)([/|.|\w|\s|-])*\.(?:jpg|gif|png|webp|svg)/gm;
    }, []);

    return { emailRegex, passwordRegex, phoneNumberRegex, urlRegex, imageUrlRegex };
};
