import TableViewIcon from '@mui/icons-material/TableView';
import { Checkbox, Grid, useMediaQuery } from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import Icon from '@mui/material/Icon';
import DateRangeRenderer from 'components/shared/DateTimeRenderer';
import MDBox from 'components/themed/MDBox';
import MDInput from 'components/themed/MDInput';
import { useApplicationUserContext } from 'context/ApplicationUser';
import { useDashboardStatsContext } from 'context/DashboadStatsContext';
import { EUserRole } from 'contracts/app-utils/EUserRole';
import dayjs from 'dayjs';
import useToggle from 'hooks/useToggle';
import { DateRange, DateRangePicker } from 'mui-daterange-picker';
import React, { useCallback, useEffect } from 'react';
import { useGetAllClientsQuery } from 'redux/services/spotdif/clients';
import { useGetAccountManagerStatsMutation } from 'redux/services/spotdif/stats';
import AverageTopValueChart from './average-top-up';
import LeadCountChart from './lead-count';
import LeadRejectionRateChart from './lead-rejection-rate';
import NoOfTopupChart from './no-of-topup';
import TopUpAmountChart from './top-up-amount';

import './style.scss';

const Stats = () => {
    const isMobile = useMediaQuery('(max-width:768px)'); // Change the breakpoint as needed

    const { user } = useApplicationUserContext();
    const { query, setQuery, setData } = useDashboardStatsContext();
    const [isDatePickerOpen, toggleDatePicker] = useToggle(false);

    const { availableAccessManagers, isLoadingAccessManager } = useGetAllClientsQuery(
        { showOnlyAccountManagers: true },
        {
            selectFromResult: ({ data, isLoading }) => {
                return {
                    availableAccessManagers: data?.data,
                    isLoadingAccessManager: isLoading,
                };
            },
        },
    );
    useEffect(() => {
        if (availableAccessManagers) {
            setQuery({
                ...query,
                accountManagerIds:
                    availableAccessManagers?.map((user) => ({ label: user.firstName, value: user._id })) ?? [],
            });
        }
    }, [availableAccessManagers]);

    const [getAccountManagerTrigger] = useGetAccountManagerStatsMutation();

    const allSelected = availableAccessManagers?.length === query.accountManagerIds?.length;

    useEffect(() => {
        getAccountManagerTrigger({ ...query, accountManagerIds: query.accountManagerIds.map(({ value }) => value) })
            .unwrap()
            .then((res) => {
                setData(res.data);
            })
            .catch((error) => {
                console.error(error, 'Something Went Wrong !');
            });
    }, [query]);
    const filter = createFilterOptions();
    const handleClearOptions = () => setQuery({ ...query, accountManagerIds: [] });

    const handleSelectAll = (isSelected) => {
        if (isSelected) {
            setQuery({
                ...query,
                accountManagerIds:
                    availableAccessManagers?.map((user) => ({ label: user.firstName, value: user._id })) ?? [],
            });
        } else {
            handleClearOptions();
        }
    };

    const handleToggleSelectAll = () => {
        handleSelectAll(!allSelected);
    };

    const handleToggleOption = (sel) => {
        setQuery({ ...query, accountManagerIds: sel });
    };

    const onDateRangeChange = useCallback(
        (dateRange: DateRange) => {
            setQuery({ ...query, startDate: dayjs(dateRange.startDate), endDate: dayjs(dateRange.endDate) });
            // setDateRange({
            //     startDate: dayjs(dateRange.startDate),
            //     endDate: dayjs(dateRange.endDate)
            // });
        },
        [query],
    );

    return (
        <>
            <div className="tab-user-content-card accout-manager-scrool-bar">
                {(user?.role === EUserRole.ADMIN || user?.role === EUserRole.SUPER_ADMIN) && (
                    <div className="form-middle notification-setting-wrapper">
                        {/* <div className="form-col-2 chart-date-filter "> */}
                        <MDBox mb={2} className="stats-header">
                            <Grid item xs={12} sm={8} className="select-account-manager-grid">
                                <Autocomplete
                                    limitTags={isMobile ? 2 : 9}
                                    multiple
                                    disableCloseOnSelect
                                    className="spotdif-field w-100 dropdown-input-stats"
                                    options={(availableAccessManagers ?? []).map((user) => ({
                                        label: user.firstName,
                                        value: user._id,
                                    }))}
                                    onChange={(e, value, reason) => {
                                        if (reason === 'selectOption' || reason === 'removeOption') {
                                            if (value.find((option) => option.value === 'select-all')) {
                                                handleToggleSelectAll();
                                                availableAccessManagers
                                                    ?.map((user) => ({ label: user.firstName, value: user._id }))
                                                    .filter((el) => el.value !== 'select-all');
                                            } else {
                                                handleToggleOption && handleToggleOption(value);
                                            }
                                        } else if (reason === 'clear') {
                                            handleClearOptions && handleClearOptions();
                                        }
                                    }}
                                    isOptionEqualToValue={(option, val) => option.value === val.value}
                                    getOptionLabel={(option) => option.label}
                                    renderOption={(props, option, { selected }) => {
                                        const selectAllProps =
                                            option.value === 'select-all' // To control the state of 'select-all' checkbox
                                                ? { checked: allSelected }
                                                : {};
                                        return (
                                            <li {...props}>
                                                <Checkbox checked={selected} {...selectAllProps} />
                                                {option.label}
                                            </li>
                                        );
                                    }}
                                    filterOptions={(options, params) => {
                                        const filtered = filter(options, params);
                                        return [{ label: 'Select All', value: 'select-all' }, ...filtered];
                                    }}
                                    value={query?.accountManagerIds}
                                    renderInput={(params) => (
                                        <MDInput
                                            {...params}
                                            isBordered={true}
                                            // variant="standard"
                                            label="Select account managers"
                                            placeholder=""
                                            className="auto-complete-input"
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <button onClick={toggleDatePicker}>
                                    {/* {query.startDate.format('DD MMM, YYYY')} - {query.endDate.format('DD MMM, YYYY')} */}
                                    <DateRangeRenderer
                                        startDate={query.startDate}
                                        endDate={query.endDate}
                                        format="DD MMM, YYYY"
                                    />
                                    {/* By Date */}
                                    <Icon fontSize="medium">
                                        <TableViewIcon />
                                    </Icon>
                                </button>

                                <div className="chart-date-picker-dropdown">
                                    <DateRangePicker
                                        open={isDatePickerOpen}
                                        wrapperClassName="spotdif-calendar-wrapper"
                                        toggle={toggleDatePicker}
                                        onChange={onDateRangeChange}
                                        initialDateRange={{
                                            startDate: query.startDate.toDate(),
                                            endDate: query.endDate.toDate(),
                                        }}
                                    />
                                </div>
                            </Grid>
                        </MDBox>
                        {/* </div> */}
                    </div>
                )}
                <Grid container className="chart-container">
                    <Grid item xs={12} md={12} className="chart-wrapper">
                        {!isLoadingAccessManager && <LeadCountChart />}
                    </Grid>
                </Grid>
                <Grid container className="chart-container">
                    <Grid item xs={12} md={6} className="chart-wrapper">
                        <TopUpAmountChart />
                    </Grid>
                    <Grid item xs={12} md={6} className="chart-wrapper">
                        <NoOfTopupChart />
                    </Grid>
                </Grid>
                <Grid container className="chart-container">
                    <Grid item xs={12} md={6} className="chart-wrapper">
                        <LeadRejectionRateChart />
                    </Grid>
                    <Grid item xs={12} md={6} className="chart-wrapper">
                        <AverageTopValueChart />
                    </Grid>
                </Grid>
            </div>
        </>
    );
};

export default Stats;
