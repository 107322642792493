import { BaseDataModel } from 'contracts/spotdif/BaseDataModel';
import { jsonMember, jsonObject, TypedJSON } from 'typedjson';

@jsonObject()
export class AuthenticationKey extends BaseDataModel {
    @jsonMember(String)
    userId: string;

    @jsonMember(String)
    notes: string;

    @jsonMember(String)
    key: string;
}

export const AuthKeySerializer = new TypedJSON(AuthenticationKey);
