import { BaseDataModel } from './BaseDataModel';
import { TypedJSON, jsonMember, jsonObject } from 'typedjson';

@jsonObject()
export class BaseCardData extends BaseDataModel {
    @jsonMember(String)
    cardNumber: string;

    @jsonMember(String)
    userId: string;

    @jsonMember(Boolean, {
        deserializer: (value: any) => {
            return !!value;
        },
    })
    isDefault: boolean;

    get order(): number {
        return this.isDefault ? 10 : 0;
    }
}

@jsonObject()
export class UserCard extends BaseCardData {
    @jsonMember(String)
    cardHolderName: string;

    @jsonMember(String)
    paymentMethod: string;

    @jsonMember(String)
    status: string;
}

export const UserCardSerializer = new TypedJSON(UserCard);
