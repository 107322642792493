/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 PRO React TS Base Styles
import colors from 'assets/theme/base/colors';
import borders from 'assets/theme/base/borders';
import typography from 'assets/theme/base/typography';

// // Material Dashboard 2 PRO React TSUI Dashboard PRO helper functions
import pxToRem from 'assets/theme/functions/pxToRem';

const { inputBorderColor, info, grey, transparent } = colors;
const { borderRadius } = borders;
const { size } = typography;

// types
type Types = any;

const inputOutlined: Types = {
    styleOverrides: {
        root: {
            backgroundColor: transparent.main,
            fontSize: size.sm,
            borderRadius: borderRadius.md,

            '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: inputBorderColor,
            },

            '&.Mui-focused': {
                '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: info.main,
                },
            },
        },

        notchedOutline: {
            borderColor: inputBorderColor,
        },

        input: {
            color: grey[700],
            padding: pxToRem(12),
            backgroundColor: transparent.main,
        },

        inputSizeSmall: {
            fontSize: size.xs,
            padding: pxToRem(10),
        },

        multiline: {
            color: grey[700],
            padding: 0,
        },
    },
};

export default inputOutlined;
