import React from 'react';
import { Helmet } from 'react-helmet';
import './style.scss';
import { useGetSiteConfigsQuery, useGetValidationConfigsQuery } from 'redux/services/spotdif/siteConfigs';
import PaymentConfigCard from './paymentConfigCard';
import ValidationConfigCard from './validationConfigCard';

const SiteConfigsSettings: React.FC = () => {
    const { siteConfigsData } = useGetSiteConfigsQuery(undefined, {
        selectFromResult: ({ data, isLoading }) => {
            return {
                siteConfigsData: data?.data,
                siteConfigsDataLoading: isLoading,
            };
        },
    });

    const { data: validationConfigsData } = useGetValidationConfigsQuery();

    return (
        <div className="site-configs-settings-wrapper">
            <Helmet>
                <title>Site Configs</title>
            </Helmet>

            <PaymentConfigCard tag="first-card-bonus" data={siteConfigsData?.firstCardBonus} title="First Card Bonus" />
            {/*  TODO: Will show the promolinks card later */}
            {/* <PaymentConfigCard tag="free-credits" data={siteConfigsData?.promoLinks} title="Promo links" /> */}

            <ValidationConfigCard title="Global Minimum Top Up Override" data={validationConfigsData} />
        </div>
    );
};

export default SiteConfigsSettings;
