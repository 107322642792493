import React from 'react';
import { IPaymentMethodRequest } from 'contracts/requests/IPaymentMethodRequest';
import { Form, useFormikContext } from 'formik';
import useAuthentication from 'hooks/useAuthentication';
import MDInput from 'components/themed/MDInput';
import handleKeyPress from 'utils/validation';
import MDButton from 'components/themed/MDButton';
import CheckIcon from '@mui/icons-material/Check';
import MDBox from 'components/themed/MDBox';
import CurrencySymbol from 'components/shared/CurrencyFormat';
import useGlobalMinimumTopupLeads from 'hooks/useGlobalMinimumTopupLeads';
import useIndustryMinimumTopupLeads from 'hooks/useIndustryMinimumTopupLeads';
import { enqueueSnackbar } from 'notistack';
import { useUpdatePaymentMethodMutation } from 'redux/services/spotdif/creditAndBilling';

interface IAutoChargeSettingSectionProps {
    isAddingCredits: boolean;
}

const AutoChargeSettingSection: React.FC<IAutoChargeSettingSectionProps> = ({ isAddingCredits }) => {
    const VAT_AMOUNT = parseFloat(process.env.REACT_APP_VAT_AMOUNT) || 0.2;
    const { user } = useAuthentication();
    const { values, handleBlur, touched, errors, handleSubmit, handleChange } =
        useFormikContext<IPaymentMethodRequest>();
    const { minimumLeadConfigAsPerAdmin } = useGlobalMinimumTopupLeads();
    const { industryMinTopupLeads } = useIndustryMinimumTopupLeads();
    const [updatePaymentMethodTrigger] = useUpdatePaymentMethodMutation();

    const handleAutoCharge = (isAutoChargeEnabled: boolean) => {
        const modifiedRequest = {
            isAutoChargeEnabled: isAutoChargeEnabled,
            _id: user?._id,
        };

        updatePaymentMethodTrigger(modifiedRequest)
            .unwrap()
            .then((res) => {
                return enqueueSnackbar(res?.message, { variant: 'success', key: 'auto-charge' });
            })
            .catch((error) => {
                return enqueueSnackbar(error?.message || 'Something went wrong', { variant: 'success' });
            });
    };

    return (
        <>
            <Form onSubmit={handleSubmit} className="credit-form-inline">
                <div className="mb-1 mt-1">
                    At
                    <MDInput
                        type="text"
                        onBlur={handleBlur}
                        name="triggerAmount"
                        placeholder="5"
                        onKeyPress={handleKeyPress}
                        value={values.triggerAmount}
                        onChange={handleChange}
                        inputProps={{ maxLength: 5 }}
                        error={touched.triggerAmount && Boolean(errors.triggerAmount)}
                        helperText={touched.triggerAmount && errors.triggerAmount}
                    />
                    remaining leads,
                </div>

                <div className="mb-1">
                    auto-charge my account for
                    <MDInput
                        type="text"
                        onBlur={handleBlur}
                        name="topupAmount"
                        placeholder={industryMinTopupLeads ? industryMinTopupLeads : minimumLeadConfigAsPerAdmin}
                        onKeyPress={handleKeyPress}
                        value={values.topupAmount}
                        onChange={handleChange}
                        inputProps={{ maxLength: 5 }}
                        error={touched.topupAmount && Boolean(errors.topupAmount)}
                        helperText={touched.topupAmount && errors.topupAmount}
                    />
                    more leads
                </div>

                <MDButton
                    className="button button-solid-dark"
                    type="submit"
                    disabled={isAddingCredits || user?.isAutoChargeEnabled}
                    isLoading={isAddingCredits}
                >
                    {user?.isAutoChargeEnabled ? 'Autocharge Active' : 'Confirm & Setup Autocharge'}
                    {/* Confirm & set up Autocharge */}
                    <CheckIcon className="ml-1" />
                </MDButton>
                <MDButton
                    className="button button-outline-blue"
                    disabled={isAddingCredits || !user?.isAutoChargeEnabled}
                    isLoading={isAddingCredits}
                    // onClick={() =>
                    //     user?.isAutoChargeEnabled
                    //         ? handleAutoCharge(false)
                    //         : handleAutoCharge(true)
                    // }
                    onClick={() => handleAutoCharge(false)}
                >
                    {/* {`${user?.isAutoChargeEnabled ? 'Stop Autocharge' : 'Start Autocharge'}`} */}
                    Stop Autocharge
                </MDButton>
            </Form>
            <MDBox className="vat-div">
                {user?.currency !== 'EUR' && (
                    <div className="d-flex">
                        <p>
                            Total (exc. VAT) <span>Based on {`${values.topupAmount || 0} * ${user?.leadCost} `}</span>
                        </p>
                        <p>
                            <CurrencySymbol currencyCode={user?.currency} />{' '}
                            {`${(Number(values.topupAmount) * user?.leadCost).toFixed(2)}`}
                        </p>
                    </div>
                )}

                {user?.currency === 'EUR' ? (
                    <div className="d-flex">
                        <p>{`Total to pay`}</p>
                        <h6>
                            <span>
                                {' '}
                                <CurrencySymbol currencyCode={user?.currency} />{' '}
                                {`${(Number(values.topupAmount) * user?.leadCost).toFixed(2)}`}
                            </span>
                        </h6>
                    </div>
                ) : (
                    <div className="d-flex">
                        <p>{`Total to pay (inc. VAT)`}</p>
                        <h6>
                            <span>
                                {' '}
                                <CurrencySymbol currencyCode={user?.currency} />{' '}
                                {`${(Number(values.topupAmount) * user?.leadCost * (1 + Number(VAT_AMOUNT))).toFixed(
                                    2,
                                )}`}
                            </span>
                        </h6>
                    </div>
                )}
                <label>{`*To be charged when number of remaining leads reaches ${values.triggerAmount || 5}`}</label>
            </MDBox>
        </>
    );
};

export default AutoChargeSettingSection;
