import { IPostCodeRequest, PostCodeResponse } from 'contracts/requests/IPostCodeRequest';
import { spotdifApi } from '.';

const postcodeApi = spotdifApi.injectEndpoints({
    endpoints: (builder) => ({
        getPostcodes: builder.query<PostCodeResponse, IPostCodeRequest>({
            query: (body) => {
                return {
                    url: `/postal-dash`,
                    method: 'POST',
                    body,
                };
            },
        }),

        exportPostCodeStats: builder.mutation<any, IPostCodeRequest>({
            query: (body) => {
                return {
                    url: `/postal-dash/export-csv-file`,
                    method: 'POST',
                    body,
                };
            },
            transformResponse: (response: any) => {
                return response;
            },
        }),
    }),
});

export const { useGetPostcodesQuery, useExportPostCodeStatsMutation, useLazyGetPostcodesQuery } = postcodeApi;
