import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';

import { SPOTDIFF_API_REDUCER_KEY } from 'contracts/reduxResourceTags';
import { IApiErrorResponse } from 'contracts/requests/IApiErrorResponse';
import {
    IAuthenticationKeyGenerationRequest,
    IAuthenticationKeyResponse,
} from 'contracts/requests/IAuthenticationKeyResponse';
import { IForgetPasswordRequest } from 'contracts/requests/IForgetPasswordRequest';
import { IMessageOnlyResponse } from 'contracts/requests/IMessageOnlyResponse';
import {
    IBaseUserInformation,
    IMobilePrefixResponse,
    IRegisterUserRequest,
    IUserAuthErrorResponse,
    IUserLoginRequest,
    IUserRegisterResponse,
} from 'contracts/requests/IRegisterUserRequest';
import {
    TAG_ACCOUNT_MANAGER_STATS,
    TAG_AUTHENTICATION_KEYS,
    TAG_CHANGE_LEAD,
    TAG_CHART_DATA,
    TAG_CHAT_ROOM_DETAILS,
    TAG_CLIENT_COLUMN_UPDATE,
    TAG_CLIENT_UPDATE,
    TAG_GENERATE_PROMO_LINKS,
    TAG_GET_ACCOUNT_MANAGER,
    TAG_GET_BADGE_STATUS,
    TAG_GET_CERTIFIED_SUPPLIER,
    TAG_GET_PARTNERS_ADS,
    TAG_GET_PERMISSION_LIST,
    TAG_GET_PROMO_LINKS,
    TAG_GET_SITE_CONFIGS,
    TAG_GET_SUB_ADMIN,
    TAG_GET_TRANSACTION_TABLE,
    TAG_GET_USER_ADS,
    TAG_GET_USER_CARD,
    TAG_GET_VALIDATION_CONFIGS,
    TAG_GET_XERO_DETAILS,
    TAG_INDUSTRIES_LEAD,
    TAG_INVITE_USER,
    TAG_LEAD_PREFERENCE,
    TAG_NON_BILLABLE_USER,
    TAG_PLAN_PACKAGES,
    TAG_POST_CODE_MAP,
    TAG_REPORT_LEAD,
    TAG_SAVE_CLIENT_DETAIL,
    TAG_SUB_ADMIN,
    TAG_SUBSCRIBER_USER,
    TAG_TRANSACTION_TABLE,
    TAG_UPDATE_CREDIT,
    TAG_USER_CARD,
    TAG_USER_PREFERENCE,
    TAG_USER_PROFILE,
} from 'contracts/spotDiffAPITags';
import { RootState } from '../../store';

export const spotdifApi = createApi({
    reducerPath: SPOTDIFF_API_REDUCER_KEY,
    tagTypes: [
        TAG_GET_PROMO_LINKS,
        TAG_POST_CODE_MAP,
        TAG_CHART_DATA,
        TAG_CHAT_ROOM_DETAILS,
        TAG_GENERATE_PROMO_LINKS,
        TAG_REPORT_LEAD,
        TAG_USER_PROFILE,
        TAG_LEAD_PREFERENCE,
        TAG_USER_PREFERENCE,
        TAG_TRANSACTION_TABLE,
        TAG_GET_TRANSACTION_TABLE,
        TAG_INDUSTRIES_LEAD,
        TAG_CLIENT_UPDATE,
        TAG_CLIENT_COLUMN_UPDATE,
        TAG_INVITE_USER,
        TAG_CHANGE_LEAD,
        TAG_SAVE_CLIENT_DETAIL,
        TAG_UPDATE_CREDIT,
        TAG_USER_CARD,
        TAG_GET_USER_CARD,
        TAG_SUBSCRIBER_USER,
        TAG_SUB_ADMIN,
        TAG_NON_BILLABLE_USER,
        TAG_PLAN_PACKAGES,
        TAG_GET_SUB_ADMIN,
        TAG_ACCOUNT_MANAGER_STATS,
        TAG_GET_PARTNERS_ADS,
        TAG_GET_ACCOUNT_MANAGER,
        TAG_GET_USER_ADS,
        TAG_GET_SITE_CONFIGS,
        TAG_GET_VALIDATION_CONFIGS,
        TAG_GET_CERTIFIED_SUPPLIER,
        TAG_GET_BADGE_STATUS,
        TAG_GET_XERO_DETAILS,
        TAG_GET_PERMISSION_LIST,
        TAG_AUTHENTICATION_KEYS,
    ],
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_SERVER}/api/v1`,
        prepareHeaders: (headers, { getState }) => {
            // By default, if we have a token in the store, let's use that for authenticated requests
            // const token = (getState() as RootState).auth.authenticated.token;
            const token = (getState() as RootState).auth.token;
            // console.log(getState() as RootState, ">>>>>>>>>>>>>>>")
            if (token) {
                headers.set('authorization', `Bearer ${token}`);
            }
            headers.set('ngrok-skip-browser-warning', '123');
            return headers;
        },
    }),
    endpoints: (builder) => ({
        loginUser: builder.mutation<IUserRegisterResponse | IApiErrorResponse, IUserLoginRequest>({
            query: (body) => {
                return {
                    url: '/auth/login',
                    method: 'post',
                    body: {
                        ...body,
                        email: body.email.toLowerCase(),
                    },
                };
            },
        }),
        registerUser: builder.mutation<IUserRegisterResponse | IUserAuthErrorResponse, IRegisterUserRequest>({
            query: (body) => {
                return {
                    url: '/auth/register',
                    method: 'post',
                    body: {
                        ...body,
                        email: body.email.toLowerCase(),
                    },
                };
            },
        }),
        forgetPassword: builder.mutation<IMessageOnlyResponse, IForgetPasswordRequest>({
            query: (body) => {
                return {
                    url: '/auth/forgetPassword',
                    method: 'post',
                    body: {
                        email: body.email.toLowerCase(),
                    },
                };
            },
        }),


        getUserAuthenticationKeys: builder.query<IAuthenticationKeyResponse, void>({
            providesTags: [TAG_AUTHENTICATION_KEYS],
            query: () => {
                return {
                    url: `/auth/keys`,
                    method: 'GET',
                };
            },
        }),
        generateAuthenticationKeys: builder.mutation<IAuthenticationKeyResponse, IAuthenticationKeyGenerationRequest>({
            invalidatesTags: [TAG_AUTHENTICATION_KEYS],
            query: (body) => {
                return {
                    url: `/auth/keys`,
                    method: 'POST',
                    body,
                };
            },
        }),

        deleteUserAuthenticationKey: builder.mutation<IMessageOnlyResponse, string>({
            invalidatesTags: [TAG_AUTHENTICATION_KEYS],
            query: (keyId) => {
                return {
                    url: `/auth/keys/${keyId}`,
                    method: 'DELETE',
                };
            },
        }),

        getMobilePrefix: builder.query<IMobilePrefixResponse[], void>({
            query: () => {
                // v1/service/get-country-codes
                return {
                    url: `/service/get-country-codes`,
                    method: 'get',
                };
            },
        }),

        saveBusinessDetails: builder.mutation<IUserRegisterResponse | IUserAuthErrorResponse, IBaseUserInformation>({
            //need to change the type
            query: (body) => {
                return {
                    url: '',
                    method: 'post',
                    body,
                };
            },
        }),
    }),
});

export const {
    useLoginUserMutation,
    useRegisterUserMutation,
    useSaveBusinessDetailsMutation,
    useForgetPasswordMutation,
    useGetMobilePrefixQuery,
    useGetUserAuthenticationKeysQuery,
    useGenerateAuthenticationKeysMutation,
    useDeleteUserAuthenticationKeyMutation,
    // useSignupUserMutation
} = spotdifApi;
