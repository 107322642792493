import MDBox from 'components/themed/MDBox';
import MDButton from 'components/themed/MDButton';
import MDInput from 'components/themed/MDInput';
import './styles.scss';
import MDTypography from 'components/themed/MDTypography';
import { IXeroDetailsResponse } from 'contracts/requests/IXeroDetailsResponse';
import { enqueueSnackbar } from 'notistack';
import React from 'react';
import toTitleCase from 'utils/text-helpers';
import GenerateXero from './GenerateXero';
import TransactionTable from './../../components/creditAndBilling/TransactionTable';

export interface IXeroDetailsProps {
    xeroDetailsData: IXeroDetailsResponse;
    userId: string;
}

const XeroDetails: React.FC<IXeroDetailsProps> = ({ xeroDetailsData, userId }) => {
    return (
        <div className="xero-details-wrapper">
            <MDTypography variant="p" className="">
                Client Information as per Xero records
            </MDTypography>

            <MDBox className="xero-input-wrapper">
                <MDInput
                    type="text"
                    label="Xero Id"
                    placeholder="Xero Id"
                    className="xero-input"
                    disabled
                    id="xeroId"
                    name="xeroId"
                    value={xeroDetailsData?.xeroContactId ?? 'Not Generated'}
                />
                {xeroDetailsData?.xeroContactId && (
                    <MDButton
                        onClick={() => {
                            navigator.clipboard.writeText(xeroDetailsData?.xeroContactId).then(() => {
                                enqueueSnackbar('Copied to clipboard', {
                                    variant: 'success',
                                    key: 'copied-code',
                                });
                            });
                        }}
                        type="button"
                        className="button generate-xero-button"
                    >
                        Copy Xero ID
                    </MDButton>
                )}

                {xeroDetailsData?.xeroContactId === null && (
                    <GenerateXero userId={userId} xeroDetailsData={xeroDetailsData} />
                )}
            </MDBox>
            {xeroDetailsData && (
                <>
                    <MDBox>
                        <MDInput
                            type="text"
                            label="Business Name"
                            placeholder="Business Name"
                            className="xero-input"
                            disabled
                            id="businessName"
                            name="businessName"
                            value={xeroDetailsData?.businessName}
                        />
                    </MDBox>

                    <MDBox className="xero-details-input-wrapper">
                        <MDInput
                            type="text"
                            label="Email"
                            placeholder="Email"
                            className="xero-input"
                            disabled
                            id="email"
                            name="email"
                            value={xeroDetailsData?.email}
                        />
                        <MDInput
                            type="text"
                            label="Country"
                            placeholder="Country"
                            className="xero-input"
                            disabled
                            id="country"
                            name="country"
                            value={xeroDetailsData?.country}
                        />
                    </MDBox>
                    <MDBox className="xero-details-input-wrapper">
                        <MDInput
                            type="text"
                            label="Currency"
                            placeholder="CUrrency"
                            className="xero-input"
                            disabled
                            id="currency"
                            name="currency"
                            value={xeroDetailsData?.currency}
                        />
                        <MDInput
                            type="text"
                            label="Client Status"
                            placeholder="Client Status"
                            className="xero-input"
                            disabled
                            id="clientStatus"
                            name="clientStatus"
                            value={toTitleCase(xeroDetailsData?.clientStatus)}
                        />
                    </MDBox>
                </>
            )}

            {xeroDetailsData && <TransactionTable userId={userId} />}
            {/* <XeroNotes /> */}
        </div>
    );
};

export default XeroDetails;
