import { useEffect } from 'react';
import { useLazyGetPromoContentQuery } from 'redux/services/spotdif/promoLink';

export const usePromoLinkContent = (promoCode: string) => {
    const [getPromoContentTrigger, { data: promoContentData }] = useLazyGetPromoContentQuery();

    
    useEffect(() => {
        if (promoCode) {
            getPromoContentTrigger(promoCode);
        }
    }, [promoCode]);

    const {
        heroSection,
        leadShowCase,
        qualityLeads,
        replacementPolicyText,
        replacementPolicyHeader,
        badgeTitle,
        badgeSubTitle,
        badgeColor,
    } = promoContentData?.data || {};

    return {
        heroSection: heroSection || '<p>Get high-intend leads for <span>free</span><p>',
        leadShowCase: leadShowCase || `Access <strong>10,000+</strong> of people daily looking for services like yours`,
        badgeTitle: badgeTitle || null,
        badgeSubTitle: badgeSubTitle || null,
        badgeColor: badgeColor || null,
        qualityLeads:
            qualityLeads ||
            `<strong>Fully-qualified</strong> leads <br/><strong>High-intent</strong> and expecting your call <br/><strong>1 in 4 conversion rate </strong> on average`,
        replacementPolicyHeader: replacementPolicyHeader || null,
        replacementPolicyText: replacementPolicyText || null,
    };
};
