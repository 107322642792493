import React, { createContext, useCallback, useContext, useState } from 'react';

interface IUserOboardingContext {
    restrictJump: boolean;
    toggleJumpRestrictions: (val: boolean) => void;
}

export const UserOnboardingContext = createContext<IUserOboardingContext>({
    restrictJump: true,
    toggleJumpRestrictions: () => null,
});

export function useUserOnboardingContext() {
    const context = useContext(UserOnboardingContext);

    if (!context) {
        console.error('User Onboarding Context can only be used within User Onboarding Context Provider');
        return null;
    }

    return context;
}

export const UserOnboardingContextProvider = ({ children }) => {
    const [restrictJump, setJumpRestrictions] = useState(true);
    const toggleJumpRestrictions = useCallback((val: boolean) => setJumpRestrictions(val), [setJumpRestrictions]);

    return (
        <UserOnboardingContext.Provider
            value={{
                restrictJump: restrictJump,
                toggleJumpRestrictions: toggleJumpRestrictions,
            }}
        >
            {children}
        </UserOnboardingContext.Provider>
    );
};

export default UserOnboardingContextProvider;
