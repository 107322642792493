import { jsonArrayMember, jsonMember, jsonObject } from 'typedjson';

@jsonObject()
export class userPermissionsArray {
    @jsonMember(String)
    module: string;

    @jsonArrayMember(String)
    permission: string[];
}
