import { MODULE, PERMISSIONS } from 'components/app-permission/permission';
import { useUserHasPermission } from 'components/app-permission/useUserHasPermission';
import ChatAttachmentLightBox from 'components/Inbox/ChatAttachmentLightBox';
import { ChatFileAttachment } from 'contracts/chat/ChatFileAttachment';
import { ChatRoom } from 'contracts/chat/ChatRoom';
import useAuthentication from 'hooks/useAuthentication';
import { enqueueSnackbar } from 'notistack';
import { createContext, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useGetRoomsQuery, useLazyGetRoomDetailsQuery } from 'redux/services/chat/chatRooms';
import { useLazyGetChatTokenQuery } from 'redux/services/spotdif/chatToken';
import { addThreadMessage, selectFilteredRooms } from 'redux/slices/chat';
import { useAppDispatch, useTypedSelector } from 'redux/store';
import { io, Socket } from 'socket.io-client';

type RoomCreationPayload = {
    roomId: string;
};
export type ChatRoomNotificationPayload = RoomCreationPayload;

export type ChatRoomNotification = {
    action: string;
    payload: ChatRoomNotificationPayload;
};

export interface IChatContext {
    chatRooms: ChatRoom[];
    requestToJoinRoom: (roomID: string) => void;
    sendMessage: (roomId: string, message: string, attachments: ChatFileAttachment[]) => void;
    handleRoomListScroll: (scrollInfo: { scrollOffset: number; scrollDirection: 'forward' | 'backward' }) => void;
    attachmentInFocus: ChatFileAttachment | null;
    readRoomThreads: (roomId: string) => void;
    setAttachmentInFocus: (attachment: ChatFileAttachment) => void;
}

export const ChatContext = createContext<IChatContext>({} as IChatContext);

const SocketOutgoingEvents = {
    joinRoomRequest: 'join_room',
    joinNotificationsRoom: 'get_notifications',
    sendMessage: 'chat_push',
    readRoomThreads: 'thread_read',
    heartBeat: 'heartbeat',
};

const HEARTBEAT_INTERVAL = 20000;

const ChatContextProvider = ({ children }) => {
    const searchQuery = useTypedSelector((state) => state.chatCore.chatRoomFilters.query);

    const { user } = useAuthentication();
    const socketRef = useRef<Socket>(null);
    const heartbeatRef = useRef<number>();
    const [page, setPage] = useState(1);

    const userCanChat = useUserHasPermission(MODULE.CHAT_INBOX, PERMISSIONS.READ);

    const chatToken = useTypedSelector((state) => state.chatCore.chatToken);
    const chatRooms: Array<ChatRoom> = useTypedSelector(selectFilteredRooms);


    // TODO: use this to keep track of joined rooms and periodically check if the user is still in the room
    const joinedRooms = useRef<Set<string>>();

    const [getChatToken] = useLazyGetChatTokenQuery();

    const appDispatcher = useAppDispatch();

    const [attachmentInFocus, setAttachmentInFocus] = useState<ChatFileAttachment | null>();

    const [getDetailsForSingleRoom, { isLoading: isLoadingRoomDetails, error: errorInFetchingRoomDetails }] =
        useLazyGetRoomDetailsQuery();


    const requestToJoinRoom = useCallback(
        (roomIdToJoin: string | number): Promise<boolean> => {
            if (!joinedRooms.current) {
                console.log('ChatRooms Creating new Set for joined rooms');
                joinedRooms.current = new Set<string>();
            }
            const hasAlreadyJoined = joinedRooms.current.has(roomIdToJoin as string);
            joinedRooms.current.add(roomIdToJoin as string);

            return new Promise((resolve) => {
                window.setTimeout(() => {
                    if (!socketRef?.current?.id) return Promise.resolve(false);

                    if (!hasAlreadyJoined) {
                        const payload = {
                            roomId: roomIdToJoin,
                            socketId: socketRef.current.id,
                        };
                        socketRef.current.emit(SocketOutgoingEvents.joinRoomRequest, payload);
                    }

                    getDetailsForSingleRoom(roomIdToJoin as string);

                    resolve(true);
                }, (socketRef?.current?.id) ? 400 : 3400);
            });
        },
        [getDetailsForSingleRoom],
    );


    const {
        isLoading: isLoadingRooms,
        error: errorInFetchingRooms,
        refetch: refetchRooms,
        data: roomsData,
    } = useGetRoomsQuery({ page, searchTerm: searchQuery }, {
        refetchOnMountOrArgChange: true,
        skip: !chatToken,
    });

    const performRequestedAction = useCallback(({ action, payload }: ChatRoomNotification) => {
        switch (action) {
            default:
                requestToJoinRoom(payload.roomId).then(() => {
                });
                break;
        }
    }, [requestToJoinRoom]);


    const socketEventHandlers = useMemo(
        () => ({
            chat: (data) => {
                if (user?._id) {
                    appDispatcher(addThreadMessage({
                        ...data,
                        direction: data.senderId === user._id ? 'outgoing' : 'incoming',
                    }));
                }
            },
            notification: (data: ChatRoomNotification) => {
                performRequestedAction(data);
            },
        }),
        [appDispatcher, performRequestedAction, user?._id],
    );


    const establishChannels = useCallback(async () => {
        if (!socketRef.current?.connected) {
            window.setTimeout(() => {
                console.log('ChatRooms Socket not connected. Retrying in 5 seconds...');
                establishChannels();
            }, 5000);

            return;
        }
        socketRef.current.emit(SocketOutgoingEvents.joinNotificationsRoom, { socketId: socketRef.current.id });
        for (const roomId of joinedRooms.current) {
            await requestToJoinRoom(roomId);
        }
    }, [requestToJoinRoom]);

    const connectToChatServer = useCallback(() => {
        if (!user || !userCanChat) {
            console.log('User not logged in or does not have permission to chat');
            return;
        }

        if (!socketRef.current || (socketRef.current && !socketRef.current.connected)) {
            socketRef.current = io(`${process.env.REACT_APP_CHAT_SERVER_URL}`, {
                extraHeaders: {
                    authorization: `${chatToken}`,
                },
            });

            heartbeatRef.current = window.setInterval(() => {
                socketRef.current.emit(SocketOutgoingEvents.heartBeat);
            }, HEARTBEAT_INTERVAL);

            window.setTimeout(() => {
                establishChannels().then(() => {
                    console.log('ChatRooms connections are active.');
                });
            }, 2000);

            window.setInterval(() => {
                establishChannels().then(() => {
                    console.log('ChatRooms connection refreshed.');
                });
            }, 1000 * 60 * 15);

            Object.keys(socketEventHandlers).forEach((event) => {
                socketRef.current?.on(event, socketEventHandlers[event]);
            });
        }
    }, [user, chatToken, establishChannels, socketEventHandlers]);

    const handleScroll = useCallback(
        ({ scrollOffset, scrollDirection }) => {
            if (scrollDirection === 'forward' && scrollOffset > 0 && !isLoadingRooms) {
                if (roomsData?.meta.total === chatRooms.length) return;
                else {
                    setPage((prevPage) => prevPage + 1);
                }
            }
        },
        [chatRooms?.length, isLoadingRooms, roomsData?.meta.total],
    );

    const sendMessage = useCallback((roomId: string, message: string, attachments: ChatFileAttachment[]) => {
        if (!socketRef?.current?.id || !message.trim()) {
            if (attachments.length === 0) {
                enqueueSnackbar('Couldn\'t send empty message.', {
                    variant: 'warning',
                    key: 'chat-inbox--sendMessage',
                });
                return;
            }
        }

        const payload = {
            roomId,
            message,
            attachments: attachments.map(({ _id }) => _id),
            socketId: socketRef.current.id,
        };

        socketRef.current.emit(SocketOutgoingEvents.sendMessage, payload);
    }, []);


    const readRoomThreads = useCallback((roomId: string) => {
        if (!socketRef?.current?.id) return;

        const payload = {
            roomId,
            socketId: socketRef.current.id,
        };

        socketRef.current.emit(SocketOutgoingEvents.readRoomThreads, payload);
    }, []);

    useEffect(() => {
        if (!user || !userCanChat) {
            console.log('User not logged in or does not have permission to chat');
            return;
        }
        try {
            getChatToken({
                userId: user?._id,
                businessId: user?.businessDetailsId?._id,
                buyerId: user?.buyerId,
                industryId: user?.businessIndustryId,
                tag: 'dashboard',
            }).then(() => {
                refetchRooms();
            }).catch(e => {
                console.error('Error in fetching chat token', e);
            });
        } catch (e) {
            console.error('Error in fetching chat token #2', e);
        }
    }, [getChatToken, refetchRooms, user, userCanChat]);

    useEffect(() => {
        joinedRooms.current = new Set<string>();

        return () => {
            console.log('Disconnecting from Chat Server');
            socketRef.current?.disconnect();
            window.clearInterval(heartbeatRef.current);
        };
    }, [requestToJoinRoom]);

    useEffect(() => {
        if (!(!chatToken || socketRef.current)) {
            connectToChatServer();
        }
    }, [chatToken, connectToChatServer, getDetailsForSingleRoom, socketEventHandlers]);


    return (
        <ChatContext.Provider
            value={{
                attachmentInFocus,
                setAttachmentInFocus,
                chatRooms,
                requestToJoinRoom,
                sendMessage,
                readRoomThreads,
                handleRoomListScroll: handleScroll,
            }}
        >
            {children}

            <ChatAttachmentLightBox />
        </ChatContext.Provider>
    );
};

export default ChatContextProvider;
