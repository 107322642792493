import React from 'react';

import './style.scss';

interface IStatsCard {
    title: string;
    totalCount: number;
    buttonText?: string;
    isExportAllowed?: boolean;
    action?: any;
    children?: any;
}

const StatsCard: React.FC<IStatsCard> = ({
    title,
    totalCount,
    buttonText,
    action,
    children,
    isExportAllowed = false,
}) => {
    return (
        <div className="stats-card">
            <div className="stats-header d-flex justify-space-between mb-2">
                <div className="stats-card-title">
                    {title} <span className="stats-card-count">({totalCount})</span>
                </div>
                {isExportAllowed && (
                    <button className="stats-card-button" onClick={action}>
                        {buttonText}
                    </button>
                )}
            </div>
            <div>{children}</div>
        </div>
    );
};

export default StatsCard;
