import { AuthenticationKeys } from 'components/AuthenticationKeys';
import TeamMembers from 'components/team-members';
import MDButton from 'components/themed/MDButton';
import { IUserProfileRequest } from 'contracts/requests/IUserProfileResponse';
import { IOnboardingStepComponent } from 'contracts/view-models/IOnboardingStepComponent';
import { Form, FormikProvider, useFormik } from 'formik';
import useAuthentication from 'hooks/useAuthentication';
import { useRegexPatterns } from 'hooks/useRegexPatterns';
import { useSnackbar } from 'notistack';
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { useUpdateUserProfileMutation } from 'redux/services/spotdif/user';
import * as Yup from 'yup';
import UserProfileDetails from './UserProfileDetails';

import './style.scss';

interface IUserProfileProps extends IOnboardingStepComponent {
    isOnBoardingCompleted?: boolean;
}

const UserProfile: React.ForwardRefExoticComponent<React.RefAttributes<IUserProfileProps>> = forwardRef(
    (props, ref) => {
        const { user } = useAuthentication();
        const { enqueueSnackbar } = useSnackbar();

        const [isFormValid, setIsFormValid] = useState(false);
        const [updateUserProfileTrigger, { isLoading: isUpdatingUserProfile }] = useUpdateUserProfileMutation();

        const { phoneNumberRegex } = useRegexPatterns();

        const handleUserDetails = async (request: IUserProfileRequest) => {
            updateUserProfileTrigger(request)
                .unwrap()
                .then((res) => {
                    enqueueSnackbar('User Details Updated Successfully', { variant: 'success' });
                })
                .catch(({ data }) => {
                    enqueueSnackbar(data?.error?.message ?? 'Something Went Wrong', { variant: 'error' });
                });
        };

        const userProfileForm = useFormik<Omit<IUserProfileRequest, '_id'>>({
            initialValues: {
                firstName: user.firstName,
                lastName: user.lastName,
                email: user.email,
                phoneNumber: user.phoneNumber,
                mobilePrefixCode: user.mobilePrefixCode ?? '44',
            },
            validationSchema: Yup.object().shape({
                firstName: Yup.string().required('First Name is required'),
                lastName: Yup.string().required('Last Name is required'),
                phoneNumber: Yup.string()
                    .matches(phoneNumberRegex, 'Phone Number is not valid')
                    .required('Phone Number is required'),
            }),
            onSubmit: async (values) => {
                await handleUserDetails({
                    _id: user._id,
                    ...values,
                });
            },
        });

        useImperativeHandle(ref, () => ({
            handleValidation,
        }));

        const handleValidation = () => {
            return userProfileForm.validateForm().then((d) => {
                const isFormValid = Object.keys(d).length === 0;
                setIsFormValid(isFormValid);
                userProfileForm.handleSubmit();
                // Return the boolean value
                return isFormValid;
            });
        };

        return (
            <>
                <FormikProvider value={userProfileForm}>
                    <Helmet>
                        <title>Details</title>
                    </Helmet>
                    <Form onSubmit={userProfileForm.handleSubmit}>
                        <div className="tab-user-content">
                            <h6>Account holder</h6>
                            <UserProfileDetails />
                        </div>
                        {user.hasOnboarded && (
                            <div className="form-footer form-footer--action-group d-flex">
                                <MDButton
                                    className="button"
                                    type="submit"
                                    disabled={isUpdatingUserProfile}
                                    isLoading={isUpdatingUserProfile}
                                >
                                    Save changes
                                </MDButton>
                                <Link to="/account/reset-password" className="button button-outline-primary">
                                    Reset Password
                                </Link>
                            </div>
                        )}
                    </Form>
                </FormikProvider>


                {
                    user.hasOnboarded
                        ? (
                            <>
                                <AuthenticationKeys />
                                <TeamMembers />
                            </>
                        )
                        : null
                }
            </>
        );
    },
);

export default UserProfile;
