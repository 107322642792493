import { jsonMember, jsonObject, TypedJSON } from 'typedjson';
import dayjs from 'dayjs';
import { timeToDayJS } from 'utils/dateHelpers';

export interface IDayScheduleData {
    day: string;
    openTime: dayjs.Dayjs | string;
    closeTime: dayjs.Dayjs | string;
}

@jsonObject()
export class DaySchedule {
    constructor(schedule: IDayScheduleData) {
        this.closeTime = typeof schedule.closeTime === 'string' ? timeToDayJS(schedule.closeTime) : schedule.closeTime;

        this.openTime = typeof schedule.openTime === 'string' ? timeToDayJS(schedule.openTime) : schedule.openTime;

        this.day = schedule?.day;
    }

    @jsonMember(String)
    day: string;

    @jsonMember(() => dayjs.Dayjs, {
        deserializer: (value: any) => {
            return timeToDayJS(value);
        },
    })
    openTime: dayjs.Dayjs;

    @jsonMember(() => dayjs.Dayjs, {
        deserializer: (value: any) => {
            return timeToDayJS(value);
        },
    })
    closeTime: dayjs.Dayjs;

    @jsonMember(Boolean, {
        deserializer: (value: unknown) => {
            return !!value;
        },
    })
    isActive: boolean = false;
}

export function sanitizeWeeklySchedule(value: any): DaySchedule[] {
    // Ensure that the schedule is sorted by day of week and have all 7 days
    const sortedSchedules = getDefaultScheduleData();

    if (!(value && Array.isArray(value))) {
        return sortedSchedules;
    } else {
        const schedulesFromApi = value.map((item: any) => {
            try {
                const schedule = new DaySchedule(item);

                schedule.isActive = true;

                return schedule;
            } catch (e) {
                // console.error('Day Schedule was not initialized');
                return getDefaultDaySchedule();
            }
        });

        return sortedSchedules.reduce((acc, schedule) => {
            const found = schedulesFromApi.find((s) => s.day === schedule.day);
            if (found) {
                acc.push(found);
            } else {
                acc.push(schedule);
            }
            return acc;
        }, []);
    }
}

export function getDefaultScheduleData(): DaySchedule[] {
    return Array(7)
        .fill({})
        .map((obj, index) => {
            let sch = getDefaultDaySchedule();
            sch.day = dayjs().day(Number(index)).format('dddd');
            return sch;
        });
}

export function getDefaultDaySchedule() {
    return new DaySchedule({
        day: dayjs().day(0).format('dddd'),
        openTime: dayjs().hour(9).minute(0).second(0),
        closeTime: dayjs().hour(17).minute(0).second(0),
    });
}

export const DayScheduleSerializer = new TypedJSON(DaySchedule);
