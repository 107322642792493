import dayjs from 'dayjs';
import { jsonObject, jsonMember, TypedJSON, jsonArrayMember } from 'typedjson';

@jsonObject()
export class TokenizedCardDetails {
    @jsonMember(String)
    id: string;
    @jsonMember(Boolean)
    stored: boolean;
}

@jsonObject()
export class CardSchema {
    @jsonMember(String)
    scheme: string;

    @jsonMember(String)
    last4: string;
}

@jsonObject()
export class RyftCard {
    @jsonMember(String)
    expiryMonth: string;

    @jsonMember(String)
    expiryYear: string;

    @jsonMember(String)
    scheme: string;

    @jsonMember(String)
    last4: string;
}

@jsonObject()
export class RyftCardDetails {
    @jsonMember(String)
    type: string; // TODO: convert to Enum

    @jsonMember(String)
    id: string;

    @jsonMember(String)
    customerId: string;

    @jsonMember(() => dayjs.Dayjs, {
        deserializer: (value: any) => {
            return value ? dayjs(value) : null;
        },
    })
    createdTimestamp: dayjs.Dayjs;

    // @jsonMember(RyftCard)
    // card: RyftCard;

    @jsonArrayMember(RyftCard)
    card: RyftCard[];
}

@jsonObject()
export class CustomerPaymentMethods {
    @jsonMember(String)
    paginationToken: string;

    @jsonArrayMember(RyftCardDetails)
    items: RyftCardDetails[];
}

@jsonObject()
export class ManualPaymentConfig {
    @jsonMember(String)
    publicKey: string;

    @jsonMember(String)
    clientSecret: string;

    @jsonMember(() => CustomerPaymentMethods)
    customerPaymentMethods: CustomerPaymentMethods;
}

@jsonObject()
export class RedirectToURL {
    @jsonMember(String)
    url: string;
}

@jsonObject()
export class NextAction {
    @jsonMember(() => RedirectToURL)
    redirect_to_url?: RedirectToURL;
}

@jsonObject()
export class LastPaymentError {
    message: string;
}

@jsonObject()
export class RetrievePaymentDetailsData {
    @jsonMember(String)
    sessionID: string;

    @jsonMember(String, {
        deserializer: (value: any) => {
            return value ? `${value}` : null;
        },
    })
    status: string;

    @jsonMember(String)
    message?: string;

    @jsonMember(String)
    url?: string;

    @jsonMember(String)
    paymentMethods?: string;

    @jsonMember(() => ManualPaymentConfig)
    manualPaymentConfig?: ManualPaymentConfig;

    get needsManualPayment(): boolean {
        return !!this.manualPaymentConfig && this.status?.toLowerCase() === 'manual_payment_required';
    }

    @jsonMember(() => NextAction)
    next_action?: NextAction;

    @jsonMember(() => LastPaymentError)
    last_payment_error?: LastPaymentError;
}

export const RetrievePaymentDetailsDataSerializer = new TypedJSON(RetrievePaymentDetailsData);
