import React, { useCallback } from 'react';
import LeadCard from 'components/shared/LeadCard';
import useAuthentication from 'hooks/useAuthentication';
import { useFormikContext } from 'formik';
import { LeadDetails } from 'contracts/spotdif/LeadDetails';

const LeadHeader: React.FC = () => {
    const { user } = useAuthentication();
    const { values, errors } = useFormikContext<LeadDetails>();
    const calcAvgSales = useCallback(
        (leadsPerDay, avgConversionRate = 100) => {
            if (leadsPerDay && avgConversionRate) {
                return (avgConversionRate * leadsPerDay) / 100;
            }
        },
        [values.daily, user?.avgConversionRate],
    );

    return (
        <>
            <div className="card-wrapper-light">
                <LeadCard
                    theme="light"
                    title="Your current<br/> cost per lead"
                    value={user?.leadCost}
                    valueType="currency"
                    userCurrency={user?.currency}
                />
                <LeadCard
                    theme="light"
                    title="Your total<br /> cost per day"
                    value={!errors.daily ? user.leadCost * values.daily : 0}
                    valueType="currency"
                    userCurrency={user?.currency}
                />
            </div>
            <div className="card-wrapper-dark">
                <LeadCard
                    theme="dark"
                    title="Average conversion<br /> to sale rate"
                    value={user?.avgConversionRate}
                    valueType="percentage"
                />
                <LeadCard
                    theme="dark"
                    title="Your estimated number<br /> of sales per day"
                    value={calcAvgSales(Number(values.daily), user?.avgConversionRate)}
                    valueType="simple"
                />
            </div>
        </>
    );
};

export default LeadHeader;
