/**
 =========================================================
 * Material Dashboard 2 PRO React TS - v1.0.2
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
 * Copyright 2023 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import { TypographyProps } from '@mui/material';

// Custom styles for MDTypography
import MDTypographyRoot from 'components/themed/MDTypography/MDTypographyRoot';

// Material Dashboard 2 PRO React TS contexts
import { useMaterialUIController } from 'context/ThemeContext';
import { FC, forwardRef, ReactNode } from 'react';

// Declaring props types for MDTypography
interface Props extends TypographyProps {
    color?:
        | 'inherit'
        | 'primary'
        | 'secondary'
        | 'info'
        | 'success'
        | 'warning'
        | 'error'
        | 'light'
        | 'dark'
        | 'text'
        | 'white';
    fontWeight?: 'light' | 'regular' | 'medium' | 'bold' | undefined;
    textTransform?: 'none' | 'capitalize' | 'uppercase' | 'lowercase';
    verticalAlign?: 'unset' | 'baseline' | 'sub' | 'super' | 'text-top' | 'text-bottom' | 'middle' | 'top' | 'bottom';
    textGradient?: boolean;
    children: ReactNode;
    opacity?: number;

    [key: string]: any;
}

const MDTypography: FC<Props | any> = forwardRef(
    ({ color, fontWeight, textTransform, verticalAlign, textGradient, opacity, children, ...rest }, ref) => {
        const [controller] = useMaterialUIController();
        const { darkMode } = controller;

        return (
            <MDTypographyRoot
                {...rest}
                ref={ref}
                ownerState={{
                    color,
                    textTransform,
                    verticalAlign,
                    fontWeight,
                    opacity,
                    textGradient,
                    darkMode,
                }}
            >
                {children}
            </MDTypographyRoot>
        );
    },
);

// Declaring default props for MDTypography
MDTypography.defaultProps = {
    color: 'dark',
    fontWeight: undefined,
    textTransform: 'none',
    verticalAlign: 'unset',
    textGradient: false,
    opacity: 1,
};

export default MDTypography;
