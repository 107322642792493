import React, { useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import MDButton from 'components/themed/MDButton';
import BasicModal from 'components/core/Modal';
import { createColumnHelper } from '@tanstack/react-table';
import { Autocomplete, Checkbox } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import './style.scss';
import AuthorisedUsage from 'components/app-permission/AuthorisedUsage';
import { MODULE, PERMISSIONS } from 'components/app-permission/permission';
import SpinningComponent from 'components/shared/SpinningComponent';
import CloseIcon from '@mui/icons-material/Close';
import MDBox from 'components/themed/MDBox';
import { useUpdateRoleModulePermissionsMutation } from 'redux/services/spotdif/rolePermissions';
import SharedDataTable from 'components/shared/SharedTable/SharedDataTable';
import MDTypography from 'components/themed/MDTypography';
import { enqueueSnackbar } from 'notistack';
import { useGetRolePermissionsQuery } from 'redux/services/spotdif/sub-admin';
import { handleCheckboxChange } from 'utils/handleCheckboxChange';
import MDInput from 'components/themed/MDInput';
import { userPermissionsArray } from 'contracts/spotdif/userPermissionsArray';
import toTitleCase, { capitalizeFirstLetter } from 'utils/text-helpers';

interface IRolePermission {
    _id?: string;
    role?: string;
    permissions: userPermissionsArray[];
}

const UpdatePermissionsTable: React.FC<{ info: IRolePermission }> = ({ info }) => {
    const [rowData, setRowData] = useState<IRolePermission>(info);
    const rolePermissionsObject = {
        permissions: rowData?.permissions,
    };

    const { data: rolePermissions, isLoading: isRolePermissionsLoading } = useGetRolePermissionsQuery();
    const [openPermissionsPopup, setOpenPermissionsPopup] = useState(false);
    const [selectedRole, setSelectedRole] = useState('');

    const columnHelper = createColumnHelper();

    const handleClosePermissionsPopup = useCallback(() => {
        setOpenPermissionsPopup(false);
        setSelectedRole('');
        setRowData(rolePermissionsObject);
    }, [setRowData, setOpenPermissionsPopup]);

    const handleCheckboxChangeMemoized = useCallback(handleCheckboxChange(setRowData), [setRowData]);

    const [updateRolePermissionsTrigger] = useUpdateRoleModulePermissionsMutation();
    const updateRolePermissions = useCallback(
        async (id: string, body: IRolePermission) => {
            try {
                const response = await updateRolePermissionsTrigger({ roleId: id, body });
                if ('data' in response) {
                    enqueueSnackbar(response.data.message, { variant: 'success', key: 'change-rolePermission' });
                } else {
                    enqueueSnackbar('Failed to update role permissions', {
                        variant: 'error',
                        key: 'change-rolePermission',
                    });
                }
                handleClosePermissionsPopup();
                return response;
            } catch (error) {
                enqueueSnackbar('Failed to update role permissions', {
                    variant: 'error',
                    key: 'change-rolePermission',
                });
                throw error;
            }
        },
        [updateRolePermissionsTrigger],
    );

    const sharedDataColumns = [
        {
            accessor: 'module',
            header: () => <span>Module Name</span>,
            cell: (info) => <span>{capitalizeFirstLetter(info.getValue() ?? 'Unable to retrieve')}</span>,
        },
        {
            accessor: 'create',
            header: () => <span>Create</span>,
            cell: ({ row }) => (
                <Checkbox
                    checked={row.original.create}
                    onChange={() => handleCheckboxChangeMemoized(row.index, 'create')}
                />
            ),
        },
        {
            accessor: 'read',
            header: () => <span>Read</span>,
            cell: ({ row }) => (
                <Checkbox
                    checked={row.original.read}
                    onChange={() => handleCheckboxChangeMemoized(row.index, 'read')}
                />
            ),
        },
        {
            accessor: 'update',
            header: () => <span>Update</span>,
            cell: ({ row }) => (
                <Checkbox
                    checked={row.original.update}
                    onChange={() => handleCheckboxChangeMemoized(row.index, 'update')}
                />
            ),
        },
        {
            accessor: 'delete',
            header: () => <span>Delete</span>,
            cell: ({ row }) => (
                <Checkbox
                    checked={row.original.delete}
                    onChange={() => handleCheckboxChangeMemoized(row.index, 'delete')}
                />
            ),
        },
    ];

    const handleRoleChange = useCallback(
        (event, value) => {
            if (value) {
                const selectedRolePermissions = rolePermissions?.data?.roles.find((role) => role.role === value.role);
                if (selectedRolePermissions) {
                    const updatedRolePermissions = {
                        _id: selectedRolePermissions._id,
                        role: selectedRolePermissions.role,
                        permissions: selectedRolePermissions.permissions,
                    };
                    setRowData(updatedRolePermissions);
                    setSelectedRole(value.role);
                }
            }
        },
        [rolePermissions, setRowData, setSelectedRole],
    );

    return (
        <>
            <SpinningComponent loading={isRolePermissionsLoading}>
                <div className="table_col">
                    <div className="edit-icon cursor-pointer">
                        <AuthorisedUsage module={MODULE.ADMINS} permission={PERMISSIONS.UPDATE}>
                            <EditIcon
                                onClick={() => {
                                    setOpenPermissionsPopup(true);
                                }}
                            />
                        </AuthorisedUsage>
                    </div>
                </div>
                <BasicModal open={openPermissionsPopup} handleClose={handleClosePermissionsPopup}>
                    <MDBox className="modalFormMainWrapper">
                        <div className="permission-table-container">
                            <div className="header">
                                <MDTypography variant="h5">Update Permissions</MDTypography>
                                <Autocomplete
                                    className="dropdownMenu"
                                    onChange={handleRoleChange}
                                    options={rolePermissions?.data?.roles}
                                    getOptionLabel={(option) => option.role}
                                    renderOption={(props, option) => (
                                        <li {...props} key={option._id}>
                                            {toTitleCase(option.role ?? 'Unable to retrieve')}
                                        </li>
                                    )}
                                    renderInput={(params) => (
                                        <MDInput {...params} fullWidth placeholder="Select a role preset" />
                                    )}
                                />
                            </div>
                            <div className="table-v2 client-leads-v2 update-permission-modal">
                                <SharedDataTable
                                    tableData={rowData?.permissions?.map((permission) => ({
                                        module: permission.module,
                                        create: permission.permission.includes('create'),
                                        read: permission.permission.includes('read'),
                                        update: permission.permission.includes('update'),
                                        delete: permission.permission.includes('delete'),
                                    }))}
                                    loading={isRolePermissionsLoading}
                                    columns={sharedDataColumns}
                                    columnHelper={columnHelper}
                                    hasCurvedHeader={false}
                                    toSortHeader={'module'}
                                    hasLeftAligned={true}
                                />
                            </div>
                        </div>
                        <button
                            className="button update-table w-100"
                            onClick={() => updateRolePermissions(info._id, rolePermissionsObject)}
                        >
                            Update table
                        </button>
                        <div className="roleModalCloseWrapper">
                            <MDButton className="roleModalCloseBtn" onClick={handleClosePermissionsPopup}>
                                <CloseIcon fontSize="large" />
                            </MDButton>
                        </div>
                    </MDBox>
                </BasicModal>
            </SpinningComponent>
        </>
    );
};

export default UpdatePermissionsTable;
