const color = (status) => {
    switch (status) {
        case 'Valid':
            return '#1de993';

        case 'Reported':
            return '#edf200';

        case 'Report Rejected':
            return '#ff5252';

        case 'Report Accepted':
            return '#21afff';

        default:
            return 'rgb(29, 233, 147)';
    }
};

export const chartColor = ['primary', 'secondary', 'info', 'success', 'warning', 'error', 'light', 'dark'];
// export const chartColor =  ["error" , "light" , "dark" , "warning" , "success" , "primary" , "secondary" , "info", ];

export default color;
