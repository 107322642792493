import VisaImage from 'assets/images/small-logos/Group_31.svg';
import React from 'react';

import './style.scss';

export interface IMasterCardProps {
    card: object;
}

const MasterCard = ({ card }) => {
    return (
        <div className="imgContainer">
            <div className="cardTextContainer">
                <div className="validCardContainer"></div>
                <p className="cardNumText">{`**** ${card.cardNumber}`} </p>
            </div>
            <div className="creditCard-Box">
                <img src={VisaImage} loading="lazy" alt="cardImage" className="visaImage" />
            </div>
        </div>
    );
};

export default MasterCard;
