import CloseIcon from '@mui/icons-material/Close';
import MDBox from 'components/themed/MDBox';
import MDButton from 'components/themed/MDButton';
import MDTypography from 'components/themed/MDTypography';
import React from 'react';
import './style.scss';

const DeleteUser = ({ handleUserDeleteBtn, handleCloseDeleteModal, userData }) => {
    return (
        <>
            <MDBox className="modalFormMainWrapper" component="form" role="form">
                <MDBox className="modalMainWrapper">
                    <MDTypography className="modal-heading-2 text-center">Are you sure?</MDTypography>
                    <p className="text-center">Do you want to delete this team member? It cannot be undone.</p>
                    <div className="modal_input-wrapNew deleteModalContentWrapper">
                        <MDTypography className="deleteModalContentDetails deleteModalUserName">
                            {userData.name}
                        </MDTypography>
                        <MDTypography className="deleteModalContentDetails">{userData.email}</MDTypography>
                    </div>
                </MDBox>
                <MDButton className="button modalBtn" fullWidth onClick={() => handleUserDeleteBtn(true)}>
                    Yes, delete
                </MDButton>
                <MDBox className="modalCloseWrapper">
                    <MDButton className="modalCloseBtn" onClick={handleCloseDeleteModal}>
                        <CloseIcon fontSize="large" />
                    </MDButton>
                </MDBox>
            </MDBox>
        </>
    );
};

export default DeleteUser;
