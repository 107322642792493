//  TODO: ISO_4217/ refer this url https://en.wikipedia.org/wiki/ISO_4217

export enum ECurrency {
    GBP = 'GBP',
    USD = 'USD',
    EUR = 'EUR',
}

// TODO: ISO 3166-1 alpha-2// * refer this url https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2
export enum ECountry {
    GB = 'UK',
    US = 'USA',
    EUR = 'Ireland',
}
