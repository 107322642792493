import React from 'react';

interface FormikErrorRendererProps {
    errors: Object;
    shouldBeCentered?: boolean;
}

export const FormikErrorsRenderer: React.FC<FormikErrorRendererProps> = ({ errors, shouldBeCentered = false }) => {
    const errorClass = shouldBeCentered ? 'error-v2 text-align-center' : 'error-v2';

    const renderErrors = (errorObject: any, prefix = '') => {
        return Object.keys(errorObject).map((key) => {
            const nestedKey = prefix ? `${prefix}.${key}` : key;
            const error = errorObject[key];

            if (typeof error === 'object' && error !== null) {
                return renderErrors(error, nestedKey);
            } else {
                return <div key={nestedKey} className={errorClass}>{error}</div>;
            }
        });
    };

    return (typeof errors === 'string')
        ? <div className={errorClass}>{errors}</div>
        : <>{renderErrors(errors)}</>;
};
