import UpdatePassword from 'components/update-password';
import React from 'react';

const ResetPassword: React.FC = () => {
    return (
        <div className="layout-middle">
            <UpdatePassword />
        </div>
    );
};

export default ResetPassword;
