import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import PaymentTestimonial from 'components/shared/PaymentTestimonial/PaymentTestimonial';

import 'swiper/css';
import { PaymentHandlerContext } from 'context/RfytPaymentContext';
import useAuthentication from 'hooks/useAuthentication';
import { enqueueSnackbar } from 'notistack';
import React, { useContext, useEffect, useState } from 'react';

import { useCreateSesionMutation } from 'redux/services/spotdif/onBoarding';
import StripePaymentProcessor from './StripePaymentProcessor';
import 'swiper/css/autoplay';

const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PUBLISHING_KEY}`);

export interface ICreateSessionValues {
    clientId: string;
    amount: number;
    userId?: string;
}

export interface OptionsType {
    clientSecret: string;
}

export interface IStripePaymentProps {
    closeModal?: () => void;
    modalOpen?: boolean;
}

const StripePayment: React.FC<IStripePaymentProps> = ({ closeModal, modalOpen = false }) => {
    const { user } = useAuthentication();
    const paymentContext = useContext(PaymentHandlerContext);

    const initialValues: ICreateSessionValues = {
        clientId: user?.stripeClientId ?? '',
        amount: 0,
        userId: user?._id,
    };

    const [options, setOptions] = useState<OptionsType>({ clientSecret: '' });

    const [createSessionTrigger] = useCreateSesionMutation();

    const handleCreatePaymentSession = async () => {
        createSessionTrigger(initialValues)
            .unwrap()
            .then((res) => {
                const paymentIntent = res.data;
                setOptions({
                    clientSecret: paymentIntent.clientSecret,
                });
                paymentContext.setValues({
                    clientSecret: paymentIntent.clientSecret,
                });
            })
            .catch((err) => {
                console.log(err);
                enqueueSnackbar(err?.data?.data?.message, { variant: 'error', key: 'create-session' });
            });
    };

    useEffect(() => {
        handleCreatePaymentSession();
    }, []);

    // const testimonialData: PaymentTestimonialProps[] = [
    //     {
    //         id: 1,
    //         testimonialText:
    //             'SpotDif have been an integral partner for Evergen. They continue to innovate in data and lead generation services, outperforming the biggest names in the industry on volume and conversion.',
    //         user: 'Michael Twynam, Evergen',
    //     },
    //     {
    //         id: 2,
    //         testimonialText:
    //             'SpotDif have been an integral partner for Evergen. They continue to innovate in data and lead generation services, outperforming the biggest names in the industry on volume and conversion.',
    //         user: 'Michael Twynam, Evergen',
    //     },
    //     {
    //         id: 3,
    //         testimonialText:
    //             'SpotDif have been an integral partner for Evergen. They continue to innovate in data and lead generation services, outperforming the biggest names in the industry on volume and conversion.',
    //         user: 'Michael Twynam, Evergen',
    //     },
    // ];

    return (
        <>
            {options.clientSecret !== '' && (
                <Elements stripe={stripePromise} options={options}>
                    {!user?.hasOnboarded && (
                        <p className="stripe-form-label">
                            Adding your card details won’t charge you now. It’s just for easy purchases later, if you
                            decide to buy leads.
                        </p>
                    )}
                    <StripePaymentProcessor modalOpen={false} />
                    {/* --- carousel commented for now might need later */}
                    {/* {!user?.hasOnboarded && (
                        <Swiper
                            autoplay={{
                                delay: 2000,
                                // disableOnInteraction: false,
                            }}
                            modules={[Autoplay]}
                            slidesPerView={1}
                            className="mySwiper"
                            centeredSlides={true}
                        >
                            {testimonialData?.map((testimonial: PaymentTestimonialProps) => (
                                <SwiperSlide key={testimonial.id}>
                                    <PaymentTestimonial
                                        testimonialText={testimonial.testimonialText}
                                        user={testimonial.user}
                                    />
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    )} */}
                    {!user?.hasOnboarded && (
                        <PaymentTestimonial
                            testimonialText="SpotDif have been an integral partner for Evergen. They continue to innovate in data and lead generation services, outperforming the biggest names in the industry on volume and conversion."
                            user="Michael Twynam, Evergen"
                        />
                    )}
                </Elements>
            )}
        </>
    );
};

export default StripePayment;
