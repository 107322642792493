import { jsonMember, jsonObject } from 'typedjson';

@jsonObject()
export class LeadsDataObject {
    @jsonMember(String)
    email: string;

    @jsonMember(String)
    title: string;

    @jsonMember(String)
    leadid?: string;

    @jsonMember(String)
    businessName: string;

    @jsonMember(String)
    businessIndustry: string;

    @jsonMember(String)
    firstName: string;

    @jsonMember(String)
    lastName: string;

    // just due to name conflict on leads data

    @jsonMember(String)
    firstname: string;

    @jsonMember(String)
    lastname: string;

    @jsonMember(String)
    clientName: string;

    @jsonMember(String)
    city: string;

    @jsonMember(String)
    dob: string;

    @jsonMember(String)
    building: string;

    @jsonMember(String)
    street1: string;

    @jsonMember(String)
    street2: string;

    @jsonMember(String)
    towncity: string;

    @jsonMember(String)
    county: string;

    @jsonMember(String)
    postcode: string;

    @jsonMember(String)
    phone1: string;

    @jsonMember(String)
    phone2: string;

    @jsonMember(String)
    phone3: string;

    @jsonMember(String)
    company: string;

    @jsonMember(String)
    jobtitle: string;

    @jsonMember(String)
    fax: string;

    @jsonMember(String)
    ipaddress: string;

    @jsonMember(String)
    source: string;

    @jsonMember(String)
    gender: string;

    @jsonMember(String)
    optindate: string;

    @jsonMember(String)
    optinurl: string;

    @jsonMember(String)
    c1: string;

    @jsonMember(String)
    c2: string;

    @jsonMember(String)
    c3: string;

    @jsonMember(String)
    sid: string;
    @jsonMember(String)
    SID: string;

    @jsonMember(String)
    ssid: string;

    @jsonMember(String)
    accountManager: string;
}
