import BusinessDetails from 'components/business-details';
import LeadSettings from 'components/lead-settings';
import StripePayment from 'components/stripe-payment';
import UserProfile from 'components/user-profile';
import CountryCodeProvider from 'context/CountryCodeContext/CountryCodeContext';
import { useUserOnboardingContext } from 'context/UserOnboarding';
import { IOnboardingStepComponent } from 'contracts/view-models/IOnboardingStepComponent';
import './style.scss';
import useAuthentication from 'hooks/useAuthentication';
import React, { useEffect, useRef } from 'react';
import { Outlet, Route, Routes, useNavigate } from 'react-router-dom';
import OnboardingStepperFooter from './OnboardingStepperFooter';
import OnboardingStepperHeader from './OnboardingStepperHeader';

interface IUserDetailsProps {}

const UserOnboardingStepper: React.FC<IUserDetailsProps> = () => {
    const stepRef = useRef<IOnboardingStepComponent>();
    const { user, isUser } = useAuthentication();
    const { isNonBillableUser } = useAuthentication();
    const navigate = useNavigate();

    const { toggleJumpRestrictions } = useUserOnboardingContext();

    const onboardingSteps = React.useMemo(() => {
        const steps = [
            {
                id: 1,
                title: 'Your Details',
                path: '/on-boarding',
            },
            {
                id: 2,
                title: 'Your Business',
                path: '/on-boarding/business',
            },
            {
                id: 3,
                title: 'Lead Settings',
                path: '/on-boarding/leads',
            },
            {
                id: 4,
                title: 'Payment Information',
                path: '/on-boarding/payment',
            },
        ];

        if ((isUser && user?.isCreditsAndBillingEnabled) || (isNonBillableUser && user?.isCreditsAndBillingEnabled)) {
            return steps;
        } else {
            return steps.filter((step) => step?.id !== 4);
        }
    }, [user?.isCreditsAndBillingEnabled, isUser, isNonBillableUser]);

    useEffect(() => {
        toggleJumpRestrictions(false);
    }, [toggleJumpRestrictions]);

    return (
        <>
            <CountryCodeProvider>
                <div className="layout-middle account-tabs-wrapper">
                    <OnboardingStepperHeader
                        steps={onboardingSteps}
                        onNext={(step) => {
                            if (stepRef.current) {
                                stepRef.current.handleValidation().then((isStepValid) => {
                                    if (isStepValid) {
                                        console.log('step is valid');
                                        toggleJumpRestrictions(true);
                                    } else {
                                        console.log('step is not valid');
                                    }
                                });
                            }
                        }}
                        onPrevious={(step) => {
                            console.log('clicked previous');
                            navigate(step.path);
                        }}
                    />
                    <Routes>
                        <Route
                            path=""
                            element={
                                <div>
                                    <Outlet />
                                </div>
                            }
                        >
                            <Route path="" index element={<UserProfile ref={stepRef} />} />
                            <Route path="business" element={<BusinessDetails ref={stepRef} />} />
                            <Route path="leads" element={<LeadSettings ref={stepRef} />} />
                            {((isUser && user?.isCreditsAndBillingEnabled) ||
                                (isNonBillableUser && user?.isCreditsAndBillingEnabled)) && (
                                <Route path="payment" element={<StripePayment />} />
                            )}
                        </Route>
                    </Routes>

                    <OnboardingStepperFooter
                        steps={onboardingSteps}
                        onNext={(step) => {
                            if (stepRef.current) {
                                stepRef.current.handleValidation().then((isStepValid) => {
                                    if (isStepValid) {
                                        console.log('step is valid');
                                        toggleJumpRestrictions(true);
                                    } else {
                                        console.log('step is not valid');
                                    }
                                });
                            }
                        }}
                        onPrevious={(step) => {
                            console.log('clicked previous');
                            navigate(step.path);
                        }}
                    />
                </div>
            </CountryCodeProvider>
        </>
    );
};

export default UserOnboardingStepper;
