import classNames from 'classnames';
import React from 'react';

interface CurrencyProps {
    value: React.ReactNode;
    className?: string;
    fallback?: React.ReactNode;
}

const FormattedCurrency: React.FC<CurrencyProps> = ({ value, fallback = '-', className }) => {
    return value !== undefined || value !== null ? (
        <span className={classNames('app-currency', className)}>£{value}</span>
    ) : (
        <>{fallback}</>
    );
};

export default FormattedCurrency;
