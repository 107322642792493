import { EUserRole } from 'contracts/app-utils/EUserRole';
import { GetBusinessIndustryResponseDataObject } from 'contracts/spotdif/GetBusinessIndustryResponseDataObject';
import './style.scss';
import { UserBase } from 'contracts/spotdif/UserBase';
import ITableHeaderFilters from 'contracts/view-models/ITableHeaderFilters';
import useAuthentication from 'hooks/useAuthentication';
import React, { useEffect, useState } from 'react';

interface IAllStatusProps {
    name: string;
    value: string;
}

type ClientTypeObject = {
    label: string;
    value: string;
};
type OnboardingObject = {
    label: string;
    value: string;
};

interface ITableHeaderProps {
    leadsStatus?: string;
    status?: JSX.Element;
    filters?: ITableHeaderFilters;
    updateFilters?: (key: string, value: string | number) => void;
    entriesPerPage?: Array<number>;
    allStatus?: Array<IAllStatusProps>;
    clients?: Array<UserBase>;
    updateStatus?: Array<string>;
    businessIndustries?: Array<GetBusinessIndustryResponseDataObject>;
    showSorting?: boolean;
    accountsManagers?: Array<UserBase>;
    clientType?: Array<ClientTypeObject>;
    onboardingPerc?: Array<OnboardingObject>;
}

const TableHeader: React.FC<ITableHeaderProps> = ({
                                                      status,
                                                      filters = {
                                                          search: '',
                                                          perPage: 10,
                                                          sortingOrder: 'desc',
                                                          status: '',
                                                          clientId: '',
                                                      },
                                                      entriesPerPage = [10, 25, 50, 100],
                                                      allStatus = [],
                                                      updateFilters = () => {
                                                      },
                                                      clients = [],
                                                      businessIndustries = [],
                                                      showSorting = true,
                                                      accountsManagers = [],
                                                      clientType,
                                                      onboardingPerc,
                                                  }) => {
    const [debouncedSearch, setDebouncedSearch] = useState(filters.search);
    const { user } = useAuthentication();

    useEffect(() => {
        if (debouncedSearch) {
            const timeoutId = setTimeout(() => {
                updateFilters('search', debouncedSearch);
            }, 600);

            return () => {
                updateFilters('search', '');
                clearTimeout(timeoutId);
            };
        }
    }, [debouncedSearch]);

    const handleSearchChange = (event) => {
        const { value } = event.currentTarget;
        setDebouncedSearch(value);
    };

    return (
        // <form >
        <div className="table_key-wrap table-header">
            <div className="table_key-left add-shadow">
                <div className="table_key-left-grid">
                    <div className="search_page">
                        <div className="table_key-col">
                            <input
                                type="text"
                                className="form-select-small w-input filters-bold placeholder-bold"
                                maxLength={256}
                                name="field"
                                data-name="Field"
                                placeholder="Search"
                                id="field"
                                value={debouncedSearch}
                                onChange={handleSearchChange} // Use the debounced event handler
                            />
                        </div>
                        <div className="table_key-col">
                            <select
                                id="Per-page"
                                name="Per-page"
                                data-name="Per page"
                                onChange={({ currentTarget }) => updateFilters('perPage', currentTarget.value)}
                                className="form-select-small w-select filters-bold placeholder-bold"
                            >
                                {entriesPerPage.map((val, index) => {
                                    return <option key={index} value={val}>{`${val} per page`}</option>;
                                })}
                            </select>
                        </div>
                    </div>
                    <div className="newest_leads">
                        {showSorting && (
                            <div className="table_key-col">
                                <select
                                    id="field-2"
                                    name="field-2"
                                    data-name="Field 2"
                                    onChange={({ currentTarget }) => updateFilters('sortingOrder', currentTarget.value)}
                                    className="form-select-small w-select filters-bold placeholder-bold"
                                >
                                    <option value="desc">Newest to oldest</option>
                                    <option value="asc">Oldest to newest</option>
                                </select>
                            </div>
                        )}

                        {allStatus.length > 0 && (
                            <div className="table_key-col">
                                <select
                                    id="field-2"
                                    name="field-2"
                                    data-name="Field 2"
                                    defaultValue={allStatus[0].name}
                                    onChange={({ target }) => updateFilters('leadsStatus', target.value)}
                                    className="form-select-small w-select filters-bold placeholder-bold"
                                >
                                    {allStatus.map((item: IAllStatusProps) => (
                                        <option key={item.value} value={item.value}>
                                            {item.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        )}
                        {clients.length > 0 && (
                            <div className="table_key-col">
                                <select
                                    id="field-2"
                                    name="field-2"
                                    data-name="Field 2"
                                    defaultValue={'All Clients'}
                                    onChange={({ target }) => updateFilters('clientId', target.value)}
                                    className="form-select-small w-select filters-bold placeholder-bold"
                                >
                                    {/* <option value={""}>All Clients</option> */}
                                    <option value={''}>All Business</option>
                                    {clients?.map((val, index) => {
                                        return (
                                            <option key={index} value={val._id} className="truncate-text">
                                                {/* {`${val?.firstName} ${val?.lastName}`} */}
                                                {`${val?.businessName} `}
                                            </option>
                                        );
                                    })}
                                </select>
                            </div>
                        )}
                        {businessIndustries.length > 0 && (
                            <div className="table_key-col">
                                <select
                                    id="field-2"
                                    name="field-2"
                                    data-name="Field 2"
                                    defaultValue={'All Clients'}
                                    onChange={({ target }) => updateFilters('industry', target.value)}
                                    className="form-select-small w-select filters-bold placeholder-bold"
                                >
                                    <option value={''}>All Business Industries</option>
                                    {businessIndustries?.map((val, index) => {
                                        return (
                                            <option key={index} value={val._id}>
                                                {val.industry}
                                            </option>
                                        );
                                    })}
                                </select>
                            </div>
                        )}

                        {accountsManagers?.length > 0 && (
                            <div className="table_key-col">
                                <select
                                    id="field-2"
                                    name="field-2"
                                    data-name="Field 2"
                                    defaultValue={'Account Manager'}
                                    onChange={({ target }) => updateFilters('accountManagerId', target.value)}
                                    className="form-select-small w-select filters-bold placeholder-bold"
                                >
                                    <option value={''}>Accounts Managers</option>
                                    <option value={'orphanUser'}> Unassigned</option>
                                    {accountsManagers?.map((val, index) => {
                                        return (
                                            <option key={index} value={val._id}>
                                                {`${val?.firstName ?? '-'} ${val?.lastName ?? ''}`}
                                            </option>
                                        );
                                    })}
                                </select>
                            </div>
                        )}
                        {user.role !== EUserRole.ACCOUNT_MANAGER && clientType?.length > 0 && (
                            <div className="table_key-col">
                                <select
                                    id="field-2"
                                    name="field-2"
                                    data-name="Field 2"
                                    defaultValue={'Account Manager'}
                                    onChange={({ target }) => updateFilters('clientType', target.value)}
                                    className="form-select-small w-select filters-bold placeholder-bold"
                                >
                                    <option value={''}>Client Type</option>
                                    {clientType?.map((val, index) => (
                                        <option key={index} value={val.value}>
                                            {val?.label ?? '-'}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        )}
                        {onboardingPerc?.length > 0 && (
                            <div className="table_key-col">
                                <select
                                    id="field-2"
                                    name="field-2"
                                    data-name="Field 2"
                                    defaultValue={'All Percentages'}
                                    onChange={({ target }) => updateFilters('onBoarding', target.value)}
                                    className="form-select-small w-select filters-bold placeholder-bold"
                                >
                                    {onboardingPerc?.map((val, index) => {
                                        return (
                                            <option key={index} value={val.value}>
                                                {`${val?.label ?? '-'}`}
                                            </option>
                                        );
                                    })}
                                </select>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            {status && (
                <div className="table_key-right">
                    <div className="table_key-right-grid responsiveStatus">{status}</div>
                </div>
            )}
        </div>
        // </form>
    );
};
export default TableHeader;
