import CreditAndBilling from './CreditAndBilling';
import TransactionTable from './TransactionTable';

const CreditAndBillingMain = () => {
    return (
        <>
            <CreditAndBilling />
            <TransactionTable />
        </>
    );
};

export default CreditAndBillingMain;
