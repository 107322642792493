import { ECurrency } from 'contracts/spotdif/currencyAndCountryCodes';
import React from 'react';

interface CurrencySymbolProps {
    currencyCode: string;
    value?: string | number;
}

const CurrencySymbol: React.FC<CurrencySymbolProps> = ({ currencyCode, value }) => {
    const currencySymbols = {
        [ECurrency.USD]: '$',
        [ECurrency.GBP]: '£',
        [ECurrency.EUR]: '€',
    };

    const symbol = (currencyCode && currencySymbols[currencyCode.toUpperCase()]) || '£';
    const formattedValue = value !== undefined ? value : '';

    return <>{`${symbol}${formattedValue}`}</>;
};

export default CurrencySymbol;
