import CloseIcon from '@mui/icons-material/Close';
import MDBox from 'components/themed/MDBox';
import MDButton from 'components/themed/MDButton';
import MDTypography from 'components/themed/MDTypography';
import LegendReport from './LegendReport';

import './style.scss';

const LeadInfoModal = ({ handleCloseLeadModal }) => {
    const legendData = [
        {
            title: 'Valid Lead',
            info: 'A new or unreported lead.',
            type: 'success',
        },
        {
            title: 'Reported Lead',
            info: 'A lead that has been reported by you as unusable or invalid.',
            type: 'warning',
        },
        {
            title: 'Report Rejected',
            info: 'A lead report that has been received and checked and the lead deemed as valid. No refund or replacement will be provided.',
            type: 'danger',
        },
        {
            title: 'Report Accepted',
            info: 'A lead report that has been received and checked and the lead deemed as invalid. A replacement lead will be delivered.',
            type: 'info',
        },
    ];

    return (
        <MDBox class="modalFormMainWrapper">
            <MDBox class="modalMainWrapper leadingReportWrapper leadingReportWrapperMobile">
                <MDBox class="modalScrollArea">
                    <MDBox class="leadReportModalHeadingWrapper">
                        <MDTypography class="leadReportModalHeading">Lead reporting</MDTypography>
                        <MDTypography class="leadReportModalHeadingInfo">
                            Our lead reporting process allows you to reject leads that fall outside of agreed criteria
                            or are unusable for other reasons. This report is sent to your lead provider and, if
                            accepted, will be replaced by a new, valid lead.
                        </MDTypography>
                    </MDBox>

                    <MDBox class="leadReportModalLegends">
                        {legendData.map((data, index) => (
                            <LegendReport title={data.title} info={data.info} type={data.type} />
                        ))}
                    </MDBox>
                    <MDTypography class="leadReportModalGetInTouchLink">
                        Need help with something?
                        <>
                            <a href="mailto://leads@nmg.group">
                                <span> Get in touch</span>
                            </a>
                        </>
                    </MDTypography>
                </MDBox>
            </MDBox>
            <MDBox class="modalCloseWrapper">
                <MDButton class="modalCloseBtn" onClick={handleCloseLeadModal}>
                    <CloseIcon fontSize="large" />
                </MDButton>
            </MDBox>
        </MDBox>
    );
};

export default LeadInfoModal;
