enum DetailFilterType {
    number = 'number',
    text = 'text',
    date = 'date',
    switch = 'switch',
    dropdown = 'dropdown',
}

type DetailType = number | string | Date | boolean | Array<string> | Array<number> | Array<Date> | Array<boolean>;

export default function getTypeForValue(filterName: string, value: DetailType): DetailFilterType {
    const hardCodedFilters = {
        leadCost: DetailFilterType.dropdown,
        role: DetailFilterType.dropdown,
    };

    if (hardCodedFilters[filterName]) {
        return hardCodedFilters[filterName];
    }

    if (typeof value === 'number') {
        return DetailFilterType.number;
    }

    if (typeof value === 'string') {
        return DetailFilterType.text;
    }

    if (typeof value === 'boolean') {
        return DetailFilterType.switch;
    }

    if (value instanceof Date) {
        return DetailFilterType.date;
    }

    return DetailFilterType.dropdown;
}

export enum PopupType {
    RESET_PASS = 'resetPass',
    RESET_EMAIL = 'resetEmail',
}
