import MDInput from 'components/themed/MDInput';
import { usePlacesWidget } from 'react-google-autocomplete';

interface AddressLookUpProps {
    // TODO: use HTML input types
    handlePlaceSelect: (place: any) => void;
    onChange: (e: any) => void;
    name: string;
    className: string;
    type: string;
    label: string;
    onBlur: (e: any) => void;
    id: string;
    value: string;
    autoComplete: string;
    placeholder: string;
}

const AddressLookUp: React.FC<AddressLookUpProps> = ({
    handlePlaceSelect,
    onChange,
    name,
    className,
    type,
    label,
    onBlur,
    id,
    value,
    autoComplete,
    placeholder,
}) => {
    const { ref } = usePlacesWidget({
        apiKey: `${process.env.REACT_APP_GOOGLE_API_KEY}`,
        onPlaceSelected: handlePlaceSelect,
        options: {
            fields: ['address_components', 'geometry'],
            types: ['address'],
            // componentRestrictions: { country: "uk" },
        },
    });

    return (
        <div>
            <MDInput
                inputRef={ref}
                // placeholder="Find Address"
                onChange={onChange}
                type={type}
                name={name}
                className={className}
                onBlur={onBlur}
                id={id}
                value={value}
                label={label}
                placeholder={placeholder}
            />
        </div>
    );
};

export default AddressLookUp;
