import { jsonArrayMember, jsonMember, jsonObject } from 'typedjson';

@jsonObject()
class Criteria {
    @jsonMember(String)
    criteriaType: string;

    @jsonMember(String)
    condition: string;
}


@jsonObject()
export class ServiceDetailObject {
    @jsonMember(String)
    welcomeMessage: string;

    @jsonMember(String)
    avgInstallTime: string;

    @jsonMember(String)
    avgInstallTimeText: string;

    @jsonMember(String)
    prices: string;

    @jsonMember(String)
    startingPrice: string;

    @jsonMember(String)
    startingPriceText: string;

    @jsonMember(String)
    startingPriceCurrencyCode: string;

    @jsonMember(String)
    warranty: string;

    @jsonMember(String)
    warrantyText: string;

    @jsonMember(String)
    trustpilotReviews: string;

    @jsonMember(Boolean, {
        deserializer: (value: any) => {
            // check if value is number or else convert to number
            return value ? 'Yes' : 'No';
        },
    })
    financeOffers: string;

    @jsonMember(String)
    financeOffersText: string;

    @jsonArrayMember(String)
    accreditations: string[];

    @jsonArrayMember(Criteria)
    criteria: Criteria[];
}
