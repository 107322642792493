import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useCurrentUserDataQuery } from 'redux/services/spotdif/user';
import { AUTH_TOKEN, setAccessToken } from 'redux/slices/auth';
import { useAppDispatch } from 'redux/store';

const ImpersonateLogin = () => {
    const appDispatch = useAppDispatch();
    const navigate = useNavigate();

    const [searchParams] = useSearchParams();
    const accessToken = searchParams.get('access_token');

    sessionStorage.setItem(AUTH_TOKEN, JSON.stringify({ token: accessToken }));
    appDispatch(setAccessToken(accessToken || ''));
    const { data } = useCurrentUserDataQuery(undefined, { skip: !accessToken });

    useEffect(() => {
        if (data) {
            navigate('/dashboard');
        }
    }, [data, navigate]);

    return (
        <>
            <h1>Loading</h1>
        </>
    );
};

export default ImpersonateLogin;
