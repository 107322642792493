import MDInput from 'components/themed/MDInput';
import './style.scss';
import { IBusinessDetailsRequest } from 'contracts/requests/IBusinessDetailsRequest';
import { FieldArray, useFormikContext } from 'formik';
import useAuthentication from 'hooks/useAuthentication';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useGetAllIndustiesLeadsQuery } from 'redux/services/spotdif/industriesLeads';

interface BusinessIndustryAnswerProps {

}

const BuyerAnswers: React.FC<BusinessIndustryAnswerProps> = () => {
    const { user } = useAuthentication();
    const { values, handleBlur, touched, errors, setFieldValue } =
        useFormikContext<Partial<IBusinessDetailsRequest>>();
    const { data: businessIndustries } = useGetAllIndustiesLeadsQuery();

    const currentIndustry = useMemo(() => values.businessIndustry, [values.businessIndustry]);

    const filterSelectedIndustry = useCallback(async (pickedIndustry: string) => {
        // Filter the businessIndustries array to get only the elements with matching id
        if (businessIndustries) {
            if (businessIndustries?.data?.length > 0) {
                const currentIndustry = businessIndustries?.data?.filter(
                    (industry) => industry.industry === pickedIndustry,
                );
                const industryQuestions = currentIndustry[0]?.buyerQuestions.filter(({ title }) => title !== '');

                const userAnswers = user.buyerQuestions?.filter(({ questionSlug }) => {
                    return industryQuestions.some(({ questionSlug: slug }) => slug === questionSlug);
                });

                await setFieldValue(
                    'buyerQuestions',
                    userAnswers?.length > 0 ? userAnswers : industryQuestions,
                );
            }
        }
    }, [businessIndustries, setFieldValue, user.buyerQuestions]);

    useEffect(() => {
        filterSelectedIndustry(currentIndustry);
    }, [currentIndustry, filterSelectedIndustry]);

    const handleAnswerChange = (value, answerIndex) => {
        const updatedBuyerAnswers = values?.buyerQuestions?.map((question, index) => {
            if (index === answerIndex) {
                return { ...question, answer: value };
            }
            return question;
        });

        setFieldValue('buyerQuestions', updatedBuyerAnswers);
    };

    return (
        values.buyerQuestions?.length > 0 && (
            <FieldArray
                name="columns"
                render={(arrayHelpers) => (
                    <>
                        {businessIndustries && (
                            <div className="buyer-answer-container">
                                <div className="additional-info-title">Additional Information</div>
                                {values.buyerQuestions?.map((question, index) => (
                                    <div className="form-groups">
                                        <div className="form-input mb-0 buyer-answer-wrapper" key={index}>
                                            <MDInput
                                                type="text"
                                                label={`${question?.title}`}
                                                placeholder="Enter answer here..."
                                                name={`question-${index}`}
                                                onBlur={handleBlur}
                                                onChange={(e) => {
                                                    handleAnswerChange(e.target.value, index);
                                                }}
                                                value={question?.answer}
                                                error={
                                                    touched.buyerQuestions?.[index]?.answer &&
                                                    Boolean(errors.buyerQuestions?.[index])
                                                }
                                                helperText={
                                                    touched.buyerQuestions?.[index]?.answer &&
                                                    // @ts-ignore
                                                    errors.buyerQuestions?.[index]?.answer
                                                }
                                            />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                    </>
                )}
            />
        )
    );
};

export default BuyerAnswers;
