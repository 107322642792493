import MDTypography from 'components/themed/MDTypography';
import React from 'react';
import './index.scss';

interface IPromoBadgeProps {
    badgeColor: string;
    badgeSubTitle: string;
    badgeTitle: string;
}

export const PromoBadge: React.FC<IPromoBadgeProps> = ({ badgeColor, badgeSubTitle, badgeTitle }) => {
    return (
        <div
            className="promo-badge-wrapper"
            style={{
                background: badgeColor,
            }}
        >
            <MDTypography variant="p" className="promo-badge-heading-1">
                {badgeTitle}
            </MDTypography>
            <MDTypography variant="p" className="promo-badge-subtitle-1">
                {badgeSubTitle}
            </MDTypography>
        </div>
    );
};
