import { CustomerPaymentMethods } from 'contracts/spotdif/RetrievePaymentDetailsData';
import React, { createContext, useContext, useState } from 'react';

interface IContextValues {
    clientSecret: string;
    publicKey: string;
    paymentStatus: string; // 'Approved' | 'Captured' | 'Pending'
    paymentType: string;
    paymentMethods: CustomerPaymentMethods;
}

// Define the types for context values
type PaymentHandlerContextValues = {
    values: IContextValues;
    setValues: (contextValues: Partial<IContextValues>) => void;
    reset: () => void;
};

// Create the PaymentHandlerContext context
const PaymentHandlerContext = createContext<PaymentHandlerContextValues>(null);

interface IPaymentHandlerProviderProps {
    children: React.ReactNode;
}

// Create the PaymentHandlerProvider component
const PaymentHandlerProvider: React.FC<IPaymentHandlerProviderProps> = ({ children }) => {
    const defaultContextState = {
        clientSecret: '',
        paymentStatus: '',
        publicKey: '',
        paymentType: '',
        paymentMethods: null,
    };
    const [clientSecret, setClientSecret] = useState<IContextValues>(defaultContextState);

    // Method to reset and clear all the stored details
    const resetHandler = () => {
        setClientSecret(defaultContextState);
    };

    const setValuesHandler = (values: Partial<IContextValues>) => {
        setClientSecret((currentValues) => ({
            ...currentValues,
            ...values,
        }));
    };

    // Create the context value object with the required properties and methods
    const contextValue: PaymentHandlerContextValues = {
        values: clientSecret,
        setValues: setValuesHandler,
        reset: resetHandler,
    };

    // Provide the context value to the children components
    return <PaymentHandlerContext.Provider value={contextValue}>{children}</PaymentHandlerContext.Provider>;
};

// Create a custom hook to consume the PaymentHandlerContext
const usePaymentHandler = () => {
    const context = useContext(PaymentHandlerContext);
    if (!context) {
        throw new Error('usePaymentHandler must be used within a PaymentHandlerProvider');
    }
    return context;
};

export { PaymentHandlerContext, PaymentHandlerProvider, usePaymentHandler };
