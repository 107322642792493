import { IApiErrorResponse } from 'contracts/requests/IApiErrorResponse';
import { IChangeLeadStatusRequest } from 'contracts/requests/IChangeLeadStatusRequest';
import { IClientTablePreferenceResponse } from 'contracts/requests/IClientTablePreferenceResponse';
import { IReportedLeadsListResponse } from 'contracts/requests/IReportedLeadsListResponse';
import { IUserPreferenceRequest, IUserPreferenceResponse } from 'contracts/requests/IUserPreferenceRequest';
import { LeadsBidData } from 'contracts/spotdif/LeadsBidData';
import { TablePreferenceDataObject } from 'contracts/spotdif/TablePreferenceDataObject';
import { TAG_CHANGE_LEAD, TAG_USER_PREFERENCE } from 'contracts/spotDiffAPITags';
import ITableHeaderFilters from 'contracts/view-models/ITableHeaderFilters';
import { spotdifApi } from '.';

const reportedLeadsApi = spotdifApi.injectEndpoints({
    endpoints: (builder) => ({
        getAllReportedLeads: builder.query<any, ITableHeaderFilters>({
            providesTags: [TAG_CHANGE_LEAD],
            query: (args) => {
                return {
                    // url: "/leads/reported-leads",
                    url: `/leads/reported-leads${
                        args.clientId
                            ? `?userId=${args.clientId}&search=${args?.search}&page=${args?.page}&perPage=${args?.perPage}&sortingOrder=${args?.sortingOrder}&status=${args?.leadsStatus}&industryId=${args?.industry}&accountManagerId=${args?.accountManagerId}`
                            : `?search=${args?.search}&page=${args?.page}&perPage=${args?.perPage}&sortingOrder=${args?.sortingOrder}&status=${args?.leadsStatus}&industryId=${args?.industry}&accountManagerId=${args?.accountManagerId}`
                    }`,
                    method: 'get',
                };
            },
            // transformResponse: (response: any) => {
            //     return response.data;
            // }
        }),
        getLeadPreference: builder.query<TablePreferenceDataObject, void>({
            query: () => {
                return {
                    url: '/leads/leads-Preference',
                    method: 'get',
                };
            },
            transformResponse: (response: IClientTablePreferenceResponse) => {
                return response?.data;
            },
        }),

        changeLeadStatus: builder.mutation<LeadsBidData, IChangeLeadStatusRequest>({
            invalidatesTags: [TAG_CHANGE_LEAD],

            query: (body) => {
                return {
                    url: `/leads/update/${body.leadId}`,
                    method: 'post',
                    body: { status: body.status, isReprocessed: body?.isReprocessed },
                };
            },
            transformResponse: (response: LeadsBidData) => {
                return response;
            },
        }),

        updateReportedLeadsColumnPreference: builder.mutation<
            IUserPreferenceResponse | IApiErrorResponse,
            IUserPreferenceRequest
        >({
            invalidatesTags: [TAG_USER_PREFERENCE],

            query: (body) => {
                return {
                    url: '/leads/leads-Preference',
                    method: 'post',
                    body,
                };
            },
        }),
        exportReportedLeads: builder.mutation<any, string | void>({
            query: (filterUrl) => {
                return {
                    url: `/leads/export-csv-file-admin-leads${filterUrl}`,
                    method: 'get',
                };
            },
            transformResponse: (response: IReportedLeadsListResponse) => {
                return response?.data;
            },
        }),
    }),
});

export const {
    useGetAllReportedLeadsQuery,
    useGetLeadPreferenceQuery,
    useChangeLeadStatusMutation,
    useUpdateReportedLeadsColumnPreferenceMutation,
    useExportReportedLeadsMutation,
} = reportedLeadsApi;
