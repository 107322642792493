import { IFreeCreditsRequest, IFreeCreditsResponse } from 'contracts/requests/IFreeCreditsRequest';
import { IGetFreeCreditsLinkResponse } from 'contracts/requests/IGetFreeCreditsLinkResponse';
import { IPromoLinkContentRequest, IPromoLinkContentResponse } from 'contracts/requests/IPromoLinkContentResponse';
import { FreeCreditsResponseDataObject } from 'contracts/spotdif/FreeCreditsResponseDataObject';
import { GetFreeCreditsLinkResponseData } from 'contracts/spotdif/GetFreeCreditsLinkResponseData';
import { GetTableResponseDataObject } from 'contracts/spotdif/GetTableResponseDataObject';
import { IPromoLinkStatsResponse } from 'contracts/spotdif/PromoLinkStatsResponse';
import { TAG_GET_ACCOUNT_MANAGER, TAG_GET_PROMO_LINKS } from 'contracts/spotDiffAPITags';
import { spotdifApi } from '.';

const promoApi = spotdifApi.injectEndpoints({
    endpoints: (builder) => ({
        getPromoLinks: builder.query<GetFreeCreditsLinkResponseData[], any>({
            providesTags: [TAG_GET_PROMO_LINKS],
            query: (status) => {
                let modifiedUrl;
                if (status?.status === 'all' && status?.accountManager === null) {
                    modifiedUrl = 'freeCredits?status=all';
                } else if (status?.status === 'live' && status?.accountManager === null) {
                    modifiedUrl = 'freeCredits?status=live';
                } else if (status?.status === 'expired' && status?.accountManager === null) {
                    modifiedUrl = 'freeCredits?status=expired';
                } else {
                    let accountManagerParam = status?.accountManager ? `&accountManager=${status.accountManager}` : '';
                    modifiedUrl = `freeCredits?status=${status?.status}${accountManagerParam}`;
                }
                return {
                    url: modifiedUrl,
                    // url: `freeCredits${status === 'live' ? '?live=true' : status === 'expired' ? '?expired=true' : ''}${accountManager ? `&accountManager=${accountManager}` : ''}`,

                    method: 'get',
                };
            },
            transformResponse: (response: IGetFreeCreditsLinkResponse) => {
                return response.data || [];
            },
        }),

        getPromoLinksStats: builder.query<IPromoLinkStatsResponse, void>({
            providesTags: [TAG_GET_PROMO_LINKS],
            query: (status) => {
                return {
                    url: `freeCredits/stats`,
                    method: 'get',
                };
            },
        }),

        generatePromoLink: builder.mutation<FreeCreditsResponseDataObject, IFreeCreditsRequest>({
            invalidatesTags: [TAG_GET_PROMO_LINKS],

            query: (body) => {
                return {
                    url: '/freeCredits',
                    method: 'post',
                    body,
                };
            },
            transformResponse: (response: IFreeCreditsResponse) => {
                return response.data;
            },
        }),

        expirePromolink: builder.mutation<void, string>({
            invalidatesTags: [TAG_GET_PROMO_LINKS],

            query: (id) => ({
                url: `freeCredits/${id}`,
                // method: 'DELETE',
                method: 'post',
            }),
        }),

        deletePromoLink: builder.mutation<void, string>({
            invalidatesTags: [TAG_GET_PROMO_LINKS],
            query: (id) => ({
                url: `freeCredits/${id}`,
                method: 'DELETE',
            }),
        }),

        getAccountManager: builder.query<GetTableResponseDataObject, void>({
            providesTags: [TAG_GET_ACCOUNT_MANAGER],
            query: () => {
                return {
                    url: '/user?accountManager=true',
                    method: 'GET',
                };
            },
        }),
        generatePromoLinkContent: builder.mutation<IPromoLinkContentResponse, IPromoLinkContentRequest>({
            invalidatesTags: [TAG_GET_PROMO_LINKS],
            query: (body) => ({
                url: `/freeCreditsLink-content`,
                method: 'POST',
                body,
            }),
        }),
        updatePromoLinkContent: builder.mutation<IPromoLinkContentResponse, IPromoLinkContentRequest>({
            invalidatesTags: [TAG_GET_PROMO_LINKS],
            query: (body) => {
                return {
                    url: `/freeCreditsLink-content/${body.promoLinkContentId}`,
                    method: 'POST',
                    body,
                };
            },
        }),
        getPromoContent: builder.query<IPromoLinkContentResponse, string>({
            query: (code) => {
                return {
                    url: `/freeCreditsLink-content/${code}`,
                    method: 'GET',
                };
            },
        }),
    }),
});

export const {
    useGetPromoLinksQuery,
    useGetPromoLinksStatsQuery,
    useGeneratePromoLinkMutation,
    useExpirePromolinkMutation,
    useDeletePromoLinkMutation,
    useGetAccountManagerQuery,
    useGeneratePromoLinkContentMutation,
    useUpdatePromoLinkContentMutation,
    useGetPromoContentQuery,
    useLazyGetPromoContentQuery,
} = promoApi;
