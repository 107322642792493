// middleware.js

import { logout } from 'redux/slices/auth';

const tokenExpirationMiddleware =
    ({ dispatch, getState }) =>
    (next) =>
    (action) => {
        if (action.type.endsWith('/rejected')) {
            const response = action.payload;
            // Check if the token has expired (assuming the server returns a 401 for expired tokens)
            // -- Status code updated from 401 to 409
            if (response?.status === 409) {
                // Dispatch logout action if the token has expired
                dispatch(logout());
            }
        }

        return next(action);
    };

export default tokenExpirationMiddleware;
