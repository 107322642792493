import './style.scss';
import { INotificationRequest } from 'contracts/requests/INotificationRequest';
import { FormikProvider, useFormik } from 'formik';
import useAuthentication from 'hooks/useAuthentication';
import { useRegexPatterns } from 'hooks/useRegexPatterns';
import { useSnackbar } from 'notistack';
import React from 'react';
import { Helmet } from 'react-helmet';
import { useCreateSmsAlertMutation, usePostNotificationDataMutation } from 'redux/services/spotdif/onBoarding';
import { useUserProfileDataQuery } from 'redux/services/spotdif/user';
import * as Yup from 'yup';
import EmailNotificationsForm from './EmailNotificationsForm';
import { NOTIFICATION_STATUS } from './notificationStatus';
import SMSNotificationsForm from './SMSNotificationsForm';
import WebhookNotificationsForm from './WebhookNotificationsForm';

const NotificationSettings: React.FC = () => {
    const { enqueueSnackbar } = useSnackbar();
    const { user } = useAuthentication();
    const { userLeadsDetailsId } = user;
    const [isSMSNotification, setIsSMSNotification] = React.useState(user?.isSmsNotificationActive);

    const [postNotificationDataTrigger] = usePostNotificationDataMutation();
    const [createSMSTrigger] = useCreateSmsAlertMutation();

    const { phoneNumberRegex } = useRegexPatterns();

    useUserProfileDataQuery(user?._id, {
        refetchOnMountOrArgChange: true,
        skip: !user?._id,
    });

    const postNotificationDetails = async (data: INotificationRequest) => {
        postNotificationDataTrigger(data)
            .unwrap()
            .then((res) => {
                enqueueSnackbar(res?.data?.message, { variant: 'success' });
            })
            .catch((err) => {
                enqueueSnackbar('Something Went Wrong', { variant: 'error' });
            });
    };

    const EnableSMSNotification = (data) => {
        createSMSTrigger(data)
            .unwrap()
            .then((res) => {
                console.log(res);
                enqueueSnackbar(res?.message ? res?.message : 'SMS Settings Saved Successfully', {
                    variant: 'success',
                    key: 'sms-notification',
                });
            })
            .catch((err) => {
                enqueueSnackbar(err?.data?.error?.message ? err?.data?.error?.message : 'Something Went Wrong', {
                    variant: 'error',
                    key: 'sms-notification',
                });
            });
    };

    const emailNotificationForm = useFormik({
        initialValues: {
            leadAlertsFrequency: userLeadsDetailsId?.leadAlertsFrequency || NOTIFICATION_STATUS[0],
        },
        validationSchema: Yup.object().shape({
            leadAlertsFrequency: Yup.string().required('Lead frequency is required'),
        }),
        onSubmit: async (values) => {
            postNotificationDetails({
                _id: userLeadsDetailsId?._id,
                leadAlertsFrequency: NOTIFICATION_STATUS[values.leadAlertsFrequency],
            });
        },
    });

    const CRMNotificationForm = useFormik({
        initialValues: {
            zapierUrl: userLeadsDetailsId?.zapierUrl || '',
        },
        validationSchema: Yup.object().shape({
            // zapierUrl: Yup.string().required("Web hook URL is required"),
        }),
        onSubmit: async (values) => {
            postNotificationDetails({
                _id: userLeadsDetailsId?._id,
                sendDataToZapier: values.zapierUrl ? true : false,
                ...values,
            });
        },
    });

    const SMSNotificationForm = useFormik({
        initialValues: {
            smsPhoneNumber: user?.smsPhoneNumber || '',
            isSmsNotificationActive: isSMSNotification,
        },
        validationSchema: isSMSNotification
            ? Yup.object().shape({
                smsPhoneNumber: Yup.string()
                    .required('Mobile Number is Required')
                    .matches(phoneNumberRegex, 'Mobile number is not valid'),
            })
            : Yup.object().shape({}),
        onSubmit: async (values) => {
            // values.smsPhoneNumber = "+44" + values.smsPhoneNumber;
            EnableSMSNotification({
                _id: user?._id,
                ...values,
            });
        },
    });

    return (
        <div className="notification-setting-wrapper">
            <Helmet>
                <title>Notification</title>
            </Helmet>
            <div className="tab-user-content">
                <h6>Email Notifications</h6>
                <FormikProvider value={emailNotificationForm}>
                    <EmailNotificationsForm />
                </FormikProvider>
            </div>

            <div className="tab-user-content">
                <h6>Webhook Notifications</h6>
                <FormikProvider value={CRMNotificationForm}>
                    <WebhookNotificationsForm />
                </FormikProvider>
            </div>

            <div className="tab-user-content">
                <h6>SMS Notifications</h6>
                <FormikProvider value={SMSNotificationForm}>
                    <SMSNotificationsForm />
                </FormikProvider>
            </div>
        </div>
    );
};

export default NotificationSettings;
