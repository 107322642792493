// import { BrowserRouter } from "react-router-dom";
import App from 'App';

// Material Dashboard 2 PRO React TS Context Provider
import { MaterialUIControllerProvider } from 'context/ThemeContext';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { createRoot } from 'react-dom/client';
import UserAnalyticsProvider from './context/UserAnalytics';
import reportWebVitals from './reportWebVitals';

const root = createRoot(document.getElementById('root'));

root.render(
    <MaterialUIControllerProvider>
        <UserAnalyticsProvider>
            <DndProvider backend={HTML5Backend}>
                <App />
            </DndProvider>
        </UserAnalyticsProvider>
    </MaterialUIControllerProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
console.log(`You are using Spotdif v${process.env.REACT_APP_VERSION}`);
