import reportAccepted from 'assets/images/icons/report-accepted.png';
import validLeads from 'assets/images/icons/valid-leads.png';
import classNames from 'classnames';
import AuthorisedUsage from 'components/app-permission/AuthorisedUsage';
import { MODULE, PERMISSIONS } from 'components/app-permission/permission';
import BasicModal from 'components/core/Modal';
import CreateIndustry from 'components/industries-leads/createIndustry';

import HeaderCard from 'components/shared/Card/HeaderCard';
import Pagination from 'components/shared/Pagination/Pagination';
import SpinningComponent from 'components/shared/SpinningComponent';
import TableHeaderV2 from 'components/shared/TableHeaderV2';
import { IGetBusinessIndustryRequest } from 'contracts/requests/IGetBusinessIndustryResponse';
import React, { useEffect, useState } from 'react';
import {
    useGetAllIndustiesLeadsQuery,
    useGetBusinessIndustryStatsQuery,
    useGetCurrencyCodesQuery,
} from 'redux/services/spotdif/industriesLeads';
import Table from './Table';
import './style.scss';

const IndustriesLeadsTableV2: React.FC = () => {
    const [openModal, setOpenModal] = useState(false);
    const [query, setQuery] = useState<IGetBusinessIndustryRequest>({
        search: '',
        perPage: 10,
        sortingOrder: 'asc',
        page: 1,
        total: 0,
        pageCount: 0,
    });

    const { statsData, statsLoading } = useGetBusinessIndustryStatsQuery(null, {
        selectFromResult: ({ data, isLoading }) => {
            return {
                statsData: data?.data,
                statsLoading: isLoading,
            };
        },
    });

    const { data: industriesLeadData, isLoading: industriesLeadDataLoading } = useGetAllIndustiesLeadsQuery(query);
    const { data: currencies } = useGetCurrencyCodesQuery();

    useEffect(() => {
        if (industriesLeadData?.meta) {
            setQuery({
                ...query,
                pageCount: industriesLeadData?.meta?.pages,
            });
        }
    }, [industriesLeadData?.meta]);

    const updateLeadFilters = (key: string, value: string | number) => {
        setQuery({ ...query, [key]: value, page: 1 });
    };

    const renderStatusHeader = () => (
        <AuthorisedUsage module={MODULE.BUSINESS_INDUSTRIES} permission={PERMISSIONS.CREATE}>
            <div className="table_export_leads">
                <button
                    type="button"
                    onClick={() => setOpenModal(true)}
                    className={classNames('button is-small w-button export-csv', {
                        table_export_leads_disabled: industriesLeadDataLoading,
                    })}
                >
                    Add Industry
                </button>
            </div>
        </AuthorisedUsage>
    );

    const handleClose = () => setOpenModal(false);

    return (
        <>
            <SpinningComponent loading={industriesLeadDataLoading}>
                <div className="layout-middle table-v2 industries-leads">
                    <AuthorisedUsage module={MODULE.BUSINESS_INDUSTRIES} permission={PERMISSIONS.READ}>
                        <TableHeaderV2
                            filters={query}
                            updateFilters={updateLeadFilters}
                            status={renderStatusHeader()}
                        />
                    </AuthorisedUsage>

                    <AuthorisedUsage module={MODULE.BUSINESS_INDUSTRIES} permission={PERMISSIONS.READ}>
                        <div className="table-card-block">
                            <HeaderCard
                                icon={validLeads}
                                custClass="valid-leads"
                                value={statsData?.activeBusinessIndustries}
                                heading="Live"
                            />
                            <HeaderCard
                                icon={reportAccepted}
                                value={statsData?.pausedBusinessIndustries}
                                custClass="report-accepted"
                                heading="Paused"
                            />
                        </div>
                    </AuthorisedUsage>

                    <AuthorisedUsage module={MODULE.BUSINESS_INDUSTRIES} permission={PERMISSIONS.READ}>
                        <Table industriesData={industriesLeadData?.data} />
                        {industriesLeadData?.meta && (
                            <Pagination
                                totalCount={industriesLeadData?.meta?.total}
                                currentPage={industriesLeadData?.meta?.page * 1}
                                pageSize={industriesLeadData?.meta?.perPage}
                                onPageChange={(page) => setQuery({ ...query, page })}
                            />
                        )}
                    </AuthorisedUsage>
                </div>
            </SpinningComponent>

            {currencies && (
                <BasicModal open={openModal} handleClose={handleClose} width={600}>
                    <CreateIndustry handleCloseModal={handleClose} currenciesData={currencies?.data} />
                </BasicModal>
            )}
        </>
    );
};

export default IndustriesLeadsTableV2;
