import React, { useCallback, useMemo } from 'react';
import { IHeaderStepperProps } from 'contracts/requests/IHeaderStepperProps';
import { useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Icon from '@mui/material/Icon';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import EastIcon from '@mui/icons-material/East';
import { getStepsForPath } from './getStepsForPath';
import useAuthentication from 'hooks/useAuthentication';

export default function OnboardingStepperFooter({ steps, onNext, onPrevious }: IHeaderStepperProps) {
    const { pathname } = useLocation();
    const { user, isNonBillableUser, isUser } = useAuthentication();

    const { currentStep, nextStep, previousStep } = useMemo(() => {
        return getStepsForPath(steps, pathname);
    }, [pathname, steps]);

    const handleNext = useCallback(() => {
        onNext(nextStep);
    }, [nextStep, onNext]);

    const handleBack = useCallback(() => {
        onPrevious(previousStep);
    }, [onPrevious, previousStep]);

    const isLastStep: boolean = useMemo(() => {
        return user.isFinalOnboardingStep(pathname);
    }, [pathname, nextStep]);

    const isFirstStep: boolean = useMemo(() => {
        return user.isFirstAllowedStep(pathname);
    }, [pathname, nextStep]);

    const shouldDisableLastStep = useMemo<boolean>(() => {
        if (isUser) {
            if (currentStep?.id === 4) {
                return !nextStep;
            }
        } else if (isNonBillableUser) {
            return !nextStep && !isNonBillableUser;
        }
    }, [currentStep]);

    return (
        <>
            <Box sx={{ width: '100%' }}>
                <>
                    <Box className={'onboarding-actions onboarding-action-footer'}>
                        {/* disabled={!previousStep} */}
                        <Button
                            color="inherit"
                            className="prev-button"
                            disabled={!!isFirstStep}
                            onClick={handleBack}
                            sx={{ mr: 1 }}
                        >
                            <Icon fontSize="small">
                                <KeyboardBackspaceIcon />
                            </Icon>{' '}
                            Back
                        </Button>
                        <Button disabled={shouldDisableLastStep} onClick={handleNext} className="next-button">
                            {/* {user.onboardingPercentage === 75 ? 'Finish' : 'Next'} */}
                            {isLastStep ? 'Finish' : 'Next'}
                            <Icon fontSize="small">
                                <EastIcon />
                            </Icon>
                        </Button>
                    </Box>
                </>
            </Box>
        </>
    );
}
