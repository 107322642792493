import { jsonArrayMember, jsonMember, jsonObject, TypedJSON } from 'typedjson';

@jsonObject()
export class PostCodeTargetingList {
    @jsonMember(String)
    key?: string;

    @jsonMember(String)
    county: string;

    @jsonArrayMember(String)
    postalCode?: string[];
}

export const PostCodeTargetingListSerializer = new TypedJSON(PostCodeTargetingList);

export interface IPostCodeCollection {
    [key: string]: string[];
}
