import React, { JSXElementConstructor, ReactElement } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { convertToHTML } from 'draft-convert';
import { cleanUpHTML } from 'utils/text-helpers';
import { EditorState } from 'draft-js';

interface IEditorFieldProps {
    label: string;
    editorState: EditorState;
    onEditorStateChange: (editorState: EditorState) => void;
    fieldName: string;
    generateContentForm: {
        setFieldValue: (fieldName: string, value: any) => void;
        touched: {
            [fieldName: string]: boolean;
        };
        errors: {
            [fieldName: string]: string;
        };
    };
    customStyleMap?: { [style: string]: React.CSSProperties };
    toolbarCustomButtons?: ReactElement<HTMLElement, string | JSXElementConstructor<React.ReactNode[]>>[];
}

const EditorField: React.FC<IEditorFieldProps> = ({
    label,
    editorState,
    onEditorStateChange,
    fieldName,
    generateContentForm,
    customStyleMap,
    toolbarCustomButtons,
}) => {
    const toolbarOptions = {
        options: ['inline', 'history'],
        inline: {
            options: ['bold', 'italic', 'underline'],
        },
        history: {
            options: ['undo', 'redo'],
        },
    };

    return (
        <div className="form-groups">
            <div className="form-input mb-0">
                <div className="mt-1">{label}</div>
                <Editor
                    editorState={editorState}
                    toolbar={toolbarOptions}
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapperClassName"
                    editorClassName="editorClassName"
                    onEditorStateChange={(value) => {
                        onEditorStateChange(value);

                        const editorContentAsHTML = convertToHTML(value.getCurrentContent());
                        const cleanedHTML = cleanUpHTML(editorContentAsHTML);

                        generateContentForm.setFieldValue(fieldName, cleanedHTML);
                    }}
                    customStyleMap={customStyleMap}
                    toolbarCustomButtons={toolbarCustomButtons}
                />
                {generateContentForm.touched[fieldName] && generateContentForm.errors[fieldName] && (
                    <div className="errors">{generateContentForm.errors[fieldName]}</div>
                )}
            </div>
        </div>
    );
};

export default EditorField;
