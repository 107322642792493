import StringFormatter from 'components/shared/Text/StringFormatter';
import MDBox from 'components/themed/MDBox';
import MDTypography from 'components/themed/MDTypography';
import { enqueueSnackbar } from 'notistack';
import React from 'react';
import './style.scss';
import { useAdvertismentClickMutation } from 'redux/services/spotdif/packages';

export interface IAdvertisementCardProps {
    key: string;
    icon: string;
    title: string;
    promotionText: string;
    description: string;
    buttonText: string;
    advertisementId?: string;
    callToAction?: string;
}

const AdvertisementCard: React.FC<IAdvertisementCardProps> = ({
                                                                  icon,
                                                                  title = 'Demo',
                                                                  promotionText,
                                                                  description = 'DEMO',
                                                                  buttonText = 'DEMO',
                                                                  advertisementId,
                                                                  callToAction,
                                                              }) => {
    const [advertisementClickTrigger] = useAdvertismentClickMutation();

    const getAddClick = (value) => {
        advertisementClickTrigger(value)
            .unwrap()
            .then((res) => {
                // enqueueSnackbar(res?.message, { variant: "success", key: "advertisement-trigger" });
                const formattedURL =
                    callToAction.startsWith('http://') || callToAction.startsWith('https://')
                        ? callToAction
                        : `https://${callToAction}`;

                window.open(formattedURL, '_blank');
            })
            .catch((error) => {
                enqueueSnackbar(error?.data?.error ?? 'Something went wrong', {
                    variant: 'error',
                    key: 'advertisement-trigger',
                });
            });
    };
    const processedDescription = description.replace(/\r\n/g, '<br>');
    return (
        <div className="list-card advertisement-card">
            {
                promotionText && (
                    <div className="advertisement-promotion">
                        <StringFormatter length={29} text={promotionText} />
                    </div>
                )
            }
            <div className="advertisement-container">
                <MDBox className="advertisement-icon">
                    <div className="card_image_box">
                        <img src={process.env.REACT_APP_API_SERVER + icon}
                             alt={`${title} ${processedDescription.substring(0, 30)}`} />
                    </div>
                </MDBox>
                <MDTypography variant="h4" className="head_card_box">
                    {title}
                </MDTypography>
                <div
                    className="description description_box"
                    dangerouslySetInnerHTML={{ __html: processedDescription }}
                ></div>
            </div>
            <MDBox>
                <button
                    className="button button-outline-primary w-100 mt-2"
                    onClick={() => getAddClick(advertisementId)}
                >
                    {buttonText}
                </button>
            </MDBox>
        </div>
    );
};

export default AdvertisementCard;
