import millify from 'millify';
import React, { useEffect, useState } from 'react';

import './style.scss';

interface HeaderProps {
    icon: string;
    value: string | number;
    heading: string;
    custClass: string;
}

const HeaderCard: React.FC<HeaderProps> = ({ icon, custClass, value, heading }) => {
    const [count, setCount] = useState(0);

    useEffect(() => {
        if (!value) {
            setCount(0);
        } else setCount(Number(value));
    }, [value]);

    return (
        <div className="table-header-card">
            <div className="table-header-card-left">
                <div className={`card-icon-parent ${custClass}`}>
                    <img src={icon} alt="card_icon" />
                </div>
            </div>
            <div className="table-header-card-right">
                <h3>{millify(count)}</h3>
                <p>{heading || '-'}</p>
            </div>
        </div>
    );
};

export default HeaderCard;
