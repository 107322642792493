import CloseIcon from '@mui/icons-material/Close';

import MDBox from 'components/themed/MDBox';
import MDButton from 'components/themed/MDButton';
import MDTypography from 'components/themed/MDTypography';

interface IAlertPopUp {
    fn: any; // any should be removed
    handleCloseModal: any;
    heading: any;
    subheading: any;
    buttonText: any;
    value: any;
}

const AlertPopUp: React.FC<IAlertPopUp> = ({ fn, handleCloseModal, heading, subheading, buttonText, value }) => {
    return (
        <>
            <MDBox className="modalFormMainWrapper">
                <MDBox className="modalMainWrapper">
                    <MDTypography className="modal-heading-2 text-center">{heading}</MDTypography>
                    <MDTypography className="text-center">{subheading}</MDTypography>
                </MDBox>
                <button
                    onClick={() => {
                        fn(value);
                    }}
                    type="submit"
                    id="w-node-_0bea9dcc-b36c-bc0d-2d8a-91c15aa9a1ab-9e7c9e88"
                    className="button w-100"
                >
                    {buttonText}
                </button>

                <MDBox className="modalCloseWrapper">
                    <MDButton className="modalCloseBtn" onClick={handleCloseModal}>
                        <CloseIcon fontSize="large" />
                    </MDButton>
                </MDBox>
            </MDBox>
        </>
    );
};

export default AlertPopUp;
