import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import Icon from '@mui/material/Icon';
import React from 'react';
import './styles.scss';

export interface PaymentTestimonialProps {
    id?: number;
    testimonialText: string;
    user: string;
}

const PaymentTestimonial: React.FC<PaymentTestimonialProps> = ({ testimonialText, user }) => {
    return (
        <div className="payments-testimonial-wrapper">
            <Icon className="payments-testimonial-icon" fontSize="large">
                <FormatQuoteIcon />
            </Icon>
            <p className="payments-testimonial-text">{testimonialText}</p>
            <p className="payments-testimonial-user">{user}</p>
        </div>
    );
};

export default PaymentTestimonial;
