import CircularProgress from '@mui/material/CircularProgress';
import classNames from 'classnames';
import React from 'react';
import './styles.scss';

interface LoadingComponentProps {
    loading: boolean;
    isFullSized?: boolean;
    children: React.ReactNode;
}

const SpinningComponent: React.FC<LoadingComponentProps> = ({ loading, children, isFullSized = true }) => {
    return (
        <>
            {loading ? (
                <div
                    className={
                        classNames({
                            'text-align-center': true,
                            'loader-container-div': isFullSized,
                        })
                    }
                >
                    <CircularProgress />
                </div>
            ) : (
                children
            )}
        </>
    );
};

export default SpinningComponent;
