import CloseIcon from '@mui/icons-material/Close';
import { Autocomplete } from '@mui/material';
import classNames from 'classnames';
import SendReportModal from 'components/admin-leads/SendReportModal';
import { contactStatusOptions, reportReasonOptions } from 'components/client-leads-table/ExpandedRow';
import LeadSaleReport from 'components/client-leads-table/LeadSaleReport';
import BasicModal from 'components/core/Modal';
import BadgeItem from 'components/leads-table/BadgeItem';
import { BadgeNature } from 'components/leads-table/BadgeV2';
import MDBox from 'components/themed/MDBox';
import MDInput from 'components/themed/MDInput';
import { LeadsBidDataSerializer } from 'contracts/spotdif/LeadsBidData';
import dayjs from 'dayjs';
import { enqueueSnackbar } from 'notistack';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useGetChatRoomDetailsQuery } from 'redux/services/spotdif/chatToken';
import { useReportLeadMutation } from 'redux/services/spotdif/leads';
import RateLead from './RateLead';

interface IChatRoomDetailsProps {
    chatRoomId: string;
    closeDetails: () => void;
}

const ChatRoomDetails: React.FC<IChatRoomDetailsProps> = ({ chatRoomId, closeDetails }) => {
    const { chatRoomDetails: chatRoomLeadDetails } = useGetChatRoomDetailsQuery(chatRoomId, {
        selectFromResult: ({ data }) => {
            return {
                chatRoomDetails: LeadsBidDataSerializer.parse(data?.data),
            };
        },
    });

    const [clientNotes, setClientNotes] = useState(chatRoomLeadDetails?.clientNotes);
    const notesDebounceRef = useRef<number>(null);
    const [errors, setErrors] = useState(false);
    const [contactStatus, setContactStatus] = useState(chatRoomLeadDetails?.contactStatus);
    const [reportTime, setReportTime] = useState(chatRoomLeadDetails?.invalidLeadReason ?? '');
    const [selectedLeadId, setSelectedLeadId] = useState('');
    const [showSendReportModal, setShowSendReportModal] = useState(false);

    const [reportLead] = useReportLeadMutation();

    const isLeadFixed = useMemo<boolean>(
        () =>
            chatRoomLeadDetails?.status === 'Report Accepted' ||
            chatRoomLeadDetails?.status === 'Report Rejected' ||
            chatRoomLeadDetails?.status === 'Reported',
        [chatRoomLeadDetails?.status],
    );

    const updateNotes = useCallback((notes: string, contactStatus: string) => {
        reportLead({ clientNotes: notes, leadId: chatRoomLeadDetails?._id, contactStatus })
            .unwrap()
            .then((payload) => {
                enqueueSnackbar('Lead notes saved successfully', { variant: 'success', key: 'chat-inbox' });
                // setExpanded({});
            })
            .catch(({ data }) => {
                enqueueSnackbar(data?.error?.message ?? 'Something went wrong.', {
                    variant: 'error',
                    key: 'chat-inbox',
                });
            });
    }, [chatRoomLeadDetails?._id, reportLead]);

    const handleNotesChange = useCallback(
        (event: React.ChangeEvent<HTMLTextAreaElement>) => {
            const { value } = event.target;
            setClientNotes(value);
            setSelectedLeadId(chatRoomLeadDetails?._id);

            if (notesDebounceRef.current) {
                clearTimeout(notesDebounceRef.current);
            }
            notesDebounceRef.current = window.setTimeout(() => updateNotes(value, contactStatus), 1000);
        },
        [chatRoomLeadDetails?._id, contactStatus, updateNotes],
    );

    const handleStatusChange = useCallback(
        (event: React.SyntheticEvent, contactStatus: string) => {
            setContactStatus(contactStatus);
            setSelectedLeadId(chatRoomLeadDetails?._id);

            if (notesDebounceRef.current) {
                clearTimeout(notesDebounceRef.current);
            }
            notesDebounceRef.current = window.setTimeout(() => updateNotes(clientNotes, contactStatus), 1000);
        },
        [chatRoomLeadDetails?._id, clientNotes, updateNotes],
    );

    const handleArchive = (e, status, clientNotes, leadId, contactStatus) => {
        if (status !== 'Reported') {
            const newStatus = () => {
                if (status === 'Valid') {
                    return 'Archived';
                    // return 'Archived Leads';
                } else {
                    return 'Valid';
                }
            };

            reportLead({ status: newStatus(), clientNotes, leadId, contactStatus })
                .unwrap()
                .then((payload) => {
                    enqueueSnackbar(`Lead ${newStatus() === 'Archived' ? 'Archived' : 'Unarchived'} Successfully`, {
                        variant: 'success',
                        key: 'user-auth',
                    });
                })
                .catch(({ data }) => {
                    enqueueSnackbar(data.error.message, { variant: 'error', key: 'user-auth' });
                });
        }
    };

    const handleCloseSendReportModal = useCallback(() => {
        setShowSendReportModal(false);
    }, []);

    const onClickSendReportModal = useCallback(
        (leadId) => {
            if (clientNotes.trim().length > 0) {
                setSelectedLeadId(leadId);
                setShowSendReportModal(true);
                setErrors(false);
            } else {
                setErrors(true);
            }
        },
        [clientNotes],
    );

    const changeInvalidLeadReason = async (values) => {
        await reportLead({
            invalidLeadReason: values,
            clientNotes: clientNotes,
            leadId: selectedLeadId,
            contactStatus,
        })
            .unwrap()
            .then((payload) => {
                enqueueSnackbar('Lead Reported Successfully', { variant: 'success', key: 'user-auth' });
                // setExpanded({});
            })
            .catch(({ data }) => {
                enqueueSnackbar(data?.error?.message ?? 'Something went wrong.', {
                    variant: 'error',
                    key: 'user-auth',
                });
            });

        handleCloseSendReportModal();
    };

    return (
        <>
            <div className={classNames('justify-content-center align-items-center p-1')}>
                <div>
                    <MDBox className="chat-room-details-header">
                        <div>
                            <h4>Lead Details</h4>
                        </div>
                        <div className="d-flex gap-0">
                            {/* <LaunchIcon fontSize="large" color="primary" className="cursor-pointer" /> */}

                            <CloseIcon fontSize="large" className="cursor-pointer" onClick={closeDetails} />
                        </div>
                    </MDBox>
                    <div>
                        <RateLead chatRoomId={chatRoomId} />
                    </div>
                </div>
                <div>
                    <div className="display-flex">
                        <div className="block-one">
                            <div className="block-one-details-container">
                                <MDBox className="block-one-details" sx={{ width: '100%' }}>
                                    <MDBox className="section-one">
                                        <BadgeItem
                                            item={{
                                                nature: BadgeNature.normal,
                                                className: `${chatRoomLeadDetails?.status?.split(' ').join('-')}-v2`,
                                                label: 'Status',
                                                value: chatRoomLeadDetails?.status,
                                            }}
                                        />
                                        <MDBox>
                                            <MDBox className="lead-id">
                                                Lead ID
                                                <p>{chatRoomLeadDetails?._id}</p>
                                            </MDBox>
                                            <MDBox className="sid">SID</MDBox>
                                        </MDBox>
                                    </MDBox>
                                    <MDBox className="section-two">
                                        <MDBox className="date">
                                            Date
                                            <p>{dayjs(chatRoomLeadDetails?.updatedAt).format('DD/MM/YYYY')}</p>
                                        </MDBox>
                                        <MDBox className="sale-info">Sale Info</MDBox>
                                    </MDBox>
                                    <MDBox className="section-three">
                                        <MDBox>
                                            <MDBox className="email">
                                                Email
                                                <p>{chatRoomLeadDetails?.email}</p>
                                            </MDBox>
                                        </MDBox>
                                        <MDBox>
                                            <MDBox className="post-code mt-1">
                                                Postcode
                                                <p>{chatRoomLeadDetails?.postCode}</p>
                                            </MDBox>
                                        </MDBox>
                                    </MDBox>
                                </MDBox>
                            </div>
                        </div>
                        <div className="block-middle">
                            <p className="add-notes-heading">Add Notes</p>
                            <div className="action-block-main">
                                <div>
                                    <p>Notes</p>
                                    <textarea
                                        rows={8}
                                        style={{
                                            cursor: isLeadFixed ? 'not-allowed' : 'pointer',
                                        }}
                                        disabled={isLeadFixed}
                                        placeholder="Enter notes here"
                                        name="Notes"
                                        value={clientNotes}
                                        onChange={handleNotesChange}
                                        id="clientsNotes"
                                        className="w-100 action-block-notes"
                                    />
                                    {errors && <div className="errors">Notes are Required</div>}
                                </div>

                                <div className="contract">
                                    <p>Contact Status</p>
                                    <Autocomplete
                                        className="autocomplete-field-chat-room w-100"
                                        disableClearable
                                        defaultValue={
                                            chatRoomLeadDetails?.contactStatus ? chatRoomLeadDetails?.contactStatus : ''
                                        }
                                        options={contactStatusOptions}
                                        onChange={handleStatusChange}
                                        renderInput={(params) => (
                                            <MDInput {...params} fullWidth placeholder="Select a contact status" />
                                        )}
                                        style={{
                                            cursor: isLeadFixed ? 'not-allowed' : 'pointer',
                                        }}
                                        disabled={isLeadFixed}
                                    />
                                </div>

                                <LeadSaleReport leadId={chatRoomLeadDetails?._id}
                                                soldAt={chatRoomLeadDetails?.soldAt} />
                            </div>
                        </div>
                        <div className="block-right">
                            <div>
                                <div className="block-right-header-main">
                                    <p className="report-lead-heading">Report Lead</p>
                                </div>
                                <div className="report-reason-main">
                                    <p>Reason for report (please add comments in notes)</p>

                                    <Autocomplete
                                        className="autocomplete-field"
                                        disableClearable
                                        defaultValue={
                                            chatRoomLeadDetails?.invalidLeadReason ? chatRoomLeadDetails?.invalidLeadReason : ''
                                        }
                                        options={reportReasonOptions}
                                        onChange={(event, value) => setReportTime(value)}
                                        renderInput={(params) => (
                                            <MDInput {...params} fullWidth placeholder="Select a reason for report" />
                                        )}
                                        style={{
                                            cursor: isLeadFixed
                                                ? // leadDetail?.status === 'Valid'
                                                'not-allowed'
                                                : 'pointer',
                                        }}
                                        disabled={isLeadFixed}
                                    />
                                </div>
                            </div>
                            <div className="">
                                <div className="action-box">
                                    <div className="mt-2">
                                        <input
                                            type="button"
                                            className={classNames('max-width-full w-100 button button-gray')}
                                            disabled={isLeadFixed}
                                            onClick={(e) =>
                                                handleArchive(
                                                    e,
                                                    chatRoomLeadDetails?.status,
                                                    clientNotes,
                                                    chatRoomLeadDetails?._id,
                                                    contactStatus,
                                                )
                                            }
                                            style={{
                                                cursor:
                                                    isLeadFixed
                                                        ? 'not-allowed'
                                                        : 'pointer',
                                            }}
                                            value={
                                                chatRoomLeadDetails?.status !== 'Report Accepted' &&
                                                chatRoomLeadDetails?.status !== 'Report Rejected'
                                                    ? chatRoomLeadDetails?.status === 'Archived'
                                                        ? 'Unarchive this lead'
                                                        : 'Archive this lead'
                                                    : 'Cannot update'
                                            }
                                        />
                                    </div>
                                    <div
                                        className={classNames('w-100 mt-1', {
                                            'disabled-wrapper': chatRoomLeadDetails?.status !== 'Reported',
                                        })}
                                    >
                                        <input
                                            disabled={isLeadFixed}
                                            style={{
                                                cursor:
                                                    isLeadFixed ||
                                                    reportTime === ''
                                                        ? // leadDetail?.status === 'Valid'
                                                        'not-allowed'
                                                        : 'pointer',
                                            }}
                                            onClick={() => onClickSendReportModal(chatRoomLeadDetails?._id)}
                                            value={'Send report'}
                                            type="button"
                                            className={classNames('max-width-full w-100 button', {
                                                disabled:
                                                    isLeadFixed ||
                                                    reportTime === '',
                                            })}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <BasicModal open={showSendReportModal} handleClose={handleCloseSendReportModal}>
                <SendReportModal
                    handleCloseSendReportModal={handleCloseSendReportModal}
                    checkMyReport={(values) => changeInvalidLeadReason(values)}
                    values={reportTime}
                />
            </BasicModal>
        </>
    );
};

export default ChatRoomDetails;
