import { userPermissionsArray } from 'contracts/spotdif/userPermissionsArray';
import { cloneDeep } from 'lodash';

interface IRolePermission {
    _id?: string;
    role?: string;
    permissions: userPermissionsArray[];
}

export const handleCheckboxChange = (setData: React.Dispatch<React.SetStateAction<IRolePermission>>) => {
    return (index: number, permissionType: string) => {
        setData((prevData) => {
            const newData = { ...prevData };
            const updatedPermissions = [...newData.permissions];
            const updatedPermission = cloneDeep(updatedPermissions[index]);

            // Toggle the permission type
            if (updatedPermission.permission.includes(permissionType)) {
                updatedPermission.permission = updatedPermission.permission.filter((p) => p !== permissionType);
            } else {
                updatedPermission.permission.push(permissionType);
            }

            updatedPermissions[index] = updatedPermission;
            newData.permissions = updatedPermissions;
            return newData;
        });
    };
};
