import DeleteIcon from '@mui/icons-material/Delete';
import { Card, CircularProgress, Grid, Icon, Switch, Tooltip } from '@mui/material';
import MasterCard from 'components/masterCard';
import MDBox from 'components/themed/MDBox';
import MDTypography from 'components/themed/MDTypography';
import { UserCard } from 'contracts/spotdif/BaseCardData';
import { useSnackbar } from 'notistack';
import React from 'react';

import './style.scss';

export interface ICreditCardProps {
    card: UserCard;
    isLoading: boolean;
}

const CreditCard: React.FC<ICreditCardProps> = ({ card, isLoading }) => {
    const { enqueueSnackbar } = useSnackbar();

    return !isLoading ? (
        <>
            <Grid item xs={12} lg={4} mb={7 / 4} className="spotdif-payment-card">
                <MasterCard card={card} />
                <MDBox
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                    className="spotdif-payment-card-control"
                >
                    <MDTypography variant="div" className="spotdif-paymentcard-defaultTitle">
                        Default payment card
                    </MDTypography>
                    <MDBox sx={{ display: 'flex', alignItems: 'center', gap: 2 }} className="default-toggle">
                        <Tooltip title={card.isDefault ? 'Default Card' : 'Set Default'} placement="top">
                            <Switch defaultChecked={card.isDefault} disabled />
                        </Tooltip>

                        <Icon
                            className="payment-delete-icon delete-icon"
                            onClick={() => enqueueSnackbar('Default Card Cannot be Deleted', { variant: 'error' })}
                        >
                            <DeleteIcon />
                        </Icon>
                    </MDBox>
                </MDBox>
            </Grid>
        </>
    ) : (
        <Card className="spotdif-card-loader">
            <CircularProgress size={40} />
        </Card>
    );
};

export default CreditCard;
