import CloseIcon from '@mui/icons-material/Close';
import Icon from '@mui/material/Icon';
import MDBox from 'components/themed/MDBox';
import MDTypography from 'components/themed/MDTypography';

import { useMaterialUIController } from 'context/ThemeContext';
import { useEffect, useState } from 'react';
import ConfiguratorRoot from './ConfiguratorRoot';

import SingleColumn from './SingleColumn';

function Configurator({
    visibleColumn,
    columnPreference,
    // setColumnsFields,
    currentVisible,
    currentInvisible,
    columnPreferenceUpdate,
    openConfigurator,
    handleCloseConfigurator,
}) {
    const [controller] = useMaterialUIController();
    const [currentVisibleColumns, setCurrentVisibleColumns] = useState(currentVisible);
    const [currentInVisibleColumns, setCurrentInVisibleColumns] = useState(currentInvisible);

    const [colPref, setColPref] = useState([...(columnPreference?.columns || [])]);

    const [status, setStatus] = useState('idle');
    const { darkMode } = controller;
    const [disabled, setDisabled] = useState(false);

    useEffect(() => {
        // A function that sets the disabled state of the buttons for the sidenav type.
        function handleDisabled() {
            return window.innerWidth > 1200 ? setDisabled(false) : setDisabled(true);
        }

        // The event listener that's calling the handleDisabled function when resizing the window.
        window.addEventListener('resize', handleDisabled);

        // Call the handleDisabled function to set the state with the initial value.
        handleDisabled();

        // Remove event listener on cleanup
        return () => window.removeEventListener('resize', handleDisabled);
    }, []);

    const handleSelectOption = (e, value, oldValue) => {
        let setVisible = currentVisibleColumns?.map((column) => {
            if (column.originalName === oldValue.originalName) {
                return {
                    ...column,
                    originalName: value.originalName,
                    isVisible: column.isVisible,
                    index: oldValue.index,
                    displayName: value.displayName,
                };
            }
            return column;
        });
        let setInVisible = currentInVisibleColumns?.map((column) => {
            if (column.originalName === value.originalName) {
                return {
                    ...column,
                    originalName: oldValue.originalName,
                    isVisible: column.isVisible,
                    index: value.index,
                    displayName: oldValue.displayName,
                };
            }
            return column;
        });
        setCurrentInVisibleColumns(setInVisible);
        setCurrentVisibleColumns(setVisible);
    };

    const handleDeleteColumn = (e, deleteValue) => {
        const setVisible = currentVisibleColumns.filter((item) => item.originalName !== deleteValue.originalName);

        const deleteColumn = {
            originalName: deleteValue.originalName,
            isVisible: !deleteValue.isVisible,
            index: deleteValue.index,
            displayName: deleteValue.displayName,
        };

        let setInVisible = currentInVisibleColumns;
        setInVisible = [deleteColumn, ...setInVisible];

        setCurrentInVisibleColumns(setInVisible);
        setCurrentVisibleColumns(setVisible);
    };

    const handleAddColumn = () => {
        const popValueFromInvisibleColumn = currentInVisibleColumns.pop();

        if (popValueFromInvisibleColumn) {
            const newColumn = {
                originalName: popValueFromInvisibleColumn.originalName,
                isVisible: !popValueFromInvisibleColumn.isVisible,
                index: popValueFromInvisibleColumn.index,
                displayName: popValueFromInvisibleColumn.displayName,
            };

            const updatedVisibleColumns = [...currentVisibleColumns, newColumn];
            setCurrentVisibleColumns(updatedVisibleColumns);
        }
    };

    const handleSubmit = async () => {
        setStatus('pending');

        const result = currentInVisibleColumns.concat(currentVisibleColumns);
        try {
            const data = await columnPreferenceUpdate(result);

            if (data) {
                setColPref(data.data.data.columns);
                setStatus('success');
            }
        } catch (error) {
            console.log(error);
            setStatus('rejected');
        }
    };

    return (
        <ConfiguratorRoot variant="permanent" ownerState={{ openConfigurator }}>
            <MDBox display="flex" justifyContent="space-between" alignItems="baseline" pt={4} pb={2} px={1}>
                <MDBox>
                    <MDTypography variant="h5" className="config-heading" pl={3} color="black">
                        Edit your dashboard
                    </MDTypography>
                </MDBox>

                <Icon
                    sx={({ typography: { size }, palette: { dark, white } }) => ({
                        fontSize: `${size.lg} !important`,
                        color: darkMode ? white.main : dark.main,
                        stroke: 'currentColor',
                        strokeWidth: '1px',
                        cursor: 'pointer',
                        transform: 'translateY(0px)',
                    })}
                    onClick={handleCloseConfigurator}
                >
                    <CloseIcon />
                </Icon>
            </MDBox>

            <MDBox pt={0.5} pb={3} px={1} className="column-slide-right">
                {currentVisibleColumns?.map((column, i) => {
                    return (
                        <MDBox mb={0.7} width="100%" key={`${column}_${i}`}>
                            <MDTypography
                                variant="h6"
                                ml={4.2}
                                fontWeight="light"
                                className="column-heading"
                            >{`Column ${i + 1}`}</MDTypography>
                            <SingleColumn
                                column={column}
                                index={i}
                                id={column?.index}
                                setColPref={setColPref}
                                handleDeleteColumn={handleDeleteColumn}
                                handleSelectOption={handleSelectOption}
                                value={column}
                                currentVisible={currentVisibleColumns}
                                setCurrentColumn={setCurrentVisibleColumns}
                                defaultValue={column}
                                options={currentInVisibleColumns}
                            />
                        </MDBox>
                    );
                })}
                <MDBox
                    sx={{
                        display: 'flex',
                        mt: 3,
                        mr: 1,
                        flexDirection: 'column',
                    }}
                >
                    {currentInVisibleColumns.length ? (
                        <MDBox
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                cursor: 'pointer',
                            }}
                            onClick={() => handleAddColumn()}
                        >
                            <div
                                style={{
                                    padding: 10,
                                    border: '1px solid gray',
                                    width: '100%',
                                    marginRight: 10,
                                    borderRadius: 30,
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                <p>+ Add a column</p>
                            </div>
                        </MDBox>
                    ) : (
                        ''
                    )}

                    <button
                        style={{ marginBottom: 15, marginTop: 25 }}
                        onClick={() => {
                            handleSubmit();
                        }}
                        className="button w-button"
                    >
                        Save Changes
                    </button>
                </MDBox>
            </MDBox>
        </ConfiguratorRoot>
    );
}

export default Configurator;
