import CloseIcon from '@mui/icons-material/Close';
import MDBox from 'components/themed/MDBox';
import MDButton from 'components/themed/MDButton';
import MDInput from 'components/themed/MDInput';
import MDTypography from 'components/themed/MDTypography';
import { Form, FormikProvider, useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import PasswordStrengthBar from 'react-password-strength-bar';
import { useChangeClientEmailMutation, useChangeClientPasswordMutation } from 'redux/services/spotdif/clients';
import * as Yup from 'yup';
import './UpdateModal.scss';
import { useRegexPatterns } from 'hooks/useRegexPatterns';

export interface IUpdateUserProps {
    onModalClose: () => void;
    modalHeader: string;
    modalButton: string;
    id: string;
}

const UpdateModal: React.FC<IUpdateUserProps> = ({
    onModalClose,
    modalHeader = 'Change Password',
    modalButton = 'Change Password',
    id,
}) => {
    const { enqueueSnackbar } = useSnackbar();
    const [changePasswordTrigger] = useChangeClientPasswordMutation();
    const [changeEmailTrigger] = useChangeClientEmailMutation();

    const { passwordRegex, emailRegex } = useRegexPatterns();

    const initState = {
        id: id,
        password: '',
        confirmPassword: '',
        email: '',
        isEmailModal: false,
    };

    const validationSchema = Yup.object().shape({
        password: Yup.string().when('isEmailModal', {
            is: false,
            then: (schema) =>
                schema
                    .required('Password required *')
                    .matches(
                        passwordRegex,
                        'Password must be of 8 Characters, must contain one uppercase, one lowercase, one numeric character',
                    ),
        }),
        confirmPassword: Yup.string().when('isEmailModal', {
            is: false,
            then: (schema) =>
                schema
                    .trim()
                    .oneOf([Yup.ref('password'), null], 'Passwords must match')
                    .required('Confirm Password is required *')
                    .min(3)
                    .matches(/^\S*$/, 'Password cannot be all whitespace'),
        }),

        email: Yup.string().when('isEmailModal', {
            is: true,
            then: (schema) =>
                schema
                    .required('Email required *')
                    .email('Must be a valid email')
                    .matches(emailRegex, 'Enter a valid email'),
        }),
    });

    const formik = useFormik({
        initialValues: initState,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            if (values.isEmailModal) {
                changeEmailTrigger({ id, email: values.email })
                    .unwrap()
                    .then((res) => {
                        enqueueSnackbar('Email Updated Successfully', { variant: 'success', key: 'user-auth' });
                    })
                    .catch((err) => {
                        enqueueSnackbar(err?.data?.error?.message, { variant: 'error' });
                    });
                onModalClose();
            } else {
                changePasswordTrigger({ id, password: values.password })
                    .unwrap()
                    .then((res) => {
                        enqueueSnackbar('Password Updated Successfully', { variant: 'success', key: 'user-auth' });
                    })
                    .catch((err) => {
                        enqueueSnackbar(err?.data?.error?.message, { variant: 'error' });
                    });
                onModalClose();
            }
        },
    });

    useEffect(() => {
        if (modalHeader === 'Update Email') {
            formik.setFieldValue('isEmailModal', true);
        } else {
            formik.setFieldValue('isEmailModal', false);
        }
    }, []);

    return (
        <MDBox className="addUserModalWrapper" sx={{ width: 1 }}>
            <FormikProvider value={formik}>
                <Form onSubmit={formik.handleSubmit} className="spotdif-login-form">
                    <MDBox className="modalFormMainWrapper">
                        <MDBox className="modalMainWrapper reset-pass-modal-width">
                            <MDTypography variant="h2" className="modal-heading-2">
                                {modalHeader}
                            </MDTypography>
                            <div className="form-groups reset-password-groups">
                                {formik.values.isEmailModal ? (
                                    <div className="form-input w-100">
                                        <MDInput
                                            type="text"
                                            label="Email"
                                            placeholder="Email"
                                            sx={{ width: 1 }}
                                            value={formik.values.email}
                                            id="email"
                                            autoComplete="email"
                                            onChange={(e) => formik.setFieldValue('email', e.target.value.trim())}
                                            error={formik.touched.email && Boolean(formik.errors.email)}
                                            helperText={formik.touched.email && formik.errors.email}
                                            fullWidth
                                        />
                                    </div>
                                ) : (
                                    <>
                                        <div className="form-input w-100">
                                            <MDInput
                                                type="password"
                                                label="Password"
                                                placeholder="Password"
                                                sx={{ width: 1 }}
                                                value={formik.values.password}
                                                id="password"
                                                autoComplete="password"
                                                onChange={(e) =>
                                                    formik.setFieldValue('password', e.target.value.trim())
                                                }
                                                error={formik.touched.password && Boolean(formik.errors.password)}
                                                helperText={formik.touched.password && formik.errors.password}
                                                fullWidth
                                            />
                                        </div>
                                        <div className="form-input w-100">
                                            <MDInput
                                                type="password"
                                                name="confirmPassword"
                                                label="Confirm Password"
                                                placeholder="Confirm Password"
                                                sx={{ width: 1 }}
                                                value={formik.values.confirmPassword}
                                                id="lastName"
                                                autoComplete="lastName"
                                                onChange={(e) =>
                                                    formik.setFieldValue('confirmPassword', e.target.value.trim())
                                                }
                                                error={
                                                    formik.touched.confirmPassword &&
                                                    Boolean(formik.errors.confirmPassword)
                                                }
                                                helperText={
                                                    formik.touched.confirmPassword && formik.errors.confirmPassword
                                                }
                                                fullWidth
                                            />
                                        </div>
                                    </>
                                )}
                            </div>
                            {formik.values.password.length > 3 ? (
                                <MDBox mb={3} className="spotdif-textbox-flex">
                                    <PasswordStrengthBar
                                        password={formik.values.password}
                                        onChangeScore={(score, feedback) => {}}
                                        shortScoreWord={false}
                                    />
                                </MDBox>
                            ) : null}
                        </MDBox>

                        <MDButton className="button modalBtn" type="submit" fullWidth>
                            {modalButton}
                        </MDButton>

                        <MDBox className="modalCloseWrapper">
                            <MDButton className="modalCloseBtn" onClick={onModalClose}>
                                <CloseIcon fontSize="large" />
                            </MDButton>
                        </MDBox>
                    </MDBox>
                </Form>
            </FormikProvider>
        </MDBox>
    );
};

export default UpdateModal;
