import { IOnboardingStep } from 'contracts/requests/IHeaderStepperProps';
import { removeTrailingSlash } from 'utils/text-helpers';

export function getStepsForPath(steps: IOnboardingStep[], pathname: string) {
    const currentStepIndex = steps.findIndex(
        (step) => removeTrailingSlash(step.path) === removeTrailingSlash(pathname),
    );

    const currentStep = steps[currentStepIndex];
    const previousStep = currentStepIndex >= 1 ? steps[currentStepIndex - 1] : null;
    const nextStep = currentStepIndex < steps.length - 1 ? steps[currentStepIndex + 1] : null;

    return {
        currentStep,
        previousStep,
        nextStep,
    };
}
