import Icon from '@mui/material/Icon';
import React from 'react';
import MDBox from '../../themed/MDBox';
import MDTypography from '../../themed/MDTypography';
import './style.scss';

interface IChartTitleBoxParams {
    description:
        | string
        | React.ReactElement<any, string | React.JSXElementConstructor<any>>
        | number
        | Iterable<React.ReactNode>
        | React.ReactPortal
        | boolean;
    component:
        | React.ReactElement<any, string | React.JSXElementConstructor<any>>
        | string
        | number
        | Iterable<React.ReactNode>
        | React.ReactPortal
        | boolean;
    color: 'primary' | 'secondary' | 'info' | 'success' | 'warning' | 'error' | 'light' | 'dark';
    title: string;
}

export const ChartTitleBox: React.FC<IChartTitleBoxParams> = ({ color, component, description, title }) => {
    return (
        <MDBox display="flex" px={description ? 1 : 0} pt={description ? 1 : 0} className="chart-title-box">
            {component && (
                <MDBox
                    width="4rem"
                    height="4rem"
                    bgColor={color || 'info'}
                    variant="gradient"
                    coloredShadow={color || 'info'}
                    borderRadius="xl"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    color="white"
                    mt={-5}
                    mr={2}
                >
                    <Icon fontSize="medium">{component}</Icon>
                </MDBox>
            )}
            <MDBox mt={component ? -2 : 0}>
                {title && <MDTypography variant="h6">{title}</MDTypography>}
                <MDBox mb={2}>
                    <MDTypography component="div" variant="button" color="text">
                        {description}
                    </MDTypography>
                </MDBox>
            </MDBox>
        </MDBox>
    );
};
