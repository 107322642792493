import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import './style.scss';
import { Tooltip } from '@mui/material';
import Icon from '@mui/material/Icon';
import { ColumnDef, createColumnHelper, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import classNames from 'classnames';
import AuthorisedUsage from 'components/app-permission/AuthorisedUsage';
import { MODULE, PERMISSIONS } from 'components/app-permission/permission';
import BasicModal from 'components/core/Modal';
import AlertPopUp from 'components/shared/AlertPopUp';
import PrimarySwitch from 'components/shared/PrimarySwitch';
import SpinningComponent from 'components/shared/SpinningComponent';
import MDButton from 'components/themed/MDButton';
import InviteUser from 'components/user-profile/AddUserModal';
import { EUserRole } from 'contracts/app-utils/EUserRole';
import { INonBillableLIstQuery } from 'contracts/requests/INonBillableLIstQuery';
import { GetSubAdminReponseDataObject } from 'contracts/spotdif/GetSubAdminResponseDataObject';
import { enqueueSnackbar } from 'notistack';
import React, { useCallback, useLayoutEffect, useMemo, useState } from 'react';
import {
    useAddSubAdminMutation,
    useChangeAdminStatusMutation,
    useDeleteSubAdminMutation,
    useGetSubAdminListQuery,
    useGetUserModulePermissionsQuery,
} from 'redux/services/spotdif/sub-admin';
import { useUpdateUserProfileMutation, useUserProfileDataQuery } from 'redux/services/spotdif/user';
import toTitleCase from 'utils/text-helpers';
import ResponsiveTable from './ResponsiveTable';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import PermissionTable from 'components/shared/PermissionTable';
import CloseIcon from '@mui/icons-material/Close';

const columnHelper = createColumnHelper<GetSubAdminReponseDataObject>();

const FirstNameHeader = () => <span>First Name</span>;
const FirstNameCell = (info) => <span>{info.getValue() || '-'}</span>;

const LastNameHeader = () => <span>Last Name</span>;
const LastNameCell = (info) => <span>{info.getValue() || '-'}</span>;

const EmailHeader = () => <span>Email</span>;
const EmailCell = (info) => <span>{info.getValue() || '-'}</span>;

const RoleHeader = () => <span>Role</span>;
const RoleCell = (info) => <span>{toTitleCase(info.getValue() || '-')}</span>;

const StatusHeader = () => (
    <AuthorisedUsage module={MODULE.ADMINS} permission={PERMISSIONS.UPDATE}>
        {' '}
        <span>Status</span>{' '}
    </AuthorisedUsage>
);
const StatusCell = (info) => {
    const [changeAdminStatusTrigger] = useChangeAdminStatusMutation();
    const changeAdminStatus = (id: string, currentStatus: boolean) => {
        const newStatus = !currentStatus;
        const data = {
            id,
            isActive: newStatus,
        };
        changeAdminStatusTrigger(data)
            .unwrap()
            .then(() => {
                enqueueSnackbar('Status Changed Successfully', { variant: 'success', key: 'change-subAdmin' });
            })
            .catch(() => {
                enqueueSnackbar('Failed to change status', { variant: 'error', key: 'change-subAdmin' });
            });
    };
    return (
        <div className="table_col">
            <div className="delete-icon">
                <AuthorisedUsage module={MODULE.ADMINS} permission={PERMISSIONS.UPDATE}>
                    <Tooltip title={info.row.original.isActive ? 'Active' : 'Disabled'}>
                        <PrimarySwitch
                            name="adminStatus"
                            checked={info.row.original.isActive ?? false}
                            onChange={() => changeAdminStatus(info.getValue(), info.row.original.isActive)}
                        />
                    </Tooltip>
                </AuthorisedUsage>
            </div>
        </div>
    );
};

const ActionHeader = () => (
    <AuthorisedUsage module={MODULE.ADMINS} permission={PERMISSIONS.UPDATE}>
        <span>Action</span>
    </AuthorisedUsage>
);
const ActionCell = (info) => {
    const [subAdminID, setSubAdminID] = useState('');
    const [showDeletePopup, setShowDeletePopup] = useState(false);
    const [openEditPopup, setOpenEditPopup] = useState(false);
    const handleClosePopup = () => {
        setShowDeletePopup(false);
        setSubAdminID('');
    };

    const [openPermissionsPopup, setOpenPermissionsPopup] = useState(false);

    const handleCloseEditPopup = useCallback(() => {
        setOpenEditPopup(false);
        setSubAdminID('');
    }, [setOpenEditPopup, setSubAdminID]);

    const handleClosePermissionsPopup = useCallback(() => {
        setOpenPermissionsPopup(false);
        setSubAdminID('');
    }, [setOpenPermissionsPopup, setSubAdminID]);

    const { data: adminData } = useUserProfileDataQuery(subAdminID, {
        skip: !subAdminID,
    });

    const adminId = adminData?.data?._id;
    const { data: userModulePermission, isLoading: isUserModulePermissionLoading } = useGetUserModulePermissionsQuery(
        adminId,
        {
            skip: !adminId,
        },
    );

    const [deleteSubAdminTrigger] = useDeleteSubAdminMutation();

    const deleteSubAdmin = async (id: string) => {
        deleteSubAdminTrigger(id)
            .unwrap()
            .then(() => {
                enqueueSnackbar('Deleted Successfully', { variant: 'success', key: 'deleted-subAdmin' });
                handleClosePopup();
            })
            .catch(() => {
                enqueueSnackbar('Failed to delete', { variant: 'error', key: 'deleted-subAdmin' });
            });
    };
    return (
        <>
            <div className="table_col">
                <div className="delete-icon mr-1">
                    <AuthorisedUsage module={MODULE.ADMINS} permission={PERMISSIONS.DELETE}>
                        <DeleteIcon
                            onClick={() => {
                                setShowDeletePopup(true);
                                setSubAdminID(info.getValue());
                            }}
                        />
                    </AuthorisedUsage>
                </div>
                <div className="edit-icon cursor-pointer mr-1">
                    <AuthorisedUsage module={MODULE.ADMINS} permission={PERMISSIONS.UPDATE}>
                        <EditIcon
                            onClick={() => {
                                setOpenEditPopup(true);
                                setSubAdminID(info.getValue());
                            }}
                        />
                    </AuthorisedUsage>
                </div>
                <div className="edit-icon cursor-pointer">
                    <AuthorisedUsage module={MODULE.ADMINS} permission={PERMISSIONS.UPDATE}>
                        <ManageAccountsIcon
                            onClick={() => {
                                setOpenPermissionsPopup(true);
                                setSubAdminID(info.getValue());
                            }}
                        />
                    </AuthorisedUsage>
                </div>
            </div>
            <BasicModal open={showDeletePopup} handleClose={handleClosePopup}>
                <AlertPopUp
                    fn={(subAdminID) => deleteSubAdmin(subAdminID)}
                    handleCloseModal={handleClosePopup}
                    heading={`Are you sure?`}
                    subheading={`Do you want to delete this admin. It cannot be undone.`}
                    buttonText="Yes"
                    value={subAdminID}
                />
            </BasicModal>

            {adminData && (
                <BasicModal open={openEditPopup} handleClose={handleCloseEditPopup}>
                    <InviteUser
                        onModalClose={handleCloseEditPopup}
                        useSaveMutation={useUpdateUserProfileMutation}
                        modalHeader={`Update ${toTitleCase(adminData?.data?.role)}`}
                        modalButton="Update"
                        role={adminData?.data?.role}
                        adminData={adminData?.data}
                    />
                </BasicModal>
            )}

            {adminData && (
                <BasicModal open={openPermissionsPopup} handleClose={handleClosePermissionsPopup}>
                    <SpinningComponent loading={isUserModulePermissionLoading}>
                        {userModulePermission?.data && (
                            <PermissionTable userModulePermission={userModulePermission?.data} />
                        )}
                        <div className="roleModalCloseWrapper">
                            <MDButton className="roleModalCloseBtn" onClick={handleClosePermissionsPopup}>
                                <CloseIcon fontSize="large" />
                            </MDButton>
                        </div>
                    </SpinningComponent>
                </BasicModal>
            )}
        </>
    );
};

const SubAdminTable: React.FC = () => {
    const [filters, setFilters] = useState<INonBillableLIstQuery>({
        search: '',
        perPage: 100,
        page: 1,
        total: 0,
        pageCount: 0,
    });
    const [openSubAdminModal, setOpenSubAdminModal] = useState(false);
    const [openAccountManagerModal, setOpenAccountManagerModal] = useState(false);
    const handleOpen = () => setOpenSubAdminModal(true);
    const handleClose = () => setOpenSubAdminModal(false);

    const showAccountManagerModal = () => setOpenAccountManagerModal(true);
    const hideAccountManagerModal = () => setOpenAccountManagerModal(false);
    const { data: subAdminListData, isLoading: isSubAdminListDataLoading } = useGetSubAdminListQuery(filters);
    const [data, setData] = useState(() => (subAdminListData ? [...subAdminListData.data] : []));

    useLayoutEffect(() => {
        if (subAdminListData?.data) {
            setData([...subAdminListData.data]);
        }
    }, [subAdminListData]);

    const columns: ColumnDef<GetSubAdminReponseDataObject, keyof GetSubAdminReponseDataObject>[] = useMemo(
        () => [
            columnHelper.accessor('_id', {
                header: ActionHeader,
                cell: ActionCell,
            }),
            columnHelper.accessor('firstName', {
                header: FirstNameHeader,
                cell: FirstNameCell,
            }),
            columnHelper.accessor('lastName', {
                header: LastNameHeader,
                cell: LastNameCell,
            }),
            columnHelper.accessor('email', {
                header: EmailHeader,
                cell: EmailCell,
            }),
            columnHelper.accessor('role', {
                header: RoleHeader,
                cell: RoleCell,
            }),
            columnHelper.accessor('_id', {
                header: StatusHeader,
                cell: StatusCell,
            }),
        ],
        [],
    );

    const table = useReactTable<GetSubAdminReponseDataObject>({
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
    });

    return (
        <>
            {/* <SpinningComponent loading={gettingSubAdminData}> */}
            <SpinningComponent loading={isSubAdminListDataLoading}>
                <div className="layout-middle-admin table-v2 industries-leads sub-admin-table client-leads-v2">
                    <div className="table_key-right">
                        <div className="table_key-right-grid responsiveStatus">
                            <AuthorisedUsage module={MODULE.ADMINS} permission={PERMISSIONS.CREATE}>
                                <MDButton
                                    onClick={() => handleOpen()}
                                    className="button icon-button mt-1 admin-screen-add-button"
                                >
                                    <Icon>
                                        <AddIcon />
                                    </Icon>
                                    Add Admin
                                </MDButton>

                                <MDButton
                                    onClick={() => showAccountManagerModal()}
                                    className="button icon-button mt-1 admin-screen-add-button"
                                >
                                    <Icon>
                                        <AddIcon />
                                    </Icon>
                                    Add Account Manager
                                </MDButton>
                            </AuthorisedUsage>
                        </div>
                    </div>

                    <AuthorisedUsage module={MODULE.ADMINS} permission={PERMISSIONS.READ}>
                        <div className="table-v2-block">
                            <div className="table-list-scroller">
                                <table>
                                    <thead>
                                        {table.getHeaderGroups().map((headerGroup) => (
                                            <tr key={headerGroup.id}>
                                                {headerGroup.headers.map((header, index) => (
                                                    <th key={header.id + '_' + index}>
                                                        {header.isPlaceholder
                                                            ? null
                                                            : flexRender(
                                                                  header.column.columnDef.header,
                                                                  header.getContext(),
                                                              )}
                                                    </th>
                                                ))}
                                            </tr>
                                        ))}
                                    </thead>
                                    <tbody>
                                        {table.getRowModel().rows.map((row, index) => {
                                            return (
                                                <tr
                                                    key={row.id}
                                                    className={classNames({ 'active-row': row.getIsExpanded() })}
                                                >
                                                    {row.getVisibleCells().map((cell, index) => (
                                                        <td
                                                            key={cell.id + '_' + index}
                                                            // className={`${leadDetail.isActive ? "Valid" : "paused"}`}
                                                        >
                                                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                                        </td>
                                                    ))}
                                                </tr>
                                            );
                                        })}
                                        {isSubAdminListDataLoading && (
                                            <tr className="">
                                                <td colSpan={12} className="text-align-center loading-center">
                                                    Loading...
                                                </td>{' '}
                                            </tr>
                                        )}
                                        {table.getRowModel().rows.length === 0 && !isSubAdminListDataLoading && (
                                            <tr>
                                                <td colSpan={12}>
                                                    <div className="no-data">No Data Found</div>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <SpinningComponent loading={isSubAdminListDataLoading}>
                            <div className="table-v2-block table-v2-responsive">
                                {table.getRowModel().rows.map((row) => {
                                    return <ResponsiveTable row={row} />;
                                })}
                                {table.getRowModel().rows.length === 0 && (
                                    <h4 className="table-loading">No data found</h4>
                                )}
                            </div>
                        </SpinningComponent>
                    </AuthorisedUsage>
                </div>
            </SpinningComponent>

            <BasicModal open={openSubAdminModal} handleClose={handleClose}>
                <InviteUser
                    onModalClose={handleClose}
                    useSaveMutation={useAddSubAdminMutation}
                    modalHeader="Add a new admin"
                    modalButton="Add Admin"
                    role={EUserRole.ADMIN}
                />
            </BasicModal>

            <BasicModal open={openAccountManagerModal} handleClose={hideAccountManagerModal}>
                <InviteUser
                    onModalClose={hideAccountManagerModal}
                    useSaveMutation={useAddSubAdminMutation}
                    modalHeader="Add a new Account Manager"
                    modalButton="Add Account Manager"
                    role={EUserRole.ACCOUNT_MANAGER}
                />
            </BasicModal>
        </>
    );
};

export default SubAdminTable;
