import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import StatsCard from 'components/shared/StatsCard';
import SharedDataTable from 'components/shared/SharedTable/SharedDataTable';
import { createColumnHelper } from '@tanstack/react-table';
import { IPostCodeRequest, postcodeSerializer } from 'contracts/requests/IPostCodeRequest';
import { useGetPostcodesQuery, useLazyGetPostcodesQuery } from 'redux/services/spotdif/postcode';
import { useGetAllIndustiesLeadsQuery } from 'redux/services/spotdif/industriesLeads';
import SpinningComponent from 'components/shared/SpinningComponent';
import TableHeaderV2 from 'components/shared/TableHeaderV2';
import { IPartnersAdsQuery } from 'contracts/spotdif/IGetPartnersAdsResponse';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import { enqueueSnackbar } from 'notistack';
import MDInput from 'components/themed/MDInput';
import VirtualizedDataTable from 'components/shared/SharedTable/VirtualizedDataTable';
import upArrow from 'assets/svg/up-arrow.svg';
import downArrow from 'assets/svg/down-arrow.svg';
import AuthorisedUsage from 'components/app-permission/AuthorisedUsage';
import { MODULE, PERMISSIONS } from 'components/app-permission/permission';
import { CSVLink } from 'react-csv';
import useDebounce from 'hooks/useDebounce';
import { useSelector, useDispatch } from 'react-redux';
import { setNationalPlusGeoOutputData, setNationalOutputData } from 'redux/slices/postcode';
import type { RootState } from '@reduxjs/toolkit/query';
import './style.scss';
import { useAppDispatch, useTypedSelector } from 'redux/store';

const GeoPostcodeResponsiveHeader = {
    postalCode: 'Postcodes',
    activeClients: 'Client Count',
};
const PostcodeTable: React.FC = () => {
    const [query, setQuery] = useState<IPostCodeRequest>({
        search: '',
        perPage: 5000,
        total: 0,
        pageCount: 0,
        page: 1,
        industry: [],
    });
    const dispatch = useAppDispatch();
    const [allData, setAllData] = useState([]);
    const [postcodesInput, setPostcodesInput] = useState('');
    const debouncedSearchText = useDebounce(postcodesInput, 300);
    const nationalPlusGeoOutputData = useTypedSelector((state) => state.postcodes.nationalPlusGeoOutputData);
    const nationalOutputData = useTypedSelector((state) => state.postcodes.nationalOutputData);
    // const [nationalPlusGeoOutputData, setNationalPlusGeoOutputData] = useState([]);
    // const [nationalOutputData, setNationalOutputData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const userInput = useMemo(() => {
        return postcodesInput
            ?.split(/[\n, ]+/)
            .filter((postcode) => postcode.trim() !== '')
            .map((postcode) => postcode.trim().toUpperCase());
    }, [postcodesInput]);

    const { postcodeData, postcodeDataLoading, isFetchingData, postcodeSerializedData }: any = useGetPostcodesQuery(
        query,
        {
            selectFromResult: ({ data, isLoading, isFetching }) => {
                return {
                    postcodeData: data,
                    postcodeSerializedData: data?.data?.map((item) => postcodeSerializer.parse(item)),
                    postcodeDataLoading: isLoading,
                    isFetchingData: isFetching,
                };
            },
        },
    );

    const { data: businessIndustries } = useGetAllIndustiesLeadsQuery();

    const concatenatedData = useMemo(() => {
        if (postcodeData) {
            return [...allData, ...postcodeSerializedData];
        }
    }, [allData, postcodeData]);

    const fetchNextPage = useCallback(() => {
        setQuery({ ...query, perPage: query.perPage + 50 });
    }, [query]);

    const updateFilters = useCallback(
        (key: string, value: string | number) => {
            if (key == 'industry') {
                setQuery((currentQueryValues) => {
                    let newQueryValues = { ...currentQueryValues } as IPartnersAdsQuery;

                    newQueryValues.page = 1;
                    newQueryValues.industry = value ? [value as string] : ([] as Array<string>);
                    return newQueryValues;
                });
                return;
            } else {
                setQuery({ ...query, [key]: value, page: 1 });
            }
        },
        [setQuery, query],
    );

    const handleCopyPostcodes = useCallback(() => {
        const allDataString = (postcodeSerializedData || []).map((item) => item.postalCode).join('\n');
        navigator.clipboard.writeText(allDataString);
        enqueueSnackbar('Copied', { variant: 'success', key: 'copied-code' });
    }, [postcodeData]);

    const handleCopyData = useCallback(
        (data, accessor: string) => {
            const allDataString = (data || []).map((item) => item[accessor]).join('\n');
            navigator.clipboard.writeText(allDataString);
            enqueueSnackbar('Copied', { variant: 'success', key: 'copied-code' });
        },
        [nationalPlusGeoOutputData, nationalOutputData],
    );

    const columnHelper = useMemo(() => createColumnHelper<any>(), []);

    const GeoPostcodesColumns = useMemo(
        () => [
            {
                accessor: 'postalCode',
                header: (
                    <span className="table-header-text-color">
                        Postcodes
                        <span className="ml-1">
                            <ContentCopyOutlinedIcon onClick={handleCopyPostcodes} />
                        </span>
                    </span>
                ),
                cell: (info) => <span>{info.getValue() ?? '-'}</span>,
            },

            {
                accessor: 'activeCount',
                header: <span className="table-header-text-color mr-1">Client Count</span>,
                cell: (info) => <span>{info.getValue() ?? '-'}</span>,
            },

            // {
            //     accessor: '_id',
            //     header: (
            //         <span className="table-header-text-color">
            //             <div className="d-flex-column">
            //                 <img src={upArrow} alt="up-arrow" />
            //                 <img src={downArrow} alt="delete-arrow" />
            //             </div>
            //         </span>
            //     ),
            //     cell: (info) => <span></span>,
            // },
        ],
        [handleCopyPostcodes],
    );

    const GeoPlusNationalPostcodesColumns = useMemo(
        () => [
            {
                accessor: 'geo_nation',
                header: (
                    <span className="bg-stats-header">
                        Geo + National
                        <span className="ml-1">
                            <ContentCopyOutlinedIcon
                                onClick={() => handleCopyData(nationalPlusGeoOutputData, 'geo_nation')}
                            />
                        </span>
                    </span>
                ),
                cell: (info) => <span className="text-upper-case">{info.getValue() ?? null}</span>,
            },

            {
                accessor: 'postalCode',
                header: (
                    <span className="table-header-text-color">
                        Geo only
                        <span className="ml-1">
                            <ContentCopyOutlinedIcon
                                onClick={() => handleCopyData(nationalPlusGeoOutputData, 'postalCode')}
                            />
                        </span>
                    </span>
                ),
                cell: (info) => <span>{info.getValue() ?? '-'}</span>,
            },

            {
                accessor: 'count',
                header: <span className="table-header-text-color">Client Count</span>,
                cell: (info) => <span>{info.getValue() ?? '-'}</span>,
            },
        ],
        [handleCopyData, nationalPlusGeoOutputData],
    );

    const NationalOutputColums = useMemo(
        () => [
            {
                accessor: 'geo_nation',
                header: (
                    <span className="bg-stats-header">
                        GEO + National
                        <span className="ml-1">
                            <ContentCopyOutlinedIcon onClick={() => handleCopyData(nationalOutputData, 'geo_nation')} />
                        </span>
                    </span>
                ),
                cell: (info) => <span className="text-upper-case">{info.getValue() ?? null}</span>,
            },

            {
                accessor: 'postalCode',
                header: (
                    <span className="table-header-text-color mr-1">
                        Geo Only
                        <span className="ml-1">
                            <ContentCopyOutlinedIcon onClick={() => handleCopyData(nationalOutputData, 'postalCode')} />
                        </span>
                    </span>
                ),
                cell: (info) => <span>{info.getValue() ?? '-'}</span>,
            },

            {
                accessor: 'count',
                header: <span className="table-header-text-color mr-1">Client Count</span>,
                cell: (info) => <span>{info.getValue() ?? '-'}</span>,
            },
        ],
        [handleCopyData, nationalOutputData],
    );

    const usersToPostalResponse = useCallback((users) => {
        return Object.keys(users).reduce((postalArray, currentUserId) => {
            const postalCodes = users[currentUserId];

            postalCodes?.forEach((postalCode) => {
                const existingPostalCode = postalArray.find((item) => item.postalCode === postalCode);

                if (existingPostalCode) {
                    existingPostalCode.count++;
                } else {
                    postalArray.push({ postalCode, count: 1 });
                }
            });

            return postalArray;
        }, []);
    }, []);

    const generateUsersPostCodes = useCallback(
        (data) => {
            return data?.reduce((acc, postalObject) => {
                postalObject.activeClients.forEach((client) => {
                    if (acc[client]) {
                        acc[client].push(postalObject.postalCode.toUpperCase());
                    } else {
                        acc[client] = [postalObject.postalCode.toUpperCase()];
                    }
                });

                return acc;
            }, {});
        },
        [postcodeSerializedData],
    );

    const usersPostCodes = useMemo(
        () => generateUsersPostCodes(postcodeSerializedData),
        [generateUsersPostCodes, postcodeSerializedData],
    );

    const addGeoNationToUsers = useCallback((users, input) => {
        const usersWithGeoNation = input.map((postalCode) => ({
            geo_nation: postalCode,
        }));
        usersWithGeoNation.forEach((item, index) => {
            users[index] = { ...users[index], ...item };
        });
        return users;
    }, []);

    const handlePostcodesEntered = useCallback(async () => {
        setIsLoading(true);
        const overlappingUsers = {};
        postcodeSerializedData?.forEach((postalObject) => {
            if (userInput.includes(postalObject.postalCode.toUpperCase())) {
                postalObject.activeClients.forEach((client) => {
                    overlappingUsers[client] = usersPostCodes[client];
                });
            }
        });

        const nonOverlappingUsers = Object?.keys(usersPostCodes).reduce((acc, currentUserId) => {
            if (!overlappingUsers[currentUserId]) {
                acc[currentUserId] = usersPostCodes[currentUserId];
            }
            return acc;
        }, {});

        const overlappedUsers = usersToPostalResponse(overlappingUsers).filter(
            (pc) => !userInput.includes(pc.postalCode),
        );
        const nonOverlappedUsers = usersToPostalResponse(nonOverlappingUsers);

        addGeoNationToUsers(overlappedUsers, userInput);
        addGeoNationToUsers(nonOverlappedUsers, userInput);

        dispatch(setNationalPlusGeoOutputData(overlappedUsers));
        dispatch(setNationalOutputData(nonOverlappedUsers));
        setIsLoading(false);
    }, [postcodeSerializedData, setNationalPlusGeoOutputData, setNationalOutputData, isLoading]);

    const csvInstance: any = useRef();
    const csvInstanceNationalWithGeo: any = useRef();
    const csvInstanceNationalOnly: any = useRef();

    const ExportFile = useCallback((value) => {
        value.current.link.click();
    }, []);

    const transformData = useCallback(
        (allData) => {
            return allData.map((data) => {
                let transformedData = { ...data };

                if (allData === postcodeSerializedData) {
                    delete transformedData.inactiveClients;
                    delete transformedData.nationalPostcode;
                    transformedData = {
                        ...transformedData,
                        activeClients: transformedData.activeClients ? transformedData.activeClients.length : null,
                    };
                } else if (allData === nationalPlusGeoOutputData || allData === nationalOutputData) {
                    delete transformedData.activeClients;
                    transformedData = {
                        ...transformedData,
                    };
                }
                return transformedData;
            });
        },
        [postcodeSerializedData, nationalPlusGeoOutputData, nationalOutputData],
    );

    useEffect(() => {
        if (debouncedSearchText) {
            handlePostcodesEntered();
        }
    }, [debouncedSearchText]);

    return (
        <>
            <SpinningComponent loading={postcodeDataLoading || isLoading}>
                <div className="layout-middle table-v2 client-leads-v2">
                    <div className="table_header">
                        <AuthorisedUsage module={MODULE.POST_CODE_STATS} permission={PERMISSIONS.READ}>
                            <div className="table_header-col d-flex w-100">
                                <TableHeaderV2
                                    hideSearchAndPerPage={false}
                                    updateFilters={updateFilters}
                                    businessIndustries={businessIndustries?.data}
                                    filters={{
                                        perPage: 10,
                                        sortingOrder: 'desc',
                                        status: '',
                                        clientId: '',
                                    }}
                                    dateRange={true}
                                    hidePerPage={true}
                                />
                            </div>
                        </AuthorisedUsage>
                    </div>

                    <div className="d-flex responsive-flex  gap-2 justify-space-between mt-2">
                        <AuthorisedUsage module={MODULE.POST_CODE_STATS} permission={PERMISSIONS.READ}>
                            <StatsCard title="National postcodes" totalCount={userInput.length} isExportAllowed={false}>
                                <div className="full-width-field national-postcode-field">
                                    <MDInput
                                        className="bg-white"
                                        type="text"
                                        value={postcodesInput}
                                        onChange={(e) => setPostcodesInput(e.target.value)}
                                        id="businessDescription"
                                        name="businessDescription"
                                        autoComplete="businessDescription"
                                        multiline
                                        rows={32}
                                    />
                                </div>
                                {/* <button className="stats-card-button" onClick={handlePostcodesEntered}>
                                    Filter Postcodes
                                </button> */}
                            </StatsCard>
                        </AuthorisedUsage>

                        <AuthorisedUsage module={MODULE.POST_CODE_STATS} permission={PERMISSIONS.READ}>
                            <StatsCard
                                title="GEO postcodes"
                                totalCount={postcodeData?.meta?.total}
                                isExportAllowed={true}
                                buttonText="Export list"
                                action={() => ExportFile(csvInstance)}
                            >
                                <VirtualizedDataTable
                                    tableData={concatenatedData}
                                    columns={GeoPostcodesColumns}
                                    columnHelper={columnHelper}
                                    responsiveTableHeader={GeoPostcodeResponsiveHeader}
                                    isFetching={isFetchingData}
                                    isLoading={postcodeDataLoading}
                                    fetchNextPage={fetchNextPage}
                                />
                            </StatsCard>
                        </AuthorisedUsage>
                    </div>

                    <div className="d-flex gap-2 justify-space-between mt-2">
                        <AuthorisedUsage module={MODULE.POST_CODE_STATS} permission={PERMISSIONS.READ}>
                            <StatsCard
                                title="National + GEO Output"
                                totalCount={nationalPlusGeoOutputData.length}
                                isExportAllowed={true}
                                buttonText="Export list"
                                action={() => ExportFile(csvInstanceNationalWithGeo)}
                            >
                                <SharedDataTable
                                    tableData={nationalPlusGeoOutputData}
                                    columns={GeoPlusNationalPostcodesColumns}
                                    columnHelper={columnHelper}
                                    responsiveTableHeader={GeoPostcodeResponsiveHeader}
                                    hasCurvedHeader={true}
                                />
                            </StatsCard>
                        </AuthorisedUsage>

                        <AuthorisedUsage module={MODULE.POST_CODE_STATS} permission={PERMISSIONS.READ}>
                            <StatsCard
                                title="National Output(No overlap)"
                                totalCount={nationalOutputData.length}
                                isExportAllowed={true}
                                buttonText="Export list"
                                action={() => ExportFile(csvInstanceNationalOnly)}
                            >
                                <SharedDataTable
                                    tableData={nationalOutputData}
                                    columns={NationalOutputColums}
                                    columnHelper={columnHelper}
                                    responsiveTableHeader={GeoPostcodeResponsiveHeader}
                                    hasCurvedHeader={true}
                                />
                            </StatsCard>
                        </AuthorisedUsage>
                    </div>
                </div>
                {postcodeSerializedData && (
                    <CSVLink
                        data={transformData(postcodeSerializedData)}
                        filename={'Spotdif-GEO-Postcode.CSV'}
                        ref={csvInstance}
                    />
                )}

                {nationalPlusGeoOutputData && (
                    <CSVLink
                        data={transformData(nationalPlusGeoOutputData)}
                        filename={'Spotdif-NationalWithGeo-Postcode.CSV'}
                        ref={csvInstanceNationalWithGeo}
                    />
                )}

                {nationalOutputData && (
                    <CSVLink
                        data={transformData(nationalOutputData)}
                        filename={'Spotdif-NationalOnly-Postcode.CSV'}
                        ref={csvInstanceNationalOnly}
                    />
                )}
            </SpinningComponent>
        </>
    );
};

export default PostcodeTable;
