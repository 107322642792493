import { IApiErrorResponse } from 'contracts/requests/IApiErrorResponse';
import { IChatTokenRequest } from 'contracts/requests/IChatTokenRequest';
import { IChatTokenResponse } from 'contracts/requests/IChatTokenResponse';
import { ILeadRatingObject } from 'contracts/requests/ILeadRateRequest';
import { IChatRoomResponse } from 'contracts/requests/ILeadsListResponse';
import { LeadsBidData } from 'contracts/spotdif/LeadsBidData';
import { TAG_CHAT_ROOM_DETAILS } from 'contracts/spotDiffAPITags';
import { spotdifApi } from '.';

export const chatTokenApi = spotdifApi.injectEndpoints({
    endpoints: (builder) => ({
        getChatToken: builder.query<IChatTokenResponse | IApiErrorResponse, IChatTokenRequest>({
            query: (body) => ({
                url: `/chat`,
                method: 'POST',
                body,
            }),
        }),
        getChatRoomDetails: builder.query<IChatRoomResponse, string>({
            providesTags: (res, err, chatRoomId) => {
                return (res?.data?.leads?.leadid) ? [{ type: TAG_CHAT_ROOM_DETAILS, id: res.data.leads.leadid }] : [];
            },
            query: (chatRoomId) => ({
                url: `/chat-room/${chatRoomId}`,
                method: 'GET',
            }),
        }),
        rateLead: builder.mutation<{
            lead: LeadsBidData,
            message: string
        }, ILeadRatingObject>({
            invalidatesTags: (res, err, { leadId }) => {
                return [{ type: TAG_CHAT_ROOM_DETAILS, id: res.lead.leads.leadid }];
            },
            query: (body) => {

                return {
                    url: `/leads/rate-lead/${body.leadId}`,
                    method: 'POST',
                    body: {
                        value: body.value,
                    },
                };
            },
        }),
    }),
});

export const { useLazyGetChatTokenQuery, useGetChatRoomDetailsQuery, useRateLeadMutation } = chatTokenApi;
