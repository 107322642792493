export interface IChatTokenResponse {
    data: tokenData;
}

interface tokenData {
    accessToken: string;
    test: string;
}

// export function isUserLoginResponse(obj: any): obj is IUserRegisterResponse {
//     return !!(obj as IUserRegisterResponse).token;
// }
export function isChatTokenResponse(obj: any): obj is IChatTokenResponse {
    return !!(obj as IChatTokenResponse).data.accessToken;
}
