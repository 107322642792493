import AddCircleIcon from '@mui/icons-material/AddCircle';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import DeleteIcon from '@mui/icons-material/Delete';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import Icon from '@mui/material/Icon';
import {
    ColumnDef,
    createColumnHelper,
    ExpandedState,
    flexRender,
    getCoreRowModel,
    getExpandedRowModel,
    getSortedRowModel,
    SortingState,
    useReactTable,
} from '@tanstack/react-table';
import classNames from 'classnames';
import Configurator from 'components/core/Configurator';
import BasicModal from 'components/core/Modal';
import AlertPopUp from 'components/shared/AlertPopUp';
import TableHeader from 'components/shared/TableHeader';
import userDetailRenderer from 'components/user-profile/UserDetailRenderer';
import { ColumnsDataObject } from 'contracts/spotdif/ColumnsDataObject';
import { UserBaseSerializer } from 'contracts/spotdif/UserBase';
import ITableHeaderFilters from 'contracts/view-models/ITableHeaderFilters';
import { enqueueSnackbar } from 'notistack';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';

import {
    useClientPreferenceUpdateMutation,
    useDeleteClientMutation,
    useExportClientsMutation,
    useGetAllClientsQuery,
    useGetClientColumnsQuery,
} from 'redux/services/spotdif/clients';
import { useGetAllIndustiesLeadsQuery } from 'redux/services/spotdif/industriesLeads';
import { useGetAllUsersQuery } from 'redux/services/spotdif/user';
import toTitleCase from 'utils/text-helpers';
import ClientDetailForm from './ClientDetailForm';

import './style.scss';

const columnHelper = createColumnHelper<ColumnsDataObject>();

const ClientTable: React.FC = () => {
    const [filters, setFilters] = useState<ITableHeaderFilters>({
        search: '',
        perPage: 100,
        sortingOrder: 'desc',
        status: '',
        page: 1,
        total: 0,
        pageCount: 0,
        clientId: '',
        leadsStatus: '',
        industry: '',
        business: '',
        showOnlyAccountManagers: false,
        accountManagerId: '',
        clientType: 'all',
    });

    const [sorting, setSorting] = useState<SortingState>([]);
    const [clientID, setClientID] = useState('');
    const [showPopUp, setShowPopUp] = useState(false);

    const { availableAccessManagers, isLoadingAccessManager } = useGetAllClientsQuery(
        { showOnlyAccountManagers: true, accountManagerId: '' },
        {
            selectFromResult: ({ data, isLoading }) => {
                return {
                    availableAccessManagers: data?.data,
                    isLoadingAccessManager: isLoading,
                };
            },
        },
    );

    const { data: clients, isLoading: isLoadingUsers } = useGetAllClientsQuery({ ...filters, sortBy: sorting });
    const { columnPreference, isLoadingColumnNames } = useGetClientColumnsQuery(undefined, {
        selectFromResult: ({ data, isLoading }) => {
            return {
                // columnPreference: columnPreferenceSerializer.parse(data),
                columnPreference: data,
                isLoadingColumnNames: isLoading,
            };
        },
    });

    const { data: businessIndustries } = useGetAllIndustiesLeadsQuery();

    const { data: allClients, isLoading: isLoadingClients } = useGetAllUsersQuery();
    const [exportClientsData, { isLoading, error, isError, data: csvData }] = useExportClientsMutation();
    const [deleteClientTriger] = useDeleteClientMutation();

    // const serializedColumns = columnPreferenceSerializer.parse(columnPreference)

    const [expanded, setExpanded] = useState<ExpandedState>({});
    const [openConfig, setOpenConfig] = useState<Boolean>(false);

    const [
        clientPreferenceUpdate,
        { isLoading: updateLeadLoading, error: updateLeadError, isError: isUpdateLeadError },
    ] = useClientPreferenceUpdateMutation();

    const handleClosePopup = () => {
        setShowPopUp(false);
        setClientID('');
    };

    const csvInstance: any = useRef();

    useEffect(() => {
        if (csvData && csvInstance.current && csvInstance.current?.link) {
            setTimeout(() => {
                csvInstance.current.link.click();
            });
        }
    }, [csvData]);

    const availableStatuses = useMemo(
        () => [
            {
                name: 'All Clients',
                value: '',
            },
            {
                name: 'Archived Clients',
                value: 'archived',
            },
            {
                name: 'Active Clients',
                value: 'active',
            },
            {
                name: 'Paused Clients',
                value: 'paused',
            },
        ],
        [],
    );

    const columns = useMemo<ColumnDef<any, React.ReactNode>[]>(() => {
        return isLoadingColumnNames || isLoadingUsers
            ? []
            : [
                columnHelper.display({
                    id: 'expander',
                    header: ({ table }) => (
                        <button onClick={() => setOpenConfig(true)}>
                            <Icon fontSize="medium">
                                <AppRegistrationIcon />
                            </Icon>
                        </button>
                    ),
                    enableSorting: false,
                    cell: ({ row }) => (
                        <div className="d-flex gap client-button">
                            <button>
                                {row.getIsExpanded() ? (
                                    <Icon fontSize="small">
                                        <RemoveCircleIcon />
                                    </Icon>
                                ) : (
                                    <Icon fontSize="small">
                                        <AddCircleIcon />
                                    </Icon>
                                )}
                            </button>

                            <button className="delete-icon client-deletButton">
                                <Icon fontSize="small">
                                    <DeleteIcon
                                        onClick={(e) => {
                                            row.toggleExpanded();
                                            setShowPopUp(true);
                                            setClientID(row.original._id);
                                        }}
                                    />
                                </Icon>
                            </button>
                        </div>
                    ),
                }),

                ...(columnPreference?.columns || [])
                    .filter((c: ColumnsDataObject) => c.originalName && c.isVisible)
                    .map((item: ColumnsDataObject) => {
                        return {
                            id: item.originalName,
                            accessorFn: (row) => {
                                return row[item.originalName];
                            },
                            header: () => {
                                if (item.originalName === 'createdAt') {
                                    return <span>Signup Date</span>;
                                } else if (item.originalName === 'daily') {
                                    return <span>Daily Lead Cap</span>;
                                } else {
                                    return <span>{toTitleCase(item?.displayName ?? item.originalName)}</span>;
                                }
                            },
                            // enableSorting: true,
                            enableMultiSort: true,
                            // sortingFn: sortingFns.textCaseSensitive,
                            cell: (info) => {
                                const user = UserBaseSerializer.parse(info.row.original);

                                const detailComponent = userDetailRenderer({ user, detailPath: item.originalName });
                                if (detailComponent) {
                                    return detailComponent;
                                } else {
                                    return <span>{info.cell.renderValue()?.toString() || '-'}</span>;
                                }
                            },
                            footer: (info) => info.column.id,
                        };
                    }),
            ];
    }, [isLoadingColumnNames, isLoadingUsers, columnPreference]);

    const table = useReactTable({
        data: clients?.data || [],
        columns,
        getCoreRowModel: getCoreRowModel(),
        // enableMultiSort: true,
        manualSorting: true,

        state: { expanded, sorting },
        onExpandedChange: setExpanded,
        getExpandedRowModel: getExpandedRowModel(),

        // onSortingChange: setSorting,
        onSortingChange: (getSorting) => {
            let sortedValue = [...(typeof getSorting === 'function' ? getSorting(sorting) : [])];

            setSorting(sortedValue);
            setFilters({ ...filters, sortingOrder: '' });
        },
        getSortedRowModel: getSortedRowModel(),

        // getPaginationRowModel: getPaginationRowModel(),
    });

    const numberArray = (num) => Array.from({ length: num }, (_, index) => index + 1);

    const columnPreferenceUpdate = (result) => {
        return clientPreferenceUpdate({ columns: result })
            .unwrap()
            .then((payload) => {
                enqueueSnackbar('Column preference updated.', { variant: 'success', key: 'user-auth' });
                setExpanded({});
                setOpenConfig(false);
            })
            .catch(({ data }) => {
                enqueueSnackbar(data?.error?.message ?? 'Something went wrong.', {
                    variant: 'error',
                    key: 'user-auth',
                });
            });
    };

    useEffect(() => {
        if (clients?.meta) {
            setFilters({
                ...filters,
                total: clients?.meta?.total,
                perPage: clients?.meta?.perPage,
                pageCount: clients?.meta?.pages,
                page: typeof clients?.meta?.page === 'string' ? parseInt(clients?.meta?.page) : clients?.meta?.page,
            });
        }
    }, [clients?.meta]);

    const exportClients = () => {
        const { leadsStatus } = filters;
        let filterUrl = `?${
            leadsStatus === 'archived'
                ? 'isArchived=true'
                : leadsStatus === 'active'
                    ? 'isActive=true'
                    : leadsStatus === 'paused'
                        ? 'isInActive=true'
                        : ''
        }&sortingOrder=${filters.sortingOrder}`;
        if (filters.clientId) {
            filterUrl = `${filterUrl}&id=${filters.clientId}`;
        }

        exportClientsData(filterUrl)
            .then((res) => {
                // console.log(res);
            })
            .catch((err) => {
                // console.log(err);
            });
    };
    const renderStatusHeader = () => (
        <>
            <div id="w-node-c8142863-1705-29c0-b081-be5d020ed7cd-25da26d6" className="table_key-right-col add-shadow">
                <div className="table_key-right-colour background-color-statusgreen"></div>
                <div>Active Clients</div>
            </div>
            <div id="w-node-_30b9fb8d-3342-9b56-e15d-d707bf0b70fc-25da26d6" className="table_key-right-col add-shadow">
                <div className="table_key-right-colour background-color-statuspaused"></div>
                <div>Paused Clients</div>
            </div>
            <div className="table_export_leads">
                <a onClick={exportClients} className="button is-small add-shadow w-button export-csv">
                    Export Clients
                </a>
                {csvData ? <CSVLink data={csvData} filename={'Clients.CSV'} ref={csvInstance} /> : undefined}
            </div>
        </>
    );

    const updateLeadFilters = (key: string, value: string | number) => {
        if (key === 'sortingOrder') {
            setSorting([]);
        }
        setFilters({ ...filters, [key]: value, page: 1 });
        setExpanded({});
    };

    const deleteClient = (id) => {
        try {
            table.getRowModel().rows.map((row) => row.toggleExpanded(false));
            deleteClientTriger(id)
                .unwrap()
                .then(() => {
                    enqueueSnackbar('Deleted Successfully', { variant: 'success', key: 'delete-client' });
                    handleClosePopup();
                })
                .catch(() => {
                    enqueueSnackbar('Something went wrong', { variant: 'error', key: 'delete-client' });
                });
        } catch (err) {
            console.log('err', err);
        }
    };

    return (
        <div className="layout-middle">
            {!(isLoadingColumnNames && isLoadingUsers) ? (
                <>
                    <TableHeader
                        status={renderStatusHeader()}
                        filters={filters}
                        updateFilters={updateLeadFilters}
                        // entriesPerPage={[10, 25, 50, 100]}
                        entriesPerPage={[100, 50, 25, 10]}
                        clients={allClients}
                        allStatus={availableStatuses}
                        businessIndustries={businessIndustries?.data}
                        accountsManagers={availableAccessManagers}
                        clientType={[
                            { label: 'Billable', value: 'billable' },
                            { label: 'Non-billable', value: 'nonBillable' },
                        ]}
                    />
                    <div className="listing-table">
                        <div className="listing-table-scroll">
                            <table>
                                <thead>
                                {table.getHeaderGroups().map((headerGroup) => (
                                    <tr key={headerGroup.id}>
                                        {headerGroup.headers.map((header) => (
                                            <th key={header.id}>
                                                {header.isPlaceholder
                                                    ? null
                                                    : flexRender(
                                                        header.column.columnDef.header,
                                                        header.getContext(),
                                                    )}

                                                {/* {header.isPlaceholder ? null : (
                                                        <div
                                                            {...{
                                                                className: header.column.getCanSort()
                                                                    ? "cursor-pointer select-none"
                                                                    : "",
                                                                onClick: header.column.getToggleSortingHandler(),
                                                            }}
                                                        >
                                                            <div className="heading-box">
                                                                {" "}
                                                                {flexRender(
                                                                    header.column.columnDef.header,
                                                                    header.getContext()
                                                                )}
                                                                {header.column.getCanSort() &&
                                                                    ({
                                                                        asc: <div className="sort-by asc"></div>,
                                                                        // asc: ' 🔼',
                                                                        // desc: ' 🔽',
                                                                        desc: <div className="sort-by desc"></div>,
                                                                        // no: 'dd'
                                                                    }[header.column.getIsSorted() as string] ?? (
                                                                        <div className="sort-by"></div>
                                                                    ))}
                                                            </div>
                                                        </div>
                                                    )} */}
                                            </th>
                                        ))}
                                    </tr>
                                ))}
                                </thead>
                                <tbody>
                                {table.getRowModel().rows.map((row, index) => {
                                    const userDetail: any = row.original;
                                    return (
                                        <React.Fragment key={index}>
                                            <tr
                                                key={row.id}
                                                {...{
                                                    onClick: () => row.toggleExpanded(),
                                                    style: { cursor: 'pointer' },
                                                }}
                                            >
                                                {row.getVisibleCells().map((cell) => (
                                                    <td
                                                        key={cell.id}
                                                        className={classNames({
                                                            Archived:
                                                                (userDetail?.isActive && userDetail?.isArchived) ||
                                                                (!userDetail?.isActive && userDetail?.isArchived),
                                                            Valid: userDetail?.isActive && !userDetail?.isArchived,
                                                            paused:
                                                                !userDetail?.isActive && !userDetail?.isArchived,
                                                        })}
                                                    >
                                                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                                    </td>
                                                ))}
                                            </tr>
                                            {row.getIsExpanded() ? (
                                                <tr>
                                                    <ClientDetailForm
                                                        availableAccessManagers={availableAccessManagers}
                                                        userDetail={userDetail}
                                                        setExpanded={setExpanded}
                                                        spanlength={columnPreference?.visibleColLength ?? 0}
                                                    />
                                                </tr>
                                            ) : (
                                                ''
                                            )}
                                        </React.Fragment>
                                    );
                                })}

                                {table.getRowModel().rows.length === 0 && (
                                    <tr>
                                        {columnPreference && (
                                            <td colSpan={12}>
                                                <div className="no-data">No Data Found</div>
                                            </td>
                                        )}
                                    </tr>
                                )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    {clients?.meta && (
                        <>
                            {clients?.meta?.pages > 1 && (
                                <div className="pagination">
                                    <button
                                        onClick={() => {
                                            table.getRowModel().rows.map((row) => row.toggleExpanded(false));
                                            filters?.page > 1 &&
                                            setFilters({
                                                ...filters,
                                                page: filters.page - 1,
                                            });
                                        }}
                                        disabled={filters?.page === 1 && true}
                                    >
                                        {'<'}
                                    </button>

                                    <span>
                                        {(numberArray(clients?.meta?.pages) ?? []).map((item, index) =>
                                            item === filters?.page ? (
                                                <strong key={index}> {item} </strong>
                                            ) : (
                                                <span
                                                    onClick={() => {
                                                        table
                                                            .getRowModel()
                                                            .rows.map((row) => row.toggleExpanded(false));
                                                        setFilters({ ...filters, page: item });
                                                    }}
                                                >
                                                    {item}
                                                </span>
                                            ),
                                        )}
                                    </span>

                                    <button
                                        onClick={() => {
                                            table.getRowModel().rows.map((row) => row.toggleExpanded(false));
                                            filters?.page <= filters?.pageCount &&
                                            setFilters({
                                                ...filters,
                                                page: filters?.page + 1,
                                            });
                                        }}
                                        disabled={filters?.page === filters?.pageCount && true}
                                    >
                                        {'>'}
                                    </button>
                                </div>
                            )}
                        </>
                    )}
                    {openConfig && (
                        <Configurator
                            visibleColumn={columns}
                            columnPreference={columnPreference}
                            currentVisible={columnPreference?.columns?.filter((column) => column.isVisible)}
                            currentInvisible={columnPreference?.columns?.filter((column) => !column.isVisible)}
                            columnPreferenceUpdate={columnPreferenceUpdate} // update api hitting
                            openConfigurator={openConfig}
                            handleCloseConfigurator={() => setOpenConfig(false)}
                        />
                    )}
                </>
            ) : (
                <span>Loading...</span>
            )}

            <BasicModal open={showPopUp} handleClose={handleClosePopup}>
                <AlertPopUp
                    fn={(clientID) => deleteClient(clientID)}
                    handleCloseModal={handleClosePopup}
                    heading={`Are you sure?`}
                    subheading={`Do you want to delete this client? It cannot be undone`}
                    buttonText="Yes"
                    value={clientID}
                />
            </BasicModal>
        </div>
    );
};

export default ClientTable;
