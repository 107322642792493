import React from 'react';
import { Form, useFormikContext } from 'formik';
import { INotificationRequest } from 'contracts/requests/INotificationRequest';
import { InputAdornment } from '@mui/material';
import { Switch, Tooltip } from '@mui/material';
import useAuthentication from 'hooks/useAuthentication';
import MDInput from 'components/themed/MDInput';

const SMSNotificationsForm: React.FC = () => {
    const { values, handleBlur, handleChange, handleSubmit, touched, errors, setFieldValue } =
        useFormikContext<INotificationRequest>();
    const { user } = useAuthentication();
    const [isSMSNotification, setIsSMSNotification] = React.useState(user?.isSmsNotificationActive);
    return (
        <>
            <Form onSubmit={handleSubmit}>
                <div className="tab-user-content-card payment-card">
                    <div className="full-width-field">
                        <div className="d-flex">
                            <Tooltip title={isSMSNotification ? 'Active' : 'InActive'}>
                                <Switch
                                    defaultChecked={user?.isSmsNotificationActive}
                                    onChange={() => {
                                        setIsSMSNotification(!isSMSNotification);
                                        setFieldValue('isSmsNotificationActive', !values.isSmsNotificationActive);
                                    }}
                                />
                            </Tooltip>

                            <p className="para-graph-color">
                                Enable SMS Notification to receive lead data to registered Mobile Number.
                            </p>
                        </div>

                        <MDInput
                            type="tel"
                            id="smsPhoneNumber"
                            name="smsPhoneNumber"
                            placeholder="Enter Mobile Number"
                            value={values.smsPhoneNumber}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <span>+44</span>
                                    </InputAdornment>
                                ),
                            }}
                            error={touched.smsPhoneNumber && isSMSNotification && Boolean(errors.smsPhoneNumber)}
                            helperText={touched.smsPhoneNumber && isSMSNotification && errors.smsPhoneNumber}
                            disabled={isSMSNotification === false ? true : false}
                        />
                    </div>
                </div>

                <button type="submit" className="button">
                    Save changes
                </button>
            </Form>
        </>
    );
};

export default SMSNotificationsForm;
