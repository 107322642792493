import React, { createContext, useState } from 'react';

type CreditCardContextValues = {
    openAddCreditModal: boolean;

    setOpenAddCreditModal: React.Dispatch<React.SetStateAction<boolean>>;
};

// Set default values using undefined

const defaultCreditCardContextValues: CreditCardContextValues = {
    openAddCreditModal: false,

    setOpenAddCreditModal: () => {},
};

// Create a new context

const CreditCardHandlerContext = createContext<CreditCardContextValues>(defaultCreditCardContextValues);

interface ICreditCardHandlerProviderProps {
    children: React.ReactNode;
}

// Create the provider component

const CreditCardHandlerProvider: React.FC<ICreditCardHandlerProviderProps> = ({ children }) => {
    const [openAddCreditModal, setOpenAddCreditModal] = useState(false);

    return (
        <CreditCardHandlerContext.Provider value={{ openAddCreditModal, setOpenAddCreditModal }}>
            {children}
        </CreditCardHandlerContext.Provider>
    );
};

export { CreditCardHandlerContext, CreditCardHandlerProvider };
