import {
    ILeftDashboardChartRequest,
    ILeftDashboardChartResponse,
} from 'contracts/requests/ILeftDashboardChartResponse';
import {
    IRightDashboardChartRequest,
    IRightDashboardChartResponse,
} from 'contracts/requests/IRightDashboardChartResponse';
import { ITopThreeChartsResponse } from 'contracts/requests/ITopThreeChartsResponse';
import { TAG_CHART_DATA, TAG_CHART_DATA_LEFT, TAG_CHART_DATA_RIGHT } from 'contracts/spotDiffAPITags';
import { spotdifApi } from '.';

const statsApi = spotdifApi.injectEndpoints({
    endpoints: (builder) => ({
        getLeftDashboardChartData: builder.query<ILeftDashboardChartResponse, ILeftDashboardChartRequest>({
            providesTags: [
                {
                    type: TAG_CHART_DATA,
                    id: TAG_CHART_DATA_LEFT,
                },
            ],

            query(requestArgs) {
                return {
                    url: `/leads/leftDashboardChart?start=${requestArgs.start}&end=${requestArgs.end}`,
                    method: 'get',
                };
            },
        }),
        getRightDashboardChartData: builder.query<IRightDashboardChartResponse, IRightDashboardChartRequest>({
            providesTags: [
                {
                    type: TAG_CHART_DATA,
                    id: TAG_CHART_DATA_RIGHT,
                },
            ],

            query(requestArgs) {
                return {
                    url: `/leads/rightDashboardChart?start=${requestArgs.start}&end=${requestArgs.end}`,
                    method: 'get',
                };
            },
        }),

        getTopDashboardCharData: builder.query<ITopThreeChartsResponse, void>({
            providesTags: [
                {
                    type: TAG_CHART_DATA,
                    id: TAG_CHART_DATA_RIGHT,
                },
            ],
            query() {
                return {
                    url: 'leads/dashboardTopCards',

                    method: 'get',
                };
            },
        }),
        getAccountManagerStats: builder.mutation<any, any>({
            query(body) {
                return {
                    url: '/user/account-manager/stats',
                    method: 'post',
                    body,
                };
            },
            transformErrorResponse: (response: any) => {
                const data = {
                    // leadsRejectedCount: [],
                    creditsSum: [],
                    creditsCount: [],
                    creditsAvg: [],
                    labels: [],
                };
                response.data.forEach((graphItem) => {
                    data.labels.push(graphItem.name);
                    data.creditsSum.push(graphItem.creditsSum);
                    data.creditsAvg.push(graphItem.creditsAvg);
                    data.creditsCount.push(graphItem.creditsCount);
                });

                return data;
            },
        }),
    }),
});

export const {
    useGetLeftDashboardChartDataQuery,
    useGetRightDashboardChartDataQuery,
    useGetTopDashboardCharDataQuery,
    useGetAccountManagerStatsMutation,
} = statsApi;
