import { timeFormatter } from './text-helpers';

export const expandDataCopy = (leadData, adminLeadsData, fieldToExclude = ['firstname', 'lastname', 'phone1']) => {
    const data = [];
    leadData?.columns?.forEach(({ originalName, displayName, isVisible }) => {
        if (!fieldToExclude.includes(originalName) && isVisible) {
            if (originalName === 'recieved' || originalName === 'received') {
                data.push({
                    label: displayName,
                    value: leadData.leads?.[originalName]
                        ? leadData.leads?.[originalName]
                        : leadData?.['createdAt']
                            ? timeFormatter(leadData?.['createdAt'], 'DD/MM/YYYY, h:mm:ss A')
                            : '-',
                });
                return;
            }
            data.push({
                label: displayName,
                value:
                    leadData.leads?.[originalName] ??
                    adminLeadsData?.data?.find((leads) => leads._id === leadData._id).leads?.[originalName],
            });
        }
    });
    // console.log(adminLeadsData, ">>>>>>>>>")
    // console.log(data, ">>>>>>>")

    return data;
};

// ?? timeToDayJS( leadData?.['createdAt'])
