import { ChatFileAttachment, ChatFileAttachmentSerializer } from 'contracts/chat/ChatFileAttachment';
import dayjs from 'dayjs';
import { jsonArrayMember, jsonMember, jsonObject, TypedJSON } from 'typedjson';

export enum MessageDirection {
    Incoming = 'incoming',
    Outgoing = 'outgoing',
}

@jsonObject()
export class ReadReceipt {
    @jsonMember(String)
    participantId: string;

    @jsonMember(() => dayjs.Dayjs, {
        deserializer: (value: any) => {
            return dayjs(value);
        },
    })
    readAt: dayjs.Dayjs;
}

@jsonObject()
export class MessageThread {
    @jsonMember(String)
    roomId: string;

    @jsonMember(String)
    senderId: string;

    @jsonMember(String)
    text: string;

    @jsonMember(() => MessageDirection, {
        deserializer: (value: any) => {
            return `${value}`.toLowerCase() === 'incoming' ? MessageDirection.Incoming : MessageDirection.Outgoing;
        },
    })
    direction: MessageDirection;

    @jsonMember(() => ReadReceipt)
    readReceipt: ReadReceipt;

    @jsonArrayMember(() => ChatFileAttachment, {
        deserializer: (value: unknown) => {
            if (Array.isArray(value)) {
                return value.map((item: unknown) => {
                    return (typeof item === 'string') ? null : ChatFileAttachmentSerializer.parse(item);
                }).filter((item) => {
                    return item !== null && item.path !== '';
                });
            } else {
                return [];
            }
        },
    })
    attachments: ChatFileAttachment[];

    @jsonMember(String)
    _id: string;

    @jsonMember(() => dayjs.Dayjs, {
        deserializer: (value: any) => {
            return dayjs(value);
        },
    })
    createdAt: dayjs.Dayjs;

    @jsonMember(() => dayjs.Dayjs, {
        deserializer: (value: any) => {
            return dayjs(value);
        },
    })
    updatedAt: dayjs.Dayjs;

    // FUTURE: support media content uploads.
    get media(): string {
        return '';
    }

    get itemHeight(): number {
        return (((this.text.length / 58) - 1) * 30) + 100;
    }
}

export const MessageThreadSerializer = new TypedJSON(MessageThread);
