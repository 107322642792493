import { Avatar, Badge, Grid, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material';
import classNames from 'classnames';
import HumanDateRenderer from 'components/shared/DateTimeRenderer/HumanDateRenderer';
import StringFormatter from 'components/shared/Text/StringFormatter';
import { ChatRoom } from 'contracts/chat/ChatRoom';
import React, { useEffect, useRef } from 'react';
import { generatePath, Link, useParams } from 'react-router-dom';
import { stringFormatter } from 'utils/text-helpers';

interface IUserItemProps {
    index: number;
    room: ChatRoom;
    // unknown for now cause style is css object
    style: React.CSSProperties;
    setRoomHeight: (arg1: number, arg2: number) => void;
}

const RoomListItem: React.FC<IUserItemProps> = ({ index, style, room, setRoomHeight }) => {
    const { roomId } = useParams();

    // TODO: Use Chat Status instead of string. and refactor into own file
    function chatBackground(status: string): string {
        switch (status.toLowerCase()) {
            case 'read':
                return '#FFFFFF';
            case 'unread':
                return '#EDFFF5';
            case 'current':
                return '#EEEEF4';
            default:
                return 'transparent'; // Default color
        }
    }

    const roomRef = useRef({});

    useEffect(() => {
        if (roomRef.current) {
            // @ts-ignore
            setRoomHeight(index, roomRef.current.clientHeight);
        }
    }, [roomRef]);

    return (
        <div
            className={classNames({
                'chat-rooms--list': true,
                'chat-rooms--list-active': room._id === roomId,
            })}
            key={`chat-room-list--${room._id}`}
            style={style}
        >
            <Link to={generatePath('/chat-inbox/:roomId', { roomId: room._id })}>
                {/* @ts-ignore */}
                <Grid
                    item
                    xs={12}
                    md={12}
                    className="user"
                    sx={{
                        background: chatBackground(room.status),
                    }}
                    ref={roomRef}
                >
                    <Grid item xs={12} md={12}>
                        <ListItem alignItems="flex-start">
                            <ListItemAvatar className="avatar-outer">
                                <Avatar
                                    className="user-profile"
                                    alt={room.formattedTitle}
                                    src="/static/images/avatar/2.jpg"
                                />
                                <Badge
                                    color="secondary"
                                    className="online-user"
                                    overlap="circular"
                                    badgeContent=""
                                    variant="dot"
                                ></Badge>
                            </ListItemAvatar>
                            <ListItemText
                                className="username"
                                primary={
                                    <>
                                        <Typography
                                            sx={{
                                                display: 'inline',
                                                color: '#000',
                                                fontWeight: 600,
                                                fontSize: '18px',
                                            }}
                                            component="span"
                                            variant="body1"
                                            color="text.primary"
                                        >
                                            <StringFormatter text={room.formattedTitle} length={29} />

                                            <span
                                                style={{
                                                    color: '#7E7E9A',
                                                    fontWeight: 400,
                                                    marginLeft: '8px',
                                                }}
                                            >
                                                #{room?.leadId}
                                            </span>
                                        </Typography>
                                    </>
                                }
                                secondary={
                                    <React.Fragment>
                                        <Typography
                                            sx={{
                                                display: 'inline',
                                                fontSize: '14px',
                                                fontWeight: 600,
                                            }}
                                            component="span"
                                            variant="body2"
                                            color="text.primary"
                                        >
                                            <Typography className="last-createdAt" component="span">
                                                <Typography variant="body2" className="latest-message-with-badge">
                                                    {' '}
                                                    <span>{stringFormatter(30, room.formattedSubTitle)}</span>
                                                    {room.status === 'unread' && (
                                                        <Badge
                                                            sx={{
                                                                '& .MuiBadge-badge': {
                                                                    backgroundColor: '#24C568',
                                                                    color: '#fff',
                                                                },
                                                                pl: '24px',
                                                            }}
                                                            color="success"
                                                            overlap="circular"
                                                            badgeContent={index}
                                                            className="unread-count-badge"
                                                        ></Badge>
                                                    )}
                                                </Typography>
                                                <Typography
                                                    variant="body2"
                                                    sx={{
                                                        fontSize: 'small',
                                                    }}
                                                >
                                                    {' '}
                                                    <HumanDateRenderer date={room.lastUpdatedAt} />
                                                </Typography>
                                            </Typography>
                                        </Typography>
                                    </React.Fragment>
                                }
                            />
                        </ListItem>
                    </Grid>
                </Grid>
            </Link>
        </div>
    );
};

export default RoomListItem;
