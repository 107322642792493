import { IHasDailySchedule } from 'contracts/view-models/IHasDailySchedule';
import { jsonArrayMember, jsonMember, jsonObject, TypedJSON } from 'typedjson';
import { BaseDataModel } from './BaseDataModel';
import { DaySchedule, sanitizeWeeklySchedule } from './DaySchedule';
import { BuyerQuestionsConfig, BuyerQuestionsConfigSerializer } from './GetBusinessIndustryResponseDataObject';

@jsonObject()
export class BusinessDetailsDataObject extends BaseDataModel implements IHasDailySchedule {
    @jsonArrayMember(() => BuyerQuestionsConfig, {
        deserializer: (value: unknown) => {
            if (value) {
                let values = null;

                try {
                    values = (value as Array<unknown>).map(v => BuyerQuestionsConfigSerializer.parse(v));
                } catch (ex) {
                    console.error(ex);
                }

                return values;
            } else {
                return null;
            }
        },
    })
    buyerQuestions?: BuyerQuestionsConfig[];

    @jsonMember(String)
    businessIndustry?: string;

    @jsonMember(String)
    businessName?: string;

    @jsonMember(String)
    businessUrl?: string;

    @jsonMember(String)
    address1?: string;

    @jsonMember(String)
    address2?: string;

    @jsonMember(String)
    businessSalesNumber?: string;

    @jsonMember(String)
    businessDescription?: string;

    @jsonMember(String)
    businessLogo: string;

    @jsonMember(String)
    businessAddress?: string;

    @jsonMember(String)
    businessCity?: string;

    @jsonMember(String)
    businessPostCode?: string;

    @jsonMember(String)
    businessMobilePrefixCode?: string;

    @jsonArrayMember(DaySchedule, {
        deserializer: sanitizeWeeklySchedule,
    })
    businessOpeningHours?: DaySchedule[];

    get schedule(): DaySchedule[] {
        return this.businessOpeningHours;
    }

    getValue(propName: string) {
        return this[propName] ?? ('' as string);
    }
}

export const BusinessDetailsDataObjectSerializer = new TypedJSON(BusinessDetailsDataObject);
