import dayjs from 'dayjs';
import moment from 'moment-timezone';
import { enqueueSnackbar } from 'notistack';

export default function toTitleCase(input: string): string {
    // Input overrides map
    const inputOverrides: { [key: string]: string } = {
        dob: 'Date of Birth',
        g: 'Gender',
        // Add more overrides here if needed
    };

    // Check if there is an input override
    const override = inputOverrides[input?.toLowerCase()];
    if (override) {
        return override;
    }

    // Split the input string based on underscores and capital letters
    const words = input?.split(/[_\s]|(?=[A-Z])/);

    // Capitalize the first letter of each word and join them back with spaces
    const titleCasedWords = words?.map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());
    return titleCasedWords?.join(' ');
}

export function removeTrailingSlash(input: string): string {
    // Check if the input string has a trailing slash
    if (input.endsWith('/')) {
        // Remove the trailing slash using slice
        return input.slice(0, -1);
    }
    // If there's no trailing slash, return the input as it is
    return input;
}

export const numberArray = (num) => Array.from({ length: num }, (_, index) => index + 1);

export const onlyNumbersRegExp = /^\d*$/;

export const onlyNumberWithPositiveNegativeRegExp = /^[-+]?\d*$/;

export const onlyNumbersWithTwoDecimal = /^[0-9]{0,5}(\.[0-9]{0,2})?$/;

export const avgConversionRateRegex = /^([1-9]?\d|100)$/;

export const checkForNegativeValue = /^\d*[0-9]\d*$/;

export const copyData = (data, msg) => {
    navigator.clipboard.writeText(data).then(() => {
        //   notifySuccess('URL Copied');
        enqueueSnackbar(msg, { variant: 'success', key: 'copied-code' });
    });
};

export const timeFormatter = (value: string | dayjs.Dayjs, format: string): string => {
    return dayjs.isDayjs(value) ? (value as dayjs.Dayjs).format(format) : moment.tz(value, moment.tz.guess()).format(format).toString();
};

export const capitalizeFirstLetter = (string: string) => {
    if (typeof string !== 'string' || string.length === 0) {
        return '';
    }
    return string.charAt(0).toUpperCase() + string.slice(1);
};

// export const stringFormatter = (count, text) => {
//     return text?.length > count ? text.slice(0, count)+'...' : text
// }

export const stringFormatter = (count, text, suffix = '...') => {
    if (typeof text === 'number') {
        return text.toFixed(2);
    } else {
        const finalSuffix = suffix ?? '...';

        return text?.length > count ? text.slice(0, count) + finalSuffix : text;
    }
};

export const generateXAxisLabel = (chartTitle: string = '') => {
    switch (chartTitle.toLowerCase()) {
        case 'lead cost':
            return 'Dates';
        case 'lead volume':
            return 'Dates';
        default:
            return '';
    }
};

export const generateYAxisLabel = (chartTitle: string = '') => {
    switch (chartTitle.toLowerCase()) {
        case 'lead cost':
            return 'Cost in GBP';
        case 'lead volume':
            return 'Volume';
        default:
            return '';
    }
};

export const showChartTitle = (chartTitle: string = '') => {
    if (chartTitle.toLowerCase() === 'lead cost') return true;
    else if (chartTitle.toLowerCase() === 'lead volume') return true;
    else return false;
};

export const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    const charCode = event.which || event.keyCode;
    const char = String.fromCharCode(charCode);
    const regex = /^[0-9]{0,5}(\.[0-9]{0,2})?$/; // Allow 5 numbers before and 2 after decimal

    if (!regex.test(char) && charCode !== 8 /* Backspace */) {
        event.preventDefault(); // Prevent the keypress event
    }
};

export const generateCollapseName = (pathname) => {
    const pathnameArray = pathname.split('/');
    return pathnameArray[pathnameArray.length - 1];
};

export const sumIfAbsoluteDifferenceIsNonNegative = (a, b) => {
    // Calculate the absolute difference
    let absoluteDifference = Math.abs(a - b);

    // Check if the absolute difference is 0 or more
    if (absoluteDifference >= 0) {
        // If true, return the sum
        return a + b;
    } else {
        // If false, return some indicator (you can modify this based on your specific requirements)
        return -1;
    }
};

export const cleanUpHTML = (htmlString: string) => {
    // Remove empty <p> tags
    return htmlString.replace(/<p><\/p>/g, '');
};

export const copyUrl = (url, msg = 'URL Copied') => {
    navigator.clipboard.writeText(url).then(() => {
        enqueueSnackbar(msg, { variant: 'success', key: 'copied-code' });
    });
};


export const splitTextWithHyphens = (text: string, maxCharsPerLine: number = 40) => {
    const words = text.split(' ');
    const lines = [];
    let currentLine = '';

    words.forEach((word) => {
        if (word.length > maxCharsPerLine) {
            const parts = word.match(new RegExp(`.{1,${maxCharsPerLine - 1}}`, 'g'));
            parts.forEach((part, index) => {
                if (index === parts.length - 1) {
                    currentLine += part;
                } else {
                    currentLine += part + '-';
                    lines.push(currentLine);
                    currentLine = '';
                }
            });
        } else {
            if (currentLine.length + word.length + 1 > maxCharsPerLine) {
                lines.push(currentLine);
                currentLine = word;
            } else {
                if (currentLine.length > 0) {
                    currentLine += ' ' + word;
                } else {
                    currentLine = word;
                }
            }
        }
    });

    if (currentLine.length > 0) {
        lines.push(currentLine);
    }

    return lines.join(' ');
};
