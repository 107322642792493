import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Autocomplete, IconButton, Menu, MenuItem, useMediaQuery } from '@mui/material';
import { contactStatusOptions } from 'components/client-leads-table/ExpandedRow';
import MDInput from 'components/themed/MDInput';
import { enqueueSnackbar } from 'notistack';
import React, { useEffect, useMemo, useState } from 'react';
import { useGetChatRoomDetailsQuery } from 'redux/services/spotdif/chatToken';
import { useReportLeadMutation } from 'redux/services/spotdif/leads';

const MENU_ITEM_HEIGHT = 48;

const options = ['Jump to Lead', 'Close this lead'];

interface ILeadActionProps {
    chatRoomId?: string;
}

const LeadActions: React.FC<ILeadActionProps> = ({ chatRoomId }) => {
    const isMobile = useMediaQuery('(max-width:768px)');

    const { contactStatus, clientNotes, chatRoomDetails } = useGetChatRoomDetailsQuery(chatRoomId, {
        selectFromResult: ({ data, isLoading }) => {
            return {
                data: data,
                clientNotes: data?.data?.clientNotes,
                contactStatus: isLoading ? '' : data?.data?.contactStatus ?? '',
                chatRoomDetails: data?.data,
                isLoading: isLoading,
            };
        },
    });
    const [contactStatusLocal, setContactStatusLocal] = useState(contactStatus);

    useEffect(() => {
        setContactStatusLocal(contactStatus);
        return () => {
            setContactStatusLocal('');
        };
    }, [contactStatus]);

    const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);
    const [leadStatusAnchor, setLeadStatusAnchor] = useState<null | HTMLElement>(null);

    const isMenuOpen = Boolean(menuAnchor);

    const [reportLead] = useReportLeadMutation();

    const isLeadFixed = useMemo<boolean>(
        () =>
            chatRoomDetails?.status === 'Report Accepted' ||
            chatRoomDetails?.status === 'Report Rejected' ||
            chatRoomDetails?.status === 'Reported',
        [chatRoomDetails?.status],
    );

    const handleLeadStatusClose = (selectedOption: string) => {
        // Pass the selected option to handle it
        reportLead({ leadId: chatRoomDetails._id, contactStatus: selectedOption, clientNotes: clientNotes })
            .unwrap()
            .then((payload) => {
                enqueueSnackbar(`Contact status updated Successfully`, {
                    variant: 'success',
                    key: 'contact-status',
                });
                // setExpanded({});
            })
            .catch(({ data }) => {
                enqueueSnackbar(data.error.message, { variant: 'error', key: 'contact-status' });
            });

        setLeadStatusAnchor(null);
    };

    const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
        setMenuAnchor(event.currentTarget);
    };

    const closeMenu = (selectedOption: string) => {
        // Pass the selected option to handle it
        setMenuAnchor(null);
    };

    return (
        <>
            {isMobile ? (
                <div className="d-flex contact-main">
                    <div className="contract">
                        <p className="contact-status-label">Contact Status</p>
                        <Autocomplete
                            className="autocomplete-field contact-options"
                            disableClearable
                            value={contactStatusLocal}
                            options={contactStatusOptions}
                            onChange={(event, value) => {
                                handleLeadStatusClose(value);
                                return setContactStatusLocal(value);
                            }}
                            renderInput={(params) => (
                                <MDInput {...params} fullWidth placeholder="Select a contact status" />
                            )}
                            style={{
                                cursor: isLeadFixed ? 'not-allowed' : 'pointer',
                            }}
                            disabled={isLeadFixed}
                        />
                    </div>
                    <div className="w-100 mx-auto d-flex justify-content-center">
                        <IconButton
                            aria-label="more"
                            id="long-button"
                            aria-controls={isMenuOpen ? 'long-menu' : undefined}
                            aria-expanded={isMenuOpen ? 'true' : undefined}
                            aria-haspopup="true"
                            onClick={handleMenuClick}
                            className="w-100"
                        >
                            <MoreVertIcon />
                        </IconButton>
                        <Menu
                            id="long-menu"
                            MenuListProps={{
                                'aria-labelledby': 'long-button',
                            }}
                            anchorEl={menuAnchor}
                            open={isMenuOpen}
                            onClose={closeMenu}
                            PaperProps={{
                                style: {
                                    maxHeight: MENU_ITEM_HEIGHT * 4.5,
                                    width: '20ch',
                                },
                            }}
                        >
                            {options.map((option) => (
                                <MenuItem key={option} selected={option === 'Pyxis'} onClick={() => closeMenu(option)}>
                                    {option}
                                </MenuItem>
                            ))}
                        </Menu>
                    </div>
                </div>
            ) : (
                <>
                    <div className="d-flex contact-main">
                        <div className="contract">
                            <p className="ml-1 contact-status-label">Contact Status</p>
                            <Autocomplete
                                className="autocomplete-field contact-options"
                                disableClearable
                                value={contactStatusLocal}
                                options={contactStatusOptions}
                                onChange={(event, value) => {
                                    handleLeadStatusClose(value);
                                    return setContactStatusLocal(value);
                                }}
                                renderInput={(params) => (
                                    <MDInput {...params} fullWidth placeholder="Select a contact status" />
                                )}
                                style={{
                                    cursor: isLeadFixed ? 'not-allowed' : 'pointer',
                                }}
                                disabled={isLeadFixed}
                            />
                        </div>
                        <div className="w-100 mx-auto d-flex justify-content-center">
                            <IconButton
                                aria-label="more"
                                id="long-button"
                                aria-controls={isMenuOpen ? 'long-menu' : undefined}
                                aria-expanded={isMenuOpen ? 'true' : undefined}
                                aria-haspopup="true"
                                onClick={handleMenuClick}
                                className="w-100"
                            >
                                <MoreVertIcon />
                            </IconButton>
                            <Menu
                                id="long-menu"
                                MenuListProps={{
                                    'aria-labelledby': 'long-button',
                                }}
                                anchorEl={menuAnchor}
                                open={isMenuOpen}
                                onClose={closeMenu}
                                PaperProps={{
                                    style: {
                                        maxHeight: MENU_ITEM_HEIGHT * 4.5,
                                        width: '20ch',
                                    },
                                }}
                            >
                                {options.map((option) => (
                                    <MenuItem
                                        key={option}
                                        selected={option === 'Pyxis'}
                                        onClick={() => closeMenu(option)}
                                    >
                                        {option}
                                    </MenuItem>
                                ))}
                            </Menu>
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

export default LeadActions;
