import { jsonArrayMember, jsonMember, jsonObject, TypedJSON } from 'typedjson';
import { BaseDataModel } from './BaseDataModel';
import { DaySchedule, sanitizeWeeklySchedule } from './DaySchedule';
import { PostCodeTargetingList, PostCodeTargetingListSerializer } from 'contracts/spotdif/PostCodeTargetingList';
import { ILeadSettingsRequest } from 'contracts/requests/ILeadSettingsRequest';
import { IHasDailySchedule } from 'contracts/view-models/IHasDailySchedule';

@jsonObject()
class Criteria {
    @jsonMember(String)
    criteriaType: string;

    @jsonMember(String)
    condition: string;
}

@jsonObject()
export class PostCodePair {
    @jsonMember(String)
    miles: string;

    @jsonMember(String)
    postcode: string;
}

export function isPostCodePair(obj: any): obj is PostCodePair {
    return (
        obj?.postcode !== undefined ||
        obj?.miles !== undefined ||
        typeof obj?.postcode === 'string' ||
        typeof obj?.miles === 'string'
    );
}

@jsonObject()
export class LeadDetails extends BaseDataModel implements IHasDailySchedule, ILeadSettingsRequest {
    @jsonMember(Number)
    __v: number;

    @jsonMember(String)
    userId: string;

    @jsonMember(Number)
    total: number;

    @jsonMember(Number)
    daily: number;

    @jsonMember(Number)
    leadCost: number;

    @jsonMember(Number)
    weekly: number;

    @jsonMember(Number)
    monthly: number;

    @jsonMember(String)
    leadAlertsFrequency: string;

    @jsonMember(String)
    zapierUrl: string;

    @jsonMember(Boolean)
    sendDataToZapier: boolean;

    @jsonMember(Number)
    smsPhoneNumber: number;

    @jsonArrayMember(Criteria)
    criteria?: Criteria[];

    @jsonMember(String)
    message?: string;

    @jsonArrayMember(() => PostCodeTargetingList, {
        deserializer(json, params) {
            if (Array.isArray(json)) {
                return json.map((v) => PostCodeTargetingListSerializer.parse(v));
            } else {
                return [];
            }
        },
    })
    postCodeTargettingList?: PostCodeTargetingList[]; // TODO: FIX TYPO

    @jsonMember(String, {
        deserializer: (value: unknown) => {
            if (value === 'radius') {
                return 'radius';
            } else {
                return 'map';
            }
        },
    })
    type: 'radius' | 'map';

    @jsonMember(String)
    postcode?: string;

    @jsonMember(String)
    miles?: string;

    @jsonMember(Boolean)
    isRadiusPostCode: boolean;

    @jsonArrayMember(PostCodePair)
    postCodeList?: PostCodePair[];

    @jsonArrayMember(DaySchedule, {
        deserializer: sanitizeWeeklySchedule,
    })
    leadSchedule: DaySchedule[];

    get schedule(): DaySchedule[] {
        return this.leadSchedule;
    }

    getValue(propName: string) {
        return this[propName] ?? ('' as string);
    }
}

export const LeadDetailsSerializer = new TypedJSON(LeadDetails);
