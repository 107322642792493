import { jsonMember, jsonObject } from 'typedjson';
import { ClientStatus } from './GetTableResponseDataObject';

@jsonObject()
export class ColumnsDataObject {
    @jsonMember(String)
    _id: string;

    @jsonMember(String)
    originalName: string;

    @jsonMember(String)
    displayName: string;

    @jsonMember(Boolean)
    isVisible: boolean;

    @jsonMember(Number)
    index: number;

    @jsonMember(Boolean)
    isActive: boolean;

    @jsonMember(String)
    clientStatus?: ClientStatus;

    @jsonMember(Boolean)
    isDeleted?: boolean;

    @jsonMember(Number)
    onBoardingPercentage?: number;

    @jsonMember(String)
    originalNameActive?: string;

    @jsonMember(String)
    displayNameActive?: string;
}
