import dayjs from 'dayjs';
import { enqueueSnackbar } from 'notistack';
import React, { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import { useUserPaymentSessionQuery } from 'redux/services/spotdif/creditAndBilling';

const SESSION_ID = 'client_ps_ref' as const;
const SESSSION_WAITING_SINCE = 'cleint_ps_wait' as const;
const PAYMENT_METHODS = 'client_ps_methods' as const;

// Define the types for context values
type SessionHandler = {
    isChecking: boolean;
    sessionId: string;
    setSessionId: (value: string) => void;
    waitingSince: dayjs.Dayjs;
    setSession: (value: string) => void;
    setPaymentMethod: (value: string) => void;
    paymentMethods: string;
};

// Create context
const SessionHandlerContext = createContext<SessionHandler>(null);

interface ISessionHandlerProviderProps {
    children: React.ReactNode;
}

// Custom Provider component
const SessionHandlerProvider: React.FC<ISessionHandlerProviderProps> = ({ children }) => {
    const [sessionId, setSessionId] = useState<string>(null);
    const [paymentMethods, setPaymentMethods] = useState<string>(null);
    const [waitingSince, setWaitingSince] = useState<dayjs.Dayjs>();

    // Create a memoized value for isChecking based on sessionId
    const isChecking = useMemo(() => !!sessionId, [sessionId]);

    // Use RTK Query to fetch data from the API every 20 seconds
    const { data: paymentSession } = useUserPaymentSessionQuery(
        { sessionId, paymentMethods },
        {
            skip: !isChecking,
            pollingInterval: 20000,
        },
    );

    // Method to set the sessionId
    const setSession = (sessionId) => {
        if (sessionId !== undefined) {
            setSessionId(sessionId);
        } else {
            setSessionId(null);
        }
        setWaitingSince(sessionId ? dayjs() : null);
    };

    const setPaymentMethod = useCallback(
        (paymentMethodId) => {
            if (paymentMethodId !== undefined) {
                setPaymentMethods(paymentMethodId);
            } else {
                setPaymentMethods(null);
            }
        },
        [setPaymentMethods],
    );

    useEffect(() => {
        const sessionID = localStorage.getItem(SESSION_ID);
        const wait = localStorage.getItem(SESSSION_WAITING_SINCE);
        const paymentMethod = localStorage.getItem(PAYMENT_METHODS);
        setSessionId(sessionID === 'null' ? null : sessionID);
        setPaymentMethods(sessionID === 'null' ? null : paymentMethod);
        setWaitingSince(!!wait && wait !== 'null' ? dayjs(wait) : null);
    }, []);

    useEffect(() => {
        localStorage.setItem(SESSION_ID, sessionId);
        localStorage.setItem(PAYMENT_METHODS, paymentMethods);
        localStorage.setItem(SESSSION_WAITING_SINCE, waitingSince?.toISOString() ?? '');
    }, [waitingSince, sessionId]);

    useEffect(() => {
        if (paymentSession?.data?.status === 'Captured' || paymentSession?.data?.status === 'succeeded') {
            setSession(null); // Clear sessionId
            enqueueSnackbar('Your payment was successful, please refresh in few minutes to see your new balance', {
                variant: 'success',
            });
        }
        if (paymentSession?.data?.last_payment_error) {
            setSession(null);
            enqueueSnackbar('Something went Wrong', { variant: 'error' });
        }
        // paymentSession.status === 'Approved' || 'captured' isChecking = false.
    }, [paymentSession]);

    useEffect(() => {
        if (paymentSession?.data?.next_action?.redirect_to_url?.url) {
            window.open(paymentSession?.data?.next_action?.redirect_to_url?.url, '_self');
            return;
        }
    }, [paymentSession]);

    return (
        <SessionHandlerContext.Provider
            value={{
                sessionId,
                paymentMethods,
                isChecking,
                setSessionId: setSession,
                setPaymentMethod,
                waitingSince,
                setSession,
            }}
        >
            {children}
        </SessionHandlerContext.Provider>
    );
};

export { SessionHandlerContext, SessionHandlerProvider };
