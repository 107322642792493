import MDBox from 'components/themed/MDBox';
import { usePostalCodeContext } from 'context/PostalCode/CountyContext';
import { LeadDetails } from 'contracts/spotdif/LeadDetails';
import { geoCentroid } from 'd3-geo';
import { useFormikContext } from 'formik';
import { useCountyData } from 'hooks/useCountyData';
import React, { useCallback, useState } from 'react';
import { ComposableMap, Geographies, Geography, Marker, ZoomableGroup } from 'react-simple-maps';
import { Tooltip as ReactTooltip } from 'react-tooltip';

const PROJECTION_CONFIG = {
    scale: 900,
    center: [-1.685276985168343, 55.86875152587896], // always in [East Latitude, North Longitude]
};

const DEFAULT_COLOR = '#D9EAD3';

const geographyStyle = {
    default: {
        outline: '#fff',
        stroke: '#607D8B',
        strokeWidth: 0.15,
    },
    hover: {
        fill: '#ff4e02',
        // transition: 'all 250ms',
        outline: '#fff',
        stroke: '#607D8B',
        strokeWidth: 0.15,
    },
    pressed: {
        fill: '#ff4e02',
        outline: '#fff',
        stroke: '#607D8B',
        strokeWidth: 0.15,
    },
};

interface IPostalCodePickerProps {
    field: string;
}

const PostalCodePicker: React.FC<IPostalCodePickerProps> = ({ field }) => {
    const { values, setFieldValue, setFieldTouched } = useFormikContext<Partial<LeadDetails>>();
    const selectedCounties = values[field] || [];
    const { updateCurrentCounty } = usePostalCodeContext();

    const { ukCounties, irelandCounties, availableCountyPostalCodes } = useCountyData();
    const [tooltipContent, setTooltipContent] = useState('');
    const [labelSize, setLabelSize] = useState(3);
    const [position, setPosition] = useState({
        coordinates: [-3.685276985168343, 55.86875152587896],
        zoom: 1,
    });

    const handleZoomIn = (e) => {
        e.preventDefault();
        if (position.zoom >= 8) return;
        setPosition((pos) => ({ ...pos, zoom: pos.zoom * 2 }));
        e.stopPropagation();
    };

    const handleZoomOut = (e) => {
        e.preventDefault();
        if (position.zoom <= 1) return;
        setPosition((pos) => ({ ...pos, zoom: pos.zoom / 2 }));
        e.stopPropagation();
    };

    const handleMoveEnd = (position) => {
        setPosition(position);
    };

    React.useEffect(() => {
        setLabelSize(() => (position.zoom > 3 ? 1 : 3));
    }, [position.zoom]);

    const onMouseEnter = (geo) => {
        return () => {
            setTooltipContent(`${geo.properties.name}`);
        };
    };

    const onMouseLeave = () => {
        setTooltipContent('');
    };

    const setPostalCodes = useCallback(
        (postalCodes, county, key) => {
            let countyIndex = selectedCounties.findIndex(
                (singleCounty) => singleCounty.county === county && singleCounty.key === key,
            ); // && singleCounty.key === key
            updateCurrentCounty(county);
            if (countyIndex === -1) {
                selectedCounties.push({
                    county,
                    postalCode: postalCodes,
                    key,
                });
            } else {
                selectedCounties[countyIndex].postalCode = postalCodes;
            }
            setFieldTouched(field, true);
            setFieldValue(field, [...selectedCounties]);
        },
        [field, selectedCounties, setFieldValue, updateCurrentCounty, setFieldTouched],
    );

    const clickCountyGeography = (e, geo) => {
        if (geo?.properties?.POSTAL_CODES) {
            setPostalCodes(geo?.properties?.POSTAL_CODES, geo.properties.name, geo.properties.NAME_0);
        } else {
            setPostalCodes([], geo.properties.name, geo.properties.NAME_0);
        }
    };

    const checkGeoLabel = useCallback((geo) => {
        const allowedLabels = [
            'EF',
            // 'TR',
            'YZ',
            'HB',
            'SU',
            'LC',
            'HD',
            'BE',
            'HW',
            'EG',
            'NK',
            'RU',
            'XB',
            'GR',
            'CY',
            // 'WF',
            'YR',
            'HV',
            'HY',
            'NK',
            // 'BA',
            'LW',
            'LW',
            'WT',
            // 'WV',
            'HV',
            'HK',
            'WW',
            'SQ',
            'CI',
            'NT',
            'NH',
            'LT',
            'WH',
            'CN',
            'IT',
            'KC',
            'LN',
            'MT',
            'RT',
            'CP',
            'VG',
            'ZT',
            'NW',
            'BF',
            'TH',
            'EC',
            'WC',
            // 'SW',
            'HA',
            'UB',
            // 'W',
            'N',
            'SM',
            'IG',
        ];

        return allowedLabels.includes(geo.properties.LABEL) ? null : geo.properties.LABEL;
    }, []);

    // console.log(ukCounties, "> >>>>>>>>>>>>>> uk counties")
    // console.log(selectedCounties,">>>>>>>>>>>>> selected countiers")
    return (
        <>
            <div className="full-width-height container post-code-picker">
                <ReactTooltip>{tooltipContent}</ReactTooltip>
                <ComposableMap
                    projectionConfig={PROJECTION_CONFIG}
                    projection="geoMercator"
                    width={350}
                    height={320}
                    className={`composable-map`}
                    style={{
                        marginTop: 14,
                    }}
                    data-tip=""
                >
                    <ZoomableGroup
                        zoom={position.zoom}
                        center={position.coordinates}
                        onMoveEnd={(e) => handleMoveEnd(e)}
                    >
                        {[ukCounties, irelandCounties].map((countyGeography, countyIndex) => (
                            <Geographies key={`countyGeography--${countyIndex}`} geography={countyGeography}>
                                {({ geographies }) => (
                                    <>
                                        {geographies.map((geo) => {
                                            // console.log(geo, ">>>>>>>>>geo")
                                            let selectedCountyValues = selectedCounties.find(({ county, key }) => {
                                                // return county === geo.properties.name}
                                                return county === geo.properties.name && key === geo.properties.NAME_0;
                                            });

                                            const isCountySelected = !!selectedCountyValues;
                                            const isFullySelected =
                                                availableCountyPostalCodes[
                                                    `${geo.properties.NAME_0}.${geo.properties.name}`
                                                ]?.length === selectedCountyValues?.postalCode?.length;

                                            return (
                                                <Geography
                                                    key={`ge-${geo.rsmKey}`}
                                                    geography={geo}
                                                    fill={
                                                        isCountySelected
                                                            ? !isFullySelected
                                                                ? '#ff4e02'
                                                                : '#000032'
                                                            : DEFAULT_COLOR
                                                    }
                                                    stroke="#232323"
                                                    style={geographyStyle}
                                                    onClick={(e) => clickCountyGeography(e, geo)}
                                                    onMouseEnter={onMouseEnter(geo)}
                                                    onMouseLeave={onMouseLeave}
                                                />
                                            );
                                        })}

                                        {geographies.map((geo) => {
                                            const centroid = geoCentroid(geo);
                                            let selectedCountyValues = selectedCounties.find(
                                                ({ county }) =>
                                                    county === geo.properties.name &&
                                                    county.key === geo.properties.NAME_0,
                                                // ({ county }) => county === geo.properties.name
                                            );
                                            const isCountySelected = !!selectedCountyValues;

                                            const isHovered = tooltipContent.includes(geo.properties.name);

                                            return (
                                                <g key={geo.rsmKey} onClick={(e) => clickCountyGeography(e, geo)}>
                                                    <Marker coordinates={centroid} cursor="pointer">
                                                        <text
                                                            y="2"
                                                            fontSize={labelSize}
                                                            textAnchor="middle"
                                                            fill={isCountySelected ? DEFAULT_COLOR : '#000032'}
                                                            className={
                                                                isHovered || isCountySelected
                                                                    ? 'isSelected'
                                                                    : 'isNotSelected'
                                                            }
                                                        >
                                                            {checkGeoLabel(geo)}
                                                        </text>
                                                    </Marker>
                                                </g>
                                            );
                                        })}
                                    </>
                                )}
                            </Geographies>
                        ))}
                    </ZoomableGroup>
                </ComposableMap>

                <MDBox
                    sx={{
                        height: '110px',
                        width: '160px',
                        border: '1px solid #dcdde0',
                        top: {
                            xs: '14px',
                            md: '105px',
                        },
                        right: {
                            xs: '0px',
                            md: '26px',
                        },
                        position: 'absolute',
                        display: 'flex',
                        flexDirection: 'column',
                        borderRadius: '2%',
                    }}
                    className="map-legend"
                >
                    <MDBox sx={{ display: 'flex', pt: 1, gap: 1, pl: 1 }}>
                        <div className="colorBox" style={{ background: '#D9EAD3' }}></div>
                        <div className="text">Not Selected</div>
                    </MDBox>
                    <MDBox sx={{ display: 'flex', pt: 1, gap: 1, pl: 1 }}>
                        <div className="colorBox" style={{ background: '#ff4e02' }}></div>
                        <div className="text">Partially Selected</div>
                    </MDBox>
                    <MDBox sx={{ display: 'flex', pt: 1, gap: 1, pl: 1 }}>
                        <div className="colorBox" style={{ background: '#000032' }}></div>
                        <div className="text">Selected</div>
                    </MDBox>
                </MDBox>
                <div className="controls">
                    <button onClick={(e) => handleZoomIn(e)}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth="3"
                        >
                            <line x1="12" y1="5" x2="12" y2="19" />
                            <line x1="5" y1="12" x2="19" y2="12" />
                        </svg>
                    </button>
                    <button onClick={(e) => handleZoomOut(e)}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth="3"
                        >
                            <line x1="5" y1="12" x2="19" y2="12" />
                        </svg>
                    </button>
                </div>
            </div>
        </>
    );
};

export default PostalCodePicker;
