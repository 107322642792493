import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';

import './style.scss';

import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import OfferBanner from 'components/shared/OfferBanner';
import MDButton from 'components/themed/MDButton';
import { ECountry } from 'contracts/spotdif/currencyAndCountryCodes';
import useAuthentication from 'hooks/useAuthentication';
import { enqueueSnackbar } from 'notistack';
import React, { useCallback, useState } from 'react';

export interface IStripePaymentProcessorProps {
    modalOpen?: boolean;
}

const StripePaymentProcessor: React.FC<IStripePaymentProcessorProps> = ({ modalOpen }) => {
    const { user } = useAuthentication();

    const stripe = useStripe();
    const elements = useElements();
    const [loading, setLoading] = useState(false);
    // const [errorMessage, setErrorMessage] = useState(null);

    const getUserCountry = useCallback(() => {
        if (user?.country === ECountry.US) {
            return 'US';
        } else {
            return 'GB';
        }
    }, [user]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);

        if (!stripe || !elements) {
            // Stripe.js hasn't yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        const { error } = await stripe.confirmSetup({
            //`Elements` instance that was used to create the Payment Element
            elements,
            confirmParams: {
                return_url: `${process.env.REACT_APP_API_SERVER}/api/v1/cardDetails/strip-return-url`,
            },
        });

        if (error) {
            // setErrorMessage(error.message);
            enqueueSnackbar(error.message, { variant: 'error', key: 'stripe-error' });
            setLoading(false);
        } else {
            setLoading(true);
            // Your customer will be redirected to your `return_url`. For some payment
            // methods like iDEAL, your customer will be redirected to an intermediate
            // site first to authorize the payment, then redirected to the `return_url`.
            enqueueSnackbar('Success', { variant: 'success', key: 'stripe-error' });
            setLoading(false);
        }
    };

    const handleLimitedTimeOfferClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        // Change here
        // Prevent the default form submission when the "Limited time offer" button is clicked
        event.preventDefault();
        // Add any additional logic for the limited time offer here
    };

    return (
        <form
            onSubmit={handleSubmit}
            className={`${modalOpen ? 'modalMainWrapper bg-white' : 'modalMainWrapper bg-white large width'}`}
        >
            <PaymentElement
                options={{
                    defaultValues: {
                        billingDetails: {
                            address: {
                                country: getUserCountry(),
                            },
                        },
                    },
                }}
            />
            {/* <button disabled={!stripe}>Submit</button> */}
            <MDButton
                className={`button mt-10 ${
                    modalOpen ? 'spotdif-normal-Button' : 'button modalBtn spotdif-normal-Button largeButton'
                }`}
                type="button"
                fullWidth
                onClick={handleSubmit}
                isLoading={loading}
                disabled={!stripe || loading}
            >
                Save
            </MDButton>
            {!user?.hasOnboarded && (
                <OfferBanner
                    icon={<AutoAwesomeIcon className="offer-icon-sparkle" />}
                    title="<b>Get 5 FREE</b> leads when you top up your account with 10 today"
                    buttonText="Limited time offer only"
                    onButtonClick={handleLimitedTimeOfferClick}
                />
            )}
            {/* {errorMessage && <div>{errorMessage}</div>} */}
        </form>
    );
};

export default StripePaymentProcessor;
