import UserOnboardingStepper from 'components/user-account-details/UserOnboardingStepper';
import UserOnboardingContextProvider, { useUserOnboardingContext } from 'context/UserOnboarding';

import useAuthentication from 'hooks/useAuthentication';
import usePageName from 'hooks/usePageName';
import React from 'react';
import { Helmet } from 'react-helmet';
import { Navigate, useLocation } from 'react-router-dom';

const UserOnboarding: React.FC = () => {
    return (
        <UserOnboardingContextProvider>
            <OnboardingSetups />
        </UserOnboardingContextProvider>
    );
};

const OnboardingSetups = () => {
    const { user } = useAuthentication();
    const pageTitle = usePageName();

    const { pathname } = useLocation();

    const { restrictJump } = useUserOnboardingContext();

    return user.hasOnboarded ? (
        <Navigate to="/dashboard" />
    ) : (!restrictJump || pathname === user.onboardingRoute) && user.allowedOnboardingRoute.includes(pathname) ? (
        <>
            <Helmet>
                <title>Account Onboarding - {pageTitle}</title>
            </Helmet>
            <UserOnboardingStepper />
        </>
    ) : (
        <Navigate to={user.onboardingRoute} />
    );
};

export default UserOnboarding;
