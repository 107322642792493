import { Grid } from '@mui/material';
import LeadCostChart from 'components/analytics/lead-charts/lead-cost';
import LeadVolumeChart from 'components/analytics/lead-charts/lead-volume';
import Stats from 'components/analytics/lead-charts/stats';

import './style.scss';
import ErrorBoundary from 'components/shared/ErrorBoundary';
import MDBox from 'components/themed/MDBox';
import { DashboardStatsContextProvider } from 'context/DashboadStatsContext';
import AccountManagerStats from 'pages/AccountManagerStats';
import React from 'react';
import { Helmet } from 'react-helmet';

const AdminDashboard: React.FC = () => {
    return (
        <>
            <DashboardStatsContextProvider>
                <Helmet>
                    <title>Admin</title>
                </Helmet>

                <AccountManagerStats />
                <MDBox className="layout-middle">
                    <Grid container className="chart-container">
                        <Grid item xs={12} md={6} className="chart-wrapper">
                            <ErrorBoundary>
                                <LeadCostChart />
                            </ErrorBoundary>
                        </Grid>
                        <Grid item xs={12} md={6} className="chart-wrapper">
                            <ErrorBoundary>
                                <LeadVolumeChart />
                            </ErrorBoundary>
                        </Grid>
                    </Grid>
                </MDBox>
                <MDBox className="layout-middle">
                    <Grid container className="chart-container">
                        <Grid item xs={12} md={12}>
                            <ErrorBoundary>
                                <Stats />
                            </ErrorBoundary>
                        </Grid>
                    </Grid>
                </MDBox>
            </DashboardStatsContextProvider>
        </>
    );
};

export default AdminDashboard;
