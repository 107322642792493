import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import {
    CHAT_API_REDUCER_KEY as CHAT_REDUCER_PATH,
    SPOTDIFF_API_REDUCER_KEY as SPOTDIFF_REDUCER_PATH,
} from 'contracts/reduxResourceTags';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import tokenExpirationMiddleware from 'utils/tokenExpirationMiddleware';
import rootReducer from './reducers';
import { chatSystemsApi } from './services/chat';
import { spotdifApi } from './services/spotdif';

import authSliceReducer from './slices/auth';
import chatSystemsApiReducer from './slices/chat';
import postcodesReducer from './slices/postcode';

export const store = configureStore({
    reducer: {
        rootReducer,
        auth: authSliceReducer,
        chatCore: chatSystemsApiReducer,
        postcodes: postcodesReducer,
        [SPOTDIFF_REDUCER_PATH]: spotdifApi.reducer,
        [CHAT_REDUCER_PATH]: chatSystemsApi.reducer,
    },
    middleware: (getDefaultMiddleware) => [
        ...getDefaultMiddleware().concat([spotdifApi.middleware, chatSystemsApi.middleware]),
        tokenExpirationMiddleware,
    ],
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
