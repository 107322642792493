import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { Tooltip } from '@mui/material';
import { flexRender, useReactTable } from '@tanstack/react-table';
import { createColumnHelper, getCoreRowModel } from '@tanstack/table-core';
import ExpiredLinks from 'assets/images/icons/report-accepted.png';
import validLinks from 'assets/images/icons/valid-leads.png';
import AuthorisedUsage from 'components/app-permission/AuthorisedUsage';
import { MODULE, PERMISSIONS } from 'components/app-permission/permission';
import BasicModal from 'components/core/Modal';
import AlertPopUp from 'components/shared/AlertPopUp';
import SpinningComponent from 'components/shared/SpinningComponent';
import MDBadge from 'components/themed/MDBadge';
import { GetAccountManagerDropdownDataObject } from 'contracts/spotdif/AccountManager';
import { GetBusinessIndustryDropdownDataObject } from 'contracts/spotdif/BusinessIndustry';
import { FreeCreditUser, GetFreeCreditsLinkResponseData } from 'contracts/spotdif/GetFreeCreditsLinkResponseData';
import useToggle from 'hooks/useToggle';
import { enqueueSnackbar } from 'notistack';
import React, { useCallback, useMemo, useState } from 'react';
import { useDeletePromoLinkMutation, useExpirePromolinkMutation } from 'redux/services/spotdif/promoLink';
import { copyUrl, timeFormatter } from 'utils/text-helpers';
import PromoLinkResponsiveTable from './PromoLinkResponsiveTable';

import GeneratePromoLinkContentModal from './GeneratePromoLinkContentModal';
import './style.scss';
import SignedUpUserSection from './SignedUpUserSection';

interface TableProps {
    promoLinksData: GetFreeCreditsLinkResponseData[];
    loading?: boolean;
    accountManagerList?: Array<GetAccountManagerDropdownDataObject>;
    businessIndustries?: Array<GetBusinessIndustryDropdownDataObject>;
}

const columnHelper = createColumnHelper<GetFreeCreditsLinkResponseData>();

const PromoTable: React.FC<TableProps> = ({
    promoLinksData,
    loading = false,
    accountManagerList,
    businessIndustries,
}) => {
    const [isUserPopupOpen, toggleUserPopup] = useToggle(false);
    const [selectedPromoUsers, setSelectedPromoUsers] = useState<FreeCreditUser[]>([]);
    const [showDeletePopup, setShowDeletePopup] = useState(false);
    const [showPopUp, setShowPopUp] = useState(false);
    const [promoLinkID, setPromoLinkID] = useState('');
    const [expirePromoLinkTrigger] = useExpirePromolinkMutation();
    const [deletePromoLinkTrigger] = useDeletePromoLinkMutation();
    const [rowData, setRowData] = useState(null);
    const [openEditPromolinkContentPopup, setOpenEditPromolinkContentPopup] = useState(false);

    const openUserPopup = useCallback(
        (users: FreeCreditUser[]) => {
            toggleUserPopup();
            setSelectedPromoUsers(users);
        },
        [isUserPopupOpen, setSelectedPromoUsers],
    );

    const handleCloseDeletePopup = useCallback(() => {
        setShowDeletePopup(false);
        setPromoLinkID('');
    }, [promoLinkID, showDeletePopup]);

    const handleClosePopup = useCallback(() => {
        setShowPopUp(false);
        setPromoLinkID('');
    }, [showPopUp, promoLinkID]);

    const handleModalAction = useCallback(
        (action, successMsg) => async (id) => {
            try {
                await action(id);
                enqueueSnackbar(successMsg, { variant: 'success', key: successMsg.toLowerCase().replace(' ', '-') });
                handleClosePopup();
                handleCloseDeletePopup();
            } catch (error) {
                enqueueSnackbar('Something went wrong', { variant: 'error', key: 'error-promo' });
            }
        },
        [handleCloseDeletePopup, handleClosePopup],
    );

    const destroyedLink = handleModalAction(expirePromoLinkTrigger, 'Expired Successfully');
    const deletePromoLinkPermanently = handleModalAction(deletePromoLinkTrigger, 'Deleted Successfully');

    const handleClosePromolinkContentEditPopup = useCallback(() => {
        setOpenEditPromolinkContentPopup(false);
        setPromoLinkID('');
    }, [setOpenEditPromolinkContentPopup, setPromoLinkID]);

    const columns = useMemo(
        () => [
            columnHelper.accessor('isDisabled', {
                header: () => <span className="ml-3">Status</span>,
                footer: (info) => info.column.id,
                id: 'status',
                cell: (info) => (
                    <div className="table_col ml-3">
                        <Tooltip title={info.getValue() ? 'Expired' : 'Live'} placement="top">
                            <img
                                src={info.getValue() ? ExpiredLinks : validLinks}
                                alt={info.getValue() ? 'expired' : 'valid'}
                            />
                        </Tooltip>
                    </div>
                ),
            }),
            columnHelper.accessor('isDisabled', {
                header: () => (
                    <AuthorisedUsage module={MODULE.PROMO_LINKS} permission={PERMISSIONS.UPDATE}>
                        {' '}
                        <span className="ml-3">Action</span>{' '}
                    </AuthorisedUsage>
                ),
                footer: (info) => info.column.id,
                id: 'action',
                cell: (info) => (
                    <div className="table_col ml-1 pt-0">
                        <div className="d-flex gap align-items-center">
                            <AuthorisedUsage module={MODULE.PROMO_LINKS} permission={PERMISSIONS.UPDATE}>
                                {info.getValue() ? (
                                    <div style={{ cursor: 'not-allowed' }} className="expire-link">
                                        <Tooltip title="Already Expired" placement="top">
                                            <ErrorOutlineIcon style={{ color: '#19cbb1' }} />
                                        </Tooltip>
                                    </div>
                                ) : (
                                    <div
                                        onClick={(e) => {
                                            setShowPopUp(true);
                                            setPromoLinkID(info.row.original._id);
                                        }}
                                        className="expire-link"
                                    >
                                        <Tooltip title="Expire this link" placement="top">
                                            <HighlightOffIcon />
                                        </Tooltip>
                                    </div>
                                )}
                            </AuthorisedUsage>

                            <AuthorisedUsage module={MODULE.PROMO_LINKS} permission={PERMISSIONS.DELETE}>
                                <div
                                    onClick={(e) => {
                                        setShowDeletePopup(true);
                                        setPromoLinkID(info.row.original._id);
                                    }}
                                    className="trash-icon-red"
                                >
                                    <Tooltip title="Delete" placement="top">
                                        <DeleteIcon />
                                    </Tooltip>
                                </div>
                            </AuthorisedUsage>
                            <AuthorisedUsage module={MODULE.PROMO_LINKS} permission={PERMISSIONS.UPDATE}>
                                <div className="edit-icon cursor-pointer mr-3">
                                    <EditIcon
                                        onClick={(e) => {
                                            setOpenEditPromolinkContentPopup(true);
                                            setRowData(info.row.original);
                                            setPromoLinkID(info.row.original._id);
                                        }}
                                    />
                                </div>
                            </AuthorisedUsage>
                        </div>
                    </div>
                ),
            }),

            columnHelper.accessor('name', {
                header: () => <span className="m-3">Name</span>,
                cell: (info) => <div className="m-3 ">{info.getValue() !== undefined ? info.getValue() : '-'}</div>,
                footer: (info) => info.column.id,
            }),

            columnHelper.accessor('code', {
                header: () => <span className="m-3">Promo Link</span>,
                cell: (info) =>
                    (
                        <span className="m-3 text-align-left parent-copy-url">
                            <span className="copy-url p-04">{`.../sign-up?code=${info.getValue()}`}</span>
                            <ContentCopyOutlinedIcon
                                onClick={() => {
                                    const encodedIndustryId = info.row.original.businessIndustryId
                                        ? encodeURIComponent(info.row.original.businessIndustryId)
                                        : '';
                                    const url = `${process.env.REACT_APP_API_SERVER}/sign-up?code=${info.getValue()}${
                                        encodedIndustryId ? `&utm=${encodedIndustryId}` : ''
                                    }`;
                                    copyUrl(url);
                                }}
                                className="copy-url-icon"
                            />
                        </span>
                    ) || '-',
                footer: (info) => info.column.id,
            }),
            columnHelper.accessor('giveCreditOnAddCard', {
                header: () => <span className="m-3">First Card Bonus</span>,
                cell: (info) => `${info.getValue() ? 'Enabled' : 'Disabled'}`,
                footer: (info) => info.column.id,
            }),
            columnHelper.accessor('firstCardBonusCredit', {
                header: () => <span className="m-3">First Card Credits</span>,
                cell: (info) => info.getValue() ?? '-',
                footer: (info) => info.column.id,
            }),
            columnHelper.accessor('discount', {
                header: () => <span className="m-3">Discount</span>,
                cell: (info) => info.getValue() ?? '-',
                footer: (info) => info.column.id,
            }),

            columnHelper.accessor('accountManager', {
                header: () => <span>Account Manager</span>,
                cell: (info) => `${info.getValue() !== undefined ? info.getValue() : '-'}`,

                footer: (info) => info.column.id,
            }),
            columnHelper.accessor('isCommission', {
                header: () => <span className="m-3">Commission Status</span>,
                cell: (info) => (
                    <span className="m-3">{info.getValue() === true ? 'Commission' : 'Non Commission' || '-'}</span>
                ),
            }),

            columnHelper.accessor('freeCredits', {
                header: () => <span className="m-3">Free Leads</span>,
                cell: (info) => <span className="m-3">{info.getValue() || '-'}</span>,

                footer: (info) => info.column.id,
            }),

            columnHelper.accessor('topUpAmount', {
                header: () => <span>Top Up Leads</span>,
                cell: (info) => `${info.getValue()}` || '-',
                footer: (info) => info.column.id,
            }),
            columnHelper.accessor('maxUseCounts', {
                header: () => <span className="m-3">Max Use Amount</span>,
                cell: (info) => <span className="m-3">{info.getValue() || '-'}</span>,
                footer: (info) => info.column.id,
            }),
            columnHelper.accessor('users', {
                header: () => <span>Users</span>,
                cell: (info) => {
                    return (
                        <>
                            <p className="users-count-wrapper">
                                {info.getValue()?.length || '-'}
                                {info.getValue()?.length > 0 && (
                                    <MDBadge
                                        color="info"
                                        sx={{ cursor: 'pointer' }}
                                        ml={2}
                                        badgeContent="View Users"
                                        onClick={() => {
                                            openUserPopup(info.getValue());
                                        }}
                                        container
                                    />
                                )}
                            </p>
                        </>
                    );
                },
                footer: (info) => info.column.id,
            }),
            columnHelper.accessor('createdAt', {
                header: () => <span className="m-3">Created At</span>,
                footer: (info) => info.column.id,
                cell: (info) =>
                    <span className="m-3">{timeFormatter(info.getValue(), 'DD/MM/YYYY, h:mm:ss A')}</span> || '-',
            }),
        ],
        [],
        // {dayjs(leadDetail.createdAt, 'DD / MM / YYYY').toString()}
    );

    const numberOfHeads = columns.length;

    const table = useReactTable({
        data: promoLinksData ?? [],
        columns,
        getCoreRowModel: getCoreRowModel(),
    });

    return (
        <div className=" client-leads-v2">
            <div className="table-v2-block promo-table-v2">
                <div className="promo-table table-list-scroller">
                    <table>
                        <thead>
                            {table.getHeaderGroups().map((headerGroup) => (
                                <tr key={headerGroup.id}>
                                    {headerGroup.headers.map((header) => (
                                        <th key={header.id}>
                                            {header.isPlaceholder
                                                ? null
                                                : flexRender(header.column.columnDef.header, header.getContext())}
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody>
                            {table.getRowModel().rows.map((row) => (
                                <>
                                    <tr>
                                        {row.getVisibleCells().map((cell) => (
                                            <td key={cell.id}>
                                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                            </td>
                                        ))}
                                    </tr>
                                </>
                            ))}
                            {loading && (
                                <tr className="">
                                    <td colSpan={12} className="text-align-center">
                                        Loading...
                                    </td>{' '}
                                </tr>
                            )}

                            {table.getRowModel().rows.length === 0 && !loading && (
                                <tr style={{ width: '100%' }}>
                                    <td colSpan={numberOfHeads}>
                                        <div className="no-data">No Data Found</div>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
            {/* For Mobile View */}
            <SpinningComponent loading={loading}>
                <div className="table-v2-block table-v2-responsive">
                    {table.getRowModel().rows.map((row) => {
                        return <PromoLinkResponsiveTable row={row} />;
                    })}
                </div>
            </SpinningComponent>

            <SignedUpUserSection
                isUserPopupOpen={isUserPopupOpen}
                toggleUserPopup={toggleUserPopup}
                selectedPromoUsers={selectedPromoUsers}
            />

            <BasicModal open={showPopUp} handleClose={handleClosePopup}>
                <AlertPopUp
                    fn={(promoLinkID) => destroyedLink(promoLinkID)}
                    handleCloseModal={handleClosePopup}
                    heading={`Are you sure?`}
                    subheading={`Do you want to expire this Promo Link? It cannot be undone.`}
                    buttonText="Yes"
                    value={promoLinkID}
                />
            </BasicModal>

            <BasicModal open={showDeletePopup} handleClose={handleCloseDeletePopup}>
                <AlertPopUp
                    fn={(promoLinkID) => deletePromoLinkPermanently(promoLinkID)}
                    handleCloseModal={handleCloseDeletePopup}
                    heading={`Are you sure?`}
                    subheading={`Do you want to delete this Promo Link? It cannot be undone.`}
                    buttonText="Yes"
                    value={promoLinkID}
                />
            </BasicModal>

            {openEditPromolinkContentPopup && (
                <GeneratePromoLinkContentModal
                    closePopup={handleClosePromolinkContentEditPopup}
                    onSave={() => {}}
                    accountManagerList={accountManagerList}
                    businessIndustries={businessIndustries}
                    promoLinkData={rowData}
                    isEditMode={true}
                />
            )}
        </div>
    );
};

export default PromoTable;
