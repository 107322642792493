import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import * as React from 'react';
import './style.scss';

interface IBasicModal {
    children?: any;
    button?: any;
    handleClose?: () => void;
    open?: boolean;
    width?: number;
    height?: number;
    postalCodeModal?: any;
}

export default function BasicModal({
                                       children,
                                       button,
                                       handleClose,
                                       open,
                                       width = 400,
                                       height,
                                       postalCodeModal,
                                   }: IBasicModal) {
    return (
        <>
            {/* {button} */}
            <Modal
                open={open}
                className="modalWrapper"
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className={postalCodeModal ? `boxModalStyles` : `boxModalStyles`}>{children}</Box>
            </Modal>
        </>
    );
}
