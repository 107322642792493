import reportRejected from 'assets/images/icons/decline.svg';
import reportAccepted from 'assets/images/icons/report-accepted.png';
import reportedLeads from 'assets/images/icons/reported-leads.png';

import validLeads from 'assets/images/icons/valid-leads.png';
import classNames from 'classnames';
import AuthorisedUsage from 'components/app-permission/AuthorisedUsage';
import { MODULE, PERMISSIONS } from 'components/app-permission/permission';
import HeaderCard from 'components/shared/Card/HeaderCard';
import Pagination from 'components/shared/Pagination/Pagination';

import './style.scss';
import SpinningComponent from 'components/shared/SpinningComponent';
import TableHeaderV2 from 'components/shared/TableHeaderV2';
import MDButton from 'components/themed/MDButton';

import { ILeadsLIstQuery } from 'contracts/requests/ILeadsListResponse';
import { LeadsBidDataSerializer } from 'contracts/spotdif/LeadsBidData';
import { enqueueSnackbar } from 'notistack';
import React, { useEffect, useMemo, useState } from 'react';
import { useGetAllClientsQuery } from 'redux/services/spotdif/clients';
import { useGetAllIndustiesLeadsQuery } from 'redux/services/spotdif/industriesLeads';
import { useExportLeadsMutation, useGetAdminLeadsStatsQuery, useGetAllLeadsQuery } from 'redux/services/spotdif/leads';
import { useGetAllUsersQuery } from 'redux/services/spotdif/user';
import Table from './Table';
import { leadsAvailableStatuses } from 'contracts/app-utils/ELeadsStatus';

const LeadsTableV2: React.FC = () => {
    const [query, setQuery] = useState<ILeadsLIstQuery>({
        search: '',
        perPage: 100,
        sortingOrder: 'desc',
        leadsStatus: '',
        total: 0,
        pageCount: 0,
        page: 1,
        industry: '',
        accountManagerId: '',
    });

    const { adminLeadsData, adminSerializedData, leadsLoading } = useGetAllLeadsQuery(query, {
        selectFromResult: ({ data, isLoading }) => {
            return {
                adminLeadsData: data,
                adminSerializedData: data?.data?.map((item) => LeadsBidDataSerializer.parse(item)),
                leadsLoading: isLoading,
            };
        },
    });
    const { data: allClients } = useGetAllUsersQuery();
    const { statsData, statsLoading } = useGetAdminLeadsStatsQuery(null, {
        selectFromResult: ({ data, isLoading }) => {
            return {
                statsData: data?.data,
                statsLoading: isLoading,
            };
        },
    });
    const { data: businessIndustries } = useGetAllIndustiesLeadsQuery();
    const { availableAccessManagers, isLoadingAccessManager } = useGetAllClientsQuery(
        { showOnlyAccountManagers: true, accountManagerId: '' },
        {
            selectFromResult: ({ data, isLoading }) => {
                return {
                    availableAccessManagers: data?.data,
                    isLoadingAccessManager: isLoading,
                };
            },
        },
    );

    useEffect(() => {
        if (adminLeadsData?.meta) {
            setQuery({
                ...query,
                pageCount: adminLeadsData?.meta?.pages,
            });
        }
    }, [adminLeadsData?.meta]);

    const [exportLeads, { isLoading: exportLoading, error: exportError, isError: exportIsError }] =
        useExportLeadsMutation();

    const exportClients = () => {
        let filterUrl = `?&sortingOrder=${query.sortingOrder}&status=${query.leadsStatus}`;
        if (query.clientId) {
            filterUrl = `${filterUrl}&id=${query.clientId}`;
        }
        if (query.industry) {
            filterUrl = `${filterUrl}&industry=${query.industry}`;
        }
        if (query.accountManagerId) {
            filterUrl = `${filterUrl}&accountManagerId=${query.accountManagerId}`;
        }

        // const loadingToastKey = enqueueSnackbar('we are Exporting the data, It will take some while', {
        //     variant: 'default',
        //     persist: true,
        //     action: () => (
        //         <div style={{ display: 'flex', alignItems: 'center' }}>
        //             <CircularProgress size={24} style={{ marginRight: 12 }} />
        //         </div>
        //     ),
        // });

        exportLeads(filterUrl)
            .unwrap()
            .then((res) => {
                console.log('Res', res);
                enqueueSnackbar(res?.message ?? 'File mailed successfully', {
                    variant: 'success',
                    key: 'export-admin-lead',
                });
            })
            .catch((err) => {
                console.error('Err', err);
                // closeSnackbar(loadingToastKey);
                enqueueSnackbar('Error exporting data', { variant: 'error' });
            });
    };

    const renderStatusHeader = () => (
        <>
            <AuthorisedUsage module={MODULE.LEADS_CSV} permission={PERMISSIONS.READ}>
                <div
                    className={classNames('table_export_leads show-button', {
                        table_export_leads_disabled: leadsLoading,
                    })}
                >
                    <MDButton
                        onClick={exportClients}
                        className="button is-small w-button export-csv"
                        disabled={exportLoading}
                    >
                        Export Leads
                    </MDButton>
                </div>
            </AuthorisedUsage>
        </>
    );

    const updateLeadFilters = (key: string, value: string | number) => {
        setQuery({ ...query, [key]: value, page: 1 });
    };

    return (
        <>
            <SpinningComponent loading={leadsLoading}>
                <div className="layout-middle table-v2 leads-table-v2 client-leads-v2">
                    <AuthorisedUsage module={MODULE.LEADS} permission={PERMISSIONS.READ}>
                        <TableHeaderV2
                            clients={allClients}
                            leadsType={leadsAvailableStatuses}
                            updateFilters={updateLeadFilters}
                            entriesPerPage={[100, 50, 25, 10]}
                            filters={query}
                            status={renderStatusHeader()}
                            showSorting={true}
                            businessIndustries={businessIndustries?.data}
                            accountsManagers={availableAccessManagers}
                        />
                    </AuthorisedUsage>

                    <AuthorisedUsage module={MODULE.LEADS} permission={PERMISSIONS.READ}>
                        <div className="table-card-block leads-header-align">
                            <HeaderCard
                                icon={validLeads}
                                custClass="valid-leads"
                                value={statsData?.validLeads}
                                heading="Valid Leads"
                            />
                            <HeaderCard
                                icon={reportedLeads}
                                value={statsData?.reportedLeads}
                                custClass="reported-leads"
                                heading="Reported Leads"
                            />
                            <HeaderCard
                                icon={reportAccepted}
                                value={statsData?.reportAcceptedLeads}
                                custClass="report-accepted"
                                heading="Report Accepted"
                            />
                            <HeaderCard
                                icon={reportRejected}
                                value={statsData?.reportRejectedLeads}
                                custClass="report-rejected"
                                heading="Report Rejected"
                            />
                        </div>
                    </AuthorisedUsage>

                    <AuthorisedUsage module={MODULE.LEADS} permission={PERMISSIONS.READ}>
                        <Table
                            loading={leadsLoading}
                            adminSerializedData={adminSerializedData}
                            query={query}
                            setQuery={setQuery}
                            adminLeadsData={adminLeadsData}
                        />
                        {adminLeadsData?.meta && (
                            <Pagination
                                totalCount={adminLeadsData?.meta?.total}
                                currentPage={
                                    typeof adminLeadsData?.meta?.page === 'string'
                                        ? parseInt(adminLeadsData?.meta?.page)
                                        : adminLeadsData?.meta?.page * 1
                                }
                                pageSize={adminLeadsData?.meta?.perPage}
                                onPageChange={(page) => setQuery({ ...query, page })}
                            />
                        )}
                    </AuthorisedUsage>
                </div>
            </SpinningComponent>
        </>
    );
};

export default LeadsTableV2;
