import React from 'react';
import useAuthentication from 'hooks/useAuthentication';
import CurrencySymbol from 'components/shared/CurrencyFormat';

const RemainingLeadSection: React.FC = () => {
    const { user } = useAuthentication();
    return (
        <div className="available-credit-details">
            <span>
                {/* Remaining leads <i>{`${user?.credits && user?.leadCost > 0 ? Math.round(user?.credits / user?.leadCost) : 0}`}</i> */}
                Remaining leads <i>{user?.normalizedUserCredits}</i>
            </span>
            <span>
                {/* Your current CPL is &#163; <b>{user?.leadCost}</b> + VAT */}
                <CurrencySymbol currencyCode={user?.currency} />{' '}
                {Math.round((user?.credits * 100) / 100).toFixed(2) ?? '0'}
            </span>
            <span className="credit-balance">
                {/* &#163; {Math.round((user?.credits * 100) / 100).toFixed(2) ?? "0"} <StarIcon /> */}
                Your current CPL is <CurrencySymbol currencyCode={user?.currency} /> <b>{user?.leadCost}</b>{' '}
                {user?.currency !== 'EUR' && '+ VAT'}
            </span>
        </div>
    );
};

export default RemainingLeadSection;
