import dayjs from 'dayjs';
import React from 'react';

interface DateRendererProps {
    date: dayjs.Dayjs;
    fallback?: string;
}

const HumanDateRenderer: React.FC<DateRendererProps> = ({ date, fallback = '-' }) => {
    const isDateFromToday = date.isSame(dayjs(), 'day');

    return <>{date.isValid()
        ? isDateFromToday ? date.fromNow() : date.format('MMMM D, YYYY')
        : fallback
    }</>;
};

export default HumanDateRenderer;
