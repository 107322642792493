import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { CHAT_API_REDUCER_KEY } from 'contracts/reduxResourceTags';
import { TAG_CHAT_ROOMS } from 'contracts/spotDiffAPITags';
import { RootState } from '../../store';

export const chatSystemsApi = createApi({
    reducerPath: CHAT_API_REDUCER_KEY,
    tagTypes: [
        TAG_CHAT_ROOMS,
    ],
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_CHAT_SERVER_URL}/api/v1`,
        prepareHeaders: (headers, { getState }) => {
            const token = (getState() as RootState).chatCore.chatToken;
            if (token) {
                headers.set('authorization', `Bearer ${token}`);
            }
            return headers;
        },
    }),
    endpoints: (builder) => ({}),
});
