import DeleteIcon from '@mui/icons-material/Delete';
import classNames from 'classnames';
import BasicModal from 'components/core/Modal';
import AlertPopUp from 'components/shared/AlertPopUp';
import PrimarySwitch from 'components/shared/PrimarySwitch';
import { enqueueSnackbar } from 'notistack';
import React, { useCallback, useState } from 'react';
import { useChangeAdminStatusMutation, useDeleteSubAdminMutation } from 'redux/services/spotdif/sub-admin';
import toTitleCase from 'utils/text-helpers';

interface ResponsiveTableProps {
    row: any;
}

const ResponsiveTable: React.FC<ResponsiveTableProps> = ({ row }) => {
    const [showDeletePopup, setShowDeletePopup] = useState(false);

    const [subAdminID, setSubAdminID] = useState('');

    const handleClosePopup = () => {
        setShowDeletePopup(false);
        setSubAdminID('');
    };

    const [deleteSubAdminTrigger] = useDeleteSubAdminMutation();
    const [changeAdminStatusTrigger] = useChangeAdminStatusMutation();

    const deleteSubAdmin = useCallback(
        async (id: string) => {
            deleteSubAdminTrigger(id)
                .unwrap()
                .then(() => {
                    enqueueSnackbar('Deleted Successfully', { variant: 'success', key: 'deleted-subAdmin' });
                    handleClosePopup();
                })
                .catch(() => {
                    enqueueSnackbar('Failed to delete', { variant: 'error', key: 'deleted-subAdmin' });
                });
        },
        [deleteSubAdminTrigger],
    );

    const changeAdminStatus = useCallback(
        (id: string, currentStatus: boolean) => {
            const newStatus = !currentStatus;
            const data = {
                id,
                isActive: newStatus,
            };
            changeAdminStatusTrigger(data)
                .unwrap()
                .then(() => {
                    enqueueSnackbar('Status Changed Successfully', { variant: 'success', key: 'change-subAdmin' });
                })
                .catch(() => {
                    enqueueSnackbar('Failed to change status', { variant: 'error', key: 'change-subAdmin' });
                });
        },
        [changeAdminStatusTrigger],
    );

    return (
        <>
            <table className="table-v2-responsive-row admin-table-responsive">
                {[
                    {
                        label: 'Action',
                        content: (
                            <div className="table-col responsive-delete-icon">
                                <div className="delete-icon">
                                    <DeleteIcon
                                        onClick={() => {
                                            setShowDeletePopup(true);
                                            setSubAdminID(row.original._id);
                                        }}
                                    />
                                </div>
                            </div>
                        ),
                    },
                    {
                        label: 'First Name',
                        content: row.original.firstName,
                    },
                    {
                        label: 'Last Name',
                        content: row.original.lastName,
                    },
                    {
                        label: 'Email',
                        content: row.original.email,
                    },
                    {
                        label: 'Role',
                        content: toTitleCase(row.original.role),
                    },
                    {
                        label: 'Status',
                        content: (
                            <div className="table-col responsive-switch-icon">
                                <div className="delete-icon">
                                    <PrimarySwitch
                                        checked={row.original.isActive}
                                        onChange={(e) => {
                                            changeAdminStatus(row.original._id, row.original.isActive);
                                        }}
                                        className="responsive-switch"
                                    />
                                </div>
                            </div>
                        ),
                    },
                ].map((rowItem, index) => (
                    <tr key={index} id="table-row-admin">
                        <td id="table-data-admin" className="data-labels">
                            {rowItem.label}
                        </td>
                        <td
                            id="table-data-admin"
                            className={classNames({ 'break-all': rowItem.content && rowItem.content.length > 10 })}
                        >
                            {rowItem.content ? rowItem.content : '-'}
                        </td>
                    </tr>
                ))}
            </table>

            <BasicModal open={showDeletePopup} handleClose={handleClosePopup}>
                <AlertPopUp
                    fn={(subAdminID) => deleteSubAdmin(subAdminID)}
                    handleCloseModal={handleClosePopup}
                    heading={`Are you sure?`}
                    subheading={`Do you want to delete this admin. It cannot be undone.`}
                    buttonText="Yes"
                    value={subAdminID}
                />
            </BasicModal>
        </>
    );
};

export default ResponsiveTable;
