/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 PRO React TS Helper Functions
import pxToRem from 'assets/theme/functions/pxToRem';

// types
type Types = any;

const step: Types = {
    styleOverrides: {
        root: {
            padding: `0 ${pxToRem(6)}`,
        },
    },
};

export default step;
