import 'components/Inbox/style.scss';
import ChatSearch from 'components/Inbox/ChatSearch';
import VirtualizedChatList from 'components/Inbox/VirtualizedChatList';

const ChatList: React.FC = () => {
    return (
        <>
            <ChatSearch />
            <VirtualizedChatList />
        </>
    );
};
export default ChatList;
