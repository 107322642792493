import { IApiErrorResponse } from 'contracts/requests/IApiErrorResponse';
import { IChangeEmailRequest } from 'contracts/requests/IChangeEmailRequest';
import { IClientTablePreferenceResponse } from 'contracts/requests/IClientTablePreferenceResponse';
import { IGetUserStatsResponse } from 'contracts/requests/IGetUserStatsResponse';
import { IChangePasswordRequest } from 'contracts/requests/IResetPassword';
import { IUpdateCreditRequest } from 'contracts/requests/IUpdateCreditRequest';
import { IUpdateCreditResponse } from 'contracts/requests/IUpdateCreditResponse';
import { IUserPreferenceRequest, IUserPreferenceResponse } from 'contracts/requests/IUserPreferenceRequest';
import { IAllUsersResponse } from 'contracts/requests/IUserProfileResponse';
import { GetTableResponseDataObject } from 'contracts/spotdif/GetTableResponseDataObject';
import { TablePreferenceDataObject } from 'contracts/spotdif/TablePreferenceDataObject';
import { UserBase } from 'contracts/spotdif/UserBase';
import {
    TAG_CLIENT_COLUMN_UPDATE,
    TAG_CLIENT_UPDATE,
    TAG_NON_BILLABLE_USER,
    TAG_SAVE_CLIENT_DETAIL,
    TAG_USER_PREFERENCE,
} from 'contracts/spotDiffAPITags';
import ITableHeaderFilters from 'contracts/view-models/ITableHeaderFilters';
import { spotdifApi } from '.';

const clientApi = spotdifApi.injectEndpoints({
    endpoints: (builder) => ({
        // FIXME: IClientTableResponse is to be used but react-table is not able to work at the moment.
        getAllClients: builder.query<GetTableResponseDataObject, ITableHeaderFilters>({
            providesTags: [TAG_CLIENT_UPDATE, TAG_SAVE_CLIENT_DETAIL, TAG_NON_BILLABLE_USER],
            query: (args) => {
                return {
                    url: `/user${
                        args.clientId
                            ? '/' + args.clientId + '?business=true'
                            : `?search=${args?.search ?? ''}&page=${args?.page ?? ''}&perPage=${
                                args?.perPage ?? ''
                            }&sortingOrder=${args?.sortingOrder ?? ''}&industryId=${args?.industry ?? ''}&${
                                //  JSON.stringify([{id: "createdAt", order: args?.sortingOrder}])
                                args?.leadsStatus === 'archived'
                                    ? 'isArchived=true'
                                    : args?.leadsStatus === 'active'
                                        ? 'isActive=true'
                                        : args?.leadsStatus === 'paused'
                                            ? 'isActive=false'
                                            : ''
                            }&business=${args?.business ? 'true' : ''}&accountManager=${
                                args?.showOnlyAccountManagers ? 'true' : ''
                            }&clientType=${args?.clientType ?? 'all'}&onBoardingPercentage=${
                                args?.onBoarding ?? 'all'
                            }&accountManagerId=${args?.accountManagerId ?? ''}&sortBy=${
                                args?.sortBy
                                    ? JSON.stringify(
                                        args?.sortBy.map((sort) => ({
                                            id: sort.id,
                                            order: sort.desc ? 'desc' : 'asc',
                                        })),
                                    )
                                    : ''
                            }`
                    }`,
                    method: 'get',
                };
            },
            transformResponse: (response: any) => {
                // return Array.isArray(response.data) ? response.data : [response.data] ;
                return {
                    data: Array.isArray(response.data) ? response.data : [response.data],
                    meta: response.meta,
                };
                // response.data.map((user) => UserBaseSerializer.parse(user))
            },
        }),

        getAllClientsDeatils: builder.query<GetTableResponseDataObject, ITableHeaderFilters>({
            providesTags: [TAG_CLIENT_UPDATE, TAG_SAVE_CLIENT_DETAIL, TAG_NON_BILLABLE_USER],
            query: (filters) => {
                const queryParams = new URLSearchParams();
                if (filters) {
                    filters.clientId && queryParams.append('businessDetailId', filters.clientId); /* All Business Key */
                    filters.search && queryParams.append('search', filters.search);
                    filters.page && queryParams.append('page', filters.page.toString());
                    filters.perPage && queryParams.append('perPage', filters.perPage.toString());
                    filters.sortingOrder && queryParams.append('sortingOrder', filters.sortingOrder);
                    filters.industry && queryParams.append('industryId', filters.industry);
                    filters.leadsStatus && queryParams.append('clientStatus', filters.leadsStatus);
                    filters.business && queryParams.append('business', filters.business);
                    filters.clientType && queryParams.append('clientType', filters.clientType);
                    filters.onBoarding && queryParams.append('onBoardingPercentage', filters.onBoarding);
                    filters.accountManagerId && queryParams.append('accountManagerId', filters.accountManagerId);
                }
                const queryString = queryParams.toString();
                return {
                    url: `/user/v2/${queryString ? `?${queryString}` : ''}`,
                    method: 'get',
                };
            },
            transformResponse: (response: any) => {
                return {
                    data: Array.isArray(response.data) ? response.data : [response.data],
                    meta: response.meta,
                };
            },
        }),
        getClientColumns: builder.query<TablePreferenceDataObject, void>({
            providesTags: [TAG_CLIENT_COLUMN_UPDATE],
            query: () => {
                return {
                    url: '/adminSettings/clientColumnsPreference',
                    method: 'get',
                };
            },
            transformResponse: (response: IClientTablePreferenceResponse) => {
                return response?.data;
            },
        }),

        getUserStats: builder.query<IGetUserStatsResponse, void>({
            providesTags: [TAG_CLIENT_COLUMN_UPDATE, TAG_CLIENT_UPDATE],
            query: () => {
                return {
                    url: '/user/stats',
                    method: 'get',
                };
            },
            transformResponse: (response: IGetUserStatsResponse) => {
                return response;
            },
        }),

        saveClientDetails: builder.mutation<UserBase, any>({
            invalidatesTags: [TAG_CLIENT_UPDATE, TAG_SAVE_CLIENT_DETAIL],
            query: (body) => {
                return {
                    url: `/user/${body._id}`,
                    method: 'post',
                    body,
                };
            },
            transformResponse: (response: UserBase) => {
                return response;
            },
        }),

        updateCredit: builder.mutation<IUpdateCreditResponse, IUpdateCreditRequest>({
            invalidatesTags: [TAG_CLIENT_UPDATE, TAG_SAVE_CLIENT_DETAIL],
            query: (body) => {
                return {
                    url: `/user/manual-adjustment`,
                    method: 'post',
                    body,
                };
            },
            transformResponse: (response: IUpdateCreditResponse) => {
                return response;
            },
        }),

        clientPreferenceUpdate: builder.mutation<IUserPreferenceResponse | IApiErrorResponse, IUserPreferenceRequest>({
            invalidatesTags: [TAG_USER_PREFERENCE, TAG_CLIENT_COLUMN_UPDATE],

            query: (body) => {
                return {
                    url: 'adminSettings/clientColumnsPreference',
                    method: 'post',
                    body,
                };
            },
        }),
        exportClients: builder.mutation<any, string | void>({
            query: (filterUrl = '') => {
                return {
                    url: `user/export-csv-file${filterUrl}`,
                    method: 'get',
                };
            },
            transformResponse: (response: IAllUsersResponse) => {
                return response?.data;
            },
        }),

        deleteClient: builder.mutation<UserBase, any>({
            invalidatesTags: [TAG_CLIENT_UPDATE, TAG_SAVE_CLIENT_DETAIL],
            query: (agrs) => {
                return {
                    url: `/user/${agrs}`,
                    method: 'DELETE',
                };
            },
            transformResponse: (response: UserBase) => {
                return response;
            },
        }),

        changeClientPassword: builder.mutation<any, IChangePasswordRequest>({
            query: (body) => {
                return {
                    url: 'auth/changeClientPassword',
                    method: 'post',
                    body,
                };
            },
        }),

        changeClientEmail: builder.mutation<any, IChangeEmailRequest>({
            invalidatesTags: [TAG_CLIENT_UPDATE, TAG_SAVE_CLIENT_DETAIL, TAG_NON_BILLABLE_USER],
            query: (body) => {
                return {
                    url: `user/update-email/${body.id}`,
                    method: 'post',
                    body: {
                        email: body.email.toLowerCase(),
                    },
                };
            },
        }),

        getAllUsersDetails: builder.query<GetTableResponseDataObject, ITableHeaderFilters>({
            query: (filters) => {
                const queryParams = new URLSearchParams();
                if (filters) {
                    filters.search && queryParams.append('search', filters.search);
                    filters.onBoarding && queryParams.append('onBoardingPercentage', filters.onBoarding);
                }
                const queryString = queryParams.toString();
                return {
                    url: `/user/v2/${queryString ? `?${queryString}` : ''}`,
                    method: 'get',
                };
            },
            transformResponse: (response: any) => {
                return {
                    data: Array.isArray(response.data) ? response.data : [response.data],
                    meta: response.meta,
                };
            },
        }),
    }),
});

export const {
    useGetAllClientsQuery,
    useGetAllClientsDeatilsQuery,
    useGetClientColumnsQuery,
    useSaveClientDetailsMutation,
    useClientPreferenceUpdateMutation,
    useExportClientsMutation,
    useDeleteClientMutation,
    useUpdateCreditMutation,
    useGetUserStatsQuery,
    useChangeClientPasswordMutation,
    useChangeClientEmailMutation,
    useLazyGetAllUsersDetailsQuery,
} = clientApi;
